import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Modal } from 'antd';
import { Link } from "react-router-dom";

import Date from '../common/components/date';
import { actions } from './groups.redux';
import NewGroupModal from './new-group.modal';
import './groups.page.css';

const confirm = Modal.confirm;

const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    render: (val, record) => {
      return <Link to={"/groups/" + record.id}>{val}</Link>;
    }
  }, {
    title: 'Creado',
    dataIndex: 'created_at',
    render: (val, record) => {
      return <Date relative>{val}</Date>
    }
  }];

class GroupsPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    modalTitle: 'Crear Grupo',
    modalVisible: false
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  componentDidMount() {
    this.props.fetch(1);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.metadata) {
      this.setState({ pagination: { ...this.state.pagination, total: nextProps.metadata.total, pageSize: nextProps.metadata.limit } })
    }

    if(this.props.saving && !nextProps.saving) {
      if(nextProps.error) {
        alert(nextProps.error);
      }
      else {
        this.refreshTable();
      }
    }

    if(this.props.deleting && !nextProps.deleting) {
      if(nextProps.error) {
        alert(nextProps.error);
      }
      else {
        this.refreshTable();
      }
    }
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.hideModal();
    this.setState({ selectedRowKeys: [] });
    this.props.fetch(this.state.pagination.current||1);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    await this.setState({pagination});
    this.props.fetch(pagination.current);
  }

  getCheckboxProps = (record) => {
    return {
      disabled: false,//record.disabled,
      name: record.name
    }
  }

  showDeleteConfirm = () => {
    var count = this.state.selectedRowKeys.length === 1 ? 'el grupo seleccionado' : `los ${this.state.selectedRowKeys.length} grupos seleccionados`
    confirm({
      title: `¿Estás seguro que deseas eliminar ${count}?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.delete(this.state.selectedRowKeys)
      }
    });
  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps
    };

    const hasSelected = this.state.selectedRowKeys.length > 0;
    return <div className="content-internal">
      { this.canEdit() && <div className="top-buttons">
        <Button type="primary" icon="plus" onClick={this.openCreate}>Nuevo grupo</Button>
        { hasSelected && 
          <Button icon="user-delete" onClick={this.showDeleteConfirm}>Eliminar grupos</Button>
        }
      </div> }
      
      <Table {...this.state} loading={this.props.fetching} size="small" className="striped-rows"
        onChange={this.handleTableChange} pagination={this.state.pagination} 
        rowKey={r => r.id} rowSelection={this.canEdit() ? rowSelection : undefined} columns={columns} 
        dataSource={this.props.groups} />

      <NewGroupModal wrappedComponentRef={this.saveFormRef}
        visible={this.state.modalVisible}
        confirmLoading={this.props.saving}
        onCancel={this.hideModal}
        onCreate={this.handleCreate} />
    </div>
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  openCreate = () => {
    this.setState({ modalVisible: true });
  }

  hideModal = () => {
    this.setState({ modalVisible: false });
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      
      this.props.create(values.name);
    });
  }
}

function mapStateToProps(state) {
  return { ...state.groups, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (page) => dispatch(actions.fetch(page)),
    getAreas: () => dispatch(actions.getAreas()),
    create: (name) => dispatch(actions.create(name)),
    delete: (ids) => dispatch(actions.delete(ids))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
