import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import { Link } from "react-router-dom";

import Date from '../common/components/date';
import { actions } from './formation-paths.redux';
import './formation-paths.page.css';
import NewFormationPathModal from './new-formation-path.modal';

class FormationPathsPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    modalTitle: 'Crear nuevo plan',
    modalVisible: false
  };

  columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      render: (val, record) => {
        return <Link to={"/formation-paths/" + record.id}>{val}</Link>;
      }
    }, {
    title: 'Creado',
    dataIndex: 'created_at',
    render: (val) => {
      return <Date relative>{val}</Date>
    }
  }, {
    title: 'Nº Cursos',
    dataIndex: 'courses_count'
  }];

  componentDidMount() {
    this.props.fetch(1);
  }

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.fetching && !nextProps.fetching) {
      this.setState({ pagination: { ...this.state.pagination, total: nextProps.metadata.total, pageSize: nextProps.metadata.limit } })
    }

    if(this.props.saving && !nextProps.saving) {
      this.hideModal();
      this.props.fetch(this.state.pagination.current||1);
    }
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.props.fetch(pagination.current);
    await this.setState({pagination});
  }

  getCheckboxProps = (record) => {
    return {
      disabled: record.disabled,
      name: record.first_name
    }
  }

  handleOk = (name) => {
    this.props.create(name);
  }

  hideModal = () => {
    this.setState({ modalVisible: false});
  }

  openEdit = () => {

  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps
    };

    const hasSelected = this.state.selectedRowKeys.length > 0;

    return <div className="content-internal">
      { this.canEdit() && <div className="top-buttons">
        <Button type="primary" icon="plus" onClick={() => this.setState({ modalVisible: true})}>Nuevo plan</Button>
        { hasSelected && 
          <Button icon="delete">Eliminar Seleccionados</Button>
        }
      </div> }

      <Table {...this.state} loading={this.props.fetching} 
        onChange={this.handleTableChange} pagination={this.state.pagination} 
        rowKey={u => u.id} rowSelection={this.canEdit() ? rowSelection : undefined} columns={this.columns} 
        dataSource={this.props.formationPaths}  locale={{emptyText: 'No hay planes en el momento'}}/>

      <NewFormationPathModal title={this.state.modalTitle}
          visible={this.state.modalVisible}
          onOk={this.handleOk}
          confirmLoading={this.props.saving}
          onCancel={this.hideModal}>
      </NewFormationPathModal>
    </div>
  }
}

function mapStateToProps(state) {
  return { ...state.formationPaths, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (page) => dispatch(actions.fetch(page)),
    create: (name) => dispatch(actions.create(name)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormationPathsPage);
