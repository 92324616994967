import { createActions, handleActions } from 'redux-actions';
import CoursesService from '../core/services/externalCourses';

//INITIAL STATE
const initialState = { 
  courses: [],
  fetching: false,
  error: null,
  metadata: null,
  details: {
  },
  fetching_report: false,
  error_report: null,
  saving: false,
  saving_error: null,
  assigned_users: []
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

let actionCreators = createActions({
  EXTCOURSES: {
    FETCH: (page) => CoursesService.list(100,100 * (page - 1)), 
    CREATE: (course) => CoursesService.createCourse(course),
    EDIT: (courseId, course) => CoursesService.updateCourse(courseId, course),
    DELETE: (course) => CoursesService.deleteCourse(course),
    GETUSERS: (courseId) => CoursesService.getUsers(courseId), 
    ASSIGNUSERS: (courseId, userIds) => CoursesService.assignUsers(courseId, userIds),
    EDITUSER: (userId, courseId, course) => CoursesService.updateUserCourse(userId, courseId, course),
    DELETEUSER: (courseId, userId) => CoursesService.disableUsers(courseId, [userId]),
    IMPORT: (courseId, data) => CoursesService.import(courseId, data)
  }
});

export const actions = actionCreators.extcourses;

//REDUCER
const reducer = handleActions({
  [`${actions.fetch}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, courses: payload.results, metadata: payload.pagination, error: null, fetching: false };
  },
  [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, fetching: false };
  },
  [`${actions.getusers}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.getusers}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, assigned_users: payload, error: null, fetching: false };
  },
  [`${actions.getusers}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, fetching: false };
  },
  [`${actions.create}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.create}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.create}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.edit}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.edit}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.edit}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.delete}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.delete}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.delete}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.assignusers}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.assignusers}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving_error: null, saving: false };
  },
  [`${actions.assignusers}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.disableusers}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.disableusers}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving_error: null, saving: false };
  },
  [`${actions.disableusers}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.edituser}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.edituser}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.edituser}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.deleteuser}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.deleteuser}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.deleteuser}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.import}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.import}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.import}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
}, initialState);

export default reducer;
