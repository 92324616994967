import BaseService from "./baseService";

export default class InstructorsService extends BaseService {
  static async list(limit, skip) {
    return await super.request(`/instructors?limit=${limit}&skip=${skip}`);
  }

  static async getInstructor(id) {
    return await super.request(`/instructors/${id}`, 'GET');
  }

  static async createInstructor(data, files) {
    const formData = new FormData();
    for (const key in data) if (data[key]) formData.append(key, data[key]);
    for (const key in files) if (files[key]) formData.append(key, files[key]);

    return await super.request(
      '/instructors',
      'POST',
      formData,
      { 'Content-Type': 'multipart/form-data' },
      false,
    );

  }

  static async updateInstructor(instructorId, data, files) {
    const formData = new FormData();
    for (const key in data) if (data[key]) formData.append(key, data[key]);
    for (const key in files) if (files[key]) formData.append(key, files[key]);

    return await super.request(
      `/instructors/${instructorId}`,
      'PUT',
      formData,
      { 'Content-Type': 'multipart/form-data' },
      false,
    );
  }

  static async deleteInstructor(instructorId) {
    return await super.request(`/instructors/${instructorId}`, 'DELETE');
  }

}