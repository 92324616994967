import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Icon,
  Modal,
  Row,
  Col,
  Descriptions,
  Statistic,
  Card,
  List,
} from 'antd';

import { actions } from './communications.redux';
import styles from './communicationdetail.module.css';
import service from '../core/services/communications';
import AddMessageModal from './add-message.modal';
import EditMessageModal from './edit-message.modal';
import StatisticsModal from './statistics.modal';
import moment from 'moment';

const confirm = Modal.confirm;

const isImage = (file) => {
  return /\.(jpg|png|gif|svg|jpeg|webp)(\?.*)?/i.test(file);
};

const isVideo = (file) => {
  return /\.(mpg|mp4|avi|mov|mkv|webm)(\?.*)?/i.test(file);
};

const getFileName = (url) => {
  let display = url;
  if (
    display.startsWith('https://firebasestorage') ||
    display.startsWith('https://storage.googleapis.com')
  ) {
    display = decodeURIComponent(new URL(display).pathname.split('/').pop());
    if (display.lastIndexOf('/') >= 0) {
      display = display.substring(display.lastIndexOf('/') + 1);
    }
  }
  return display;
};

class CommunicationDetailPage extends PureComponent {
  state = {
    communication: null,
    pagination: {},
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  openCreate = () => {
    this.setState({ modalVisible: true });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  openStats = (mid) => {
    this.setState({ modalStatsVisible: true, selectedMessage: mid });
  };

  hideStatsModal = () => {
    this.setState({ modalStatsVisible: false });
  };

  handleCreate = (file) => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.create(
        this.state.communication.id,
        values.body ?? '',
        values.programmed ? values.programmed_date : undefined,
        file
      );
    });
  };

  saveEditFormRef = (formRef) => {
    this.editFormRef = formRef;
  };

  openEdit = (mid) => {
    this.setState({ editModalVisible: true, selectedMessage: mid });
  };

  handleEdit = (file) => {
    const form = this.editFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.update(
        this.state.selectedMessage,
        values.body ?? '',
        values.programmed ? values.programmed_date : undefined,
        file
      );
    });
  };

  hideEditModal = () => {
    this.setState({ editModalVisible: false, selectedMessage: null });
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  async componentDidMount() {
    const commId = this.props.match.params.id;
    let communication = this.props.communications.find(
      (c) => c.id === parseInt(commId, 10)
    );
    if (!communication) {
      communication = await service.getCommunication(commId);
    }

    this.props.layout.changeTitle(communication.name);
    this.setState({ communication });
    this.props.fetchmessages(communication.id, 1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metadata_messages) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          total: nextProps.metadata_messages.total,
          pageSize: nextProps.metadata_messages.limit,
        },
      });
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.error) {
        alert(nextProps.error);
      } else {
        this.hideModal();
        this.hideEditModal();
        this.props.fetchmessages(this.state.communication.id, 1);
      }
    }
  }

  openDelete = async (id) => {
    confirm({
      title: `¿Estás seguro que deseas eliminar el mensaje?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.delete(id);
      },
    });
  };

  renderFile = (item) => {
    if (!item.file_url) return false;
    else {
      if (isImage(item.file_url)) {
        return (
          <div className={styles.file}>
            <img width={272} alt='imagen adjunta' src={item.file_url} />
          </div>
        );
      } else if (isVideo(item.file_url)) {
        return (
          <div className={styles.file}>
            <video src={item.file_url} controls alt='video adjunto' />
          </div>
        );
      } else {
        return (
          <div className={styles.attachment}>
            <a
              href={item.file_url}
              target='_blank'
              rel='noopener noreferrer'
              download={getFileName(item.file_url)}
            >
              <Icon type='file' /> {getFileName(item.file_url)}
            </a>
          </div>
        );
      }
    }
  };

  render() {
    const { messages } = this.props;
    if (!this.state.communication) return <div />;

    return (
      <div>
        <Row gutter={10}>
          <Col span={6}>{this.renderAudienceInfo()}</Col>
          <Col span={18}>
            <div className='content-internal'>
              <div className='top-buttons' style={{ float: 'right' }}>
                <Button type='primary' icon='plus' onClick={this.openCreate}>
                  Nuevo mensaje
                </Button>
              </div>
              <h2>Mensajes</h2>
              <List
                itemLayout='vertical'
                size='large'
                pagination={false}
                dataSource={messages}
                renderItem={(item) => (
                  <List.Item
                    key={item.title}
                    actions={
                      item.status === 'programmed'
                        ? [
                            <span>
                              <Icon
                                type='clock-circle'
                                style={{ marginRight: 8 }}
                              />{' '}
                              Programado:{' '}
                              {moment(item.programmed_date).format(
                                'DD/MM/YYYY hh:mm a'
                              )}
                            </span>,
                            <span
                              style={{ color: '#EDB95E' }}
                              onClick={(e) => this.openEdit(item.id)}
                            >
                              <Icon type='edit' style={{ marginRight: 8 }} />{' '}
                              Editar
                            </span>,
                            <span
                              style={{ color: '#E23636' }}
                              onClick={(e) => this.openDelete(item.id)}
                            >
                              <Icon type='delete' style={{ marginRight: 8 }} />{' '}
                              Eliminar
                            </span>,
                          ]
                        : [
                            <span
                              style={{ color: '#82DD55' }}
                              onClick={(e) => this.openStats(item.id)}
                            >
                              <Icon
                                type='check-circle'
                                style={{ marginRight: 8 }}
                              />{' '}
                              {item.sent} enviados
                            </span>,
                            <span
                              style={{ color: '#E23636' }}
                              onClick={(e) => this.openStats(item.id)}
                            >
                              <Icon
                                type='close-circle'
                                style={{ marginRight: 8 }}
                              />{' '}
                              {item.problems} problemas
                            </span>,
                            <span
                              style={{ color: '#AAA' }}
                              onClick={(e) => this.openStats(item.id)}
                            >
                              <Icon
                                type='exclamation-circle'
                                style={{ marginRight: 8 }}
                              />{' '}
                              {item.others} pendientes
                            </span>,
                          ]
                    }
                    extra={this.renderFile(item)}
                  >
                    <List.Item.Meta
                      title={moment(item.created_at).format(
                        'DD/MM/YYYY hh:mm a'
                      )}
                    />
                    {item.body}
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
        <AddMessageModal
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.modalVisible}
          onCancel={this.hideModal}
          onCreate={this.handleCreate}
          confirmLoading={this.props.saving}
        />
        <EditMessageModal
          wrappedComponentRef={this.saveEditFormRef}
          visible={this.state.editModalVisible}
          onCancel={this.hideEditModal}
          onCreate={this.handleEdit}
          confirmLoading={this.props.saving}
          message={messages.find((m) => m.id === this.state.selectedMessage)}
        />
        <StatisticsModal
          visible={this.state.modalStatsVisible}
          onCancel={this.hideStatsModal}
          communicationId={this.state.communication.id}
          messageId={this.state.selectedMessage}
          confirmLoading={this.props.saving}
        />
      </div>
    );
  }

  renderAudienceInfo = () => {
    const { communication } = this.state;
    return (
      <div
        className='content-internal'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <h2>Audiencia</h2>
        <Card size='small'>
          <Statistic
            title='Usuarios actuales'
            value={communication.total_audience}
          />
        </Card>
        <div style={{ marginTop: 20 }}>
          <Descriptions column={1}>
            <Descriptions.Item label='Áreas'>
              {(communication.areas ?? []).length > 0
                ? communication.areas.map((a) => a.name).join(', ')
                : 'Todas'}
            </Descriptions.Item>
            <Descriptions.Item label='Cargos'>
              {(communication.positions ?? []).length > 0
                ? communication.positions.map((p) => p.name).join(', ')
                : 'Todos'}
            </Descriptions.Item>
            <Descriptions.Item label='Ubicaciones'>
              {(communication.locations ?? []).length > 0
                ? communication.locations.map((l) => l.location).join(', ')
                : 'Todas'}
            </Descriptions.Item>
            <Descriptions.Item label='Centros de costo'>
              {(communication.extended_field1_values ?? []).length > 0
                ? communication.extended_field1_values
                    .map((l) => l.extended_field1_value)
                    .join(', ')
                : 'Todos'}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return { ...state.communications, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchmessages: (commId, page) =>
      dispatch(actions.fetchmessages(commId, page)),
    create: (commId, body, programmed_date, attachment) =>
      dispatch(
        actions.createmessage(commId, body, programmed_date, attachment)
      ),
    update: (id, body, programmed_date, attachment) =>
      dispatch(actions.updatemessage(id, body, programmed_date, attachment)),
    delete: (id) => dispatch(actions.deletemessage(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationDetailPage);
