import { message, Modal, Input, Table } from "antd";
import CoursesService from "../../core/services/courses";
import React, { useEffect, useState } from "react";

const PAGE_SIZE = 10;

export const AddCourseModal = ({ visible, onCancel, onOk, confirmLoading, initialSelection }) => {

  const [courses, setCourses] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [query, setQuery] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState(initialSelection);

  useEffect(() => {
    if (visible) {
      setQuery(null);
      setPage(1);
      setSelectedRowKeys(initialSelection);
    }
  }, [visible, initialSelection]);

  useEffect(() => {
    const queryCourses = async () => {
      try {
        setFetching(true);
        var resp = await CoursesService.list(PAGE_SIZE, PAGE_SIZE * (page - 1), query);
        setCourses(resp.results);
        setTotal(resp.pagination.total);
        //pageSize: resp.pagination.limit 
      }
      catch (ex) {
        message.error('Error al consultar los cursos');
      }
      finally {
        setFetching(false);
      }
    };

    queryCourses();
  }, [query, page]);

  const doSearch = (value) => {
    setQuery(value);
    setPage(1);
  }

  const getCheckboxProps = (record) => {
    return {
      disabled: false,
      name: record.name
    }
  }

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps
  };

  const pagination = { current: page, pageSize: PAGE_SIZE, total };

  return <Modal title="Asignar cursos"
    visible={visible}
    onOk={() => onOk(selectedRowKeys.filter(k => !initialSelection.includes(k)))}
    confirmLoading={confirmLoading}
    destroyOnClose={true}
    onCancel={onCancel}
    width="60vw"
    okText="Guardar"
    cancelText="Cancelar">
    <Input.Search
      placeholder="Buscar..."
      onSearch={doSearch}
      style={{ width: 400, marginBottom: '5px' }}
    />
    <Table loading={fetching} onChange={handleTableChange}
      rowKey={u => u.id} columns={[
        {
          title: 'Curso',
          dataIndex: 'name'
        },
        {
          title: 'Descripción',
          dataIndex: 'description'
        }
      ]} rowSelection={rowSelection} pagination={pagination}
      dataSource={courses} locale={{ emptyText: 'No hay cursos para asignar' }} />

  </Modal>

};