import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

const AuthenticatedRoute = ({ component: Component, render, ...rest }) => {
  const hasPermission = rest.loggedIn && (!rest.superadmin || rest.user.superadmin);
  const redirectRoute = rest.superadmin ? '/' : '/login';
  
  return <Route
    {...rest}
    render={props =>
      hasPermission ? (
        render(props)
      ) : (
        <Redirect
          to={{
            pathname: redirectRoute,
            state: { from: props.location }
          }}
        />
      )
    }
  />;
}

function mapStateToProps(state) {
  return state.auth;
}

export default connect(mapStateToProps)(AuthenticatedRoute);
