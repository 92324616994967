import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, DatePicker, Select, Collapse, Icon, Menu, Dropdown, notification } from 'antd';

import * as moment from 'moment';

import * as styles from './dashboardpage.module.css';
import Assistance from './assistance';
import Approval from './approval';
import Cost from './cost';
import Impact from './impact';
import HoursByType from './hours-by-type';
import Detailed from './detailed';
import FiltersService from '../core/services/filters';
import ExternalCoursesService from '../core/services/externalCourses';
import InstructorsService from '../core/services/instructors';

const historyTypes = {
  'attendance': 'Impacto',
  'hours' : 'Asistencia',
  'approval' : 'Aprobación',
  'cost' : 'Costo'
};

const historyGroups = {
  'month': 'Mes',
  'category' : 'Escuela',
  'cost_center' : 'Centro de costos',
  'instructor' : 'Formador'
};
class DashboardPage extends Component {
  state = {
    costCenters: [],
    loadingCostCenters: false,
    categories: [],
    loadingCategories: false,
    competencies: [],
    loadingCompetencies: false,
    courses: [],
    loadingCourses: false,
    instructors: [],
    loadingInstructors: false,
    selectedFilters: {},
    historyType: 'attendance',
    historyGroup: 'month'
  };

  loadCostCenters = async () => {
    try {
      this.setState({ loadingCostCenters: true });
      let costCenters = (await FiltersService.listValues(1, 200, 0)).results;
      this.setState({ costCenters });
    }
    catch (ex) {
      notification.error({ message: 'Ocurrió un error', description: ex.message });
    }
    this.setState({ loadingCostCenters: false });
  }

  loadCategories = async () => {
    try {
      this.setState({ loadingCategories: true });
      let categories = await ExternalCoursesService.getCategories();
      this.setState({ categories });
    }
    catch (ex) {
      notification.error({ message: 'Ocurrió un error', description: ex.message });
    }
    this.setState({ loadingCategories: false });
  }

  loadCompetencies = async () => {
    try {
      this.setState({ loadingCompetencies: true });
      let competencies = await ExternalCoursesService.getCompetencies();
      this.setState({ competencies });
    }
    catch (ex) {
      notification.error({ message: 'Ocurrió un error', description: ex.message });
    }
    this.setState({ loadingCompetencies: false });
  }

  loadCourses = async () => {
    try {
      this.setState({ loadingCourses: true });
      let res = await ExternalCoursesService.list(1000, 0);
      this.setState({ courses: res.results });
    }
    catch (ex) {
      notification.error({ message: 'Ocurrió un error', description: ex.message });
    }
    this.setState({ loadingCourses: false });
  }

  loadInstructors = async () => {
    try {
      this.setState({ loadingInstructors: true });
      let instructors = (await InstructorsService.list(200, 0)).results;
      this.setState({ instructors });
    }
    catch (ex) {
      notification.error({ message: 'Ocurrió un error', description: ex.message });
    }
    this.setState({ loadingInstructors: false });
  }

  async componentDidMount() {
    await this.loadCostCenters();
    await this.loadCategories();
    await this.loadCompetencies();
    await this.loadCourses();
    await this.loadInstructors();
  }

  disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  setFilter = (name, values) => {
    let val = Array.isArray(values) ? values.filter(v => v !== '').join(',') : values;
    this.setState({ selectedFilters: { ...this.state.selectedFilters, [name]: val||undefined }});
  }

  changeHistoryType = (type) => {
    this.setState({ historyType: type });
  }

  changeHistoryGroup = (group) => {
    this.setState({ historyGroup: group });
  }

  renderHeader = () => {
    return (
      <div className={styles.header}>
        <h1>Indicadores</h1>
        <Collapse bordered={false} expandIconPosition="right" className={styles.collapse} expandIcon={(props) => props.isActive ? <Icon type="caret-up" /> : <Icon type="caret-down" />}>
          <Collapse.Panel header={<>
            <div className={styles.topLine}>
              <div className={styles.filter} style={{ marginTop: 0 }} onClick={e => e.stopPropagation()}>
                <span className={styles.filterTitle}>Rango de fechas</span>
                <DatePicker.RangePicker separator="-" disabledDate={this.disabledDate} suffixIcon={<></>} allowClear placeholder={['desde', 'hasta']} onChange={(d, ds) => this.setFilter('dt', ds)} />
              </div>
              <span className={styles.moreFiltersLabel}>Mas filtros</span>
            </div>
          </>}>

            <div className={styles.filter}>
              <span className={styles.filterTitle}>C. de costos</span>
              <Select placeholder="Todos" loading={this.state.loadingCostCenters} mode="multiple" dropdownClassName={styles.dd} maxTagCount={0} onChange={(vals) => this.setFilter('cc', vals)}>
                {this.state.costCenters.map(c => <Select.Option key={c.id} value={c.id} title={c.value}>{c.value}</Select.Option>)}
              </Select>
            </div>
            <div className={styles.filter}>
              <span className={styles.filterTitle}>Competencia</span>
              <Select placeholder="Todas" loading={this.state.loadingCompetencies} mode="multiple" dropdownClassName={styles.dd} maxTagCount={0} onChange={(vals) => this.setFilter('comp', vals)}>
                {this.state.competencies.map(c => <Select.Option key={c.name} value={c.name} title={c.name}>{c.name}</Select.Option>)}
              </Select>
            </div>
            <div className={styles.filter}>
              <span className={styles.filterTitle}>Escuela</span>
              <Select placeholder="Todas" loading={this.state.loadingCategories} mode="multiple" dropdownClassName={styles.dd} maxTagCount={0} onChange={(vals) => this.setFilter('esc', vals)}>
                {this.state.categories.map(c => <Select.Option key={c.name} value={c.name} title={c.name}>{c.name}</Select.Option>)}
              </Select>
            </div>
            <div className={styles.filter}>
              <span className={styles.filterTitle}>Curso</span>
              <Select placeholder="Todas" loading={this.state.loadingCourses} mode="multiple" dropdownClassName={styles.dd} maxTagCount={0} onChange={(vals) => this.setFilter('cour', vals)}>
                {this.state.courses.map(c => <Select.Option key={c.id} value={c.id} title={c.name}>{c.name}</Select.Option>)}
              </Select>
            </div>
            <div className={styles.filter}>
              <span className={styles.filterTitle}>Formador</span>
              <Select placeholder="Todos" loading={this.state.loadingInstructors} mode="multiple" dropdownClassName={styles.dd} maxTagCount={0} onChange={(vals) => this.setFilter('form', vals)}>
              {this.state.instructors.map(c => <Select.Option key={c.id} value={c.id} title={c.name}>{c.name}</Select.Option>)}
              </Select>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }

  render() {
    const { selectedFilters: filters, historyGroup, historyType } = this.state;
    return <div>
      <div className="top-buttons">
        {this.renderHeader()}
      </div>
      <Row gutter={20}>
        <Col span={8}>
          <div className={styles.indicator}>
            <h2>Horas de asistencia</h2>
            <p>Horas asistidas vs horas programadas</p>
            <div className={styles.indicatorBody}>
              <Assistance filters={filters} />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.indicator}>
            <h2>Porcentaje de aprobación</h2>
            <p>Personas que aprobaron vs el total de formados</p>
            <div className={styles.indicatorBody}>
              <Approval filters={filters} />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.indicator}>
            <h2>Impacto</h2>
            <p>Personas formadas vs total de matriculados</p>
            <div className={styles.indicatorBody}>
              <Impact filters={filters} />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={8}>
          <div className={styles.indicator}>
            <h2>Costo</h2>
            <p>Costo promedio teórico (en millones de pesos)</p>
            <div className={styles.indicatorBody}>
              <Cost filters={filters} />
            </div>
          </div>
          <div className={styles.indicator}>
            <h2>Horas por tipo</h2>
            <p>Horas programadas por tipo de formación</p>
            <div className={styles.indicatorBody}>
              <HoursByType filters={filters} />
            </div>
          </div>
        </Col>
        <Col span={16}>
          <div className={styles.indicator}>
            <h2><Dropdown
              overlay={<Menu>
                {Object.keys(historyTypes)
                  .filter(k => k !== historyType)
                  .map(k => (
                    <Menu.Item key={k} onClick={() => this.changeHistoryType(k)}>{historyTypes[k]}</Menu.Item>
                    ))
                }
              </Menu>}>
              <a href="/#"  onClick={e => e.preventDefault()}>
                {historyTypes[historyType]} <Icon type="down" />
              </a>
            </Dropdown> por <Dropdown
              overlay={<Menu>
                {Object.keys(historyGroups)
                  .filter(k => k !== historyGroup)
                  .map(k => (
                    <Menu.Item key={k} onClick={() => this.changeHistoryGroup(k)}>{historyGroups[k]}</Menu.Item>
                    ))
                }
              </Menu>}>
                <a href="/#" onClick={e => e.preventDefault()}>
                  {historyGroups[historyGroup]} <Icon type="down" />
                </a>
              </Dropdown></h2>
            <p>Seleccione el indicador deseado y la variable por la que desea agrupar</p>
            <div className={styles.indicatorBody}>
              <Detailed filters={filters} groupBy={historyGroup} type={historyType} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  }

}

function mapStateToProps(state) {
  return { ...state.dashboard };
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
