import React, { Component } from 'react';
import commonStyles from './common.module.css';
import styles from './video.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class VideoTemplate extends Component {
  render() {
    const { element, theme, slug, section } = this.props;
    const { title, extended_props } = element;
    const { video } = extended_props || {};

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer}`}
        style={containerStyles}
      >
        <div className={commonStyles.templateTitle} style={textStyles}>
          {stylizeText(title, controlStyles.backgroundColor)}
        </div>
        <div className={`${commonStyles.templateBody} ${styles.body}`}>
          {!!video && (
            <iframe
              title='youtube video'
              width='100%'
              height='315'
              src={`https://www.youtube.com/embed/${video}`}
              frameborder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            ></iframe>
          )}
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${index + 1}. Video`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.video',
        display: 'Id. del video',
        value: extProps.video,
        type: 'text',
      },
      {
        name: 'extended_props.textAlignment',
        display: 'Alineación Texto',
        value: extProps.textAlignment,
        type: 'select',
        values: [
          { title: 'Centrado', value: 'center' },
          { title: 'Izquierda', value: 'left' },
          { title: 'Derecha', value: 'right' },
        ],
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
    ];
  };
}

export default VideoTemplate;
