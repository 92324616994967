import React, { PureComponent } from 'react';
import { Modal, Form, Input, Col, Row, Upload, Icon, Select, Switch, InputNumber } from 'antd';
import slugify from 'slugify';
import InstructorsService from '../../core/services/instructors';

import styles from './new-course-modal.module.css';
import { getBase64 } from '../course-design/utils';
import CoursesService from '../../core/services/courses';
import AdminService from '../../core/services/admin';

const FormItem = Form.Item;

class NewCourseModal extends PureComponent {
  state = {
    detailImageUrl: null,
    galleryImageUrl: null,
    instructorLogoUrl: null,
    slugDirty: false,
    instructors: [],
    tenants: [],
    categories: [],
    files: {}
  };

  async componentDidMount() {
    if (this.props.isSA) await this.queryTenants();
    await this.queryIntructors();
    await this.queryCategories();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible) {
      if (this.props.isSA) await this.queryTenants();
      await this.queryIntructors();
      await this.queryCategories();
    }
  }

  generateSlug = (e) => {
    const { value } = e.target;
    if (!this.state.slugDirty) this.props.form.setFieldsValue({ slug: slugify(value, { lower: true }) });
  }

  queryTenants = async () => {
    const { results: tenants } = await AdminService.getCompanies();
    this.setState({ tenants });
  }

  queryCategories = async () => {
    const categories = await CoursesService.getCourseCategories();
    this.setState({ categories });
  }

  queryIntructors = async (value) => {
    if (this.props.isSA) {
      const { form } = this.props;
      const tenantId = value ?? form.getFieldValue('owner_tenant_id');
      if (!tenantId) return;
      const instructors = await AdminService.getInstructors(tenantId, 1000, 0);
      this.setState({ instructors: instructors.results });
    }
    else {
      const instructors = await InstructorsService.list(1000, 0);
      this.setState({ instructors: instructors.results });
    }
  }

  onTenantChange = async (value) => {
    await this.queryIntructors(value);
  }

  onCancel = (e) => {
    const { onCancel } = this.props;
    this.setState({
      detailImageUrl: null,
      galleryImageUrl: null,
      slugDirty: false
    });
    if (onCancel) onCancel(e);
  }

  onOk = () => {
    this.props.onCreate(this.state.files);
  }

  render() {
    const { visible, form, confirmLoading } = this.props;
    const { getFieldDecorator } = form;
    const { galleryImageUrl, detailImageUrl } = this.state;

    return <Modal title="Nuevo curso"
      visible={visible}
      layout="vertical"
      onOk={this.onOk}
      confirmLoading={confirmLoading}
      destroyOnClose
      onCancel={this.onCancel}
      width="80vw"
      okText="Crear"
      cancelText="Cancelar">
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label="Nombre">
              {getFieldDecorator('name', {
                rules: [{
                  required: true, message: 'Debes especificar el nombre del grupo',
                }],
              })(
                <Input onChange={this.generateSlug} />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Slug">
              {getFieldDecorator('slug', {
                rules: [{
                  required: true, message: 'Debes especificar el slug',
                }],
              })(
                <Input onChange={() => this.setState({ slugDirty: true })} />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label="Descripción">
              {getFieldDecorator('description', {
                rules: [{
                }],
              })(
                <Input.TextArea rows={4} />
              )}
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              label="Miniatura">
              {getFieldDecorator('gallery_image', {
                valuePropName: 'fileList',
                rules: [{
                  required: true, message: 'Debes especificar la imagen miniatura'
                }],
              })(
                <Upload
                  name="gallery_image"
                  listType="picture-card"
                  className={styles.uploader}
                  showUploadList={false}
                  beforeUpload={(file) => this.beforeUpload(file, 'galleryImageUrl', 'gallery_image')}
                >
                  {galleryImageUrl ? <img src={galleryImageUrl} alt="Imagen miniatura" style={{ width: '100%', objectFit: 'cover' }} /> :
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">Cargar</div>
                    </div>}
                </Upload>
              )}
            </FormItem>
          </Col>
          <Col span={8}>

            <FormItem
              label="Portada">
              {getFieldDecorator('detail_image', {
                valuePropName: 'fileList',
                rules: [{
                  required: true, message: 'Debes especificar la imagen de portada',
                }],
              })(
                <Upload
                  name="detail_image"
                  listType="picture-card"
                  className={styles.uploader}
                  showUploadList={false}
                  beforeUpload={(file) => this.beforeUpload(file, 'detailImageUrl', 'detail_image')}
                >
                  {detailImageUrl ? <img src={detailImageUrl} alt="Imagen de portada" style={{ width: '100%', objectFit: 'cover' }} /> :
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">Cargar</div>
                    </div>}
                </Upload>
              )}
            </FormItem>
          </Col>
        </Row>
        {this.props.isSA && <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label="Compañía dueña">
              {getFieldDecorator('owner_tenant_id', {
              })(
                <Select placeholder="Seleccione" onChange={this.onTenantChange}
                // dropdownRender={menu => (
                //   <div>
                //     {menu}
                //     <Divider style={{ margin: '4px 0' }} />
                //     <div
                //       style={{ padding: '4px 8px', cursor: 'pointer' }}
                //       onMouseDown={e => e.preventDefault()}
                //       onClick={this.onNewInstructor}
                //     >
                //       <Icon type="plus" /> Nuevo instructor
                //     </div>
                //   </div>
                // )}
                >
                  {(this.state.tenants ?? []).map(c => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Asignar a:">
              {getFieldDecorator('other_tenants', {
                initialValue: 1,
              })(
                <Select placeholder="Seleccione" mode='multiple'
                // dropdownRender={menu => (
                //   <div>
                //     {menu}
                //     <Divider style={{ margin: '4px 0' }} />
                //     <div
                //       style={{ padding: '4px 8px', cursor: 'pointer' }}
                //       onMouseDown={e => e.preventDefault()}
                //       onClick={this.onNewInstructor}
                //     >
                //       <Icon type="plus" /> Nuevo instructor
                //     </div>
                //   </div>
                // )}
                >
                  {(this.state.tenants ?? []).filter(t => t.id !== form.getFieldValue('owner_tenant_id')).map(c => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>}
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label="Experto temático">
              {getFieldDecorator('instructor_id', {
              })(
                <Select placeholder="Seleccione"
                // dropdownRender={menu => (
                //   <div>
                //     {menu}
                //     <Divider style={{ margin: '4px 0' }} />
                //     <div
                //       style={{ padding: '4px 8px', cursor: 'pointer' }}
                //       onMouseDown={e => e.preventDefault()}
                //       onClick={this.onNewInstructor}
                //     >
                //       <Icon type="plus" /> Nuevo instructor
                //     </div>
                //   </div>
                // )}
                >
                  {(this.state.instructors ?? []).map(c => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Categoría">
              {getFieldDecorator('category_id', {
              })(
                <Select placeholder="Seleccione"
                // dropdownRender={menu => (
                //   <div>
                //     {menu}
                //     <Divider style={{ margin: '4px 0' }} />
                //     <div
                //       style={{ padding: '4px 8px', cursor: 'pointer' }}
                //       onMouseDown={e => e.preventDefault()}
                //       onClick={this.onNewInstructor}
                //     >
                //       <Icon type="plus" /> Nuevo instructor
                //     </div>
                //   </div>
                // )}
                >
                  {(this.state.categories ?? []).map(c => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label="Duración (en horas)">
              {getFieldDecorator('duration_hours', {
                rules: []
              })(
                <Input type="number" />
              )}
            </FormItem>
          </Col>
          <Col span={5}>
            <FormItem
              label="Mostrar chat con experto">
              {getFieldDecorator('show_instructor_chat', {
                valuePropName: 'checked',
                rules: [],
              })(
                <Switch />
              )}
            </FormItem>
          </Col>
          <Col span={7}>
            <FormItem
              label="Link para chat grupal">
              {getFieldDecorator('group_chat_link', {
              })(
                <Input />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label="Validez en años (dejar vacío si no vence)">
              {getFieldDecorator('validity_years', {
                valuePropName: 'checked',
                rules: [],
              })(
                <InputNumber />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Notificación previo a expiración (en días)">
              {getFieldDecorator('days_before_expiration', {
              })(
                <InputNumber />
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  }

  beforeUpload = (file, propName, fieldName) => {
    // Get this url from response in real world.
    getBase64(file, imageUrl =>
      this.setState({
        [propName]: imageUrl,
        files: { ...this.state.files, [fieldName]: file }
      })
    );

    return false;
  }
}

export default Form.create()(NewCourseModal);
