const SERVER_BASE_URL = '';//process.env.NODE_ENV === 'development' ? 'http://localhost:3030' : '';
const API_BASE_URL = `${SERVER_BASE_URL}/api`;

const COMMON_HEADERS = {
  'User-Agent': 'Intrena/4.0 Admin',
  'Content-Type': 'application/json'
}

export default class BaseService {
  static async request(path, method = 'GET', body = null, headers = {}, serializeBody = true) {
    const user = JSON.parse(localStorage.getItem('user'));
    const allHeaders = { ...COMMON_HEADERS, ...headers };
    
    if(user && user.token) {
      allHeaders.Authorization = `Bearer ${user.token}`;
    }
    
    if((allHeaders['Content-Type']||'').toLowerCase() === 'multipart/form-data') delete allHeaders['Content-Type']; 
    var response = await fetch(`${API_BASE_URL}${path}`, {
      method,
      body: body ? (serializeBody ? JSON.stringify(body) : body) : undefined,
      headers: allHeaders
    });

    var result = null;

    var ctHeaders = response.headers.get('content-type');
    if(ctHeaders && ctHeaders.indexOf('application/json') >= 0) result = await response.json();
    else result = await response.text();

    if(!response.ok) {
      const err = new Error(result.message||'Ocurrió un error desconocido');
      err.data = result;
      throw err;
    }

    return result;
  }

  static async download(path, method = 'GET', body = null, headers = {}, serializeBody = true) {
    const user = JSON.parse(localStorage.getItem('user'));
    const allHeaders = { ...COMMON_HEADERS, ...headers };
    
    if(user && user.token) {
      allHeaders.Authorization = `Bearer ${user.token}`;
    }
    
    var response = await fetch(`${API_BASE_URL}${path}`, {
      method,
      body: body ? (serializeBody ? JSON.stringify(body) : body) : undefined,
      headers: allHeaders
    });

    let result;

    if(!response.ok) {
      result = await response.json();
      const err = new Error(result.message||'Ocurrió un error desconocido');
      err.data = result
      throw err;
    }
    else {
      result = await response.blob();
    }

    return result;
  }
}