import React, { PureComponent } from 'react';
import { Modal, Form, Input, Select } from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class EditPositionModal extends PureComponent {

  state = {
    filters: 0
  };

  render() {
    const { visible, onCancel, onCreate, form, position, confirmLoading, paths_error, paths_fetching, paths } = this.props;
      const { getFieldDecorator } = form;
    if(!position) return <div/>;
    return <Modal title="Editar cargo"
      visible={visible}
      onOk={onCreate}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText="Guardar"
      cancelText="Cancelar">
      <Form>
      <FormItem
          {...formItemLayout}
          label="Código">
          {getFieldDecorator('code', {
            initialValue: position.code,
            rules: [{
              required: true, message: 'Debes especificar el código del cargo',
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Nombre">
          {getFieldDecorator('name', {
            initialValue: position.name,
            rules: [{
              required: true, message: 'Debes especificar el nombre del cargo',
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Plan de formación">
          {getFieldDecorator('formation_path_id', {
            initialValue: position.formation_path_id,
            rules: [],
          })(
            <Select notFoundContent="No hay resultados" disabled={paths_error || paths_fetching}>
              { paths_error ? 
                <Select.Option value="">Error al cargar los planes de formación</Select.Option> :
                paths_fetching ?
                <Select.Option value="">Cargando ...</Select.Option> :
                (paths||[]).map((a) => <Select.Option key={a.id} value={a.id}>{a.name}</Select.Option>)
              }
            </Select>
          )}
        </FormItem>
      </Form>
    </Modal>
  } 
}

export default Form.create()(EditPositionModal);
