import { createStore, applyMiddleware, combineReducers } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import promiseMiddleware from 'redux-promise-middleware';
import { createBrowserHistory } from 'history';
import reduxThunk from 'redux-thunk';

import authReducer from '../../auth/auth.redux';
import usersReducer from '../../users/users.redux';
import groupsReducer from '../../groups/groups.redux';
import formationPathsReducer from '../../formation-paths/formation-paths.redux';
import coursesReducer from '../../courses/courses.redux';
import externalCoursesReducer from '../../external-courses/external-courses.redux';
import areasReducer from '../../areas/areas.redux';
import positionsReducer from '../../positions/positions.redux';
import adminReducer from '../../admin/admin.redux';
import reportsReducer from '../../reports/reports.redux';
import instructorsReducer from '../../instructors/instructors.redux';
import organizationalUnitsReducer from '../../organizational-units/organizational-units.redux';
import communicationsReducer from '../../communications/communications.redux';
import certificationsReducer from '../../certifications/certifications.redux';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  groups: groupsReducer,
  formationPaths: formationPathsReducer,
  courses: coursesReducer,
  externalCourses: externalCoursesReducer,
  areas: areasReducer,
  positions: positionsReducer,
  routing: routerReducer,
  admin: adminReducer,
  reports: reportsReducer,
  instructors: instructorsReducer,
  organizationalUnits: organizationalUnitsReducer,
  communications: communicationsReducer,
  certifications: certificationsReducer,
});

const middlewares = [
  routerMiddleware(history),
  promiseMiddleware(),
  reduxThunk,
];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

export default () => {
  const store = createStore(rootReducer, applyMiddleware(...middlewares));
  return { store };
};
