import React, { PureComponent } from 'react';
import { Modal, Form, Input, Select, Switch, Tooltip, Icon } from 'antd';
import CountryPhoneInput from 'antd-country-phone-input';
import usersService from '../core/services/users';
import OrganizationalUnitsService from '../core/services/organizationalUnits';
import DebounceSelect from '../main/components/debounceSelect';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

async function fetchOrganizationalUnits(query) {
  const result = await OrganizationalUnitsService.list(query, 20, 0);
  return result.results.map((r) => ({
    label: r.name,
    value: r.id,
    key: r.id,
  }));
}

class NewUserModal extends PureComponent {
  state = {
    submitting: false,
    users: [],
  };

  validateUnique = async (rule, value, callback) => {
    if (!value) callback();
    if (
      !value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      callback(); //Si no es un email válido no verificar si existe
    } else {
      var result = await usersService.emailExists(value);
      if (!result) callback();
      else callback('El correo electrónico ya existe');
    }
  };

  validateMobile = async (rule, value, callback) => {
    if (!value || !value.phone) callback();
    if (!value.phone.match(/^[\s0-9]+$/)) {
      callback('Ingresa un número de celular válido (solo números)');
    } else {
      const mobileValue = `+${value.code} ${(value.phone || '')
        .trim()
        .replace(/\s/g, '')}`;
      var result = await usersService.mobileExists(
        encodeURIComponent(mobileValue)
      );
      if (!result) callback();
      else callback('Este número de celular ya está asociado a otro usuario');
    }
  };

  handleSearch = async (value) => {
    try {
      var response = await usersService.search(value);
      this.setState({ users: (response || {}).results || [] });
    } catch (ex) {
      console.error(ex);
    }
  };

  onCreateInternal = () => {
    if (this.state.submitting) return;
    this.setState({ submitting: true }, () => {
      const { form } = this.props;

      form.validateFields((err, values) => {
        if (err) {
          this.setState({ submitting: false });
          return;
        }

        this.props.onCreate();
      });
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.confirmLoading && !this.props.confirmLoading) {
      this.setState({
        submitting: false
      });
    }
  }

  render() {
    const { submitting } = this.state;
    const {
      visible,
      onCancel,
      form,
      confirmLoading,
      customFields,
      areas,
      areas_fetching,
      areas_error,
      positions,
      positions_fetching,
      positions_error,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        title='Crear nuevo usuario'
        visible={visible}
        onOk={this.onCreateInternal}
        confirmLoading={submitting || confirmLoading}
        onCancel={!submitting ? onCancel : undefined}
        okText='Crear'
        cancelText='Cancelar'
      >
        <Form>
          <FormItem {...formItemLayout} label='Correo electrónico'>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'Debes especificar un correo válido',
                },
                {
                  required: true,
                  message: 'Debes especificar el correo electrónico',
                },
                {
                  validator: this.validateUnique,
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Nombres'>
            {getFieldDecorator('first_name', {
              rules: [
                {
                  required: true,
                  message: 'Debes especificar el nombre',
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Apellidos'>
            {getFieldDecorator('last_name', {
              rules: [],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Celular'>
            {getFieldDecorator('mobile', {
              initialValue: { short: 'CO' },
              rules: [
                {
                  validator: this.validateMobile,
                },
              ],
            })(<CountryPhoneInput inline type='tel' />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Área'>
            {getFieldDecorator('area_id', {
              rules: [],
            })(
              <Select
                showSearch
                placeholder='Seleccione el área'
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent='No hay resultados'
                onSearch={this.handleSearch}
              >
                {areas_error ? (
                  <Select.Option value=''>
                    Error al cargar las areas
                  </Select.Option>
                ) : areas_fetching ? (
                  <Select.Option value=''>Cargando ...</Select.Option>
                ) : (
                  (areas || []).map((a) => (
                    <Select.Option key={a.id} value={a.id}>
                      {a.name}
                    </Select.Option>
                  ))
                )}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Cargo'>
            {getFieldDecorator('position_id', {
              rules: [],
            })(
              <Select
                notFoundContent='No hay resultados'
                disabled={positions_error || positions_fetching}
              >
                {positions_error ? (
                  <Select.Option value=''>
                    Error al cargar los cargos
                  </Select.Option>
                ) : positions_fetching ? (
                  <Select.Option value=''>Cargando ...</Select.Option>
                ) : (
                  (positions || []).map((a) => (
                    <Select.Option key={a.id} value={a.id}>
                      {a.name}
                    </Select.Option>
                  ))
                )}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Rol'>
            {getFieldDecorator('role', {
              initialValue: 'user',
              rules: [],
            })(
              <Select>
                <Select.Option value='user'>Usuario</Select.Option>
                <Select.Option value='reader'>Solo lectura</Select.Option>
                <Select.Option value='admin'>Administrador</Select.Option>
              </Select>
            )}
          </FormItem>
          {['reader', 'admin'].indexOf(form.getFieldValue('role')) >= 0 && (
            <FormItem
              {...formItemLayout}
              label={
                <span>
                  Unidades de negocio&nbsp;
                  <Tooltip title='Unidades de negocio a las que tendrá acceso el usuario administrador (solo podrá ver y editar usuarios y cursos de estas unidades de negocio)'>
                    <Icon type='question-circle-o' />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('organizational_units', {
                rules: [],
              })(
                <DebounceSelect
                  mode='multiple'
                  placeholder='Todas'
                  style={{ width: '100%' }}
                  fetchOptions={fetchOrganizationalUnits}
                />
              )}
            </FormItem>
          )}
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Especificar contraseña?&nbsp;
                <Tooltip title='Si no se especifica una contraseña en la creación del usuario se creará una automáticamente y se le enviará por correo electrónico'>
                  <Icon type='question-circle-o' />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('set_pass', { valuePropName: 'checked' })(
              <Switch />
            )}
          </FormItem>
          {form.getFieldValue('set_pass') && (
            <FormItem
              {...formItemLayout}
              label='Contraseña (min. 6 caracteres)'
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Debes especificar la contraseña',
                  },
                  {
                    min: 6,
                    message:
                      'La contraseña debe tener por lo menos 6 caracteres',
                  },
                ],
              })(<Input />)}
            </FormItem>
          )}
          <FormItem {...formItemLayout} label='Número de Identificación'>
            {getFieldDecorator('identification_number', {
              rules: [],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Ubicación'>
            {getFieldDecorator('location', {
              rules: [],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Jefe'>
            {getFieldDecorator('manager', {
              rules: [],
            })(
              <Select
                showSearch
                placeholder='Seleccione el jefe'
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent='No hay resultados'
                onSearch={this.handleSearch}
              >
                {this.state.users.map((d) => (
                  <Select.Option key={d.id}>{`${d.first_name || ''} ${d.last_name || ''
                    }`}</Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
          {(customFields || []).map((cf) => (
            <FormItem
              key={cf.field_name}
              {...formItemLayout}
              label={cf.field_display_name}
            >
              {getFieldDecorator(cf.field_name, {
                rules: [],
              })(<Input />)}
            </FormItem>
          ))}
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(NewUserModal);
