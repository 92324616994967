import React, { useState, useEffect } from 'react';
import DashboardService from '../core/services/dashboard';
import { Column, Line } from '@ant-design/charts';

const label_names = {
  percentage: 'Porcentaje',
  assistance: 'Formados',
  total: 'Total',
  assisted_hours: 'Horas asistidas',
  programmed_hours: 'Horas programadas',
  approval: 'Aprobados',
  total_cost: 'Costo',
}

const formatter = new Intl.NumberFormat('es-CO',{ style:'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 });

const Detailed = ({ type, groupBy, filters }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await DashboardService.historic(filters, type, groupBy);
      setData(res);
    }
    
    fetchData();
  }, [type, groupBy, filters]);

  const config = {
    data,
    yField: type === 'cost' ? 'total_cost' : 'percentage',
    xField: 'label',
    seriesField: groupBy === 'month' ? undefined : 'label',
    color: groupBy === 'month' ? 'rgb(0, 21, 41)' : ['#ED5244', '#00BCD4', 'rgb(0, 21, 41)', '#D6D6D6'],
    yAxis: {
      label: {
        formatter: (val) => {
          if(type === 'cost') return formatter.format(val);
          return val + '%';
        }
      }
    },
    connectNulls: true,
    tooltip: {
      fields: type === 'attendance' ? ['percentage', 'assistance', 'total'] : type === 'hours' ? ['percentage', 'assisted_hours', 'programmed_hours'] : type === 'approval' ? ['percentage', 'approval', 'total'] : ['total_cost'],
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { name, value, color } = item;
                return (
                  <li
                    key={index}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                  >
                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span style={{ margiRight: 16 }}>{label_names[name]}: &nbsp;</span>
                      <span className="g2-tooltip-list-item-value">{name === 'percentage' ? `${value}%` : type === 'cost' ? formatter.format(value) : value}</span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#ED5244',
        lineWidth: 2,
      },
    },
  };

  return groupBy === 'month' ? <Line {...config} /> : <Column {...config} />;
};

export default React.memo(Detailed);