import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, Card, Button, Icon, message } from 'antd';
import { Link } from 'react-router-dom';

import { actions } from '../admin.redux';
import styles from './course-selection.module.css';
import NewCourseModal from './new-course.modal';
import service from '../../core/services/admin.js';

const { Meta } = Card;

const trimDescription = (description) => {
  const maxLength = 60;
  return description && description.length > maxLength ? description.substring(0, maxLength - 3) + '...' : description;
}

class CourseSelectionPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    modalVisible: false,
    saving: false,
    loading: false
  };

  loadCourses = (page) => {
    this.setState({ loading: true });
    this.props.fetch('', null, page);
  }

  componentDidMount() {
    this.loadCourses(1);
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.hideModal();
    this.props.fetch('', null, this.state.pagination.current || 1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.courses_fetching && !this.props.courses_fetching) {
      this.setState({ loading: false });
    }
    if (this.props.metadata !== prevProps.metadata) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          total: this.props.metadata.total,
          pageSize: this.props.metadata.limit
        }
      });
    }

    if (prevProps.saving && !this.props.saving) {
      if (this.props.error) {
        message.error(this.props.error);
      }
      else {
        this.refreshTable();
      }
    }
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  openCreate = () => {
    this.setState({ modalVisible: true });
  }

  hideModal = () => {
    this.setState({ modalVisible: false });
  }

  handleCreate = (files) => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        this.setState({ saving: true });
        delete values.gallery_image;
        delete values.detail_image;
        await service.createCourse(values, files);
        this.hideModal();
        this.refreshTable();
      }
      catch (ex) {
        console.error(ex);
        message.error('Ocurrió un error al guardar el curso');
      }
      this.setState({ saving: false });
    });
  }

  render() {
    const { loading, pagination } = this.state;
    return <div className="content-internal">
      <div className={styles.topButtons}>
        <Button type="primary" icon="plus" onClick={this.openCreate}>Nuevo curso</Button>
      </div>
      <List
        grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 4 }}
        dataSource={this.props.courses}
        pagination={{ ...pagination, onChange: (page) => this.loadCourses(page) }}
        loading={loading}
        renderItem={item => (
          <List.Item>
            <Link to={`/admin/courses/${item.id}/design`}>
              <Card
                hoverable
                style={{ height: 350 }}
                cover={item.image ? <img alt={item.name} className={styles.courseImage} src={item.image} /> : <div className={styles.coursePlaceholder}><Icon type="picture" /></div>}
              >
                <Meta
                  title={item.name}
                  description={trimDescription(item.description)}
                />
              </Card>
            </Link>
          </List.Item>
        )}
      />
      <NewCourseModal wrappedComponentRef={this.saveFormRef}
        visible={this.state.modalVisible}
        confirmLoading={this.state.saving}
        isSA={true}
        onCancel={this.hideModal}
        onCreate={this.handleCreate} />
    </div>
  }
}

function mapStateToProps(state) {
  return state.admin;
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (query, tenantId, page) => dispatch(actions.getcourses(query, tenantId, page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSelectionPage);
