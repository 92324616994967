import React, { PureComponent } from 'react';
import { Row, Col, Icon, Spin, Table } from 'antd';
import { G2 } from 'bizcharts';
import { connect } from 'react-redux';

import { actions } from './admin.redux';
import FiltersSidebar from './components/filters-sidebar';

const positionTableCols = [
  {
    title: 'Nombre',
    dataIndex: 'full_name'
  }, {
    title: 'Area',
    dataIndex: 'area',
    width: 90
  },
  {
    title: 'Puntos',
    dataIndex: 'points',
    width: 70,
    align: 'right'
  }];

const areasTableCols = [
  {
    title: 'Area',
    dataIndex: 'name'
  },
  {
    title: 'Promedio',
    dataIndex: 'average',
    width: 90,
    align: 'right'
  }];

G2.track(false);

class ReportUsersPage extends PureComponent {
  state = {
    reportGenerated: false,
    reportDetails: {},
    sortInfo: {
      topTable: null,
      areasTable: null,
      areasTopUsersTable: null
    }
  };


  componentDidMount() {
    this.onReportLoaded();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetching && !this.props.fetching) {
      if (!this.props.error) {
        this.onReportLoaded();
      }
      else {
        //set error
      }
    }
  }

  renderReport = () => {
    const { reportDetails } = this.state;
    if (!reportDetails) return <div />;
    return <div>
      <Row gutter={10}>
        <Col xs={24} sm={12} md={12} lg={8}>
          <h3>Completados ({this.props.fetching_report ? '...' : (reportDetails.report_completed || []).length})</h3>
          <Table loading={this.props.fetching_report} className="tbl_report" size="small" scroll={{ y: 'max-content' }}
            rowKey={u => u.id} columns={positionTableCols} pagination={false} rowClassName={(record, index) => record.enabled ? '' : 'disabled'}
            dataSource={reportDetails.report_completed} locale={{ emptyText: 'Ningun usuario ha completado el curso' }} />
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          <h3>Activos ({this.props.fetching_report ? '...' : (reportDetails.report_active || []).length})</h3>
          <Table loading={this.props.fetching_report} className="tbl_report" size="small" scroll={{ y: 240 }}
            rowKey={u => u.id} columns={positionTableCols} pagination={false} rowClassName={(record, index) => record.enabled ? '' : 'disabled'}
            dataSource={reportDetails.report_active} locale={{ emptyText: 'No hay usuarios activos el curso' }} />
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          <h3>Sin avance ({this.props.fetching_report ? '...' : (reportDetails.report_downloaded || []).length})</h3>
          <Table loading={this.props.fetching_report} className="tbl_report" size="small" scroll={{ y: 240 }}
            rowKey={u => u.id} columns={positionTableCols} pagination={false} rowClassName={(record, index) => record.enabled ? '' : 'disabled'}
            dataSource={reportDetails.report_downloaded} locale={{ emptyText: 'No hay usuarios sin avance en el curso' }} />
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          <h3>No descargada ({this.props.fetching_report ? '...' : (reportDetails.report_not_downloaded || []).length})</h3>
          <Table loading={this.props.fetching_report} className="tbl_report" size="small" scroll={{ y: 240 }}
            rowKey={u => u.id} columns={positionTableCols} pagination={false} rowClassName={(record, index) => record.enabled ? '' : 'disabled'}
            dataSource={reportDetails.report_not_downloaded} locale={{ emptyText: 'No hay usuarios sin descargar la aplicación' }} />
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          <h3>Areas</h3>
          <Table loading={this.props.fetching_report} className="tbl_report" size="small" scroll={{ y: 240 }}
            rowKey={u => u.id} columns={areasTableCols} pagination={false}
            dataSource={reportDetails.report_areas} locale={{ emptyText: 'No hay información en el momento' }} />
        </Col>
      </Row>
    </div>;
  }

  queryReport = (values) => {
    const { course, company } = values;
    this.setState({ filters: values });

    const queryFilters = {};
    if (values.areas) queryFilters.area = values.areas;
    if (values.location) queryFilters.location = values.location;
    if (values.dates && values.dates.length > 0) {
      queryFilters.from = values.dates[0].valueOf();
      queryFilters.to = values.dates[1].valueOf();
    }

    this.props.getreport(company, course, queryFilters);
  }

  onReportLoaded = () => {
    const { report_data } = this.props;
    this.setState({ reportGenerated: true });

    const repData = report_data?.users;
    if (!repData) return;

    const sortFunc = (a, b) => {
      if (a.points !== b.points) {
        return b.points - a.points;
      } else {
        if (a.last_activity && b.last_activity) return a.last_activity < b.last_activity;
        else return 0;
      }
    };

    const report_completed = repData.filter(d => d.status === 'finished').sort(sortFunc);
    const report_active = repData.filter(d => d.status === 'active').sort(sortFunc);
    const report_downloaded = repData.filter(d => d.status === 'downloaded').sort(sortFunc);
    const report_not_downloaded = repData.filter(d => d.status === 'not-downloaded').sort(sortFunc);

    const data_areas = {};

    for (var datum of repData) {
      if (!datum.area) continue;
      var normalizedArea = datum.area.trim().toLowerCase();
      var current = data_areas[normalizedArea] || { points: 0, users: 0 };
      current.points += datum.points;
      current.users++;
      data_areas[normalizedArea] = current;
    }

    var report_areas = Object.keys(data_areas).map(k => ({
      ...data_areas[k],
      average: data_areas[k].users > 0 ? Math.round(data_areas[k].points / data_areas[k].users) : 0,
      name: k[0].toUpperCase() + k.substring(1)
    })).sort((a, b) => b.average - a.average);

    this.setState({
      reportDetails: {
        report_completed,
        report_active,
        report_downloaded,
        report_not_downloaded,
        report_areas
      }
    });
  }

  renderEmpty = () => {
    return <div style={{ textAlign: 'center', margin: 20 }}>
      <Icon type="reconciliation" theme="twoTone" style={{ fontSize: '240px', opacity: 0.3 }} />
      <p style={{ fontSize: '20px', color: '#ccc' }}>Para generar el reporte seleccione los filtros y presione el botón</p>
    </div>;
  }

  render() {
    return <Row gutter={16}>
      <Col span={18}>
        <div className="content-internal">
          <Spin spinning={this.props.fetching}>
            {this.state.reportGenerated && !this.props.fetching ? this.renderReport() : this.renderEmpty()}
          </Spin>
        </div>
      </Col>
      <Col span={6}>
        <FiltersSidebar buttonText="Generar reporte" fetching={this.props.fetching} onFilter={this.queryReport} />
      </Col>
    </Row>;
  }
}

function mapStateToProps(state) {
  return state.admin;
}

function mapDispatchToProps(dispatch) {
  return {
    getreport: (tenantId, courseId, filters) => dispatch(actions.getreport2(tenantId, courseId, filters))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportUsersPage);
