import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions } from './formation-paths.redux';
import './formation-path-detail.page.css';
import { CoursesList } from './components/courses-list';
import { ExternalCoursesList } from './components/external-courses-list';
import { CertificationsList } from './components/certifications-list';

class FormationPathDetailPage extends PureComponent {
  state = {
    path: null
  };

  componentDidMount() {
    this.queryPath();
  }

  queryPath = () => {
    const pathId = this.props.match.params.id;
    this.props.get(pathId);
  }

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.path) {
      this.props.layout.changeTitle(nextProps.path.name);
    }
  }

  render() {
    return <div className="content-internal">
      <div>
        <CoursesList pathId={this.props.match.params.id} canEdit={this.canEdit()} />
        <ExternalCoursesList pathId={this.props.match.params.id} canEdit={this.canEdit()} />
        <CertificationsList pathId={this.props.match.params.id} canEdit={this.canEdit()} />
      </div>
    </div>
  }
}

function mapStateToProps(state) {
  return { ...state.formationPaths, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    get: (pathId) => dispatch(actions.get(pathId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormationPathDetailPage);
