import React from 'react';
import { BlockPicker } from 'react-color';
import tinycolor from 'tinycolor2';
import styles from './color-picker.module.css';
import { Popover } from 'antd';

class ColorPicker extends React.Component {
  state = {
    color: null,
  };

  componentDidMount() {
    this.setColor(this.props.color);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.color !== this.props.color) this.setColor(this.props.color);
  }

  componentWillReceiveProps(nextProps) {
    this.setColor(nextProps.color);
  }

  setColor = (color) => {
    var rgbColor = color;
    if(typeof rgbColor === 'string') {
      rgbColor = tinycolor(rgbColor).toRgb();
    }
    this.setState({ color: rgbColor });
  }

  handleChange = (color) => {
    const format = this.props.format || 'rgb';
    this.setColor(color.rgb);
    if(this.props.onChange) this.props.onChange(format === 'hex' ? color.hex : color.rgb);
  };

  render() {
    const { color } = this.state;
    const { disabled } = this.props;

    const inner = <div className={`${styles.swatch} ${disabled ? styles.disabled : ''}`}>
      <div className={styles.color} style={{backgroundColor: color ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : 'transparent'}} />
    </div>;

    if(disabled) {
      return inner;
    }
    
    return <Popover overlayClassName={styles.popover} trigger="click" placement="bottom" content={<BlockPicker color={this.state.color} onChange={this.handleChange} />}>
      {inner}
    </Popover>;
  }
}

export default ColorPicker