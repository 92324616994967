import BaseService from './baseService';

export default class OrganizationalUnitsService extends BaseService {
  static async list(query, limit, skip) {
    return await super.request(
      `/organizational-units?limit=${limit}&skip=${skip}&q=${query ? encodeURIComponent(query) : ''
      }`
    );
  }

  static async listForUser(userId) {
    return await super.request(
      `/organizational-units/for-user/${userId}`
    );
  }

  static async create(name, courses, areas) {
    return await super.request(`/organizational-units`, 'POST', {
      name,
      courses,
      areas,
    });
  }

  static async update(id, name, courses, areas) {
    return await super.request(`/organizational-units/${id}`, 'PUT', {
      name,
      courses,
      areas,
    });
  }

  static async delete(ids) {
    return await super.request(
      `/organizational-units/${JSON.stringify(ids)}`,
      'DELETE'
    );
  }
}
