import React, { Component } from 'react';
import commonStyles from './common.module.css';
import styles from './section-subjects.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import bullseye from '../../../../../assets/mini_module_1_review_icon@2x.png';
import { DEFAULTS } from '../../theme-editor.modal';

class SectionSubjectsTemplate extends Component {

  state = {
    imageFailed: false
  };

  onImageError = (e) => {
    if(this.state.imageFailed) return;
    const { theme } = this.props;
    this.setState({imageFailed: true});
    e.target.src = getAssetUri('images', 'general', theme.module_subjects_image);
  }

  render() {
    const { element, theme, slug } = this.props;
    const { subjects, subjects_title } = element;

    const textStyles = {
      color: theme.text_color || undefined
    };

    const bgImage = theme.background_image ? theme.background_image.startsWith('http') 
      ? theme.background_image : getAssetUri('images', slug, theme.background_image, false) : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined
    };

    const controlStyles = {
      backgroundColor: theme.controls_color || colorIntToHex(element.color) || DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    } 
    
    const imgSrc = getAssetUri('images', slug, theme.module_subjects_image) || bullseye;

    var subj = (subjects||'').split(';').map((s, idx) => {
      if(s === '') return null;
      return <div key={'s_' + idx}>
        { idx > 0 && <hr className={styles.separator} />}
        <p className={styles.text} style={textStyles}>{stylizeText(s, controlStyles.backgroundColor)}</p>
      </div>;
    });

    return <div className={`${commonStyles.templateContainer} ${styles.container}`} style={containerStyles}>
        { subjects && <div className={commonStyles.templateBody} style={{alignItems:'center'}}>
          <img className={styles.icon} alt="Objetivos" src={imgSrc} onError={this.onImageError}/>
          <div className={styles.title} style={textStyles}>{ subjects_title ? stylizeText(subjects_title, controlStyles.backgroundColor) : '¿Qué aprenderás en este submódulo?'}</div>
          {subj}
        </div> }
    </div>;
  }

  static renderMiniature ({ element, index, parentIndex, ...props}) {
    return <div className={commonStyles.miniature} style={{backgroundColor: '#3498db'}}>
      <img className={commonStyles.minIcon} alt="bullseye" src={bullseye}/>
      <div className={commonStyles.minTitle} style={{color: 'white'}}>Objetivos</div>
    </div>
  }

  static getProps = ({ element }) => {
    return [
      { name: 'subjects_title', display: 'Título', value: element.title, type: 'multiline' },
      { name: 'subjects', display: 'Temas', value: element.subjects, type: 'text_list', separator: ';' }
    ];
  }
}

export default SectionSubjectsTemplate;
