import React, { PureComponent } from 'react';
import { Form, Modal, Input, Select, notification } from 'antd';
import FiltersService from '../core/services/filters';
import AreasService from '../core/services/areas';
import PositionsService from '../core/services/positions';
import UsersService from '../core/services/users';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class EditCommunicationModal extends PureComponent {
  state = {
    error: null,
    fetching: false,
    costCenters: [],
    loadingAreas: false,
    areas: [],
    loadingPositions: false,
    positions: [],
    loadingLocations: false,
    locations: [],
  };

  async loadSelects() {
    await this.loadAreas();
    await this.loadPositions();
    await this.loadLocations();
    await this.loadCostCenters();
  }
  async componentDidMount() {
    await this.loadSelects();
  }

  async componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
      await this.loadSelects();
    }
  }

  loadAreas = async () => {
    try {
      this.setState({ loadingAreas: true });
      let areas = (await AreasService.list('', 1000, 0)).results;
      this.setState({ areas });
    } catch (ex) {
      notification.error({
        message: 'Ocurrió un error al consultar las areas',
        description: ex.message,
      });
    }
    this.setState({ loadingAreas: false });
  };

  loadPositions = async () => {
    try {
      this.setState({ loadingPositions: true });
      let positions = (await PositionsService.list('', 1000, 0)).results;
      this.setState({ positions });
    } catch (ex) {
      notification.error({
        message: 'Ocurrió un error al consultar los cargos',
        description: ex.message,
      });
    }
    this.setState({ loadingPositions: false });
  };

  loadLocations = async () => {
    try {
      this.setState({ loadingLocations: true });
      let locations = await UsersService.getLocations();
      this.setState({ locations });
    } catch (ex) {
      notification.error({
        message: 'Ocurrió un error al consultar las ubicaciones',
        description: ex.message,
      });
    }
    this.setState({ loadingLocations: false });
  };

  loadCostCenters = async () => {
    try {
      this.setState({ loadingCostCenters: true });
      let costCenters = (await FiltersService.listValues(1, 200, 0)).results;
      this.setState({ costCenters });
    } catch (ex) {
      notification.error({
        message: 'Ocurrió un error',
        description: ex.message,
      });
    }
    this.setState({ loadingCostCenters: false });
  };

  onOk = () => {
    this.props.onCreate();
  };

  render() {
    const { visible, onCancel, confirmLoading, form, communication } =
      this.props;
    const { getFieldDecorator } = form;

    if (!communication) return <div />;
    return (
      <Modal
        title='Editar audiencia'
        visible={visible}
        onOk={this.onOk}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        onCancel={onCancel}
        width='60vw'
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Form>
          <FormItem {...formItemLayout} label='Nombre de la audiencia'>
            {getFieldDecorator('name', {
              initialValue: communication.name,
              rules: [
                {
                  required: true,
                  message: 'Debes especificar el nombre',
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Áreas'>
            {getFieldDecorator('areas', {
              initialValue: (communication.areas ?? []).map((a) => a.id),
              rules: [],
            })(
              <Select
                mode='multiple'
                placeholder='Todas'
                notFoundContent='No hay resultados'
                optionFilterProp='title'
                disabled={this.state.loadingAreas}
                loading={this.state.loadingAreas}
              >
                {this.state.areas.map((c) => (
                  <Select.Option key={c.id} value={c.id} title={c.name}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Cargos'>
            {getFieldDecorator('positions', {
              initialValue: (communication.positions ?? []).map((a) => a.id),
              rules: [],
            })(
              <Select
                mode='multiple'
                placeholder='Todos'
                notFoundContent='No hay resultados'
                optionFilterProp='title'
                disabled={this.state.loadingPositions}
                loading={this.state.loadingPositions}
              >
                {this.state.positions.map((c) => (
                  <Select.Option key={c.id} value={c.id} title={c.name}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Ubicaciones'>
            {getFieldDecorator('locations', {
              initialValue: (communication.locations ?? []).map(
                (a) => a.location
              ),
              rules: [],
            })(
              <Select
                mode='multiple'
                placeholder='Todas'
                notFoundContent='No hay resultados'
                disabled={this.state.loadingLocations}
                loading={this.state.loadingLocations}
              >
                {this.state.locations.map((c) => (
                  <Select.Option key={c.location} value={c.location}>
                    {c.location}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Centros de costo'>
            {getFieldDecorator('extended_field1_values', {
              initialValue: (communication.extended_field1_values ?? []).map(
                (a) => a.extended_field1_value
              ),
              rules: [],
            })(
              <Select
                mode='multiple'
                placeholder='Todos'
                notFoundContent='No hay resultados'
                optionFilterProp='title'
                disabled={this.state.loadingCostCenters}
                loading={this.state.loadingCostCenters}
              >
                {this.state.costCenters.map((c) => (
                  <Select.Option key={c.code} value={c.code} title={c.value}>
                    {c.value}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(EditCommunicationModal);
