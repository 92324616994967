import React, { Component } from 'react';
import commonStyles from './common.module.css';
import styles from './image-choice.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class ImageChoiceTemplate extends Component {
  state = {};

  onImageError = (e, idx, img) => {
    if (this.state['imageFailed' + idx]) return;
    this.setState({ ['imageFailed' + idx]: true });
    e.target.src = getAssetUri('images', 'general', img);
  };

  render() {
    const { element, slug, theme, section } = this.props;
    const { title, extended_props, options } = element;
    const { description } = extended_props || {};

    const opts = Object.keys(options || {})
      .map((k) => options[k])
      .sort((a, b) => a.order - b.order);

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer}`}
        style={containerStyles}
      >
        <div className={commonStyles.templateTitle} style={textStyles}>
          {stylizeText(title, controlStyles.backgroundColor)}
        </div>
        <div
          className={commonStyles.templateText}
          style={{ ...textStyles, marginTop: '4vw' }}
        >
          {stylizeText(description, controlStyles.backgroundColor)}
        </div>
        <div
          className={`${commonStyles.templateBody}`}
          style={{ alignSelf: 'stretch' }}
        >
          <div className={styles.radioContainer}>
            {opts.map((opt, idx) => (
              <div
                className={`${styles.radio} ${
                  opt.value === 1 ? styles.selected : ''
                }`}
              >
                <img
                  className={commonStyles.templateImage}
                  alt={opt.text}
                  src={getAssetUri('images', slug, opt.text)}
                  onError={(e) => this.onImageError(e, idx, opt.text)}
                />
                <i
                  className={styles.icon}
                  style={{
                    borderColor:
                      opt.value === 1 ? controlStyles.backgroundColor : '',
                    '--color':
                      opt.value === 1
                        ? controlStyles.backgroundColor || 'white'
                        : '',
                  }}
                ></i>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${
          index + 1
        }. Selección de imagen`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.description',
        display: 'Descripción',
        value: extProps.description,
        type: 'multiline',
      },
      {
        name: 'extended_props.hide_result',
        display: 'Ocultar resultado',
        value: extProps.hide_result,
        type: 'boolean',
      },
      {
        name: 'extended_props.only_feedback',
        display: 'Sólo feedback',
        value: extProps.only_feedback,
        type: 'boolean',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
      {
        name: 'options',
        display: 'Opciones',
        value: element.options,
        type: 'options',
      },
    ];
  };
}

export default ImageChoiceTemplate;
