import BaseService from './baseService';

export default class SkillsService extends BaseService {
  static async list(query, limit, skip) {
    return await super.request(
      `/skills?limit=${limit}&skip=${skip}&q=${query ? encodeURIComponent(query) : ''
      }`
    );
  }

  static async create(name) {
    return await super.request(`/skills`, 'POST', { name });
  }

  static async update(id, name) {
    return await super.request(`/skills/${id}`, 'PUT', { name });
  }

  static async delete(ids) {
    return await super.request(`/skills/${JSON.stringify(ids)}`, 'DELETE');
  }

}
