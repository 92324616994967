import React, { PureComponent } from 'react';
import { Modal, Upload, Button, Icon } from 'antd';

class AddCertificationAttachmentsModal extends PureComponent {
  state = {
    files: [],
  };

  beforeUpload = (file) => {
    return false;
  };

  onFilesChange = ({ file, fileList, event }) => {
    this.setState({ files: fileList });
  };

  onOk = () => {
    const { onSave } = this.props;
    const { files } = this.state;
    onSave(files);
  };

  render() {
    const { visible, onCancel, confirmLoading } = this.props;

    return (
      <Modal
        title='Agregar adjunto'
        visible={visible}
        onOk={this.onOk}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Upload
          name='attachments'
          multiple
          showUploadList={true}
          onChange={this.onFilesChange}
          accept='image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          beforeUpload={this.beforeUpload}
        >
          <Button>
            <Icon type='upload' /> Cargar archivo...
          </Button>
        </Upload>
      </Modal>
    );
  }
}

export default AddCertificationAttachmentsModal;
