import React, { Component } from 'react';
import { Input } from 'antd';
import commonStyles from './common.module.css';
import styles from './type.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class TypeTemplate extends Component {
  onImageError = (e) => {
    const { element } = this.props;
    e.target.onError = null;
    e.target.src = getAssetUri(
      'images',
      'general',
      (element.extended_props || {}).image || 'img_ca_ico_recopilemos'
    );
  };

  render() {
    const { element, slug, theme, section } = this.props;
    const { title, extended_props } = element;
    const { text, image } = extended_props || {};

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer} ${styles.container}`}
        style={containerStyles}
      >
        <img
          className={`${commonStyles.templateImage} ${styles.image}`}
          alt={image}
          src={getAssetUri('images', slug, image || 'img_ca_ico_recopilemos')}
          onError={this.onImageError}
        />
        <div
          className={`${commonStyles.templateTitle} ${styles.title}`}
          style={textStyles}
        >
          {stylizeText(title, controlStyles.backgroundColor) || 'RECUERDA'}
        </div>
        <div className={commonStyles.templateBody}>
          <div
            className={`${commonStyles.templateText} ${styles.text}`}
            style={textStyles}
          >
            {stylizeText(text, controlStyles.backgroundColor)}
          </div>
          <Input.TextArea className={styles.input}></Input.TextArea>
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${
          index + 1
        }. Retroalimentación`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.text',
        display: 'Texto',
        value: extProps.text,
        type: 'multiline',
      },
      {
        name: 'extended_props.image',
        display: 'Imagen',
        value: extProps.image,
        type: 'file',
        subtype: 'images',
        note: 'Solo imágenes PNG o SVG',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'extended_props.hide_result',
        display: 'Ocultar resultado',
        value: extProps.hide_result,
        type: 'boolean',
      },
      {
        name: 'extended_props.only_feedback',
        display: 'Sólo feedback',
        value: extProps.only_feedback,
        type: 'boolean',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
      {
        name: 'options',
        display: 'Opciones',
        value: element.options,
        type: 'options',
      },
    ];
  };
}

export default TypeTemplate;
