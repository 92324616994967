import React, { PureComponent } from 'react';
import { Modal, Form, Input, Select, Switch } from 'antd';
import service from '../core/services/instructors';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class EditCourseModal extends PureComponent {

  state = {
    instructors: [],
    courses: [],
    instructors_error: null,
    instructors_fetching: false
  }

  async componentDidMount() {
    try {
      this.setState({ instructors_fetching: true });
      const instr = await service.list(1000, 0);
      this.setState({ instructors: instr.results });
    }
    catch(ex) {
      this.setState({ instructors_error: ex });
    }

    this.setState({ instructors_fetching: false });
  }

  render() {
    const { visible, onCancel, onCreate, form, confirmLoading, course } = this.props;
    const { getFieldDecorator } = form;
    const { instructors_error, instructors, instructors_fetching } = this.state;

    if(!course) return <></>;

    return <Modal title="Editar curso externo"
      visible={visible}
      onOk={onCreate}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      
      okText="Guardar"
      cancelText="Cancelar">
      <Form>
        <FormItem
          {...formItemLayout}
          label="Código">
          {getFieldDecorator('code', {
            initialValue: course.code,
            rules: []
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Nombre">
          {getFieldDecorator('name', {
            initialValue: course.name,
            rules: [{
              required: true, message: 'Debes especificar el nombre',
            }]
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Descripción">
          {getFieldDecorator('description', {
            initialValue: course.description,
            rules: []
          })(
            <Input.TextArea />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Horas programadas">
          {getFieldDecorator('hours', {
            initialValue: course.hours,
            rules: []
          })(
            <Input type="number" />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Calificable?"
        >
          {getFieldDecorator('assessable', { 
            initialValue: course.assessable,
            valuePropName: 'checked' 
          })(
            <Switch />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Competencia">
          {getFieldDecorator('competence', {
            initialValue: course.competence,
            rules: []
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Escuela">
          {getFieldDecorator('category', {
            initialValue: course.category,
            rules: []
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Formador">
          {getFieldDecorator('instructor_id', {
            initialValue: course.instructor_id,
            rules: [],
          })(
            <Select notFoundContent="No hay resultados" disabled={instructors_error || instructors_fetching }>
              { instructors_error ? 
                <Select.Option value="">Error al cargar los instructores</Select.Option> :
                instructors_fetching ?
                <Select.Option value="">Cargando ...</Select.Option> :
                (instructors||[]).map((a) => <Select.Option key={a.id} value={a.id}>{a.name}</Select.Option>)
              }
            </Select>
          )}
        </FormItem>
      </Form>
    </Modal>
  }
}

export default Form.create()(EditCourseModal);
