import React, { PureComponent } from 'react';
import { Modal, Form, Select } from 'antd';

const FormItem = Form.Item;

class NewSectionModal extends PureComponent {

  state = {
    orders: []
  }

  updateOrders = (sectionId) => {
    const { form, sections } = this.props;
    
    const parentOrder = sectionId ? `${sections[sectionId].order}.` : '';
    const sectionsToOrder = sectionId ? sections[sectionId].sections : sections;

    const orders = Object.keys(sectionsToOrder||{}).map(k => `${parentOrder}${sectionsToOrder[k].order}`);
    orders.push(`${parentOrder}${orders.length+1} (Al final)`);
    this.setState({ orders });
    form.setFieldsValue({ order: orders.length });
  }

  componentDidMount() {
    this.updateOrders(null);
  }
  
  componentDidUpdate(prevProps) {
    if(!prevProps.visible && this.props.visible) {
      this.props.form.setFieldsValue({ parent: '' });
      this.updateOrders(null);
    }
  }

  handleSectionChange = (value) => {
    this.updateOrders(value);
  }

  render() {
    const { visible, form, confirmLoading, onCreate, onCancel, sections } = this.props;
    const { getFieldDecorator } = form;
    const { orders } = this.state;

    const sectionsOrdered = Object.keys(sections||{})
      .map(k => sections[k])
      .sort((a,b)=> a.order - b.order);

    return <Modal title="Nueva sección"
      visible={visible}
      onOk={onCreate}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      okText="Crear"
      cancelText="Cancelar">
      <Form>
        <FormItem
          label="Sección padre">
          {getFieldDecorator('parent', {
            initialValue: "",
            rules: []
          })(
            <Select onChange={this.handleSectionChange}>
              <Select.Option value="">Ninguna (En la raíz)</Select.Option>
              {sectionsOrdered.map(s => <Select.Option key={s.id} value={s.id}>{`${s.order}. ${s.name}`}</Select.Option>)}  
            </Select>
          )}
        </FormItem>
        <FormItem
          label="Órden">
          {getFieldDecorator('order', {
            initialValue: "",
            rules: []
          })(
            <Select>
              {orders.map((o,idx) => <Select.Option key={o} value={idx+1}>{o}</Select.Option>)}  
            </Select>
          )}
        </FormItem>
      </Form>
    </Modal>;
  } 


}

export default Form.create()(NewSectionModal);
