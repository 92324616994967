import BaseService from './baseService';

export default class CoursesService extends BaseService {
  static async list(limit, skip, q) {
    return await super.request(
      `/courses?limit=${limit}&skip=${skip}&q=${encodeURIComponent(q || '')}`
    );
  }

  static async getDetails(courseId) {
    return await super.request(`/courses/${courseId}/details`);
  }

  static async availableUsers(courseId, q, limit, skip) {
    return await super.request(
      `/courses/${courseId}/users/available?q=${q || ''}&limit=${limit}&skip=${skip}`
    );
  }

  static async enabledUsers(courseId, q, limit, skip) {
    return await super.request(
      `/courses/${courseId}/users/enabled?q=${q || ''}&limit=${limit}&skip=${skip}`
    );
  }

  static async assignUsers(courseId, userIds) {
    return await super.request(
      `/courses/${courseId}/users/${JSON.stringify(userIds)}`,
      'POST'
    );
  }

  static async getAssignedSkills(courseId) {
    return await super.request(
      `/courses/${courseId}/skills`
    );
  }

  static async assignSkills(courseId, skills) {
    return await super.request(
      `/courses/${courseId}/skills/${JSON.stringify(skills)}`,
      'POST'
    );
  }

  static async disableUsers(courseId, userIds) {
    return await super.request(
      `/courses/${courseId}/users/disable/${JSON.stringify(userIds)}`,
      'POST'
    );
  }

  static async import(courseId, data) {
    return await super.request(`/courses/${courseId}/users/import`, 'POST', {
      file: data,
    });
  }

  static async getCourse(courseId) {
    return await super.request(`/courses/${courseId}`, 'GET');
  }

  static async getCourseCategories() {
    return await super.request(`/courses/categories`, 'GET');
  }

  static async createCourse(data, files) {
    const formData = new FormData();
    for (const key in data) if (data[key]) formData.append(key, data[key]);
    for (const key in files) if (files[key]) formData.append(key, files[key]);

    return await super.request(
      '/courses',
      'POST',
      formData,
      { 'Content-Type': 'multipart/form-data' },
      false,
    );
  }

  static async updateCourse(courseId, updates) {
    return await super.request(`/courses/${courseId}`, 'PUT', updates);
  }

  static async addSection(courseId, parent, order) {
    return await super.request(`/courses/${courseId}/sections`, 'POST', {
      parent,
      order,
    });
  }

  static async addContent(courseId, section, subSection, contentType) {
    return await super.request(`/courses/${courseId}/contents`, 'POST', {
      section,
      subSection,
      contentType,
    });
  }

  static async removeSection(courseId, id, parent) {
    return await super.request(`/courses/${courseId}/sections`, 'DELETE', {
      parent,
      id,
    });
  }

  static async removeContent(courseId, id, section, subSection) {
    return await super.request(`/courses/${courseId}/contents`, 'DELETE', {
      section,
      subSection,
      id,
    });
  }

  static async reorderContent(courseId, id, oldPosition, newPosition) {
    return await super.request(`/courses/${courseId}/contents/order`, 'PUT', {
      id,
      oldPosition,
      newPosition,
    });
  }

  static async validateCourse(hash) {
    return await super.request(`/courses/validate/${hash}`, 'GET');
  }

  static async updateCourseTheme(courseId, data, files) {
    const formData = new FormData();
    formData.append('theme', JSON.stringify(data));

    for (let key in files) if (files[key]) formData.append(key, files[key]);

    return await super.request(
      `/courses/${courseId}/theme`,
      'POST',
      formData,
      { 'Content-Type': 'multipart/form-data' },
      false
    );
  }

  static async addContentResource(courseId, file) {
    const formData = new FormData();
    formData.append('file', file);

    return await super.request(
      `/courses/${courseId}/resource`,
      'POST',
      formData,
      { 'Content-Type': 'multipart/form-data' },
      false
    );
  }

  static async getCourseResources(courseId, type) {
    return await super.request(
      `/courses/${courseId}/resources/${type ?? 'all'}`
    );
  }
}
