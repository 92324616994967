import { createActions, handleActions } from 'redux-actions';

import client from '../core/services/areas';

//INITIAL STATE
const initialState = { 
  areas: [],
  fetching: false,
  error: null,
  metadata: null,
  deleting: false,
  saving: false,
  saving_error: null
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

export const actions = createActions({
  fetch: (query, page) => client.list(query, 10, 10 * (page - 1)),
  create: (code, name) => client.create(code, name),
  update: (id, code, name) => client.update(id, code, name),
  delete: (ids) => client.delete(ids),
  fetchUsers: (areaId, page) => client.listUsers(areaId, 10, 10 * (page - 1)),
  import: (data) => client.import(data) 
}, { prefix: 'areas' });

//REDUCER
const reducer = handleActions({
  [`${actions.fetch}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, areas: payload.results, metadata: payload.pagination, error: null, fetching: false };
  },
  [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, fetching: false };
  },
  [`${actions.create}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.create}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, saving: false };
  },
  [`${actions.create}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  },
  [`${actions.update}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.update}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, saving: false };
  },
  [`${actions.update}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  },
  [`${actions.delete}_${pending}`]: (state) => {
    return { ...state, deleting: true, error: null };
  },
  [`${actions.delete}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, deleting: false };
  },
  [`${actions.delete}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, deleting: false };
  },
  [`${actions.import}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.import}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, error: null };
  },
  [`${actions.import}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  },
}, initialState);

export default reducer;
