import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Icon, Dropdown, Menu, Button, Table, Modal, message } from 'antd';
import { Link } from 'react-router-dom';

import { actions } from './communications.redux';
import './communications.page.css';
import DateDisplay from '../common/components/date';
import AddCommunicationModal from './add-communication.modal';
import EditCommunicationModal from './edit-communication.modal';

const confirm = Modal.confirm;

class CommunicationsPage extends PureComponent {
  state = {
    pagination: {},
    editElement: null,
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  handleTableAction = async (action, record) => {
    console.warn(`Action ${action}`);

    switch (action) {
      case 'edit':
        this.openEdit(record);
        break;
      case 'delete':
        this.showDeleteConfirm(record);
        break;
      default:
        console.warn('unknown action');
    }
  };

  componentDidMount() {
    this.columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        render: (val, record) => {
          return <Link to={'/communications/' + record.id}>{val}</Link>;
        },
      },
      {
        title: 'Creado',
        dataIndex: 'created_at',
        render: (val) => (val ? <DateDisplay>{val}</DateDisplay> : ''),
      },
    ];
    if (this.canEdit()) {
      this.columns.push({
        title: '',
        width: 40,
        render: (val, record) => (
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key }) => this.handleTableAction(key, record)}
              >
                <Menu.Item key='edit'>Editar</Menu.Item>
                <Menu.Item key='delete'>Eliminar</Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button type='link' className='tbl-action'>
              <Icon type='ellipsis' />
            </Button>
          </Dropdown>
        ),
      });
    }

    this.props.fetch(1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metadata) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          total: nextProps.metadata.total,
          pageSize: nextProps.metadata.limit,
        },
      });
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.saving_error) {
        message.error(
          nextProps.saving_error.data?.code === 55001
            ? 'No se puede eliminar una audiencia con mensajes asociados'
            : nextProps.saving_error.message
        );
      } else {
        this.refreshTable();
      }
    }
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.hideModal();
    this.hideEditModal();
    this.setState({ editElement: null });
    this.props.fetch(this.state.pagination.current || 1);
  };

  handleTableChange = async (pagination, filters, sorter) => {
    await this.setState({ pagination });
    this.props.fetch(pagination.current);
  };

  showDeleteConfirm = (record) => {
    confirm({
      title: `¿Estás seguro que deseas eliminar la audiencia seleccionada?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.delete(record.id);
      },
    });
  };

  render() {
    return (
      <div className='content-internal'>
        {this.canEdit() && (
          <div className='top-buttons'>
            <Button type='primary' icon='plus' onClick={this.openCreate}>
              Nueva audiencia
            </Button>
          </div>
        )}
        <Table
          loading={this.props.fetching}
          size='small'
          className='striped-rows'
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
          rowKey={(r) => r.id}
          columns={this.columns}
          dataSource={this.props.communications}
          locale={{
            emptyText:
              'En este módulo podrás crear y programar comunicaciones por WhatsApp a tus empleados',
          }}
        />
        <AddCommunicationModal
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.modalVisible}
          onCancel={this.hideModal}
          onCreate={this.handleCreate}
          confirmLoading={this.props.saving}
        />
        <EditCommunicationModal
          wrappedComponentRef={this.saveEditFormRef}
          visible={this.state.editModalVisible}
          onCancel={this.hideEditModal}
          onCreate={this.handleEdit}
          confirmLoading={this.props.saving}
          communication={this.state.editElement}
        />
      </div>
    );
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  saveEditFormRef = (formRef) => {
    this.editFormRef = formRef;
  };

  openCreate = () => {
    this.setState({ modalVisible: true });
  };

  openEdit = (course) => {
    this.setState({ editElement: course, editModalVisible: true });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  hideEditModal = () => {
    this.setState({ editModalVisible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.create(
        values.name,
        values.areas ?? [],
        values.positions ?? [],
        values.locations ?? [],
        values.extended_field1_values ?? []
      );
    });
  };

  handleEdit = () => {
    const form = this.editFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.edit(
        this.state.editElement.id,
        values.name,
        values.areas ?? [],
        values.positions ?? [],
        values.locations ?? [],
        values.extended_field1_values ?? []
      );
    });
  };
}

function mapStateToProps(state) {
  return { ...state.communications, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    create: (name, areas, positions, locations, extended_field1_values) =>
      dispatch(
        actions.create(
          name,
          areas,
          positions,
          locations,
          extended_field1_values
        )
      ),
    edit: (id, name, areas, positions, locations, extended_field1_values) =>
      dispatch(
        actions.edit(
          id,
          name,
          areas,
          positions,
          locations,
          extended_field1_values
        )
      ),
    delete: (id) => dispatch(actions.delete(id)),
    fetch: (page) => dispatch(actions.fetch(page)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationsPage);
