import { createActions, handleActions } from 'redux-actions';

import client from '../core/services/groups';
//INITIAL STATE
const initialState = { 
  groups: [],
  fetching: false,
  error: null,
  metadata: null,
  areas: [],
  fetching_areas: false,
  error_areas: null,
  deleting: false,
  users: [],
  metadata_users: null,
  fetching_users: false,
  error_users: null,
  courses: [],
  metadata_courses: null,
  fetching_courses: false,
  error_courses: null,
  plans: [],
  metadata_plans: null,
  fetching_plans: false,
  error_plans: null,
  deleting_course: false,
  deleting_plan: false,
  deleting_user: false,
  saving: false,
  saving_error: null
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

let actionCreators = createActions({
  GROUPS: {
    fetch: (page) => client.list(10, 10 * (page - 1)),
    create: (name, filters) => client.create(name),
    delete: (ids) => client.delete(ids),
    fetchUsers: (groupId, page) => client.listUsers(groupId, 10, 10 * (page - 1)),
    fetchCourses: (groupId, page) => client.listCourses(groupId, 10, 10 * (page - 1)),
    deleteCourse: (groupId, courseId) => client.deleteCourse(groupId, courseId),
    fetchPlans: (groupId, page) => client.listPlans(groupId, 10, 10 * (page - 1)),
    deletePlan: (groupId, planId) => client.deletePlan(groupId, planId),
    addPlans: (groupId, planIds) => client.addPlans(groupId, planIds),
    addCourses: (groupId, courseIds) => client.addCourses(groupId, courseIds),
    addUsers: (groupId, userIds) => client.addUsers(groupId, userIds),
    deleteUser: (groupId, userId) => client.deleteUser(groupId, userId)
  }
});

export const actions = actionCreators.groups;

//REDUCER
const reducer = handleActions({
  [`${actions.fetch}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, groups: payload.results, metadata: payload.pagination, error: null, fetching: false };
  },
  [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, fetching: false };
  },
  [`${actions.getAreas}_${pending}`]: (state) => {
    return { ...state, fetching_areas: true, error_areas: null };
  },
  [`${actions.getAreas}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, areas: payload, error_areas: null, fetching_areas: false };
  },
  [`${actions.getAreas}_${rejected}`]: (state, { payload }) => {
    return { ...state, error_areas : payload, fetching_areas: false };
  },
  [`${actions.create}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.create}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, saving: false };
  },
  [`${actions.create}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  },
  [`${actions.delete}_${pending}`]: (state) => {
    return { ...state, deleting: true, error: null };
  },
  [`${actions.delete}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, deleting: false };
  },
  [`${actions.delete}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, deleting: false };
  },
  [`${actions.fetchUsers}_${pending}`]: (state) => {
    return { ...state, fetching_users: true, error_users: null };
  },
  [`${actions.fetchUsers}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, users: payload.results, metadata_users: payload.pagination, error_users: null, fetching_users: false };
  },
  [`${actions.fetchUsers}_${rejected}`]: (state, { payload }) => {
    return { ...state, error_users : payload, fetching_users: false };
  },
  [`${actions.fetchCourses}_${pending}`]: (state) => {
    return { ...state, fetching_courses: true, error_courses: null };
  },
  [`${actions.fetchCourses}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, courses: payload.results, metadata_courses: payload.pagination, error_courses: null, fetching_courses: false };
  },
  [`${actions.fetchCourses}_${rejected}`]: (state, { payload }) => {
    return { ...state, error_courses : payload, fetching_courses: false };
  },
  [`${actions.deleteCourse}_${pending}`]: (state) => {
    return { ...state, deleting_course: true, error: null };
  },
  [`${actions.deleteCourse}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, deleting_course: false };
  },
  [`${actions.deleteCourse}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, deleting_course: false };
  },
  [`${actions.fetchPlans}_${pending}`]: (state) => {
    return { ...state, fetching_plans: true, error_plans: null };
  },
  [`${actions.fetchPlans}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, plans: payload.results, metadata_plans: payload.pagination, error_plans: null, fetching_plans: false };
  },
  [`${actions.fetchPlans}_${rejected}`]: (state, { payload }) => {
    return { ...state, error_plans : payload, fetching_plans: false };
  },
  [`${actions.deletePlan}_${pending}`]: (state) => {
    return { ...state, deleting_plan: true, error: null };
  },
  [`${actions.deletePlan}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, deleting_plan: false };
  },
  [`${actions.deletePlan}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, deleting_plan: false };
  },
  [`${actions.addPlans}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.addPlans}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving_error: null, saving: false };
  },
  [`${actions.addPlans}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.addCourses}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.addCourses}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving_error: null, saving: false };
  },
  [`${actions.addCourses}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.addUsers}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.addUsers}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving_error: null, saving: false };
  },
  [`${actions.addUsers}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.deleteUser}_${pending}`]: (state) => {
    return { ...state, deleting_user: true, error: null };
  },
  [`${actions.deleteUser}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, deleting_user: false };
  },
  [`${actions.deleteUser}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, deleting_user: false };
  },
}, initialState);

export default reducer;
