import { createActions, handleActions } from 'redux-actions';

import client from '../core/services/reports';

//INITIAL STATE
const initialState = {
  report_data: [],
  fetching: false,
  error: null
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

export const actions = createActions({
  GETROUTESREPORT: (filters) => client.getRoutesReport(filters),
  GETCOURSESREPORT: (filters) => client.getCoursesReport(filters),
  GETMODULESREPORT: (filters) => client.getModulesReport(filters),
  GETRESPONSESREPORT: (filters) => client.getResponsesReport(filters),
  GETACTIVITYREPORT: (filters) => client.getActivityReport(filters)
}, { prefix: 'reports' });

//REDUCER
const reducer = handleActions({
  [`${actions.getroutesreport}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.getroutesreport}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, report_data: payload, error: null, fetching: false };
  },
  [`${actions.getroutesreport}_${rejected}`]: (state, { payload }) => {
    return { ...state, error: payload, fetching: false };
  },
  [`${actions.getcoursesreport}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.getcoursesreport}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, report_data: payload, error: null, fetching: false };
  },
  [`${actions.getcoursesreport}_${rejected}`]: (state, { payload }) => {
    return { ...state, error: payload, fetching: false };
  },
  [`${actions.getresponsesreport}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.getresponsesreport}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, report_data: payload, error: null, fetching: false };
  },
  [`${actions.getresponsesreport}_${rejected}`]: (state, { payload }) => {
    return { ...state, error: payload, fetching: false };
  },
  [`${actions.getmodulesreport}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.getmodulesreport}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, report_data: payload, error: null, fetching: false };
  },
  [`${actions.getmodulesreport}_${rejected}`]: (state, { payload }) => {
    return { ...state, error: payload, fetching: false };
  },
  [`${actions.getactivityreport}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.getactivityreport}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, report_data: payload, error: null, fetching: false };
  },
  [`${actions.getactivityreport}_${rejected}`]: (state, { payload }) => {
    return { ...state, error: payload, fetching: false };
  }
}, initialState);

export default reducer;
