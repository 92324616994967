import React, { Component } from 'react';
import { Row, Col, Modal, message, PageHeader, Button } from 'antd';

import PropsEditor from './components/props-editor';
import Strip from './components/strip';
import ScreenPreview from './components/screen-preview';
import saService from '../../core/services/admin';
import service from '../../core/services/courses';
import * as immutable from 'object-path-immutable';
import NewSectionModal from './new-section.modal';

import styles from './course-design.module.css';
import NewContentModal from './new-content.modal';
import ThemeEditorModal from './theme-editor.modal';

const { confirm } = Modal;

class CourseDesignPage extends Component {
  service = null;

  state = {
    course: null,
    saving: false,
    isDirty: false,
    newSectionModalVisible: false,
    newContentModalVisible: false,
    themeEditorModalVisible: false,
    selectedItem: null,
  };

  constructor(props) {
    super(props);
    this.selectedRef = React.createRef();

    this.service =
      this.props.match.url.indexOf('/admin/') >= 0 ? saService : service;
  }

  async componentDidMount() {
    const courseId = this.props.match.params.id;
    var course = await this.service.getCourse(courseId);

    var keys = Object.keys(course.sections || {});
    if (keys.length > 0) {
      var firstSection = Object.keys(course.sections)
        .map((k) => course.sections[k])
        .find((s) => s.order === 1);
      if (!firstSection) firstSection = course.sections[keys[0]];
    }

    this.setState({ course });
    this.selectModule(
      'section',
      firstSection,
      0,
      undefined,
      undefined,
      undefined,
      undefined,
      false
    );
  }

  selectModule = (
    type,
    element,
    index,
    parentIndex,
    section,
    sectionId,
    subSectionId,
    scroll = false
  ) => {
    const act = () =>
      this.setState(
        {
          isDirty: false,
          selectedItem: {
            type,
            element,
            index,
            parentIndex,
            section,
            sectionId,
            subSectionId,
          },
        },
        () => {
          if (scroll && this.selectedRef.current)
            this.selectedRef.current.scrollToSelf();
        }
      );

    if (!this.state.isDirty) {
      act();
    } else {
      confirm({
        title:
          'Tienes cambios no guardados en la pantalla ¿seguro quieres salir?',
        description:
          'Si cambias de pantalla sin guardar los cambios estos se perderán',
        onOk: act,
        okText: 'Continuar',
        okType: 'danger',
        cancelText: 'Cancelar',
      });
    }
  };

  selectAdjacentModule = (deletedItem) => {
    let newItem = { ...deletedItem };

    let root =
      deletedItem.type === 'section'
        ? this.state.course.sections
        : deletedItem.type === 'subsection' ||
          deletedItem.type === 'sectionSubjects'
        ? this.state.course.sections[deletedItem.sectionId].sections
        : this.state.course.sections[deletedItem.sectionId].sections[
            deletedItem.subSectionId
          ].contents;

    const modules = Object.keys(root || {})
      .map((k) => root[k])
      .sort((a, b) => a.order - b.order);

    if (modules.length > deletedItem.index) {
      newItem.element = modules[deletedItem.index]; //same index
    } else if (modules.length > 0) {
      newItem.index -= 1;
      newItem.element = modules[newItem.index];
    } else {
      if (deletedItem.type !== 'section') {
        let newType =
          deletedItem.type === 'subsection' ||
          deletedItem.type === 'sectionSubjects'
            ? 'section'
            : 'subsection';
        let newElem =
          newType === 'section'
            ? this.state.course.sections[deletedItem.sectionId]
            : this.state.course.sections[deletedItem.sectionId].sections[
                deletedItem.subSectionId
              ];

        newItem.index = deletedItem.parentIndex;
        newItem.element = newElem;
        newItem.type = newType;
        newItem.sectionId =
          newType === 'section' ? undefined : deletedItem.sectionId;
        newItem.parentIndex =
          newType === 'section'
            ? undefined
            : Object.keys(this.state.course.sections)
                .map((k) => this.state.course.sections[k])
                .sort((a, b) => a.order - b.order)
                .findIndex((i) => i.id === deletedItem.sectionId);
      } else {
        newItem = null;
      }
    }

    this.selectModule(
      newItem.type,
      newItem.element,
      newItem.index,
      newItem.parentIndex,
      newItem.section,
      newItem.sectionId,
      newItem.subSectionId,
      true
    );
  };

  deleteContent = () => {
    const { selectedItem } = this.state;
    const courseId = this.props.match.params.id;

    confirm({
      title: 'Eliminar contenido',
      description:
        '¿Estás seguro de eliminar el contenido seleccionado? Esta acción no se puede deshacer.',
      onOk: async () => {
        try {
          this.setState({ saving: true });

          await this.service.removeContent(
            courseId,
            selectedItem.element.id,
            selectedItem.sectionId,
            selectedItem.subSectionId
          );
          var course = await this.service.getCourse(courseId);
          this.setState({ course, isDirty: false }, () =>
            this.selectAdjacentModule(selectedItem)
          );
        } catch (err) {
          console.error(err);
          message.error('Ocurrió un error al eliminar el contenido');
        }

        this.setState({ saving: false });
      },
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
    });
  };

  deleteSection = () => {
    const { selectedItem } = this.state;
    const courseId = this.props.match.params.id;

    confirm({
      title: 'Eliminar sección',
      content: (
        <p>
          ¿Estás seguro de eliminar la sección y <strong>TODOS</strong> sus
          contenidos? Esta acción no se puede deshacer.
        </p>
      ),
      onOk: async () => {
        try {
          this.setState({ saving: true });

          await this.service.removeSection(
            courseId,
            selectedItem.element.id,
            selectedItem.type === 'subsection'
              ? selectedItem.sectionId
              : undefined
          );
          var course = await this.service.getCourse(courseId);
          this.setState({ course, isDirty: false }, () =>
            this.selectAdjacentModule(selectedItem)
          );
        } catch (err) {
          console.error(err);
          message.error('Ocurrió un error al eliminar la sección');
        }

        this.setState({ saving: false });
      },
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
    });
  };

  deleteElement = () => {
    const { selectedItem } = this.state;

    switch (selectedItem.type) {
      case 'content':
        this.deleteContent();
        break;
      case 'section':
      case 'subsection':
        this.deleteSection();
        break;
      default:
        console.warn('Unknown element type');
    }
  };

  reorderModule = async (contentId, oldPosition, newPosition) => {
    try {
      this.setState({ saving: true });

      const element =
        this.state.course.sections[oldPosition.sectionId].sections[
          oldPosition.subSectionId
        ].contents[contentId];
      let section =
        this.state.course.sections[newPosition.sectionId].sections[
          newPosition.subSectionId
        ];
      let parentIndex = section.order - 1;

      const courseId = this.props.match.params.id;
      await this.service.reorderContent(
        courseId,
        contentId,
        oldPosition,
        newPosition
      );
      var course = await this.service.getCourse(courseId);
      this.setState({ course, isDirty: false }, () =>
        this.selectModule(
          'content',
          element,
          newPosition.index,
          parentIndex,
          section,
          newPosition.sectionId,
          newPosition.subSectionId,
          false
        )
      );
    } catch (err) {
      console.error(err);
      message.error('Ocurrió un error al reordenar');
    }

    this.setState({ saving: false });
  };

  saveChanges = async (changes) => {
    const { selectedItem } = this.state;
    var path;
    const fbUpdates = {};

    this.setState({ saving: true });

    for (var change of changes) {
      switch (selectedItem.type) {
        case 'section':
          path = `sections.${selectedItem.element.id}.${change.item.name}`;
          break;
        case 'subsection':
        case 'sectionSubjects':
          path = `sections.${selectedItem.sectionId}.sections.${selectedItem.element.id}.${change.item.name}`;
          break;
        case 'content':
          path = `sections.${selectedItem.sectionId}.sections.${selectedItem.subSectionId}.contents.${selectedItem.element.id}.${change.item.name}`;
          break;
        default:
          console.warn('Unknown element type');
      }

      //newCourse = immutable.set(newCourse, path, change.newValue);
      fbUpdates[path.replace(/\./g, '/')] = change.newValue;
    }

    try {
      const courseId = this.props.match.params.id;
      await this.service.updateCourse(courseId, fbUpdates);
      var course = await this.service.getCourse(courseId);
      this.setState({ course, isDirty: false });
    } catch (err) {
      console.error(err);
      message.error('Ocurrió un error al guardar');
    }

    this.setState({ saving: false });
  };

  propChanged = (item, newValue) => {
    var newSelectedItem = immutable.set(
      this.state.selectedItem,
      `element.${item.name}`,
      newValue
    );
    this.setState({ selectedItem: newSelectedItem, isDirty: true });
  };

  openNewSectionModal = () => {
    this.setState({ newSectionModalVisible: true });
  };

  openThemeEditorModal = () => {
    this.setState({ themeEditorModalVisible: true });
  };

  hideNewSectionModal = () => {
    this.setState({ newSectionModalVisible: false });
  };

  hideThemeEditorModal = () => {
    this.setState({ themeEditorModalVisible: false });
  };

  saveNewSectionFormRef = (formRef) => {
    this.newSectionFormRef = formRef;
  };

  openNewContentModal = (sectionId, subSectionId) => {
    this.setState({
      newContentModalVisible: true,
      newContentSectionId: sectionId,
      newContentSubSectionId: subSectionId,
    });
  };

  hideNewContentModal = () => {
    this.setState({ newContentModalVisible: false });
  };

  onThemeSaved = async (theme, images) => {
    try {
      const courseId = this.props.match.params.id;

      this.setState({ saving: true });

      await this.service.updateCourseTheme(courseId, theme, images);
      var course = await this.service.getCourse(courseId);

      this.setState({ course, themeEditorModalVisible: false, isDirty: false });
    } catch (ex) {
      console.error(ex);
      message.error('Ocurrió un error al guardar el tema');
    }

    this.setState({ saving: false });
  };

  onNewContent = async (contentType) => {
    try {
      const courseId = this.props.match.params.id;
      const { newContentSectionId, newContentSubSectionId } = this.state;

      this.setState({ saving: true });

      const { result } = await this.service.addContent(
        courseId,
        newContentSectionId,
        newContentSubSectionId,
        contentType
      );
      var course = await this.service.getCourse(courseId);

      const section =
        course.sections[newContentSectionId].sections[newContentSubSectionId];

      this.setState(
        { course, newContentModalVisible: false, isDirty: false },
        () =>
          this.selectModule(
            'content',
            result,
            result.order - 1,
            section.order - 1,
            section,
            newContentSectionId,
            newContentSubSectionId,
            true
          )
      );
    } catch (ex) {
      console.error(ex);
      message.error('Ocurrió un error al crear el contenido');
    }

    this.setState({ saving: false });
  };

  onNewSection = () => {
    const form = this.newSectionFormRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        const { parent, order } = values;
        this.setState({ saving: true });

        const courseId = this.props.match.params.id;
        const { result } = await this.service.addSection(
          courseId,
          parent,
          order
        );
        var course = await this.service.getCourse(courseId);

        const orderedSections = Object.keys(course.sections)
          .map((k) => course.sections[k])
          .sort((a, b) => a.order - b.order);

        const type = result.parent ? 'subsection' : 'section';
        const section = course.sections[result.parent || result.id];
        const sectionId = result.parent ? section.id : undefined;
        const subSection = result.parent ? section.sections[result.id] : null;
        const parentIndex = result.parent
          ? orderedSections.findIndex((s) => s.id === result.parent)
          : -1;

        const orderedSubsections = Object.keys(section.sections || {})
          .map((k) => section.sections[k])
          .sort((a, b) => a.order - b.order);

        const index = result.parent
          ? orderedSubsections.findIndex((s) => s.id === result.id)
          : orderedSections.findIndex((s) => s.id === result.id);

        this.setState(
          { course, newSectionModalVisible: false, isDirty: false },
          () =>
            this.selectModule(
              type,
              result.parent ? subSection : section,
              index,
              parentIndex,
              undefined,
              sectionId,
              undefined,
              true
            )
        );
      } catch (ex) {
        console.error(ex);
        message.error('Ocurrió un error al crear la sección');
      }

      this.setState({ saving: false });
    });
  };

  render() {
    if (!this.state.course) return <div></div>;

    const { theme } = this.state.course;
    const contentTheme =
      this.state.selectedItem && this.state.selectedItem.element
        ? this.state.selectedItem.element.theme || {}
        : {};

    const mergedTheme = { ...theme, ...contentTheme };

    return (
      <React.Fragment>
        <Row gutter={16} style={{ display: 'flex', alignItems: 'stretch' }}>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={this.state.course.name}
            className={styles.header}
            extra={[
              <Button.Group key='btn-group'>
                <Button
                  key='new-section'
                  type='default'
                  icon='ordered-list'
                  onClick={this.openNewSectionModal}
                >
                  Nueva sección
                </Button>
                <Button
                  key='edit-theme'
                  type='default'
                  icon='bg-colors'
                  onClick={this.openThemeEditorModal}
                >
                  Editar tema
                </Button>
              </Button.Group>,
            ]}
          />
        </Row>
        <Row gutter={16} style={{ display: 'flex', alignItems: 'stretch' }}>
          <Col span={4}>
            <Strip
              course={this.state.course}
              theme={mergedTheme}
              onSelect={this.selectModule}
              onReorder={this.reorderModule}
              selectedItem={this.state.selectedItem}
              selectedRef={this.selectedRef}
              onNewContent={this.openNewContentModal}
            />
          </Col>
          <Col span={14}>
            <ScreenPreview
              {...this.state.selectedItem}
              theme={
                this.state.selectedItem?.type === 'sectionSubjects'
                  ? theme || {}
                  : mergedTheme
              }
              elementTheme={contentTheme}
              fetching={false}
              slug={this.state.course.slug}
              courseName={this.state.course.name}
            />
          </Col>
          <Col span={6}>
            <PropsEditor
              {...this.state.selectedItem}
              theme={mergedTheme}
              onChange={this.propChanged}
              onDelete={this.deleteElement}
              onSave={this.saveChanges}
              saving={this.state.saving}
              slug={this.state.course.slug}
              service={this.service}
              courseId={this.props.match.params.id}
            />
          </Col>
        </Row>
        <NewSectionModal
          visible={this.state.newSectionModalVisible}
          confirmLoading={this.state.saving}
          onCreate={this.onNewSection}
          onCancel={this.hideNewSectionModal}
          sections={this.state.course.sections}
          wrappedComponentRef={this.saveNewSectionFormRef}
        />
        <NewContentModal
          visible={this.state.newContentModalVisible}
          confirmLoading={this.state.saving}
          onCreate={this.onNewContent}
          onCancel={this.hideNewContentModal}
          sections={this.state.course.sections}
        />
        <ThemeEditorModal
          visible={this.state.themeEditorModalVisible}
          confirmLoading={this.state.saving}
          onSave={this.onThemeSaved}
          onCancel={this.hideThemeEditorModal}
          theme={this.state.course.theme}
          slug={this.state.course.slug}
        />
      </React.Fragment>
    );
  }
}

export default CourseDesignPage;
