import { Button, Divider, Empty, Icon, message, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import FormationPathsService from "../../core/services/formation-paths";
import { AddExternalCourseModal } from "./add-external-course.modal";

export const ExternalCoursesList = ({ pathId, canEdit }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [courses, setCourses] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);

    const queryCourses = useCallback(async () => {
        try {
            setFetching(true);
            const data = await FormationPathsService.getExternalCourses(pathId);
            setCourses(data.map(d => ({ ...d, id: d.external_course_id })).sort((a, b) => a.order - b.order));
        }
        catch (err) {
            message.error('Ocurrió un error al consultar los cursos externos del plan de formación');
        }
        finally {
            setFetching(false);
        }
    }, [pathId]);

    const removeCourse = async (course) => {
        try {
            await FormationPathsService.removeExternalCourse(pathId, course.external_course_id);
            await queryCourses();
        }
        catch (err) {
            message.error('Ocurrió un error al eliminar el curso externo del plan de formación');
        }
    }

    const addCourses = async (newCourses) => {
        try {
            setSaving(true);
            const maxOrder = Math.max(...courses.map(c => c.order), 0);
            await FormationPathsService.addExternalCourses(pathId, newCourses.map((c, idx) => ({ id: c, order: maxOrder + idx + 1 })));
            setModalVisible(false);
            await queryCourses();
        }
        catch (err) {
            message.error('Ocurrió un error al agregar los cursos del plan de formación');
        }
        finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        queryCourses();
    }, [pathId, queryCourses]);

    const renderItem = ({ item }) => {
        return <div key={item.id} className="drag-item ext-drag-item">
            {item.name}
            {canEdit && <Icon type="close" className="del-icon" onClick={() => removeCourse(item)} />}
        </div>
    };

    return <div className="path-section">
        <div className="heading-row">
            <h2>Cursos externos</h2>
            {canEdit && <div className="top-buttons">
                <Button className="" type="ghost" onClick={() => setModalVisible(true)}><Icon type="plus" />Agregar curso externo</Button>
            </div>}
        </div>
        <Spin spinning={fetching}>
            {courses.length === 0 && <Empty description="Aún no hay cursos externos asignados" />}
            {courses.map(c => renderItem({ item: c }))}
        </Spin>
        <Divider />
        <AddExternalCourseModal visible={modalVisible} onCancel={() => setModalVisible(false)} confirmLoading={saving} onOk={addCourses} initialSelection={courses.map(c => c.id)} />
    </div>;

}