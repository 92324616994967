import BaseService from './baseService';

export default class GroupsService extends BaseService
{
  static async list(limit, skip) {
    return await super.request(`/groups?limit=${limit}&skip=${skip}`);
  }

  static async create(name) {
    return await super.request(`/groups`, 'POST', { name });
  }

  static async delete(ids) {
    return await super.request(`/groups/${JSON.stringify(ids)}`, 'DELETE');
  }

  static async listUsers(groupId, limit, skip) {
    return await super.request(`/groups/${groupId}/users?limit=${limit}&skip=${skip}`);
  }

  static async listUsersAvailable(groupId) {
    return await super.request(`/groups/${groupId}/users/available`);
  }

  static async deleteUser(groupId, userId) {
    return await super.request(`/groups/${groupId}/users/${userId}`, 'DELETE');
  }

  static async addUsers(groupId, userIds) {
    return await super.request(`/groups/${groupId}/users/${JSON.stringify(userIds)}`, 'POST');
  }

  static async listCourses(groupId, limit, skip) {
    return await super.request(`/groups/${groupId}/courses?limit=${limit}&skip=${skip}`);
  }

  static async listCoursesAvailable(groupId) {
    return await super.request(`/groups/${groupId}/courses/available`);
  }

  static async deleteCourse(groupId, courseId) {
    return await super.request(`/groups/${groupId}/courses/${courseId}`, 'DELETE');
  }

  static async addCourses(groupId, courseIds) {
    return await super.request(`/groups/${groupId}/courses/${JSON.stringify(courseIds)}`, 'POST');
  }

  static async listPlans(groupId, limit, skip) {
    return await super.request(`/groups/${groupId}/paths?limit=${limit}&skip=${skip}`);
  }

  static async listPlansAvailable(groupId) {
    return await super.request(`/groups/${groupId}/paths/available`);
  }

  static async deletePlan(groupId, planId) {
    return await super.request(`/groups/${groupId}/paths/${planId}`, 'DELETE');
  }

  static async addPlans(groupId, planIds) {
    return await super.request(`/groups/${groupId}/paths/${JSON.stringify(planIds)}`, 'POST');
  }
}