import React, { PureComponent } from 'react';
import { Modal, Table, notification } from 'antd';
import Date from '../common/components/date';
import CertificationsService from '../core/services/certifications';

class CertificationUsersModal extends PureComponent {
  state = {
    fetching: false,
    users: [],
  };

  loadUsers = async (cert) => {
    try {
      if (!cert) return;

      this.setState({ fetching: true });
      let users = await CertificationsService.getUsers(cert.id);
      this.setState({ users });
    } catch (ex) {
      notification.error({
        message:
          'Ocurrió un error al consultar los usuarios de la certificación',
        description: ex.message,
      });
    }
    this.setState({ fetching: false });
  };

  async componentDidMount() {
    await this.loadUsers(this.props.certification);
  }

  async componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
      await this.loadUsers(nextProps.certification);
    } else if (!this.props.certification && !!nextProps.certification) {
      await this.loadUsers(nextProps.certification);
    }
  }

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Nombres',
        dataIndex: 'first_name',
        render: (val, record) =>
          `${val ?? ''} ${record.last_name ?? ''}`.trim(),
      },
      {
        title: 'Identificación',
        dataIndex: 'identification_number',
      },
      {
        width: 150,
        title: 'Fecha cert.',
        dataIndex: 'date_completed',
        render: (val) => {
          return <Date>{val}</Date>;
        },
      },
      {
        width: 150,
        title: 'Válido hasta',
        dataIndex: 'valid_through',
        render: (val) => {
          return <Date markDue>{val}</Date>;
        },
      },
    ];
  }
  render() {
    const { visible, onCancel, certification, confirmLoading } = this.props;

    return (
      <Modal
        title={`Usuarios con certificación ${certification?.name ?? ''}`}
        visible={visible}
        width={'80vw'}
        onOk={onCancel}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        okText='Cerrar'
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Table
          loading={this.state.fetching}
          className='striped-rows'
          columns={this.columns}
          dataSource={this.state.users}
          size='small'
          pagination={false}
          tableLayout='fixed'
        />
      </Modal>
    );
  }
}

export default CertificationUsersModal;
