import React, { PureComponent } from 'react';
import { Modal, Form, Input } from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class EditAreaModal extends PureComponent {

  state = {
    filters: 0
  };

  render() {
    const { visible, onCancel, onCreate, form, area, confirmLoading } = this.props;
      const { getFieldDecorator } = form;
    if(!area) return <div/>;
    return <Modal title="Editar área"
      visible={visible}
      onOk={onCreate}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText="Guardar"
      cancelText="Cancelar">
      <Form>
      <FormItem
          {...formItemLayout}
          label="Código">
          {getFieldDecorator('code', {
            initialValue: area.code,
            rules: [{
              required: true, message: 'Debes especificar el código del área',
            }],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Nombre">
          {getFieldDecorator('name', {
            initialValue: area.name,
            rules: [{
              required: true, message: 'Debes especificar el nombre del área',
            }],
          })(
            <Input />
          )}
        </FormItem>
      </Form>
    </Modal>
  } 
}

export default Form.create()(EditAreaModal);
