import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Icon, Dropdown, Menu, Button, Table, Modal, message } from 'antd';

import { actions } from './instructors.redux';
import './instructors.page.css';
import DateDisplay from '../common/components/date';
import NewInstructorModal from './new-instructor.modal';
import EditInstructorModal from './edit-instructor.modal';
import InstructorsService from '../core/services/instructors';

const confirm = Modal.confirm;
class InstructorsPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    editElement: null
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  handleTableAction = async (action, record) => {
    console.warn(`Action ${action}`);

    switch (action) {
      case 'edit':
        this.openEdit(record);
        break;
      case 'delete':
        this.showDeleteConfirm(record);
        break;
      default:
        console.warn('unknown action');
    }
  }

  componentDidMount() {
    this.columns = [
      {
        title: 'Código',
        dataIndex: 'code'
      },
      {
        title: 'Creado',
        dataIndex: 'created_at',
        render: (val) => <DateDisplay relative>{val}</DateDisplay>
      },
      {
        title: 'Nombre',
        dataIndex: 'name'
      },
      {
        title: 'Descripción',
        dataIndex: 'description'
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (val) => val === 'internal' ? 'Interno' : 'Externo'
      }
    ];

    if (this.canEdit()) {
      this.columns.push({
        title: '',
        width: 40,
        render: (val, record) => <Dropdown overlay={(
          <Menu onClick={({ item, key }) => this.handleTableAction(key, record)}>
            <Menu.Item key="edit">Editar</Menu.Item>
            <Menu.Item key="delete">Eliminar</Menu.Item>
          </Menu>
        )} trigger={['click']}>
          <Button type="link" className="tbl-action">
            <Icon type="ellipsis" />
          </Button>
        </Dropdown>
      });
    }
    this.props.fetch(1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metadata) {
      this.setState({ pagination: { ...this.state.pagination, total: nextProps.metadata.total, pageSize: nextProps.metadata.limit } })
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.error) {
        alert(nextProps.error);
      }
      else {
        this.refreshTable();
      }
    }

    if (this.props.deleting && !nextProps.deleting) {
      if (nextProps.error) {
        alert(nextProps.error);
      }
      else {
        this.refreshTable();
      }
    }
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.hideModal();
    this.hideEditModal();
    this.setState({ selectedRowKeys: [], editElement: null });
    this.props.fetch(this.state.pagination.current || 1);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ pagination });
    this.props.fetch(pagination.current);
  }

  getCheckboxProps = (record) => {
    return {
      disabled: false,//record.disabled,
      name: record.name
    }
  }

  showDeleteConfirm = (record) => {

    confirm({
      title: `¿Estás seguro que deseas eliminar el formador seleccionado?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.delete(record.id)
      }
    });
  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps
    };

    const hasSelected = this.state.selectedRowKeys.length > 0;
    return <div className="content-internal">
      {this.canEdit() && <div className="top-buttons">
        <Button type="primary" icon="plus" onClick={this.openCreate}>Nuevo formador</Button>
        {hasSelected &&
          <Button icon="delete" onClick={this.showDeleteConfirm}>Eliminar formadores</Button>
        }
      </div>}
      <Table loading={this.props.fetching} size="small" className="striped-rows"
        onChange={this.handleTableChange} pagination={this.state.pagination}
        rowKey={r => r.id} rowSelection={this.canEdit() ? rowSelection : undefined} columns={this.columns}
        dataSource={this.props.instructors} locale={{ emptyText: 'No hay formadores registrados en el momento' }} />
      <NewInstructorModal wrappedComponentRef={this.saveFormRef} visible={this.state.modalVisible} onCancel={this.hideModal} onCreate={this.handleCreate} confirmLoading={this.props.saving} />
      <EditInstructorModal wrappedComponentRef={this.saveEditFormRef} instructor={this.state.editElement} visible={this.state.editModalVisible} onCancel={this.hideEditModal} onCreate={this.handleEdit} confirmLoading={this.props.saving} />
    </div>
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  saveEditFormRef = (formRef) => {
    this.editFormRef = formRef;
  }

  openCreate = () => {
    this.setState({ modalVisible: true });
  }

  openEdit = (course) => {
    this.setState({ editElement: course, editModalVisible: true });
  }

  hideModal = () => {
    this.setState({ modalVisible: false });
  }

  hideEditModal = () => {
    this.setState({ editModalVisible: false });
  }

  handleCreate = (files) => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        this.setState({ saving: true });
        delete values.image;
        await InstructorsService.createInstructor(values, files);
        this.hideModal();
        this.refreshTable();
      }
      catch (ex) {
        console.error(ex);
        message.error('Ocurrió un error al guardar el formador');
      }
      this.setState({ saving: false });
    });
  }


  handleEdit = (files) => {
    const form = this.editFormRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        this.setState({ saving: true });
        delete values.image;
        await InstructorsService.updateInstructor(this.state.editElement.id, values, files);
        this.hideModal();
        this.refreshTable();
      }
      catch (ex) {
        console.error(ex);
        message.error('Ocurrió un error al guardar el formador');
      }
      this.setState({ saving: false });
    });
  }
}

function mapStateToProps(state) {
  return { ...state.instructors, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    create: (instructor) => dispatch(actions.create(instructor)),
    edit: (id, instructor) => dispatch(actions.edit(id, instructor)),
    delete: (id) => dispatch(actions.delete(id)),
    fetch: (page) => dispatch(actions.fetch(page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructorsPage);
