import React, { Component } from 'react';
import commonStyles from './common.module.css';
import styles from './section.module.css';
import { colorIntToHex, getAssetUri } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class SectionTemplate extends Component {
  state = {
    imageFailed: false,
  };

  onImageError = (e) => {
    if (this.state.imageFailed) return;
    const { element } = this.props;
    this.setState({ imageFailed: true });
    e.target.src = getAssetUri('images', 'general', element.icon);
  };

  render() {
    const { element, index, parentIndex, slug, theme, elementTheme } =
      this.props;
    const { name, color, icon } = element;

    const colorHex = color ? colorIntToHex(color) : '';

    const bgImage = elementTheme.background_image
      ? elementTheme.background_image.startsWith('http') ||
        elementTheme.background_image.startsWith('data:')
        ? elementTheme.background_image
        : getAssetUri('images', slug, elementTheme.background_image, false)
      : undefined;

    const controlStyles = {
      backgroundColor:
        elementTheme.background_color ||
        (colorHex.length >= 4
          ? colorIntToHex(color)
          : theme.controls_color || DEFAULTS.controls_color),
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      color:
        elementTheme.text_color ||
        theme.intro_text_color ||
        theme.controls_text_color ||
        undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer}`}
        style={controlStyles}
      >
        <div
          className={styles.index}
          style={{
            backgroundColor: controlStyles.color,
            color: controlStyles.backgroundColor,
          }}
        >{`${parentIndex >= 0 ? parentIndex + 1 + '.' : ''}${index + 1}`}</div>
        {!!icon && (
          <img
            className={styles.icon}
            alt='Ícono del módulo'
            src={getAssetUri('images', slug, icon)}
            onError={this.onImageError}
          />
        )}
        <div className={styles.text} style={{ color: controlStyles.color }}>
          {name}
        </div>
      </div>
    );
  }

  static renderMiniature = ({ element, index, parentIndex, ...props }) => {
    const { color } = element;
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: color ? colorIntToHex(color) : null }}
      >
        <div
          className={commonStyles.minIndex}
          style={{ color: color ? colorIntToHex(color) : null }}
        >{`${parentIndex >= 0 ? parentIndex + 1 + '.' : ''}${index + 1}`}</div>
        <div className={commonStyles.minTitle} style={{ color: 'white' }}>
          {element.name}
        </div>
      </div>
    );
  };

  static getProps = ({ element }) => {
    return [
      { name: 'name', display: 'Título', value: element.name, type: 'text' },
      {
        name: 'icon',
        display: 'Ícono',
        value: element.icon,
        type: 'file',
        note: 'Solo imágenes PNG o SVG',
        subtype: 'images',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'color',
        display: 'Color de fondo',
        value: element.color,
        type: 'color',
      },
    ];
  };
}

export default SectionTemplate;
