import React, { Component } from 'react';
import {
  List,
  Input,
  Button,
  InputNumber,
  Select,
  Switch,
  Spin,
  Collapse,
} from 'antd';
import ColorPicker from '../../../common/components/color-picker';
import styles from './props-editor.module.css';
import tinyColor from 'tinycolor2';
import { colorIntToHex, colorHexToInt, TPL_PREFIX } from '../utils';
import Templates from './templates';
import * as immutable from 'object-path-immutable';
import Options from './editors/options';
import Options2 from './editors/options2';
import FileEditor from './editors/file';
import ThemeEditorModal from '../theme-editor.modal';
import Time from './editors/time';

const deep_value = (obj, path) => {
  for (var i = 0, p = path.split('.'), len = p.length; i < len; i++) {
    obj = obj[p[i]];
    if (typeof obj === 'undefined') break;
  }
  return obj;
};

class PropsEditor extends Component {
  state = {
    values: {},
    changes: [],
    themeEditorModalVisible: false,
  };

  componentDidMount() {
    this.setState({ values: this.props.element });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ values: nextProps.element });
    const newId = nextProps.element ? nextProps.element.id : null;
    const oldId = this.props.element ? this.props.element.id : null;
    if (newId !== oldId) this.setState({ changes: [] });
  }

  handleInputChange = (item, newValue) => {
    var formattedValue = newValue;

    if (item.type === 'color') {
      formattedValue = colorHexToInt(tinyColor(newValue).toHex());
      this.notifyChange(item, formattedValue);
    } else if (item.type === 'boolean') {
      this.notifyChange(item, formattedValue);
    } else if (item.type === 'options') {
      this.notifyChange(item, formattedValue);
    } else if (item.type === 'options2') {
      this.notifyChange(item, formattedValue);
    } else if (item.type === 'file') {
      this.notifyChange(item, formattedValue);
    } else if (item.type === 'time') {
      this.notifyChange(item, formattedValue);
    }

    var changes = [...this.state.changes, { item, newValue: formattedValue }];

    const newValues = immutable.set(
      this.state.values,
      item.name,
      formattedValue
    );

    this.setState({ values: newValues, changes });
  };

  onSave = async () => {
    try {
      await this.props.onSave(this.state.changes);
      this.setState({ changes: [] });
    } catch (err) {
      console.error(err);
    }
  };

  onDelete = async () => {
    this.props.onDelete();
  };

  notifyChange = (item, val) => {
    let value = val || deep_value(this.state.values, item.name);
    if (item.type === 'file' && typeof val === 'undefined') value = undefined;
    if (item.value !== value) this.props.onChange(item, value);
  };

  getInputFor = (item) => {
    switch (item.type) {
      case 'text_list':
      case 'multiline':
        return (
          <Input.TextArea
            size='small'
            rows={3}
            value={deep_value(this.state.values, item.name)}
            onChange={(e) => this.handleInputChange(item, e.target.value)}
            onBlur={(e) => this.notifyChange(item)}
          />
        );
      case 'color':
        return (
          <ColorPicker
            color={colorIntToHex(deep_value(this.state.values, item.name))}
            onChange={(val) => this.handleInputChange(item, val)}
          />
        );
      case 'select':
        return (
          <Select
            size='small'
            value={deep_value(this.state.values, item.name)}
            onChange={(val) => this.handleInputChange(item, val)}
            onBlur={(e) => this.notifyChange(item)}
          >
            {item.values.map((v) => (
              <Select.Option key={v.value} value={v.value}>
                {v.title}
              </Select.Option>
            ))}
          </Select>
        );
      case 'number':
        return (
          <InputNumber
            size='small'
            value={deep_value(this.state.values, item.name)}
            min={0}
            onChange={(val) => this.handleInputChange(item, val)}
            onBlur={(e) => this.notifyChange(item)}
          />
        );
      case 'boolean':
        return (
          <Switch
            onChange={(val) => this.handleInputChange(item, val)}
            defaultChecked={deep_value(this.state.values, item.name)}
          />
        );
      case 'url':
        return (
          <React.Fragment>
            {/* <Input size="small" value={deep_value(this.state.values,`${item.name}.url`)} 
              onChange={(e) => this.handleInputChange(item, e.target.value)}
              onBlur={(e) => this.notifyChange(item)}/>
            <Input size="small" value={deep_value(this.state.values,`${item.name}.title`)} 
              onChange={(e) => this.handleInputChange(item, e.target.value)}
              onBlur={(e) => this.notifyChange(item)}/> */}
          </React.Fragment>
        );
      case 'options':
        return (
          <Options
            value={deep_value(this.state.values, item.name)}
            onChange={(val) => this.handleInputChange(item, val)}
            onBlur={() => this.notifyChange(item)}
          />
        );
      case 'options2':
        return (
          <Options2
            value={deep_value(this.state.values, item.name)}
            onChange={(val) => this.handleInputChange(item, val)}
            onBlur={() => this.notifyChange(item)}
          />
        );
      case 'time':
        return (
          <Time
            value={deep_value(this.state.values, item.name)}
            name={item.name}
            courseId={this.props.courseId}
            onChange={(val) => this.handleInputChange(item, val)}
            onBlur={() => this.notifyChange(item)}
          />
        );
      case 'file':
        return (
          <FileEditor
            value={deep_value(this.state.values, item.name)}
            accept={item.accept}
            subtype={item.subtype}
            note={item.note}
            name={item.name}
            elementId={(this.props.element || {}).id}
            courseId={this.props.courseId}
            service={this.props.service}
            onChange={(val) => this.handleInputChange(item, val)}
            onBlur={() => this.notifyChange(item)}
          />
        );
      case 'text':
      default:
        return (
          <Input
            size='small'
            value={deep_value(this.state.values, item.name)}
            onChange={(e) => this.handleInputChange(item, e.target.value)}
            onBlur={(e) => this.notifyChange(item)}
          />
        );
    }
  };

  editTheme = () => {
    this.setState({ themeEditorModalVisible: true });
  };

  hideThemeEditorModal = () => {
    this.setState({ themeEditorModalVisible: false });
  };

  onThemeChanged = (newTheme, images) => {
    if (images && images.background_image) {
      newTheme.background_image = images.background_image.uri;
    }

    const { element } = this.props;
    const item = {
      name: 'theme',
      display: 'Tema',
      value: element.theme,
      type: 'theme',
    };
    this.notifyChange(item, newTheme);

    var changes = [...this.state.changes, { item, newValue: newTheme }];

    const newValues = immutable.set(this.state.values, item.name, newTheme);
    this.setState({
      values: newValues,
      changes,
      themeEditorModalVisible: false,
    });
  };

  render() {
    const { element, type, saving, slug } = this.props;

    var realType =
      type === 'content'
        ? element.content_template.replace(TPL_PREFIX, '')
        : type;
    const Tpl = Templates.hasOwnProperty(realType)
      ? Templates[realType]
      : Templates.Unknown;

    var ds = element ? Tpl.getProps({ element }) : [];
    return (
      <div className='content-internal' style={{ height: '100%' }}>
        {element && (
          <Spin tip='Guardando...' spinning={saving}>
            <List
              itemLayout='vertical'
              split={false}
              dataSource={ds}
              renderItem={(item) => (
                <List.Item>
                  <label htmlFor={item.name}>{item.display}</label>
                  {this.getInputFor(item)}
                </List.Item>
              )}
            />
            <Button
              block
              type='primary'
              style={{ marginTop: 20 }}
              onClick={this.onSave}
            >
              Guardar
            </Button>
            <Collapse bordered={false} className={styles.hiddenOpts}>
              <Collapse.Panel header='Opciones avanzadas' key='1'>
                {type !== 'sectionSubjects' && (
                  <Button
                    block
                    type='ghost'
                    onClick={this.editTheme}
                    style={{ marginBottom: '10px' }}
                  >
                    Editar tema de pantalla
                  </Button>
                )}
                <Button block type='ghost' onClick={this.onDelete}>
                  Eliminar
                </Button>
              </Collapse.Panel>
            </Collapse>
            <ThemeEditorModal
              visible={this.state.themeEditorModalVisible}
              onSave={this.onThemeChanged}
              onCancel={this.hideThemeEditorModal}
              theme={element.theme || {}}
              slug={slug}
              isContentTheme
            />
          </Spin>
        )}
      </div>
    );
  }
}

export default PropsEditor;
