import React from 'react';
import { Layout } from 'antd';

import './footer.css';

const year = new Date().getFullYear();
const Footer = (props) => (
  <Layout.Footer className="footer">
    Intrena &copy; {year}
  </Layout.Footer> 
);

export default Footer;