import React, { Component } from 'react';
import commonStyles from './common.module.css';
import styles from './infographic.module.css';
import { stylizeText, getAssetUri } from '../../utils';

class InfographicTemplate extends Component {
  state = {
    imageFailed: false,
  };

  onImageError = (e) => {
    if (this.state.imageFailed) return;
    const { element } = this.props;
    this.setState({ imageFailed: true });
    e.target.src = getAssetUri('svg', 'general', element.extended_props.image);
  };

  render() {
    const { element, slug } = this.props;
    const { extended_props } = element;
    const { file } = extended_props || {};

    return (
      <div className={`${styles.container}`}>
        <div className={commonStyles.templateBody}>
          {!!file && (
            <img
              className={commonStyles.templateImage}
              alt={file}
              src={getAssetUri('svg', slug, file)}
              onError={this.onImageError}
            />
          )}
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${
          index + 1
        }. Infografía`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.file',
        display: 'Archivo',
        value: extProps.file,
        type: 'file',
        note: 'Solo imágenes SVG',
        subtype: 'images',
        accept: 'image/svg+xml',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
    ];
  };
}

export default InfographicTemplate;
