import React, { Component } from 'react';
import { Card, Icon } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Miniature from './miniature';
import styles from './strip.module.css';
import miniatureStyles from './miniature.module.css';

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  //background: isDragging ? "lightgreen" : "grey",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  //background: isDraggingOver ? "lightblue" : "lightgrey"
});

class Strip extends Component {

  onDragEnd = (result) => {
    const { draggableId, source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
  }

    const [ oldSectionId, oldSubSectionId ] = source.droppableId.split('||');
    const [ newSectionId, newSubSectionId ] = destination.droppableId.split('||');

    const oldPosition = { sectionId: oldSectionId, subSectionId: oldSubSectionId, index: source.index  };
    const newPosition = { sectionId: newSectionId, subSectionId: newSubSectionId, index: destination.index  };

    this.props.onReorder(draggableId, oldPosition, newPosition);
  }

  isItemSelected = (index, type, parentId) => {
    const { selectedItem } = this.props;
    const parentKey = type === 'content' ? 'subSectionId' : type === 'subsection' || type === 'sectionSubjects' ? 'sectionId' : '';
    const isSelected =  selectedItem && selectedItem.index === index && selectedItem.type === type && (!parentId || selectedItem[parentKey] === parentId);

    return isSelected;
  }

  render() {
    return <DragDropContext onDragEnd={this.onDragEnd}>
      <div className="content-internal" style={{overflow: 'scroll', position: 'relative', height: '100%'}}>
        {this.renderSections()}
      </div> 
    </DragDropContext>
  }

  renderSections = () => {
    const sections = Object.keys(this.props.course.sections||{})
      .map(k => this.props.course.sections[k])
      .sort((a,b)=> a.order - b.order);

    return <div style={{position: 'absolute', top: '10px', left: '10px', right: '10px', bottom: '10px'}}>
      { sections.map((m, idx) => (
        <div key={`sec-${idx}`}>
          <Miniature type="section" ref={this.isItemSelected(idx, 'section') ? this.props.selectedRef : undefined} element={m} index={idx} selected={this.isItemSelected(idx, 'section')} onSelect={this.props.onSelect} />
          {this.renderSubsections(m, idx)}
        </div>
        
      ))}
    </div>;
  }

  renderSubsections = (module, moduleIndex) => {
    const sections = Object.keys(module.sections||{}).map(k => module.sections[k]).sort((a,b)=> a.order - b.order);

    return <div>
      { sections.map((m, idx) => (
        <div key={`subsec-${idx}`}>
          <Miniature type="subsection" ref={this.isItemSelected(idx, 'subsection', module.id) ? this.props.selectedRef : undefined} element={m} index={idx} sectionId={module.id} parentIndex={moduleIndex} onSelect={this.props.onSelect} selected={this.isItemSelected(idx, 'subsection', module.id)} />
          <Miniature type="sectionSubjects" ref={this.isItemSelected(idx, 'sectionSubjects', module.id) ? this.props.selectedRef : undefined} element={m} index={idx} sectionId={module.id} parentIndex={moduleIndex} onSelect={this.props.onSelect} selected={this.isItemSelected(idx, 'sectionSubjects', module.id)} />
          {this.renderContents(m, idx, module.id)}
        </div>
      ))}
    </div>;
  }

  renderContents = (module, submoduleIndex, topSectionId) => {

    const contents = Object.keys(module.contents||{}).map(k => module.contents[k]).sort((a,b)=> a.order - b.order);

    return <Droppable droppableId={`${topSectionId}||${module.id}`}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}>
          { contents.map((m, idx) => (
            <Draggable key={m.id} draggableId={m.id} index={idx}>
              {(provided, snapshot) => (
                <div
                  key={`cnt-${idx}`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <Miniature type="content" element={m} section={module} sectionId={topSectionId} subSectionId={module.id} index={idx} parentIndex={submoduleIndex} onSelect={this.props.onSelect} selected={this.isItemSelected(idx, 'content', module.id)} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
          {this.renderNewContentButton(module.id, topSectionId)}
        </div>
      )}
      </Droppable>;
  }


  renderNewContentButton = (subSectionId, sectionId) => {
    return <div className={`${miniatureStyles.miniature} ${styles.newButton}`}>
      <Card hoverable={true} onClick={() => this.props.onNewContent(sectionId, subSectionId)}>
        <Icon type="plus-circle"/>
        <div>Nuevo contenido</div>
      </Card>
    </div>;
  }
}

export default Strip;
