import React, { PureComponent } from 'react';
import { Button, Form, Slider, Select, Switch, DatePicker, Collapse } from 'antd';
import service from '../../core/services/admin';
import styles from './filters-sidebar.module.css';

const FormItem = Form.Item;

class FiltersSidebar extends PureComponent {
  state = {
    companies: [],
    courses: [],
    areas: [],
    locations: [],
    areasFetched: false
  };

  async componentDidMount() {
    const { results: companies } = (await service.getCompanies());
    this.setState({ companies });
  }

  handleCompanyChange = async (value) => {
    const company = this.state.companies.find(c => c.uuid === value);
    this.setState({ courses: company.courses });
    const areas = (await service.getAreas(company.uuid));
    const locations = (await service.getLocations(company.uuid));
    this.setState({ areas, locations, areasFetched: true });
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onFilter(values);
      }
    });
  }

  render() {
    const { buttonText, form, fetching } = this.props;
    const { getFieldDecorator } = form;

    return <div className={`content-internal ${styles.filters}`}>
      <Form onSubmit={this.handleSubmit} className={styles.form}>
        <FormItem help={false}>
          {getFieldDecorator('company', {
            
            rules: [
              {
                required: true
              }
            ]
          })(
            <Select placeholder="Compañía" onChange={this.handleCompanyChange}>
              { (this.state.companies||[]).map(c => <Select.Option key={c.uuid} value={c.uuid}>{c.name}</Select.Option>) }
            </Select>
          )}
        </FormItem>
        <FormItem  help={false}>
          {getFieldDecorator('course', {
            rules: [
              {
                required: true
              }
            ]
          })(
            <Select placeholder="Curso">
              { (this.state.courses||[]).map(c => <Select.Option key={c.uuid} value={c.uuid}>{c.name}</Select.Option>) }
            </Select>
          )}
        </FormItem>
        <Collapse bordered={false}>
            <Collapse.Panel header="Otros filtros">
              <FormItem  help={false}>
                {getFieldDecorator('areas', {
                  rules: [
                    {
                      required: false
                    }
                  ]
                })(
                  <Select placeholder="Áreas" showArrow={true} mode="multiple" maxTagCount={3} notFoundContent={`${ this.state.areasFetched ? 'No se encontraron areas' : 'Seleccione la compañía' }`}>
                    { (this.state.areas||[]).map(c => <Select.Option key={c} value={c}>{c}</Select.Option>) }
                  </Select>
                )}
              </FormItem>
              <FormItem  help={false}>
                {getFieldDecorator('location', {
                  rules: [
                    {
                      required: false
                    }
                  ]
                })(
                  <Select placeholder="Ubicación" showArrow={true} mode="multiple" maxTagCount={3} notFoundContent={`${ this.state.areasFetched ? 'No se encontraron ubicaciones' : 'Seleccione la compañía' }`}>
                    { (this.state.locations||[]).map(c => <Select.Option key={c} value={c}>{c}</Select.Option>) }
                  </Select>
                )}
              </FormItem>
              <FormItem  help={false}>
                {getFieldDecorator('dates', {
                  rules: [
                    {
                      required: false
                    }
                  ]
                })(
                  <DatePicker.RangePicker placeholder={['Desde', 'Hasta']} />
                )}
              </FormItem>
              <FormItem
                colon={false}
                labelCol={{span:19}}
                wrapperCol={{span:5}}
                label="Incluir puntajes en cero">
                {getFieldDecorator('include_zeroes', { valuePropName: 'checked', initialValue: true })(
                    <Switch />
                  )}
              </FormItem>
              <FormItem>
                <label htmlFor="users_per_table">Usuarios por tabla</label>
                {getFieldDecorator('users_per_table', { initialValue: 10 })(
                    <Slider max={30} marks={{ 0: '0', 10: '10', 20: '20', 30: '30' }} />
                  )}
              </FormItem>
              <FormItem>
                <label htmlFor="areas_per_table">Áreas por tabla</label>
                {getFieldDecorator('areas_per_table', { initialValue: 10 })(
                    <Slider max={30} marks={{ 0: '0', 10: '10', 20: '20', 30: '30' }} />
                  )}
              </FormItem>
            </Collapse.Panel>
        </Collapse>
        
        <FormItem>
          <Button block size="large" type="primary" htmlType="submit" loading={fetching}>{buttonText}</Button>
        </FormItem>
      </Form>
      
    </div>
  }
}

export default Form.create()(FiltersSidebar);
