import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Icon, Dropdown, Menu, Button, Table, Modal } from 'antd';
import { Link } from 'react-router-dom';

import { actions } from './external-courses.redux';
import './external-courses.page.css';
import NewCourseModal from './new-course.modal';
import EditCourseModal from './edit-course.modal';
import ExternalCoursesService from '../core/services/externalCourses';
import AddSkillModal from './add-skill.modal';

const confirm = Modal.confirm;



class ExternalCoursesPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    editElement: null,
    skillsModalVisible: false,
    assigning: false,
    selectedCourse: null
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  handleTableAction = async (action, record) => {
    console.warn(`Action ${action}`);

    switch (action) {
      case 'edit':
        this.openEdit(record);
        break;
      case 'delete':
        this.showDeleteConfirm(record);
        break;
      case 'skills':
        this.openSkillsModal(record.id);
        break;
      default:
        console.warn('unknown action');
    }
  }

  componentDidMount() {

    this.columns = [
      {
        title: 'Código',
        dataIndex: 'code'
      },
      {
        title: 'Nombre',
        dataIndex: 'name',
        render: (val, record) => {
          return <Link to={"/external-courses/" + record.id}>{val}</Link>;
        }
      },
      {
        title: 'Descripción',
        dataIndex: 'description'
      },
      {
        title: 'Horas',
        dataIndex: 'hours'
      },
      {
        title: 'Calificable',
        dataIndex: 'assessable',
        render: (val, record) => val ? 'Sí' : 'No'
      },
      {
        title: 'Competencia',
        dataIndex: 'competence'
      },
      {
        title: 'Escuela',
        dataIndex: 'category'
      },
      {
        title: 'Instructor',
        dataIndex: 'instructor_name'
      },
      {
        title: 'Tipo de instructor',
        dataIndex: 'instructor_type',
        render: (val) => val === 'internal' ? 'Interno' : 'Externo'
      }
    ];
    if (this.canEdit()) {
      this.columns.push({
        title: '',
        width: 40,
        render: (val, record) => <Dropdown overlay={(
          <Menu onClick={({ item, key }) => this.handleTableAction(key, record)}>
            <Menu.Item key="edit"><Icon type="edit" /> Editar</Menu.Item>
            <Menu.Item key="delete"><Icon type="delete" /> Eliminar</Menu.Item>
            <Menu.Item key="skills"><Icon type="safety" /> Asignar competencias</Menu.Item>
          </Menu>
        )} trigger={['click']}>
          <Button type="link" className="tbl-action">
            <Icon type="ellipsis" />
          </Button>
        </Dropdown>
      });
    }

    this.props.fetch(1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metadata) {
      this.setState({ pagination: { ...this.state.pagination, total: nextProps.metadata.total, pageSize: nextProps.metadata.limit } })
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.error) {
        alert(nextProps.error);
      }
      else {
        this.refreshTable();
      }
    }

    if (this.props.deleting && !nextProps.deleting) {
      if (nextProps.error) {
        alert(nextProps.error);
      }
      else {
        this.refreshTable();
      }
    }
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.hideModal();
    this.hideEditModal();
    this.setState({ selectedRowKeys: [], editElement: null });
    this.props.fetch(this.state.pagination.current || 1);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    await this.setState({ pagination });
    this.props.fetch(pagination.current);
  }

  getCheckboxProps = (record) => {
    return {
      disabled: false,//record.disabled,
      name: record.name
    }
  }

  showDeleteConfirm = (record) => {

    confirm({
      title: `¿Estás seguro que deseas eliminar el curso seleccionado?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.delete(record.id)
      }
    });
  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps
    };

    const hasSelected = this.state.selectedRowKeys.length > 0;
    return <div className="content-internal">
      {this.canEdit() && <div className="top-buttons">
        <Button type="primary" icon="plus" onClick={this.openCreate}>Nuevo curso externo</Button>
        {hasSelected &&
          <Button icon="delete" onClick={this.showDeleteConfirm}>Eliminar cursos</Button>
        }
      </div>}
      <Table loading={this.props.fetching} size="small" className="striped-rows"
        onChange={this.handleTableChange} pagination={this.state.pagination}
        rowKey={r => r.id} rowSelection={this.canEdit() ? rowSelection : undefined} columns={this.columns}
        dataSource={this.props.courses} locale={{ emptyText: 'En este módulo podrás registrar cursos externos a Intrena que han realizado los empleados para poder hacerle seguimiento a su formación' }} />
      <NewCourseModal wrappedComponentRef={this.saveFormRef} visible={this.state.modalVisible} onCancel={this.hideModal} onCreate={this.handleCreate} confirmLoading={this.props.saving} />
      <EditCourseModal wrappedComponentRef={this.saveEditFormRef} course={this.state.editElement} visible={this.state.editModalVisible} onCancel={this.hideEditModal} onCreate={this.handleEdit} confirmLoading={this.props.saving} />
      <AddSkillModal visible={this.state.skillsModalVisible}
        onCancel={this.hideSkillsModal}
        onOk={this.handleAssignSkills}
        courseId={this.state.selectedCourse}
        confirmLoading={this.state.assigning}
      />
    </div>
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  saveEditFormRef = (formRef) => {
    this.editFormRef = formRef;
  }

  openCreate = () => {
    this.setState({ modalVisible: true });
  }

  openEdit = (course) => {
    this.setState({ editElement: course, editModalVisible: true });
  }

  hideModal = () => {
    this.setState({ modalVisible: false });
  }

  hideEditModal = () => {
    this.setState({ editModalVisible: false });
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.create(values);
    });
  }

  handleEdit = () => {
    const form = this.editFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.edit(this.state.editElement.id, values);
    });
  }

  openSkillsModal = (courseId) => {
    this.setState({ selectedCourse: courseId, skillsModalVisible: true });
  }

  hideSkillsModal = () => {
    this.setState({ skillsModalVisible: false, selectedCourse: null });
  }

  handleAssignSkills = async (skills) => {
    await ExternalCoursesService.assignSkills(this.state.selectedCourse, skills);
    this.hideSkillsModal();
  }
}

function mapStateToProps(state) {
  return { ...state.externalCourses, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    create: (course) => dispatch(actions.create(course)),
    edit: (id, course) => dispatch(actions.edit(id, course)),
    delete: (id) => dispatch(actions.delete(id)),
    fetch: (page) => dispatch(actions.fetch(page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalCoursesPage);
