import React, { PureComponent } from 'react';
import { Modal, Select, Spin } from 'antd';
import SkillsService from '../core/services/skills';
import CoursesService from '../core/services/courses';

class AddSkillModal extends PureComponent {

  state = {
    value: [],
    skills: [],
    fetchingSkills: false
  }

  async componentDidMount() {
    await this.fetchSkills(null);
  }

  async componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
      await this.fetchSkills(null);
    }
  }

  fetchSkills = async (value) => {
    this.setState({ skills: [], fetchingSkills: true });
    try {
      const skills = await SkillsService.list(value, 1000, 0);
      const current = this.props.courseId ? await CoursesService.getAssignedSkills(this.props.courseId) : [];

      this.setState({ value: current, skills: skills.results, fetchingSkills: false });
    }
    finally {
      this.setState({ fetchingSkills: false });
    }
  };

  onOk = () => {
    const val = this.state.value;
    this.setState({ value: [] });
    this.props.onOk(val);
  }

  onCancel = () => {
    this.setState({ value: [] });
    this.props.onCancel();
  }

  handleChange = value => {
    this.setState({
      value
    });
  };

  render() {
    const { visible, confirmLoading } = this.props;
    const { value, fetchingSkills, skills } = this.state;

    return <Modal title="Asociar competencias"
      visible={visible}
      onOk={this.onOk}
      confirmLoading={confirmLoading || fetchingSkills}
      destroyOnClose={true}
      onCancel={this.onCancel}
      width="60vw"
      okText="Guardar"
      cancelText="Cancelar">
      {/* <Spin tip="Cargando..." spinning={fetchingSkills}> */}
      <Select placeholder="Seleccione o agregue competencias"
        value={value} onChange={this.handleChange} notFoundContent={fetchingSkills ? <Spin size="small" /> : null}
        mode="tags" loading={fetchingSkills} labelInValue
        style={{ width: '100%' }}
      >
        {(skills ?? []).map(c => <Select.Option key={c.id}>{c.name}</Select.Option>)}
      </Select>
      {/* </Spin> */}
    </Modal>
  }
}

export default AddSkillModal;
