import React, { PureComponent } from 'react';
import { Modal, Form, Input, Select, Upload, Icon } from 'antd';
import { getBase64 } from '../admin/course-design/utils';
import styles from './instructors-modal.module.css';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class EditInstructorModal extends PureComponent {

  state = {
    courses: [],
    files: {},
    imageUrl: null,
  }

  onCancel = (e) => {
    const { onCancel } = this.props;
    this.setState({
      image: null,
    });
    if (onCancel) onCancel(e);
  }

  onOk = () => {
    this.props.onCreate(this.state.files);
  }

  beforeUpload = (file, propName, fieldName) => {
    // Get this url from response in real world.
    getBase64(file, imageUrl =>
      this.setState({
        [propName]: imageUrl,
        files: { ...this.state.files, [fieldName]: file }
      })
    );

    return false;
  }

  render() {
    const { visible, form, confirmLoading, instructor } = this.props;
    const { getFieldDecorator } = form;
    const { imageUrl } = this.state;

    if (!instructor) return <></>;

    return <Modal title="Editar curso externo"
      visible={visible}
      onOk={this.onOk}
      confirmLoading={confirmLoading}
      onCancel={this.onCancel}
      okText="Guardar"
      cancelText="Cancelar">
      <Form>
        <FormItem
          {...formItemLayout}
          label="Código">
          {getFieldDecorator('code', {
            initialValue: instructor.code,
            rules: []
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Nombre">
          {getFieldDecorator('name', {
            initialValue: instructor.name,
            rules: [{
              required: true, message: 'Debes especificar el nombre',
            }]
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Descripción">
          {getFieldDecorator('description', {
            initialValue: instructor.description,
            rules: []
          })(
            <Input.TextArea />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Tipo">
          {getFieldDecorator('type', {
            initialValue: instructor.type,
            rules: [],
          })(
            <Select>
              <Select.Option value="internal" >Interno</Select.Option>
              <Select.Option value="external">Externo</Select.Option>
            </Select>
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Link para chat">
          {getFieldDecorator('chat_link', {
            initialValue: instructor.chat_link,
            rules: []
          })(
            <Input />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Logo">
          {getFieldDecorator('image', {
            hidden: true,
            valuePropName: 'fileList'
          })(
            <Upload
              name="instructor_logo"
              listType="picture-card"
              className={styles.uploader}
              showUploadList={false}
              beforeUpload={(file) => this.beforeUpload(file, 'imageUrl', 'image')}
            >
              {imageUrl ? <img src={imageUrl} alt="Instructor Logo" style={{ width: '100%' }} /> :
                <div>
                  <Icon type="plus" />
                  <div className="ant-upload-text">Cargar</div>
                </div>}
            </Upload>
          )}
        </FormItem>
      </Form>
    </Modal>
  }
}

export default Form.create()(EditInstructorModal);
