import React, { Component } from 'react';
import { Input, Modal, Button, Table, Form, Popconfirm, Icon, InputNumber } from 'antd';
import styles from './options.module.css';

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber size="small"/>;
    }
    return <Input size="small"/>;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data, editingKey: '' };
    this.columns = [
      {
        title: 'Texto',
        dataIndex: 'text',
        editable: true,
        inputType: 'text'
      },
      {
        title: 'Valor',
        dataIndex: 'value',
        editable: true,
        width: '80px',
        inputType: 'number'
      },
      {
        title: 'Orden',
        dataIndex: 'order',
        editable: true,
        width: '80px',
        inputType: 'number'
      },
      {
        title: 'Pos. correcta',
        dataIndex: 'correct_position',
        editable: true,
        width: '80px',
        inputType: 'number'
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Button type="link" style={{ marginRight: 10 }} 
                    title="Guardar" onClick={() => this.save(form, record.id)}>
                    <Icon type="check-circle"/>
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="¿Seguro que deseas cancelar la edición?" onConfirm={() => this.cancel(record.id)}>
                <Icon type="close-circle" title="Cancelar"/>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <Button type="link" disabled={editingKey !== ''} style={{marginRight:10}} title="Editar" onClick={() => this.edit(record.id)}>
                <Icon type="edit"/>
              </Button>
              <Popconfirm title="¿Seguro que deseas eliminar la opción?" onConfirm={() => this.delete(record.id)}>
                <Button type="link" disabled={editingKey !== ''} title="Eliminar">
                  <Icon type="delete"/>
                </Button>
              </Popconfirm>
              
            </span>
          );
        },
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.data !== this.props.data) {
      this.setState({data: nextProps.data});
    }
  }

  isEditing = record => record.id === this.state.editingKey;

  cancel = () => {
    
    if(this.state.editingKey === '-1') {
      var newData = [...this.state.data];
      const index = newData.findIndex(item => this.state.editingKey === item.id);
      if(index >= 0) newData.splice(index,1);
      this.setState({ data: newData });
    }

    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.id);
      if (index > -1) {
        const item = newData[index];
        if(item.id === '-1') item.id = newData.length;
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: '' });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }

      this.props.onSave(newData);
    });
  }

  delete(key) {
    const newData = [...this.state.data];
    const index = newData.findIndex(item => key === item.id);
    if(index >= 0) newData.splice(index, 1);
    this.setState({ data: newData });
    this.props.onSave(newData);
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  addNewItem = () => {
    const ds = [...this.state.data];
    ds.push({ id: '-1', text: ''});
    this.setState({ data: ds, editingKey: '-1' });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          dataSource={this.state.data}
          columns={columns}
          rowKey="id"
          rowClassName={styles.editableRow}
          pagination={false}
          size="small"
          className="striped-rows"
        />
        { this.state.editingKey === '' && <Button icon="plus-circle" style={{marginTop:20}} onClick={this.addNewItem}>Agregar nueva opción</Button>}
      </EditableContext.Provider>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);


class Options extends Component {

  state = {
    dataSource:[],
    modalOpen: false
  }

  componentDidMount() {
    this.initializeOptions(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.initializeOptions(nextProps);
  }

  initializeOptions = (props) => {
    const ds = Object.keys(props.value||{}).map(k => props.value[k]);
    this.setState({ dataSource: ds });
  }

  openModal = () => {
    this.setState({ modalOpen: true });
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  onChangeSaved = (data) => {
    this.setState({ dataSource: data.sort((a,b) => a.order - b.order) });
  }

  setValue = () => {
    var formattedValue = this.state.dataSource.reduce((prev, curr) => { prev[curr.id] = curr; return prev; } , {});
    this.props.onChange(formattedValue);
    this.closeModal();
  }

  render() {
    const { dataSource } = this.state;

    return <React.Fragment>
      <Button block onClick={this.openModal}>Editar opciones</Button>
      <Modal
        title="Opciones"
        width="80vw"
        visible={this.state.modalOpen}
        okText="Guardar"
        cancelText="Cancelar"
        destroyOnClose={true}
        onOk={this.setValue}
        onCancel={this.closeModal}>
          <EditableFormTable data={dataSource} onSave={this.onChangeSaved}  />
      </Modal>
    </React.Fragment>
  }
}

export default Options;
