import React, { Component } from 'react';
import { Tag } from 'antd';
import commonStyles from './common.module.css';
import styles from './drag-and-drop.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class DragAndDropTemplate extends Component {
  onImageError = (e) => {
    const { element } = this.props;
    e.target.onError = null;
    e.target.src = getAssetUri(
      'images',
      'general',
      (element.extended_props || {}).image_empty
    );
  };

  render() {
    const { element, section, slug, theme } = this.props;
    const { title, extended_props, options } = element;
    const {
      description,
      image_empty,
      imageWidthDP,
      imageHeightDP,
      imagePadding,
    } = extended_props || {};

    const opts = Object.keys(options || {})
      .map((k) => options[k])
      .sort((a, b) => a.order - b.order);

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    const imgStyles = {};
    if (imageWidthDP) imgStyles.width = imageWidthDP / 10 + 'vw';
    else if (!imageWidthDP && !imageHeightDP && !imagePadding)
      imgStyles.width = 150 / 10 + 'vw';
    if (imageHeightDP) imgStyles.height = imageHeightDP / 10 + 'vw';
    if (imagePadding) imgStyles.padding = imagePadding / 10 + 'vw';

    return (
      <div
        className={`${commonStyles.templateContainer}`}
        style={containerStyles}
      >
        <div className={commonStyles.templateTitle} style={textStyles}>
          {stylizeText(title, controlStyles.backgroundColor)}
        </div>
        {description && (
          <div
            className={commonStyles.templateText}
            style={{ ...textStyles, marginTop: '4vw' }}
          >
            {stylizeText(description, controlStyles.backgroundColor)}
          </div>
        )}
        <div className={commonStyles.templateBody}>
          <img
            className={`${commonStyles.templateImage} ${styles.image}`}
            style={imgStyles}
            alt={image_empty}
            src={getAssetUri('images', slug, image_empty)}
            onError={this.onImageError}
          />
          <div className={styles.optionsContainer}>
            {opts.map((o) => (
              <Tag
                className={styles.option}
                style={controlStyles}
                color={controlStyles.backgroundColor}
              >
                {o.text}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${index + 1}. Arrastrar`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.image_full',
        display: 'Imagen lleno',
        value: extProps.image_full,
        type: 'file',
        subtype: 'images',
        note: 'Solo imágenes PNG o SVG',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'extended_props.image_empty',
        display: 'Imagen vacío',
        value: extProps.image_empty,
        type: 'file',
        subtype: 'images',
        note: 'Solo imágenes PNG o SVG',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'extended_props.description',
        display: 'Descripción',
        value: extProps.description,
        type: 'multiline',
      },
      {
        name: 'extended_props.imageWidthDP',
        display: 'Ancho imagen',
        value: extProps.imageWidthDP,
        type: 'number',
      },
      {
        name: 'extended_props.imageHeightDP',
        display: 'Alto imagen',
        value: extProps.imageHeightDP,
        type: 'number',
      },
      {
        name: 'extended_props.imagePadding',
        display: 'Padding',
        value: extProps.imagePadding,
        type: 'number',
      },
      {
        name: 'extended_props.hide_result',
        display: 'Ocultar resultado',
        value: extProps.hide_result,
        type: 'boolean',
      },
      {
        name: 'extended_props.only_feedback',
        display: 'Sólo feedback',
        value: extProps.only_feedback,
        type: 'boolean',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
      {
        name: 'options',
        display: 'Opciones',
        value: element.options,
        type: 'options',
      },
    ];
  };
}

export default DragAndDropTemplate;
