import BaseService from './baseService';

export default class AreasService extends BaseService {
  static async list(query, limit, skip) {
    return await super.request(
      `/areas?limit=${limit}&skip=${skip}&q=${
        query ? encodeURIComponent(query) : ''
      }`
    );
  }

  static async create(code, name) {
    return await super.request(`/areas`, 'POST', { code, name });
  }

  static async update(id, code, name) {
    return await super.request(`/areas/${id}`, 'PUT', { code, name });
  }

  static async delete(ids) {
    return await super.request(`/areas/${JSON.stringify(ids)}`, 'DELETE');
  }

  static async listUsers(areaId, limit, skip) {
    return await super.request(
      `/areas/${areaId}/users?limit=${limit}&skip=${skip}`
    );
  }

  static async import(data) {
    return await super.request(`/areas/import`, 'POST', { file: data });
  }
}
