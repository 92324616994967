import React from 'react';

export const TPL_PREFIX =
  'co.labhouse.didacta.ui.courses.course_playback.content_templates.';

export const colorIntToHex = (colorInt) => {
  if (!colorInt) return null;
  var result = (colorInt >>> 0).toString(16).slice(-6);
  if (result.length < 6) result = '0' + result;
  return `#${result}`;
};

export const colorHexToInt = (hex) => {
  return parseInt(`ff${hex}`, 16) << 0;
};

export const isAbsoluteUri = (text) =>
  text.startsWith('https://') ||
  text.startsWith('http://') ||
  text.startsWith('data:image');

export const getAssetUri = (type, slug, name, use2x = true) => {
  if (!name) return null;
  if (isAbsoluteUri(name)) return name;
  var ext =
    type === 'images'
      ? use2x
        ? '@2x.png'
        : '.png'
      : type === 'audio'
      ? '.m4a'
      : '.svg';
  var pathTemplate = `${
    type === 'svg' ? 'images' : type
  }/app/courses/${slug}/${name}${ext}`;
  var uri = `https://firebasestorage.googleapis.com/v0/b/project-7764566410387601080.appspot.com/o/${encodeURIComponent(
    pathTemplate
  )}?alt=media`;

  return uri;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const stylizeText = (text, emphasisColor) => {
  if (!text) return '';
  var segments = [];
  var isBold = false;
  var isItalic = false;
  var isStrikeThrough = false;
  var isCode = false;
  var currentText = '';

  [...text].forEach((c, idx) => {
    if (c === '*') {
      if (currentText.length > 0) {
        segments.push(
          <span
            key={'seg_' + idx}
            style={{ fontWeight: isBold ? 'bold' : undefined }}
            dangerouslySetInnerHTML={{
              __html: currentText.replace('<ASTK>', '*'),
            }}
          ></span>
        );
        currentText = '';
      }
      isBold = !isBold;
    } else if (c === '_') {
      if (currentText.length > 0) {
        segments.push(
          <span
            key={'seg_' + idx}
            style={{ fontStyle: isItalic ? 'italic' : undefined }}
            dangerouslySetInnerHTML={{
              __html: currentText.replace('<ASTK>', '*'),
            }}
          ></span>
        );
        currentText = '';
      }
      isItalic = !isItalic;
    } else if (c === '\n') currentText += '<br/>';
    else if (c === '~') {
      if (currentText.length > 0) {
        segments.push(
          <span
            key={'seg_' + idx}
            style={{
              textDecorationLine: isStrikeThrough ? 'line-through' : undefined,
            }}
            dangerouslySetInnerHTML={{
              __html: currentText.replace('<ASTK>', '*'),
            }}
          ></span>
        );
        currentText = '';
      }
      isStrikeThrough = !isStrikeThrough;
    } else if (c === '`') {
      if (currentText.length > 0) {
        segments.push(
          <span
            key={'seg_' + idx}
            style={{ color: isCode ? emphasisColor : undefined }}
            dangerouslySetInnerHTML={{
              __html: currentText.replace('<ASTK>', '*'),
            }}
          ></span>
        );
        currentText = '';
      }
      isCode = !isCode;
    } else currentText += c;
  });

  if (currentText.length > 0) {
    segments.push(
      <span
        key={'seg_last'}
        style={{ fontWeight: isBold ? 'bold' : undefined }}
        dangerouslySetInnerHTML={{ __html: currentText }}
      ></span>
    );
  }

  return segments;
};
