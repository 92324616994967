import { createActions, handleActions } from 'redux-actions';

import client from '../core/services/certifications';

//INITIAL STATE
const initialState = {
  certifications: [],
  fetching: false,
  error: null,
  metadata: null,
  deleting: false,
  saving: false,
  saving_error: null,
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

export const actions = createActions(
  {
    fetch: (query, page) => client.list(query, 10, 10 * (page - 1)),
    create: (name, duration) => client.create(name, duration),
    update: (id, name, duration) => client.update(id, name, duration),
    delete: (ids) => client.delete(ids),
  },
  { prefix: 'certifications' }
);

//REDUCER
const reducer = handleActions(
  {
    [`${actions.fetch}_${pending}`]: (state) => {
      return { ...state, fetching: true, error: null };
    },
    [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
      return {
        ...state,
        certifications: payload.results,
        metadata: payload.pagination,
        error: null,
        fetching: false,
      };
    },
    [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
      return { ...state, error: payload, fetching: false };
    },
    [`${actions.create}_${pending}`]: (state) => {
      return { ...state, saving: true, error: null };
    },
    [`${actions.create}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, error: null, saving: false };
    },
    [`${actions.create}_${rejected}`]: (state, { payload }) => {
      return { ...state, error: payload, saving: false };
    },
    [`${actions.update}_${pending}`]: (state) => {
      return { ...state, saving: true, error: null };
    },
    [`${actions.update}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, error: null, saving: false };
    },
    [`${actions.update}_${rejected}`]: (state, { payload }) => {
      return { ...state, error: payload, saving: false };
    },
    [`${actions.delete}_${pending}`]: (state) => {
      return { ...state, deleting: true, error: null };
    },
    [`${actions.delete}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, error: null, deleting: false };
    },
    [`${actions.delete}_${rejected}`]: (state, { payload }) => {
      return { ...state, error: payload, deleting: false };
    },
  },
  initialState
);

export default reducer;
