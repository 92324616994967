import React, { useState, useEffect } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import DashboardService from '../core/services/dashboard';

import 'react-circular-progressbar/dist/styles.css';
import * as styles from './indicator.module.css';

const Impact = ({filters}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await DashboardService.attendance(filters);
      setData(res);
    }
    
    fetchData();
  }, [filters]);
  
  return (
      <CircularProgressbarWithChildren 
      value={data ? data.percentage*100 : 0}
      styles={buildStyles({
        pathColor: 'rgb(0, 21, 41)',
        textColor: 'rgb(0, 21, 41)'
      })}>
        { !!data ? <>
          <div className={styles.title}>{`${(data.percentage||0)*100} %`}</div>
          <div className={styles.subtitle}>{`${data.assistance||0} de ${data.total} formados`}</div></>
          :
          <div className={styles.subtitle}>Cargando...</div>
        }
      </CircularProgressbarWithChildren>
  );
};

export default Impact;