import { createActions, handleActions } from 'redux-actions';

import client from '../core/services/users';
import certificationsClient from '../core/services/certifications';

// INITIAL STATE
const initialState = {
  users: [],
  fetching: false,
  error: null,
  metadata: null,
  custom_fields: [],
  areas: [],
  fetching_areas: false,
  error_areas: null,
  positions: [],
  fetching_positions: false,
  error_positions: null,
  saving: false,
  user: null,
  user_fetching: false,
  user_error: null,
  user_courses: [],
  whatsapp_user_courses: [],
  fetching_user_courses: false,
  fetching_whatsapp_user_courses: false,
  error_user_courses: null,
  error_whatsapp_user_courses: null,
  audit_info: [],
  fetching_audit_info: false,
  error_audit_info: null,
  badges: [],
  fetching_badges: false,
  error_badges: null,
  certifications: [],
  fetching_certifications: false,
  error_certifications: null,
};

// ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

export const actions = createActions(
  {
    FETCH: (query, page) => client.list(query, 10, 10 * (page - 1)),
    GET: (userId) => client.find(userId),
    GETAREAS: () => client.getAreas(),
    GETPOSITIONS: () => client.getPositions(),
    CREATE: (
      username,
      email,
      firstName,
      lastName,
      mobile,
      avatar,
      area,
      position,
      role,
      pass,
      identification_number,
      location,
      manager,
      extended_field1,
      extended_field2,
      extended_field3,
      extended_field4,
      extended_field5,
      extended_field6,
      extended_field7,
      organizational_units,
    ) => client.create(
      username,
      email,
      firstName,
      lastName,
      mobile,
      avatar,
      area,
      position,
      role,
      pass,
      identification_number,
      location,
      manager,
      extended_field1,
      extended_field2,
      extended_field3,
      extended_field4,
      extended_field5,
      extended_field6,
      extended_field7,
      organizational_units,
    ),
    EDIT: (
      id,
      email,
      firstName,
      lastName,
      mobile,
      avatar,
      area,
      position,
      role,
      identification_number,
      location,
      manager,
      extended_field1,
      extended_field2,
      extended_field3,
      extended_field4,
      extended_field5,
      extended_field6,
      extended_field7,
      organizational_units,
    ) => client.update(
      id,
      email,
      firstName,
      lastName,
      mobile,
      avatar,
      area,
      position,
      role,
      identification_number,
      location,
      manager,
      extended_field1,
      extended_field2,
      extended_field3,
      extended_field4,
      extended_field5,
      extended_field6,
      extended_field7,
      organizational_units,
    ),
    CHANGEPASSWORD: (id, password) => client.changePassword(id, password),
    ENABLEDISABLE: (id, action) => client.enableDisable(id, action),
    IMPORT: (data) => client.import(data),
    BULKDISABLEENABLE: (data, action) => client.bulkEnableDisable(data, action),
    GETUSERCOURSES: (userId) => client.getUserCourses(userId),
    GETWHATSAPPUSERCOURSES: (userId) => client.getWhatsappUserCourses(userId),
    GETAUDITINFO: (userId) => client.getAuditInfo(userId),
    GETBADGES: (userId) => client.getBadges(userId),
    GETCERTIFICATIONS: (userId) => certificationsClient.listForUser(userId),
    ASSIGNCERTIFICATION: (userId, certId, valid_through, date_completed) => certificationsClient.assign(
      userId,
      certId,
      valid_through,
      date_completed,
    ),
    EDITCERTIFICATIONASSIGNMENT: (userId, id, certId, valid_through, date_completed) => certificationsClient.updateAssignment(
      userId,
      id,
      certId,
      valid_through,
      date_completed,
    ),
    UNASSIGNCERTIFICATION: (userId, certId) => certificationsClient.unassign(userId, certId),
    ADDATTACHMENTS: (userId, certId, files) => certificationsClient.addAttachments(userId, certId, files),
    REVALIDATE: (userId, courseId, percentage, date, comments) => client.revalidate(userId, courseId, percentage, date, comments),
  },
  { prefix: 'users' },
);

// REDUCER
const reducer = handleActions(
  {
    [`${actions.fetch}_${pending}`]: (state) => ({ ...state, fetching: true, error: null }),
    [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      users: payload.results,
      metadata: payload.pagination,
      custom_fields: payload.custom_fields,
      error: null,
      fetching: false,
    }),
    [`${actions.fetch}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, fetching: false }),
    [`${actions.create}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.create}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.create}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.edit}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.edit}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.edit}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.changepassword}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.changepassword}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.changepassword}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.enabledisable}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.enabledisable}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.enabledisable}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.import}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.import}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.import}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.bulkdisableenable}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.bulkdisableenable}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.bulkdisableenable}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.getareas}_${pending}`]: (state) => ({ ...state, fetching_areas: true, error_areas: null }),
    [`${actions.getareas}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      areas: payload,
      error_areas: null,
      fetching_areas: false,
    }),
    [`${actions.getareas}_${rejected}`]: (state, { payload }) => ({ ...state, error_areas: payload, fetching_areas: false }),
    [`${actions.getpositions}_${pending}`]: (state) => ({ ...state, fetching_positions: true, error_positions: null }),
    [`${actions.getpositions}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      positions: payload,
      error_positions: null,
      fetching_positions: false,
    }),
    [`${actions.getpositions}_${rejected}`]: (state, { payload }) => ({ ...state, error_positions: payload, fetching_positions: false }),
    [`${actions.get}_${pending}`]: (state) => ({ ...state, user_fetching: true, user_error: null }),
    [`${actions.get}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      user: payload.result,
      custom_fields: payload.custom_fields,
      user_error: null,
      user_fetching: false,
    }),
    [`${actions.get}_${rejected}`]: (state, { payload }) => ({ ...state, user_error: payload, user_fetching: false }),
    [`${actions.getusercourses}_${pending}`]: (state) => ({
      ...state,
      fetching_user_courses: true,
      error_user_courses: null,
    }),
    [`${actions.getusercourses}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      user_courses: payload,
      error_user_courses: null,
      fetching_user_courses: false,
    }),
    [`${actions.getusercourses}_${rejected}`]: (state, { payload }) => ({
      ...state,
      error_user_courses: payload,
      fetching_user_courses: false,
    }),
    [`${actions.getwhatsappusercourses}_${pending}`]: (state) => ({
      ...state,
      fetching_whatsapp_user_courses: true,
      error_whatsapp_user_courses: null,
    }),
    [`${actions.getwhatsappusercourses}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      whatsapp_user_courses: payload,
      error_whatsapp_user_courses: null,
      fetching_whatsapp_user_courses: false,
    }),
    [`${actions.getwhatsappusercourses}_${rejected}`]: (state, { payload }) => ({
      ...state,
      error_whatsapp_user_courses: payload,
      fetching_whatsapp_user_courses: false,
    }),
    [`${actions.getauditinfo}_${pending}`]: (state) => ({ ...state, fetching_audit_info: true, error_audit_info: null }),
    [`${actions.getauditinfo}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      audit_info: payload,
      error_audit_info: null,
      fetching_audit_info: false,
    }),
    [`${actions.getauditinfo}_${rejected}`]: (state, { payload }) => ({
      ...state,
      error_audit_info: payload,
      fetching_audit_info: false,
    }),
    [`${actions.revalidate}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.revalidate}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.revalidate}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.getbadges}_${pending}`]: (state) => ({ ...state, fetching_badges: true, error_badges: null }),
    [`${actions.getbadges}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      badges: payload,
      error_badges: null,
      fetching_badges: false,
    }),
    [`${actions.getbadges}_${rejected}`]: (state, { payload }) => ({
      ...state,
      badges: {},
      error_badges: payload,
      fetching_badges: false,
    }),
    [`${actions.getcertifications}_${pending}`]: (state) => ({
      ...state,
      fetching_certifications: true,
      error_certifications: null,
    }),
    [`${actions.getcertifications}_${fulfilled}`]: (state, { payload }) => ({
      ...state,
      certifications: payload,
      error_certifications: null,
      fetching_certifications: false,
    }),
    [`${actions.getcertifications}_${rejected}`]: (state, { payload }) => ({
      ...state,
      certifications: {},
      error_certifications: payload,
      fetching_certifications: false,
    }),
    [`${actions.assigncertification}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.assigncertification}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.assigncertification}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.editcertificationassignment}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.editcertificationassignment}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.editcertificationassignment}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.unassigncertification}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.unassigncertification}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.unassigncertification}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
    [`${actions.addattachments}_${pending}`]: (state) => ({ ...state, saving: true, error: null }),
    [`${actions.addattachments}_${fulfilled}`]: (state, { payload }) => ({ ...state, saving: false, error: null }),
    [`${actions.addattachments}_${rejected}`]: (state, { payload }) => ({ ...state, error: payload, saving: false }),
  },
  initialState,
);

export default reducer;
