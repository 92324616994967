import React, { PureComponent } from 'react';
import { Modal, Form, Input, Tooltip, Icon, Switch } from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class ChangePasswordModal extends PureComponent {
  render() {
    const { visible, onCancel, onCreate, form, confirmLoading, user } =
      this.props;
    const { getFieldDecorator } = form;

    if (!user) return <div />;
    return (
      <Modal
        title='Restablecer contraseña usuario'
        visible={visible}
        onOk={onCreate}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Form>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Especificar contraseña?&nbsp;
                <Tooltip title='Si no se especifica una contraseña en la creación del usuario se creará una automáticamente y se le enviará por correo electrónico'>
                  <Icon type='question-circle-o' />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('set_pass', { valuePropName: 'checked' })(
              <Switch />
            )}
          </FormItem>
          {form.getFieldValue('set_pass') && (
            <FormItem
              {...formItemLayout}
              label='Contraseña (min. 6 caracteres)'
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Debes especificar la contraseña',
                  },
                  {
                    min: 6,
                    message:
                      'La contraseña debe tener por lo menos 6 caracteres',
                  },
                ],
              })(<Input />)}
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(ChangePasswordModal);
