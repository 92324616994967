import React, { Component } from 'react';
import { Spin } from 'antd';
import Templates from './templates';
import { TPL_PREFIX } from '../utils';
import styles from './screen-preview.module.css';

class ScreenPreview extends Component {
  render() {
    const {
      element,
      type,
      index,
      parentIndex,
      fetching,
      section,
      slug,
      theme,
      courseName,
      elementTheme,
    } = this.props;
    var realType =
      type === 'content'
        ? element.content_template.replace(TPL_PREFIX, '')
        : type;

    const Tmpl = Templates.hasOwnProperty(realType)
      ? Templates[realType]
      : Templates.Unknown;
    return (
      <div
        className={`content-internal ${styles.prev}`}
        style={{ marginBottom: '0px', minHeight: '100%' }}
      >
        <Spin spinning={fetching}>
          <div className='aspect-ratio-9-16'>
            {element && (
              <Tmpl
                element={element}
                type={realType}
                courseName={courseName}
                index={index}
                parentIndex={parentIndex}
                slug={slug}
                section={section}
                theme={theme}
                elementTheme={elementTheme}
              />
            )}
          </div>
        </Spin>
      </div>
    );
  }
}

export default ScreenPreview;
