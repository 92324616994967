import { createActions, handleActions } from 'redux-actions';

import api from '../core/services/auth';

//INITIAL STATE
var initialState = {
  loading: false,
  user: null,
  tenant: null,
  loggingIn: false,
  error: null,
  loggedIn: false
};

//Check initial state for user
let { user, isLoggedIn } = api.checkToken();
initialState.user = user;
initialState.loggedIn = isLoggedIn;

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

export const { init, login, logout } = createActions({
  LOGIN: (email, password) => api.authenticate(email, password),
  LOGOUT: () => api.logout()
});

//REDUCER
const reducer = handleActions({
  [`${login}_${pending}`]: (state) => {
    return { ...state, loggingIn: true, error: null };
  },
  [`${login}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, user: payload.user, loggingIn: false, error: null, loggedIn: true };
  },
  [`${login}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, loggingIn: false };
  },
  [`${logout}`]: (state, { payload }) => {
    return { ...initialState, user: null, loggedIn: false };
  }
}, initialState);

export default reducer;
