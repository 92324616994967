import React, { Component } from 'react';
import commonStyles from './common.module.css';
import styles from './challenge.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class ChallengeTemplate extends Component {
  state = {
    imageFailed: false,
  };

  onImageError = (e) => {
    if (this.state.imageFailed) return;
    const { element } = this.props;
    const { image } = element.extended_props || {};
    this.setState({ imageFailed: true });
    e.target.src = getAssetUri('images', 'general', image || 'challenge_icon');
  };

  render() {
    const { element, slug, theme, section } = this.props;
    const { title, extended_props } = element;
    const { text, image, link, imageWidthDP, imageHeightDP, imagePadding } =
      extended_props || {};

    const imgStyles = {
      width: imageWidthDP ? imageWidthDP / 10 + 'vw' : 15 + 'vw',
      height: imageHeightDP ? imageHeightDP / 10 + 'vw' : 15 + 'vw',
      padding: imagePadding ? imagePadding / 10 + 'vw' : 2 + 'vw',
    };

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const linkStyles = {
      color: theme.link_color || theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer} ${styles.container}`}
        style={containerStyles}
      >
        <img
          className={`${commonStyles.templateImage}`}
          style={imgStyles}
          alt={image}
          src={getAssetUri('images', slug, image || 'challenge_icon')}
          onError={this.onImageError}
        />
        <div
          className={`${commonStyles.templateTitle} ${styles.title}`}
          style={textStyles}
        >
          {stylizeText(title, controlStyles.backgroundColor)}
        </div>
        <div className={commonStyles.templateBody}>
          <div
            className={`${commonStyles.templateText} ${styles.text}`}
            style={textStyles}
          >
            {stylizeText(text, controlStyles.backgroundColor)}
          </div>
          {link && (
            <a
              href={link.url}
              className={`${commonStyles.templateText} ${styles.link}`}
              target='_blank'
              rel='noopener noreferrer'
              title={link.title}
              style={{ ...textStyles, ...linkStyles }}
            >
              {link.title}
            </a>
          )}
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#222335' }}
      >
        <div className={commonStyles.minTitle} style={{ color: 'white' }}>{`${
          index + 1
        }. Reto`}</div>
        <div className={commonStyles.minTitle} style={{ color: 'white' }}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.image',
        display: 'Imagen',
        value: extProps.image,
        type: 'file',
        subtype: 'images',
        note: 'Solo imágenes PNG o SVG',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'extended_props.text',
        display: 'Texto',
        value: extProps.text,
        type: 'multiline',
      },
      {
        name: 'extended_props.imageWidthDP',
        display: 'Ancho imagen',
        value: extProps.imageWidthDP,
        type: 'number',
      },
      {
        name: 'extended_props.imageHeightDP',
        display: 'Alto imagen',
        value: extProps.imageHeightDP,
        type: 'number',
      },
      {
        name: 'extended_props.imagePadding',
        display: 'Padding',
        value: extProps.imagePadding,
        type: 'number',
      },
      {
        name: 'extended_props.link',
        display: 'Vínculo',
        value: extProps.link,
        type: 'url',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
    ];
  };
}

export default ChallengeTemplate;
