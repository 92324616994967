import { createActions, handleActions } from 'redux-actions';
import InstructorsService from '../core/services/instructors';

//INITIAL STATE
const initialState = { 
  instructors: [],
  fetching: false,
  error: null,
  metadata: null,
  saving: false,
  saving_error: null
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

let actionCreators = createActions({
  INSTRUCTORS: {
    FETCH: (page) => InstructorsService.list(100,100 * (page - 1)), 
    CREATE: (instructor) => InstructorsService.createInstructor(instructor),
    EDIT: (instructorId, instructor) => InstructorsService.updateInstructor(instructorId, instructor),
    DELETE: (instructor) => InstructorsService.deleteInstructor(instructor),
  }
});

export const actions = actionCreators.instructors;

//REDUCER
const reducer = handleActions({
  [`${actions.fetch}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, instructors: payload.results, metadata: payload.pagination, error: null, fetching: false };
  },
  [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, fetching: false };
  },
  [`${actions.create}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.create}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.create}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.edit}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.edit}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.edit}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  },
  [`${actions.delete}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.delete}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, saving_error: null };
  },
  [`${actions.delete}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error : payload, saving: false };
  }
}, initialState);

export default reducer;
