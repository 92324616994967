import section from './section';
import sectionSubjects from './section-subjects';
import Concept from './concept';
import Unknown from './unknown';
import SingleChoice from './single-choice';
import MultipleChoice from './multiple-choice';
import Tip from './tip';
import Challenge from './challenge';
import DragAndDrop from './drag-and-drop';
import OrderPhrase from './order-phrase';
import Type from './type';
import CompletePhrase from './complete-phrase';
import Sound from './sound';
import Sort from './sort';
import Video from './video';
import Record from './record';
import ImageChoice from './image-choice';
import WhatsApp from './whatsapp';
import WhatsAppList from './whatsapp-list';
import Infographic from './infographic';

export default {
  section,
  subsection: section,
  Unknown,
  sectionSubjects,
  Concept,
  SingleChoice,
  MultipleChoice,
  Tip,
  Challenge,
  DragAndDrop,
  OrderPhrase,
  Type,
  CompletePhrase,
  Sort,
  Sound,
  Video,
  Record,
  ImageChoice,
  WhatsApp,
  Infographic,
  WhatsAppList,
};
