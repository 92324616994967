import React, { Component } from 'react';
import { Modal, Upload, Icon, Spin, Progress, notification, Table, Tooltip, Steps } from 'antd';
import config from '../../config';
import ExternalCoursesService from '../core/services/externalCourses';
import styles from './importusersmodal.module.css';

const Dragger = Upload.Dragger;

class ImportExternalCourseUsersModal extends Component {

  interval = null;

  state = {
    validationJobId: null,
    importJobId: null,
    reading: false,
    data: null,
    step: 'initial',
    status: null,
    job: null,
    safeToCancel: true,
    canContinue: false
  }

  checkProgress = async () => {
    const { courseId } = this.props;
    try {
      let data = await ExternalCoursesService.validateImportStatus(courseId, this.state.validationJobId);
      const finished = data.job.status === 'completed' || data.job.status === 'failed';
      if(finished) clearInterval(this.interval);
      let safeToCancel = !data.job.props || !data.job.props.validation || data.job.props.validation.errors.length > 0;
      let canContinue = data.job.status !== 'in_progress';
      this.setState({ job: data.job, safeToCancel, canContinue, status: data.job.status, step: finished ? 'validation_complete' : this.state.step, error: data.job.error });
    }
    catch(ex) {
      clearInterval(this.interval);
      this.setState({ job: null, safeToCancel: true, canContinue: false, status: 'failed', error: ex.message });
      notification.error({
        message: 'Error',
        description: ex.message,
      });
    }
  }

  checkImportProgress = async () => {
    const { courseId } = this.props;
    try {
      let data = await ExternalCoursesService.importStatus(courseId, this.state.importJobId);
      const finished = data.job.status === 'completed' || data.job.status === 'failed';
      if(finished) clearInterval(this.interval);
      let safeToCancel = !data.job.props || !data.job.props.result;

      this.setState({ job: data.job, safeToCancel, canContinue: false, status: data.job.status, error: data.job.error, step: finished ? 'import_complete' : this.state.step });
    }
    catch(ex) {
      clearInterval(this.interval);
      this.setState({ job: null, safeToCancel: true, canContinue: false, status: 'failed', error: ex.message });
      notification.error({
        message: 'Error',
        description: ex.message,
      });
    }
  }

  onUpload = (file, fileList) => {
    const { courseId } = this.props;
    this.setState({ reading: true });
    var reader = new FileReader();
    reader.onload = async () => {
        this.setState({ reading: false, canContinue: false, safeToCancel: false, data: reader.result, step: 'validating', status: 'in_progress' });
        try {
          const { id } = await ExternalCoursesService.validateImport(courseId, reader.result);
          this.setState({ validationJobId: id }, 
            () => { this.interval = setInterval(this.checkProgress, 500); });
        }
        catch(ex) {
          this.setState({ status: 'failed', error: ex.message });
          notification.error({
            message: 'Error',
            description: ex.message,
          });
        }
    }
    reader.readAsText(file);

    return false;
  }

  renderPicker = () => {
    const { confirmLoading } = this.props;
    return <Spin spinning={confirmLoading || this.state.reading }>
        <Dragger accept="text/csv" beforeUpload={this.onUpload} multiple={false}
          showUploadList={false}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
            <p className="ant-upload-text">Haz clic o arrastra el archivo</p>
            <p className="ant-upload-hint">Solo se soportan archivos separados por coma tipo CSV.</p>
          </p>
        </Dragger>
        <p className="ant-upload-hint" style={{textAlign:'center', marginTop:20}}>Para descargar una plantilla de ejemplo del archivo <a download="external-course-users.csv" href={config.baseAppUrl + '/external-course-users.csv'}>Haz clic aquí</a></p>
      </Spin> 
  }

  renderJobProgress = () => {
    const { step, job } = this.state;
      return <div>
        <p>Espera unos segundos, estamos {step === 'validating' ? 'validando' : 'importando'} los datos</p>
        <Progress percent={job ? Math.round(job.progress) : 0} status="active" />
      </div>
  }

  renderSummary = () => {
    const { status, job, error, step } = this.state;
      return <div className={styles.summary}>
        <Icon type={status === 'failed' ? 'close-circle' : 'check-circle'}/>
        { status === 'failed' && <h2>Ocurrió un error al {step === 'validating' ? 'validando' : 'importando'} la información</h2> }
        { status === 'failed' && <p>Error: {error}</p> }
        { (status !== 'failed' && step === 'import_complete') && <h2>Se importaron exitosamente {job.props.imported_count} usuarios al curso</h2> }
      </div>;
  }

  renderCell = (value, validations, type, general) => {
    return validations.length > 0 ? 
      <Tooltip title={<ul>{validations.map(v => <li>{v.message}</li>)}</ul>}>
        <Icon type={type==='error' ? 'close-circle' : 'exclamation-circle'} /> <strong>{value}</strong> 
      </Tooltip>
      : type === 'correct' && general ? <><Icon type="check-circle" /> {value}</>
      : value;
  }

  getValTableColumns = (type) => {
    return [
      {
        title: 'linea',
        dataIndex: 'line',
        render: (val, rec) => this.renderCell(val, (rec.validations||[]).filter(v => v.field === ''), type, true)
      },
      {
        title: 'Identificación',
        dataIndex: 'user.identification',
        render: (val, rec) => this.renderCell(val, (rec.validations||[]).filter(v => v.field === 'identification'), type)
      },
      {
        title: 'assisted',
        dataIndex: 'user.assisted',
        render: (val, rec) => this.renderCell(val, (rec.validations||[]).filter(v => v.field === 'assisted'), type)
      },
      {
        title: 'approved',
        dataIndex: 'user.approved',
        render: (val, rec) => this.renderCell(val, (rec.validations||[]).filter(v => v.field === 'approved'), type)
      },
      {
        title: 'start_time',
        dataIndex: 'user.start_time',
        render: (val, rec) => this.renderCell(val, (rec.validations||[]).filter(v => v.field === 'start_time'), type)
      },
      {
        title: 'end_time',
        dataIndex: 'user.end_time',
        render: (val, rec) => this.renderCell(val, (rec.validations||[]).filter(v => v.field === 'end_time'), type)
      },
      {
        title: 'quantitative_result',
        dataIndex: 'user.quantitative_result',
        render: (val, rec) => this.renderCell(val, (rec.validations||[]).filter(v => v.field === 'quantitative_result'), type)
      },
      {
        title: 'qualitative_result',
        dataIndex: 'user.qualitative_result',
        render: (val, rec) => this.renderCell(val, (rec.validations||[]).filter(v => v.field === 'qualitative_result'), type)
      }
    ];
  }

  renderValidationResults = () => {
    const { job } = this.state;
    if(!job.props.validation) return <div/>;
    let errorsDS = (job.props.validation.errors||[]).reduce((prev, curr) => {
      let item = prev.find(p => p.line === curr.line);
      if(!item) {
        item = { ...curr, validations: [] };
        prev.push(item);
      }
      item.validations.push(curr);
      return prev;
    }, []);

    let warningsDS = (job.props.validation.warnings||[]).reduce((prev, curr) => {
      let item = prev.find(p => p.line === curr.line);
      if(!item) {
        item = { ...curr, validations: [] };
        prev.push(item);
      }
      item.validations.push(curr);
      return prev;
    }, []);

    return <div className={styles.validations}>
      <div>
        <h2>Errores ({job.props.validation.errors.length})</h2>
        { job.props.validation.errors.length > 0 ? <>
        <p>Estos problemas no nos permiten importar el archivo, debes solucionarlos para continuar</p>
        <Table pagination={false} size='small' dataSource={errorsDS} rowKey={(r)=>r.line} columns={this.getValTableColumns('error')} />
        </> : <p>No hay errores en el archivo</p>}
      </div>

      <div>
        <h2>Advertencias ({job.props.validation.warnings.length})</h2>
        { job.props.validation.warnings.length > 0 ? <>
        <p>Estos problemas se pueden ignorar, pero puede que cierta información no se cargue correctamente</p>
        <Table pagination={false} size='small' dataSource={warningsDS} rowKey={(r)=>r.line} columns={this.getValTableColumns('warning')} />
        </> : <p>No hay advertencias en el archivo</p>}
      </div>
      
      <div>
        <h2>Correctos ({job.props.validation.correct.length})</h2>
        { job.props.validation.correct.length > 0 ? <>
        <p>Estos usuarios no tienen ningun problema y se subirán correctamente</p>
        <Table pagination={false} size='small' dataSource={job.props.validation.correct} rowKey={(r)=>r.line} columns={this.getValTableColumns('correct')} />
        </> : <p>No hay lineas completamente correctas en el archivo</p>}
      </div>
      
    </div>
  }

  onCancel = () => {
    const { onCancel } = this.props;
    const { step, safeToCancel } = this.state;
    
    if(step === 'import_complete' && this.props.onOk) {
      this.props.onOk();
      return;
    }

    let func = () => {
      this.setState({
        state: 'initial',
        error: null,
        reading: false,
        data: null,
        status: null,
        job: null
      });
      if(onCancel) onCancel();
    }

    if(!safeToCancel) {
      Modal.confirm({
        title: 'Cancelar la importación',
        content: '¿Estás seguro que deseas cancelar la importación en curso?',
        okText: 'Sí',
        cancelText: 'Cancelar',
        onOk: func
      });
    }
    else {
      func();
    }
  }

  onOk = async () => {
    const { courseId } = this.props;
    const { step } = this.state;
    if(step === 'validation_complete') {
      this.setState({ job: null, canContinue: false, safeToCancel: false, step: 'importing' });
      try {
        const { id } = await ExternalCoursesService.import(courseId);
        this.setState({ importJobId: id }, 
          () => { this.interval = setInterval(this.checkImportProgress, 500); });
      }
      catch(ex) {
        this.setState({ status: 'failed', error: ex.message });
        notification.error({
          message: 'Error',
          description: ex.message,
        });
      }
    }
  }

  render() {
    const { visible } = this.props;
    const { step, safeToCancel, canContinue, status } = this.state;

    return <Modal title="Importar usuarios a curso externo"
      visible={visible}
      confirmLoading={status === 'validating'}
      onCancel={this.onCancel}
      onOk={this.onOk}
      destroyOnClose={true}
      width={step !== 'initial' && step !== 'validating' ? '60vw' : undefined}
      okText="Continuar"
      okButtonProps={canContinue ? undefined : { style: { display: 'none'}}}
      cancelText={safeToCancel ? 'Cerrar' : 'Cancelar'}>
      <Steps className={styles.steps} size="small" current={step === 'initial' ? 0 : step === 'validating' || step === 'validation_complete' ? 1 : step === 'importing' ? 2 : 3 }>
        <Steps.Step title="Carga" />
        <Steps.Step title="Validación" />
        <Steps.Step title="Importación" />
        <Steps.Step title="Resumen" />
      </Steps>
      { step === 'initial'  && this.renderPicker() }
      { (step === 'validating' || step === 'importing') && this.renderJobProgress() }
      { step === 'validation_complete' && this.renderValidationResults() }
      { (step === 'import_complete' || status === 'failed') && this.renderSummary() }
    </Modal>
  }
}

export default ImportExternalCourseUsersModal;
