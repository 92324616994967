import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Layout, Row, Col } from 'antd';
import FlipMove from 'react-flip-move';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import styles from './course-scoretable.module.css';
import logo from '../../assets/logo_full_dark.svg';
import profilePic from '../../assets/profile_pic.png';

var firebaseConfig = {
  apiKey: "AIzaSyCwcd0fdzHYOQsEV6KPsvKTvtdtO-0SOko",
  authDomain: "didacta.firebaseapp.com",
  databaseURL: "https://didacta.firebaseio.com",
  storageBucket: "project-7764566410387601080.appspot.com",
  messagingSenderId: "494998125862"
};

class CourseScoretablePage extends PureComponent {
  state = {
    course: null,
    users: [],
    companyId: null
  };

  companyUsers = [];
  courseUsers = [];

  async componentDidMount() {
    const courseId = this.props.match.params.id;
    const companyId = this.props.auth.user.tenant_uuid;

    firebase.initializeApp(firebaseConfig);
    await firebase.auth().signInWithEmailAndPassword('jsescobar5@gmail.com', 'jsescobar5');

    var courseSnap = await firebase.database().ref(`/courses_light/${courseId}`).once('value');
    var course = courseSnap.val();
    this.setState({ course, companyId });

    var ref = firebase.database().ref(`/companies/${companyId}/users`);
    
    ref.on('child_added', (snap) => {
      this.companyUsers.push({ ...snap.val(), key: snap.key });
      this.refreshTable();
    });
    ref.on('child_changed', (snap) => {
      var idx = this.companyUsers.findIndex((u) => u.key === snap.key);
      if(idx < 0) console.warn('User was changed, but not found in array', snap.key, snap.val());
      this.companyUsers[idx] = { ...snap.val(), key: snap.key };
      this.refreshTable();
    });
    ref.on('child_removed', (snap) => {
        var idx = this.companyUsers.findIndex((u) => u.key === snap.key);
        if(idx < 0) console.warn('User was removed, but not found in array', snap.key, snap.val());
        this.companyUsers.splice(idx, 1);
        this.refreshTable();
    });

    ref = firebase.database().ref(`/companies/${companyId}/courses/${courseId}/users`);
    
    ref.on('child_added', (snap) => {
      this.courseUsers.push({ ...snap.val(), key: snap.key });
      this.refreshTable();
    });
    ref.on('child_changed', (snap) => {
      var idx = this.courseUsers.findIndex((u) => u.key === snap.key);
      if(idx < 0) console.warn('User was changed, but not found in array', snap.key, snap.val());
      this.courseUsers[idx] = { ...snap.val(), key: snap.key };
      this.refreshTable();
    });
    ref.on('child_removed', (snap) => {
      var idx = this.courseUsers.findIndex((u) => u.key === snap.key);
      if(idx < 0) console.warn('User was removed, but not found in array', snap.key, snap.val());
      this.courseUsers.splice(idx, 1);
      this.refreshTable();
    });

  }

  refreshTable = () => {
    var users = this.companyUsers.map((cu) => {
      var courseUser = this.courseUsers.find(u => u.key === cu.key) || { points: 0 };
      if(!courseUser.points) courseUser.points = 0;
      return { ...cu, ...courseUser };
    }).sort((a,b) => {
      if(a.points !== b.points) {
        return b.points - a.points;
      } else {
        if(a.last_activity && b.last_activity) return a.last_activity - b.last_activity;
        else return 0;
      }
    }).slice(0,10);
    
    this.setState({ users });
  }

  async componentWillUnmount () {
    firebase.database().ref(`/companies/${this.state.companyId}/users`).off();
    firebase.database().ref(`/companies/${this.state.companyId}/courses/${this.props.match.params.id}/users`).off();
  }

  render() {
    if(!this.state.course) return <div/>;
    return <Layout className={styles.page}>
      <Layout.Header className={styles.header}>
        <h1> Top 10: {this.state.course.name}</h1>
        <div className={styles.logo}><img src={logo} alt="Intrena logo" /></div>
      </Layout.Header>
      <Layout.Content className={styles.content}>
        <Row>
          <Col span={16} offset={4}>
            <FlipMove className={styles.table}>
              { this.state.users.map((item, idx) => <div className={styles.tableItem} key={item.key}>
                <div className={styles.pic}> 
                  <img src={item.photo_url ? item.photo_url : profilePic} alt="Foto de perfil"/>
                  <span className={styles.position}>{idx+1}</span> 
                </div>
                <div className={styles.points}>{item.points}</div>
                <div className={styles.itemBody}>
                    <h2>{item.name + ' ' + (item.last_name||'')}</h2>
                    <p>{item.area}</p>
                </div>
              </div> ) }
            </FlipMove>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  }
}

function mapStateToProps(state) {
  return   { ...state.courses, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseScoretablePage);
