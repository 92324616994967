import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import DashboardService from '../core/services/dashboard';

const HoursByType = ({filters}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await DashboardService.hoursByType(filters);
      setData(res);
    }
    
    fetchData();
  }, [filters]);

  const getValueByType = (type) => {
    if(data) {
      let val = data.find(d => d.type === type);
      if(val) return val.total;
    }
  }

  const getPercentByType = (type) => {
    if(data) {
      let val = data.find(d => d.type === type);
      if(val) return (val.total / (data.reduce((prev, curr)=>prev+curr.total,0)) * 100);
    }
  }
  
  return <div>
    Interno
    <Progress format={() => getValueByType('internal')} percent={getPercentByType('internal')} strokeColor="var(--color-secondary)" status="normal" />
    Externo
    <Progress format={() => getValueByType('external')} percent={getPercentByType('external')} strokeColor="rgb(0, 21, 41)" status="normal" />
  </div>
};

export default HoursByType;