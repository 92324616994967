import React from 'react';
import { Card } from 'antd';

const IndicatorCard = ({ backgroundColor, textColor, title, subtitle, ...props }) => {
  return <Card style={{backgroundColor, textAlign: 'center', flex:1}}>
    <div style={{color:textColor, fontSize:'38px', fontWeight: 'bold'}}>{title}</div>
    <div style={{color:textColor, fontSize:'18px'}}>{subtitle}</div>
  </Card>
}

export default IndicatorCard;
