import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Modal, Dropdown, Menu, Icon, Input } from 'antd';

import Date from '../common/components/date';
import { actions } from './certifications.redux';
import EditCreateCertificationModal from './edit-create-certification.modal';
import CertificationUsersModal from './certification-users.modal';
import AddSkillModal from './add-skill.modal';
import CertificationsService from '../core/services/certifications';

const confirm = Modal.confirm;

class CertificationsPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    modalVisible: false,
    usersModalVisible: false,
    editCertification: null,
    query: null,
    skillsModalVisible: false,
    assigning: false,
    selectedCert: null
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
      },
      {
        title: 'Duración (horas)',
        dataIndex: 'duration_hours',
      },
      {
        title: 'Usuarios',
        dataIndex: 'users',
        render: (val, record) => {
          return (
            <Button type='link' onClick={() => this.openUsersModal(record)}>
              {val}
            </Button>
          );
        },
      },
      {
        width: 150,
        title: 'Creado',
        dataIndex: 'created_at',
        render: (val, record) => {
          return <Date relative>{val}</Date>;
        },
      },
    ];

    if (this.canEdit()) {
      this.columns.push({
        title: '',
        width: 40,
        render: (val, record) => (
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key }) => this.handleTableAction(key, record)}
              >
                <Menu.Item key='edit'><Icon type="edit" /> Editar</Menu.Item>
                <Menu.Item key='delete'><Icon type="delete" /> Eliminar</Menu.Item>
                <Menu.Item key="skills"><Icon type="safety" /> Asignar competencias</Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button type='link' className='tbl-action'>
              <Icon type='ellipsis' />
            </Button>
          </Dropdown>
        ),
      });
    }
  }

  openUsersModal = (record) => {
    this.setState({ usersModalVisible: true, editCertification: record });
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  componentDidMount() {
    this.props.fetch(null, 1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metadata) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          total: nextProps.metadata.total,
          pageSize: nextProps.metadata.limit,
        },
      });
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.error) {
        alert(nextProps.error);
      } else {
        this.refreshTable();
      }
    }

    if (this.props.deleting && !nextProps.deleting) {
      if (nextProps.error) {
        alert(nextProps.error);
      } else {
        this.refreshTable();
      }
    }
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.hideModal();

    this.setState({ selectedRowKeys: [] });
    this.props.fetch(this.state.query, this.state.pagination.current || 1);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    await this.setState({ pagination });
    this.props.fetch(this.state.query, pagination.current);
  };

  getCheckboxProps = (record) => {
    return {
      disabled: false, //record.disabled,
      name: record.name,
    };
  };

  doSearch = (value) => {
    this.setState(
      { query: value, pagination: { ...this.state.pagination, current: 1 } },
      () => this.props.fetch(this.state.query, 1)
    );
  };

  showDeleteConfirm = () => {
    var count =
      this.state.selectedRowKeys.length === 1
        ? 'la certificación seleccionada'
        : `las ${this.state.selectedRowKeys.length} certificaciones seleccionadas`;
    confirm({
      title: `¿Estás seguro que deseas eliminar ${count}?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.delete(this.state.selectedRowKeys);
      },
    });
  };

  handleAction = async (action) => {
    console.warn(`Action ${action}`);

    switch (action) {
      case 'import':
        this.openImport();
        break;
      default:
        console.warn('unknown action');
    }
  };

  handleTableAction = (action, cert) => {
    switch (action) {
      case 'edit':
        this.openEdit(cert);
        break;
      case 'delete':
        confirm({
          title: `¿Estás seguro que deseas eliminar la certificación seleccionada?`,
          content: '',
          okText: 'Si',
          okType: 'danger',
          cancelText: 'No',
          onOk: () => {
            this.props.delete([cert.id]);
          },
        });
        break;
      case 'skills':
        this.openSkillsModal(cert.id);
        break;
      default:
        console.warn('unknown action');
    }
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps,
    };

    const hasSelected = this.state.selectedRowKeys.length > 0;
    return (
      <div className='content-internal'>
        <Input.Search
          placeholder='Buscar...'
          onSearch={this.doSearch}
          style={{ width: 200, float: 'left' }}
        />
        {this.canEdit() && (
          <div className='top-buttons'>
            <Button.Group>
              {hasSelected && (
                <Button icon='user-delete' onClick={this.showDeleteConfirm}>
                  Eliminar certificaciones
                </Button>
              )}
              <Button type='primary' icon='plus' onClick={this.openCreate}>
                Nueva certificación
              </Button>
            </Button.Group>
          </div>
        )}

        <Table
          {...this.state}
          loading={this.props.fetching}
          className='striped-rows'
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
          rowKey={(r) => r.id}
          rowSelection={this.canEdit() ? rowSelection : undefined}
          columns={this.columns}
          dataSource={this.props.certifications}
          size='small'
          tableLayout='fixed'
        />

        <EditCreateCertificationModal
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.modalVisible}
          confirmLoading={this.props.saving}
          certification={this.state.editCertification}
          onCancel={this.hideModal}
          onSave={this.handleSave}
        />

        <CertificationUsersModal
          visible={this.state.usersModalVisible}
          confirmLoading={this.props.saving}
          certification={this.state.editCertification}
          onCancel={this.hideUsersModal}
        />

        <AddSkillModal visible={this.state.skillsModalVisible}
          onCancel={this.hideSkillsModal}
          onOk={this.handleAssignSkills}
          certId={this.state.selectedCert}
          confirmLoading={this.state.assigning}
        />
      </div>
    );
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  openEdit = (cert) => {
    this.setState({ editCertification: cert, modalVisible: true });
  };

  openCreate = () => {
    this.setState({ modalVisible: true, editCertification: null });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  handleSave = () => {
    const form = this.formRef.props.form;
    if (this.state.editCertification) {
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.props.update(this.state.editCertification.id, values.name, values.duration_hours);
      });
    } else {
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.props.create(values.name, values.duration_hours);
      });
    }
  };

  hideUsersModal = () => {
    this.setState({ usersModalVisible: false });
  };

  openSkillsModal = (certId) => {
    this.setState({ selectedCert: certId, skillsModalVisible: true });
  }

  hideSkillsModal = () => {
    this.setState({ skillsModalVisible: false, selectedCert: null });
  }

  handleAssignSkills = async (skills) => {
    await CertificationsService.assignSkills(this.state.selectedCert, skills);
    this.hideSkillsModal();
  }
}

function mapStateToProps(state) {
  return { ...state.certifications, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (query, page) => dispatch(actions.fetch(query, page)),
    create: (name, duration) => dispatch(actions.create(name, duration)),
    update: (id, name, duration) => dispatch(actions.update(id, name, duration)),
    delete: (ids) => dispatch(actions.delete(ids)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificationsPage);
