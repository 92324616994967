import React, { PureComponent } from 'react';
import { Modal, Upload, Icon, Spin } from 'antd';
import config from '../../config';

const Dragger = Upload.Dragger;

class ImportAreasModal extends PureComponent {

  state = {
    reading: false,
    data: null
  }

  onUpload = (file, fileList) => {
    
    this.setState({ reading: true });
    var reader = new FileReader();
    reader.onload = () => {
        this.setState({ reading: false, data: reader.result });
        this.props.onOk(reader.result);
    }
    reader.readAsText(file);

    return false;
  }

  render() {
    const { visible, onCancel, confirmLoading } = this.props;

    return <Modal title="Importar áreas"
      visible={visible}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      destroyOnClose={true}
      okText="Guardar"
      cancelText="Cancelar">
      <Spin spinning={this.state.reading || confirmLoading}>
        <Dragger accept="text/csv" beforeUpload={this.onUpload} multiple={false}
          showUploadList={false}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
            <p className="ant-upload-text">Haz clic o arrastra el archivo</p>
            <p className="ant-upload-hint">Solo se soportan archivos separados por coma tipo CSV.</p>
          </p>
        </Dragger>
        <p className="ant-upload-hint" style={{textAlign:'center', marginTop:20}}>Para descargar una plantilla de ejemplo del archivo <a download="areas.csv" href={config.baseAppUrl + '/areas.csv'}>Haz clic aquí</a></p>
      </Spin> 
    </Modal>
  }
}

export default ImportAreasModal;
