import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";

import Footer from '../components/footer';

import logo from '../../../assets/logo_full_dark.svg';
import styles from './simple-layout.module.css';

class SimpleLayout extends Component
{
  state = {
    collapsed: false,
    title: this.props.title
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.changeTitle(nextProps.title);
  }

  changeTitle = (newTitle) => {
    if(newTitle !== this.state.title) this.setState({ title: newTitle });
  }

  render () {
    const { component: Component, ...rest } = this.props;
    return (
      <Route {...rest} render={matchProps => (
        <div className={styles.app}>
          <div className={styles.content}>
            <div className={styles.top}>
              <div className={styles.header}>
                <Link to="/">
                  <img alt="logo" className={styles.logo} src={logo} />
                </Link>
              </div>
              <div className={styles.desc}></div>
            </div>
            <Component {...matchProps} layout={this} />
          </div>
          <Footer/>
        </div>
      )} />
    );
  }
};

export default SimpleLayout;