import React, { Component } from 'react';
import { Layout } from 'antd';

import AuthenticatedRoute from '../../auth/components/AuthenticatedRoute';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import Footer from '../components/footer';
import PageHeader from '../components/pageHeader';
import styles from './main-layout.module.css';

class MainLayout extends Component
{
  state = {
    collapsed: false,
    title: this.props.title
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }
  
  componentWillReceiveProps(nextProps) {
    this.changeTitle(nextProps.title);
  }

  changeTitle = (newTitle) => {
    if(newTitle !== this.state.title) this.setState({ title: newTitle });
  }
  
  render () {
    const { component: Component, ...rest } = this.props;
    return (
      <AuthenticatedRoute {...rest} render={matchProps => (
        <Layout className={styles.app}>
          <Sidebar collapsed={this.state.collapsed} toggle={this.toggle} />
          <Layout>
            <Header collapsed={this.state.collapsed} toggle={this.toggle} />
            { rest.hidePageHeader || <PageHeader title={this.state.title} showBack={rest.showBack} /> }
            <Layout.Content className={styles.content}>
              <Component {...matchProps} layout={this} />
            </Layout.Content>
            <Footer/>
          </Layout>
        </Layout>
      )} />
    );
  }
};

export default MainLayout;