import React, { Component, Fragment } from 'react';
import { Modal, Tabs, Switch, Row, Col, Icon, Radio, Tooltip, Spin, Empty, Input, Select, Upload } from 'antd';
import ColorPicker from '../../common/components/color-picker';

import styles from './theme-editor.module.css';
import { getAssetUri, getBase64 } from './utils';

export const DEFAULTS = {
  text_color: '#606060',
  background_color: '#F0F0F0',
  controls_color: '#00BCD4',
  controls_text_color: '#FFFFFF',
  button_color: '#ED5244',
  button_text_color: '#FFFFFF',
  link_color: '#FFFFFF',
  intro_text_color: '#FFFFFF',
  module_results_text_color: '#FFFFFF',
  module_lost_text_color: '#FFFFFF',
  progress_color: '#BCD383',  
  feedback_background_color: '#F0F0F0',
  type: 'light',
  font: null,
  module_won_image: 'golden_badge',
  module_lost_image: 'gameover',
  course_won_image: 'img_end_course',
  course_lost_image: 'gameover',
  module_subjects_image: 'mini_module_1_review_icon',
  background_image: null,
  module_results_bg_image: null,
  module_lost_bg_image: null
}


class ThemeEditorModal extends Component {
  
  state = {
    locks: {},
    images: {
      module_won_image: { loading: true },
      module_lost_image: { loading: true },
      course_won_image: { loading: true },
      course_lost_image: { loading: true },
      module_subjects_image: { loading: true },
      background_image: { loading: true },
      module_results_bg_image: { loading: true },
      module_lost_bg_image: { loading: true },
    }
  }

  async componentDidMount() {
    await this.initInfo();
  }

  async componentDidUpdate(prevProps) {
    if(prevProps.theme !== this.props.theme) await this.initInfo();
  }

  initInfo = async () => {
    const { theme } = this.props;
    const mergedTheme = { ...DEFAULTS, ...theme };
    const { images } = this.state;

    let locks = {};

    for(let key in DEFAULTS) {
      if(Object.keys(theme||{}).indexOf(key) >= 0) locks[key] = true;
      else locks[key] = false;
    }

    for(let image in images) {
      await this.setImageSource(image, mergedTheme[image]);
    }
    
    this.setState({ theme, locks });
  }

  changeLock = (name, val) => {
    const newTheme = { ...this.state.theme };
    if(!val) delete newTheme[name];
    else newTheme[name] = DEFAULTS[name];
    this.setState({ locks: { ...this.state.locks, [name]: val }, theme: newTheme });

    if(name.endsWith('_image')) this.setImageSource(name, DEFAULTS[name]);
  }

  changeThemeValue = (name, val) => {
    const newTheme = { ...this.state.theme };
    newTheme[name] = val;
    this.setState({ theme: newTheme });
  }
  
  setImageSource = async (key, val) => {
    try {
      let uri = null;
      if(val) {
        if(val.toLowerCase().startsWith('http') || val.toLowerCase().startsWith('data:')) {
          uri = val;
        }
        else {
          uri = getAssetUri('images', this.props.slug, val, false);
          const resp = await fetch(uri);
          if(!resp.ok) {
            uri = getAssetUri('images', 'general', val, false);
            const resp = await fetch(uri);
            if(!resp.ok) throw Error('no encontrada: ' + uri);
          }
        }
      }

      this.setState({ images: { ...this.state.images, [key]: { loading: false, uri } }});
    }
    catch(err) {
      this.setState({ images: { ...this.state.images, [key]: { loading: false, error: true } }});
    }
  }

  renderColorPicker = (name, label) => {
    const { locks, theme } = this.state;
    const mergedTheme = { ...DEFAULTS, ...theme };
    return <Fragment>
      <Tooltip title={<div><strong>{label}:</strong> <em>{name}</em></div>}>
        <label htmlFor={name} className={styles.label}>{label}: </label>
      </Tooltip>
      <Switch id={name} className={styles.switch} checkedChildren={<Icon type="unlock" />}
        unCheckedChildren={<Icon type="lock" />} checked={locks[name]} onChange={(chk) => this.changeLock(name, chk)}/>
      <ColorPicker className={styles.picker} disabled={!locks[name]} format="hex" color={mergedTheme[name]} onChange={(col) => this.changeThemeValue(name, col)} />
    </Fragment>;
  }

  renderImage = (name, label) => {
    const { locks, images } = this.state;
    //const mergedTheme = { ...DEFAULTS, ...theme };
    const img = images[name];

    return <Fragment>
        <div className={styles.tabImagesInner}>
        <Tooltip title={<div><strong>{label}:</strong> <em>{name}</em></div>}>
          <label htmlFor={name} className={styles.label}>{label}: </label>
        </Tooltip>
        <Switch id={name} className={styles.switch} checkedChildren={<Icon type="unlock" />}
          unCheckedChildren={<Icon type="lock" />} checked={locks[name]} onChange={(chk) => this.changeLock(name, chk)}/>
      </div>
      { !img.loading && !img.error && img.uri && <Tooltip title="Quitar imagen">
          <Icon type="delete" className={styles.deleteIcon} onClick={() => this.removeImage(name)} />
      </Tooltip>
      }
      <Upload disabled={!locks[name]}
        listType="picture-card"
        beforeUpload={(file)=>this.beforeUpload(file, name)}
        className={`${styles.image} ${!locks[name] ? styles.disabled : ''}`}
        showUploadList={false}
      >
        {img.loading ?
          <Spin /> :
          img.error ?
          <Empty description="Error al cargar imagen" image={<Icon type="exclamation-circle" />}/> :
          !img.uri ?
          <Empty description="Sin imagen" image={<Icon type="file-image" />}/> :
          <Fragment>
            <img src={img.uri} alt={label} />
          </Fragment>
          }
      </Upload>
      </Fragment>;
  }

  renderThemeType = (name, label) => {
    const { locks, theme } = this.state;
    const mergedTheme = { ...DEFAULTS, ...theme };
    return <Fragment>
      <label htmlFor={name} className={styles.label}>{label}: </label>
      <Switch id={name} className={styles.switch} checkedChildren={<Icon type="unlock" />}
        unCheckedChildren={<Icon type="lock" />} checked={locks[name]} onChange={(chk) => this.changeLock(name, chk)}/>
      <Radio.Group disabled={!locks[name]} onChange={(e) => this.changeThemeValue(name, e.target.value)} value={mergedTheme[name]}>
        <Radio value="light">Claro</Radio>
        <Radio value="dark">Oscuro</Radio>
      </Radio.Group>
    </Fragment>;
  }

  renderFont = (name, label) => {
    const { locks, theme } = this.state;
    const mergedTheme = { ...DEFAULTS, ...theme };
    return <Fragment>
      <label htmlFor={name} className={styles.label}>{label}: </label>
      <Switch id={name} className={styles.switch} checkedChildren={<Icon type="unlock" />}
        unCheckedChildren={<Icon type="lock" />} checked={locks[name]} onChange={(chk) => this.changeLock(name, chk)}/>
      <div className={styles.inputs}>
        <Input placeholder="Nombre" size="small" disabled={!locks[name]} onChange={(e) => this.changeThemeValue(name, { name: e.target.value, type: mergedTheme[name] ? mergedTheme[name].type : 'ttf' })} value={mergedTheme[name] ? mergedTheme[name].name : ''} />
        <Select placeholder="Tipo" onChange={(val) => this.changeThemeValue(name, { type: val, name: mergedTheme[name] ? mergedTheme[name].name : '' })} value={mergedTheme[name] ? mergedTheme[name].type : 'ttf'} size="small" disabled={!locks[name]}>
          <Select.Option value="ttf">TTF</Select.Option>
          <Select.Option value="otf">OTF</Select.Option>
        </Select>
      </div>
      
    </Fragment>;
  }

  removeImage = (key) => {
    let img =  { ...this.state.images[key] };
    delete img.uri;
    delete img.file;
    this.setState({
      images: { ...this.state.images, [key]: img },
      theme: { ...this.state.theme, [key]: '' }
    });
  }
  
  beforeUpload = (file, key) => {
    // Get this url from response in real world.
    getBase64(file, imageUrl =>
      this.setState({
        images: { ...this.state.images, [key]: { ...this.state.images[key], file, uri: imageUrl }},
        theme: { ...this.state.theme }
      })
    );

    return false;
  }

  onOk = () => {
    const { onSave } = this.props;
    const { images, theme } = this.state;
    
    const imgsToSave = Object.keys(images)
    .filter(key => images[key].file)
    .reduce((obj, key) => {
      obj[key] = images[key].file;
      obj[key].uri = images[key].uri;
      return obj;
    }, {});

    if(onSave) onSave(theme, imgsToSave);
  }

  onClose = () => {
    const { onCancel } = this.props;
    this.initInfo();
    if(onCancel) onCancel();
  }

  render() {
    const { visible, confirmLoading, isContentTheme } = this.props;

    return <Modal title="Tema"
      destroyOnClose={true}
      className={styles.tabs}
      visible={visible}
      onOk={this.onOk}
      onCancel={this.onClose}
      confirmLoading={confirmLoading}
      okText="Guardar"
      cancelText="Cancelar">
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Colores" key="1">
          <div className={styles.tabColors}>
            <Row>
              <Col span={12}>
                { this.renderColorPicker('text_color', 'Textos') }
              </Col>
              <Col span={12}>
                { this.renderColorPicker('background_color', 'Fondo') }
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                { this.renderColorPicker('controls_text_color', 'Texto controles') }
              </Col>
              <Col span={12}>
                { this.renderColorPicker('controls_color', 'Controles') }
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                { this.renderColorPicker('button_text_color', 'Texto botones') }
              </Col>
              <Col span={12}>
                { this.renderColorPicker('button_color', 'Botones') }
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                { this.renderColorPicker('link_color', 'Texto vínculos') }
              </Col>
              { !isContentTheme &&<Col span={12}>
                { this.renderColorPicker('intro_text_color', 'Texto introducción') }
              </Col>}
            </Row>
            { !isContentTheme && <><Row>
                <Col span={12}>
                  { this.renderColorPicker('module_results_text_color', 'Texto módulo ganado') }
                </Col>
                <Col span={12}>
                  { this.renderColorPicker('module_lost_text_color', 'Texto módulo perdido') }
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  { this.renderColorPicker('progress_color', 'Barra de progreso') }
                </Col>
                <Col span={12}>
                { this.renderColorPicker('feedback_background_color', 'Fondo Feedback') }
                </Col>
              </Row></>
            }
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Imágenes" key="2">
          <div className={styles.tabImages}>
            <Row>
              <Col span={12}>
                { this.renderImage('background_image', 'Fondo') }
              </Col>
              { !isContentTheme && <Col span={12}>
              { this.renderImage('module_subjects_image', 'Objetivos') }
              </Col>}
            </Row>
            { !isContentTheme && <><Row>
              <Col span={12}>
              { this.renderImage('module_won_image', 'Módulo ganado') }
              </Col>
              <Col span={12}>
              { this.renderImage('module_lost_image', 'Módulo perdido') }
              </Col>
            </Row>
            <Row>
              <Col span={12}>
              { this.renderImage('course_won_image', 'Curso ganado') }
              </Col>
              <Col span={12}>
              { this.renderImage('module_results_bg_image', 'Fondo Curso ganado') }
              </Col>
            </Row>
            <Row>
              <Col span={12}>
              { this.renderImage('course_lost_image', 'Curso perdido') }
              </Col>
              <Col span={12}>
              { this.renderImage('module_lost_bg_image', 'Fondo Curso perdido') }
              </Col>
            </Row></>}
          </div>
          
        </Tabs.TabPane>
        { !isContentTheme && <Tabs.TabPane tab="Otros" key="3">
          <div className={styles.tabOther}>
            <Row>
              <Col span={24}>
              { this.renderThemeType('type', 'Tipo de tema') }
              </Col>
            </Row>
            <Row>
              <Col span={24}>
              { this.renderFont('font', 'Fuente') }
              </Col>
            </Row>
          </div>
        </Tabs.TabPane>}
      </Tabs>
    </Modal>;
  } 
}

export default ThemeEditorModal;
