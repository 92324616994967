import React, { Component } from 'react';
import { Tag } from 'antd';
import commonStyles from './common.module.css';
import styles from './sort.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import imgPodio from '../../../../../assets/podio@2x.png';
import imgQuestionPodio from '../../../../../assets/img_question_podio@2x.png';
import { DEFAULTS } from '../../theme-editor.modal';

class SortTemplate extends Component {
  state = {
    imageFailed: false,
  };

  onImageError = (e) => {
    if (this.state.imageFailed) return;
    const { element } = this.props;
    this.setState({ imageFailed: true });
    e.target.src = getAssetUri(
      'images',
      'general',
      (element.extended_props || {}).image_empty
    );
  };

  render() {
    const { element, section, theme, slug } = this.props;
    const { title, extended_props, options } = element;
    const { description, help_text } = extended_props || {};

    const opts = Object.keys(options || {})
      .map((k) => options[k])
      .sort((a, b) => a.order - b.order);

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer}`}
        style={containerStyles}
      >
        <div className={commonStyles.templateTitle} style={textStyles}>
          {stylizeText(title, controlStyles.backgroundColor)}
        </div>
        {description && (
          <div
            className={commonStyles.templateText}
            style={{ ...textStyles, marginTop: '4vw' }}
          >
            {stylizeText(description, controlStyles.backgroundColor)}
          </div>
        )}
        <div className={commonStyles.templateBody}>
          <div className={styles.podium}>
            <img src={imgPodio} alt='podio' />
            <div className={styles.spacesContainer}>
              <div className={styles.space}>
                <img src={imgQuestionPodio} alt='espacio de podio' />
              </div>
              <div className={styles.space}>
                <img src={imgQuestionPodio} alt='espacio de podio' />
              </div>
              <div className={styles.space}>
                <img src={imgQuestionPodio} alt='espacio de podio' />
              </div>
            </div>
          </div>
          <div className={styles.helpText} style={textStyles}>
            {stylizeText(help_text, controlStyles.backgroundColor)}
          </div>
          <div className={styles.optionsContainer}>
            {opts.map((o) => (
              <Tag
                className={styles.option}
                style={controlStyles}
                color={controlStyles.backgroundColor}
              >
                {o.text}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${index + 1}. Podio`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.description',
        display: 'Descripción',
        value: extProps.description,
        type: 'multiline',
      },
      {
        name: 'extended_props.image1',
        display: 'Imagen 1',
        value: extProps.image1,
        type: 'file',
        subtype: 'images',
        note: 'Solo imágenes PNG o SVG',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'extended_props.image2',
        display: 'Imagen 2',
        value: extProps.image2,
        type: 'file',
        subtype: 'images',
        note: 'Solo imágenes PNG o SVG',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'extended_props.image3',
        display: 'Imagen 3',
        value: extProps.image3,
        type: 'file',
        subtype: 'images',
        note: 'Solo imágenes PNG o SVG',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'extended_props.help_text',
        display: 'Texto de ayuda',
        value: extProps.help_text,
        type: 'text',
      },
      {
        name: 'extended_props.hide_result',
        display: 'Ocultar resultado',
        value: extProps.hide_result,
        type: 'boolean',
      },
      {
        name: 'extended_props.only_feedback',
        display: 'Sólo feedback',
        value: extProps.only_feedback,
        type: 'boolean',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
      {
        name: 'options',
        display: 'Opciones',
        value: element.options,
        type: 'options',
      },
    ];
  };
}

export default SortTemplate;
