import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Table,
  Icon,
  Descriptions,
  Row,
  Col,
  Tabs,
  Avatar,
  Tag,
  Progress,
  Statistic,
  Alert,
  Skeleton,
  Dropdown,
  Menu,
  Tooltip,
  Button,
  message,
  Badge,
  Divider,
} from 'antd';

import { actions } from './users.redux';
import './user-detail.page.css';
import profile_pic from '../../assets/profile_pic.png';
import Date from '../common/components/date';
import RevalidationModal from './revalidation.modal';
import BaseService from '../core/services/baseService';
import AssignCertificationsModal from './assign-certifications.modal';
import AddCertificationAttachmentsModal from './add-certification-attachment.modal';
import UsersService from '../core/services/users';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

const Badges = {
  badge_cohete_novato: require('../../assets/profile/badges/badge_cohete_novato.png'),
  badge_enrrachado: require('../../assets/profile/badges/badge_enrrachado.png'),
  badge_jardinero: require('../../assets/profile/badges/badge_jardinero.png'),
  badge_perfeccionista: require('../../assets/profile/badges/badge_perfeccionista.png'),
  badge_sabiondo: require('../../assets/profile/badges/badge_sabiondo.png'),
  badge_vaquero_oeste: require('../../assets/profile/badges/badge_vaquero_oeste.png'),
  badge_podium_1: require('../../assets/profile/badges/podium_1.png'),
  badge_podium_2: require('../../assets/profile/badges/podium_2.png'),
  badge_podium_3: require('../../assets/profile/badges/podium_3.png'),
};

const BadgesOff = {
  badge_cohete_novato: require('../../assets/profile/badges/badge_cohete_novato_off.png'),
  badge_enrrachado: require('../../assets/profile/badges/badge_enrrachado_off.png'),
  badge_jardinero: require('../../assets/profile/badges/badge_jardinero_off.png'),
  badge_perfeccionista: require('../../assets/profile/badges/badge_perfeccionista_off.png'),
  badge_sabiondo: require('../../assets/profile/badges/badge_sabiondo_off.png'),
  badge_vaquero_oeste: require('../../assets/profile/badges/badge_vaquero_oeste_off.png'),
};

const isAbsoluteUri = (text) =>
  text.startsWith('https://') ||
  text.startsWith('http://') ||
  text.startsWith('data:image');

const getImageUrl = (badge, userBadge) => {
  return isAbsoluteUri(badge.image)
    ? badge.image
    : userBadge
      ? Badges[badge.id || badge.image]
      : BadgesOff[badge.id || badge.image];
};

const percentFormat = new Intl.NumberFormat('es-CO', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});

class UserDetailPage extends PureComponent {
  state = {
    user: null,
    courses: [],
    whatsappcourses: [],
    audit_info: {},
    modalVisible: false,
    certsModalVisible: false,
    attachmentsModalVisible: false,
    selectedCourse: null,
    selectedCert: null,
    errorTimes: null,
    fetchingTimes: false,
    times: {}
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  canConvalidate = () => {
    return this.props.auth.user.convalidate_enabled;
  };

  constructor(props) {
    super(props);

    this.courses_columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        render: (val, record) => (
          <span>
            {val + '   '}
            {record.external_revalidated && (
              <Tooltip
                title={
                  <div>
                    <strong>Convalidado:</strong>
                    <br />
                    {record.revalidation_comments}
                  </div>
                }
              >
                <Icon
                  type='safety-certificate'
                  style={{
                    cursor: 'pointer',
                    verticalAlign: 'middle',
                    color: 'gold',
                  }}
                />
              </Tooltip>
            )}
          </span>
        ),
      },
      {
        title: 'Progreso',
        width: 130,
        dataIndex: 'percentage_completed',
        render: (val, record) => <Progress percent={val || 0} />,
      },
      {
        title: 'Fecha',
        width: 100,
        dataIndex: 'date_completed',
        render: (val, record) => (val ? <Date>{val}</Date> : <span></span>),
      },
      {
        title: '',
        width: 0,
        render: (val, record) =>
          record.percentage_completed === 100 ||
            (this.canEdit() && this.canConvalidate()) ? (
            <Dropdown
              overlay={
                <Menu
                  onClick={({ item, key }) =>
                    this.handleTableAction(key, record)
                  }
                >
                  {record.percentage_completed === 100 ? (
                    <Menu.Item key='certificate'>
                      Descargar certificado
                    </Menu.Item>
                  ) : null}
                  {this.canEdit() && this.canConvalidate() && (
                    <Menu.Item key='revalidate'>Convalidar curso</Menu.Item>
                  )}
                </Menu>
              }
              trigger={['click']}
            >
              <Button type='link' className='tbl-action'>
                <Icon type='ellipsis' />
              </Button>
            </Dropdown>
          ) : (
            ''
          ),
      },
    ];

    this.certificates_columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
      },
      {
        title: 'Fecha certificación',
        width: 100,
        dataIndex: 'date_completed',
        render: (val, record) => (val ? <Date>{val}</Date> : <span></span>),
      },
      {
        title: 'Válido hasta',
        width: 100,
        dataIndex: 'valid_through',
        render: (val, record) =>
          val ? <Date markDue>{val}</Date> : <span></span>,
      },
      {
        title: '',
        width: 0,
        render: (val, record) =>
          record.attachments.length ? (
            <Dropdown
              overlay={
                <Menu>
                  {record.attachments.map((a) => (
                    <Menu.Item key={a.id}>
                      <a href={a.url} target='_blank' rel='noopener noreferrer'>
                        {a.name}
                      </a>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <Button type='link' className='tbl-action'>
                <Icon type='paper-clip' />
              </Button>
            </Dropdown>
          ) : (
            ''
          ),
      },
      {
        title: '',
        width: 0,
        render: (val, record) =>
          this.canEdit() ? (
            <Dropdown
              overlay={
                <Menu
                  onClick={({ item, key }) =>
                    this.handleTableAction(key, record)
                  }
                >
                  <Menu.Item key='attach'>Adjuntar archivos</Menu.Item>
                  <Menu.Item key='edit'>Editar asignación</Menu.Item>
                  <Menu.Item key='unassign'>Quitar certificación</Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button type='link' className='tbl-action'>
                <Icon type='ellipsis' />
              </Button>
            </Dropdown>
          ) : (
            ''
          ),
      },
    ];
  }

  unAssignCertification = (cert) => {
    this.props.unassigncertification(this.props.user.id, cert.id);
  };

  handleTableAction = async (action, courseOrCert) => {
    switch (action) {
      case 'certificate':
        await this.downloadCertificate(courseOrCert);
        break;
      case 'revalidate':
        this.openModal(courseOrCert);
        break;
      case 'unassign':
        await this.unAssignCertification(courseOrCert);
        break;
      case 'attach':
        await this.openAttachmentsModal(courseOrCert);
        break;
      case 'edit':
        await this.openCertsModal(courseOrCert);
        break;
      default:
        console.warn('unknown action');
    }
  };

  async componentDidMount() {
    const userId = this.props.match.params.id;
    var user = (this.props.users || []).find((u) => u.id === userId);
    if (user) {
      this.setState({ user });
    } else {
      this.props.get(userId);
    }
    this.props.getusercourses(userId);
    this.props.getwhatsappusercourses(userId);
    this.props.getauditinfo(userId);
    this.props.getbadges(userId);
    this.props.getcertifications(userId);
    await this.getTimes();
  }

  async getTimes() {
    try {
      const userId = this.props.match.params.id;
      this.setState({ fetchingTimes: true });
      const times = await UsersService.getTimes(userId);
      this.setState({ times });
    }
    catch (e) {
      this.setState({ times_error: e.message });
    }
    finally {
      this.setState({ fetchingTimes: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user) {
      this.setState({ user: nextProps.user });
    }

    if (this.props.user_courses !== nextProps.user_courses) {
      this.setState({ courses: nextProps.user_courses });
    }

    if (this.props.whatsapp_user_courses !== nextProps.whatsapp_user_courses) {
      this.setState()
    }

    if (this.props.audit_info !== nextProps.audit_info) {
      this.setState({ audit_info: nextProps.audit_info });
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.error) {
        alert(nextProps.error);
      } else {
        let form = this.formRef.props.form;
        form.resetFields();
        this.hideModal();
        form = this.certsFormRef.props.form;
        form.resetFields();
        this.hideCertsModal();
        this.props.getusercourses(this.props.user.id);
        this.props.getwhatsappusercourses(this.props.user.id);
        this.props.getcertifications(this.props.user.id);
      }
    }
  }

  render() {
    return (
      <div>
        <Row gutter={10}>
          <Col span={6}>{this.renderUserInfo()}</Col>
          <Col span={18}>
            <div className='content-internal'>
              <Tabs defaultActiveKey='1' size='large'>
                <Tabs.TabPane tab='Cursos INTRENA' key='1'>
                  <Table
                    loading={this.props.fetching_user_courses}
                    pagination={false}
                    size='small'
                    rowKey={(u) => u.id}
                    columns={this.courses_columns}
                    showHeader={false}
                    dataSource={this.props.user_courses}
                    locale={{
                      emptyText:
                        'El usuario no tiene cursos asignados en el momento',
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Cursos Whatsapp' key='2'>
                  <Table
                    loading={this.props.fetching_whatsapp_user_courses}
                    pagination={false}
                    size='small'
                    rowKey={(u) => u.id}
                    columns={this.courses_columns}
                    showHeader={false}
                    dataSource={this.props.whatsapp_user_courses}
                    locale={{
                      emptyText:
                        'El usuario no tiene cursos de whatsapp asignados en el momento',
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Auditoría' key='3'>
                  {this.props.error_audit_info ? (
                    <Alert
                      message='Error'
                      description='Ocurrió un error al cargar la información'
                      type='error'
                    />
                  ) : (
                    this.renderAuditInfo()
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab='Insignias' key='4'>
                  {this.props.error_audit_info ? (
                    <Alert
                      message='Error'
                      description='Ocurrió un error al cargar la información'
                      type='error'
                    />
                  ) : (
                    this.renderBadges()
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab='Certificaciones' key='5'>
                  {this.props.error_certifications_info ? (
                    <Alert
                      message='Error'
                      description='Ocurrió un error al cargar la información'
                      type='error'
                    />
                  ) : (
                    this.renderCertifications()
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab='Tiempos' key='6'>
                  {this.state.errorTimes ? (
                    <Alert
                      message='Error'
                      description='Ocurrió un error al cargar la información'
                      type='error'
                    />
                  ) : (
                    this.renderTimes()
                  )}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
        <RevalidationModal
          wrappedComponentRef={this.saveFormRef}
          course={this.state.selectedCourse}
          visible={this.state.modalVisible}
          confirmLoading={this.props.saving}
          onCancel={this.hideModal}
          onSave={this.handleSave}
        />
        <AssignCertificationsModal
          wrappedComponentRef={this.saveCertsFormRef}
          visible={this.state.certsModalVisible}
          confirmLoading={this.props.saving}
          onCancel={this.hideCertsModal}
          onSave={this.handleCertsSave}
          certificationAssignment={this.state.selectedCert}
        />
        <AddCertificationAttachmentsModal
          visible={this.state.attachmentsModalVisible}
          confirmLoading={this.props.saving}
          onCancel={this.hideAttachmentsModal}
          onSave={this.handleAttachments}

        />
      </div>
    );
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  saveCertsFormRef = (formRef) => {
    this.certsFormRef = formRef;
  };

  openModal = (selectedCourse) => {
    this.setState({ modalVisible: true, selectedCourse });
  };

  openCertsModal = (selectedCert) => {
    this.setState({ certsModalVisible: true, selectedCert });
  };

  openAttachmentsModal = (selectedCert) => {
    this.setState({ attachmentsModalVisible: true, selectedCert });
  };

  downloadCertificate = async (course) => {
    const { user } = this.props;
    try {
      var blob = await BaseService.download(
        `/responses/${course.tenant_course_id}/${user.id}/certificate`
      );

      var windowUrl = window.URL || window.webkitURL;
      var url = windowUrl.createObjectURL(blob);
      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `${user.first_name} ${user.last_name}`.trim() + '.pdf';
      anchor.click();
      if (anchor.parentNode) anchor.parentNode.removeChild(anchor);
      windowUrl.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
      message.error('Ocurrió un error al descargar el certificado');
    }
  };

  handleSave = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.revalidate(
        this.props.user.id,
        this.state.selectedCourse.tenant_course_id,
        values.percentage,
        values.date,
        values.comments
      );
    });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  handleCertsSave = () => {
    const form = this.certsFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (this.state.selectedCert) {
        this.props.editcertificationassignment(
          this.props.user.id,
          this.state.selectedCert.id,
          values.certification,
          values.valid_through,
          values.date_completed
        );
      }
      else {
        this.props.assigncertification(
          this.props.user.id,
          values.certification,
          values.valid_through,
          values.date_completed
        );
      }

    });
  };

  hideCertsModal = () => {
    this.setState({ certsModalVisible: false });
  };

  handleAttachments = (files) => {
    this.props.addattachments(
      this.props.user.id,
      this.state.selectedCert.id,
      files
    );
  };

  hideAttachmentsModal = () => {
    this.setState({ attachmentsModalVisible: false });
  };

  renderCertifications = () => {
    return (
      <div>
        <div className='top-buttons'>
          <Button type='primary' icon='plus' onClick={(e) => this.openCertsModal()}>
            Asignar certificación
          </Button>
        </div>
        <Table
          loading={this.props.fetching_certifications}
          pagination={false}
          size='small'
          rowKey={(u) => u.id}
          columns={this.certificates_columns}
          dataSource={this.props.certifications}
          locale={{
            emptyText: 'El usuario no tiene certificaciones en el momento',
          }}
        />
      </div>
    );
  };

  renderUserInfo = () => {
    const { user } = this.state;

    if (!user) {
      return (
        <div
          className='content-internal'
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Skeleton active avatar paragraph />
        </div>
      );
    }

    return (
      <div
        className='content-internal'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Avatar
          src={user.avatar ? user.avatar : profile_pic}
          size={64}
          shape='circle'
          style={{ alignSelf: 'center', border: '1px solid #ddd' }}
        />
        <Typography.Title
          level={3}
          style={{ alignSelf: 'center', marginTop: 10 }}
        >
          {user.full_name}
        </Typography.Title>
        <Tag
          style={{ alignSelf: 'center', marginBottom: 10 }}
          color={!user.disabled ? 'green' : null}
        >
          {user.disabled ? 'Inactivo' : 'Activo'}
        </Tag>
        <Descriptions column={1}>
          <Descriptions.Item label='Correo electrónico'>
            {user.email || ''}
          </Descriptions.Item>
          <Descriptions.Item label='Área'>
            {user.area_name || ''}
          </Descriptions.Item>
          <Descriptions.Item label='Cargo'>
            {user.position_name || ''}
          </Descriptions.Item>
          <Descriptions.Item label='Identificación'>
            {user.identification_number || ''}
          </Descriptions.Item>
          <Descriptions.Item label='Celular'>
            {user.mobile || ''}
          </Descriptions.Item>
          <Descriptions.Item label='Ubicación'>
            {user.location || ''}
          </Descriptions.Item>
          <Descriptions.Item label='Jefe'>
            {`${user.manager_first_name || ''} ${user.manager_last_name || ''
              }`.trim()}
          </Descriptions.Item>
          <Descriptions.Item label='Rol'>
            {user.role === 'admin'
              ? 'Administrador'
              : user.role === 'reader'
                ? 'Solo lectura'
                : 'Usuario'}
          </Descriptions.Item>
          {(this.props.custom_fields || []).map((f) => (
            <Descriptions.Item key={f.field_name} label={f.field_display_name}>
              {user[f.field_name] || ''}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
    );
  };

  renderTimes = () => {
    const { times } = this.state;

    const elements = [
      <div>
        <Title level={2}>Horas completadas vs totales</Title>
        <Text>Muestra la relación entre horas completadas por el usuario y las horas totales de los contenidos (cursos Intrena, cursos externos, certificaciones), para el plan de formación del usuario y para cada una de las competencias definidas</Text>
      </div>
    ];

    if (times?.plan?.name) {
      elements.push(<div style={{ marginTop: 40 }}><Title level={3}>Plan de formación</Title></div>);
      elements.push(<Divider style={{ margin: 0 }} />);
      elements.push(<div style={{ marginTop: 20, marginRight: 20 }}>
        <Title level={4}>{times.plan.name}</Title>
        <Text>Horas usuario: {times.plan.user} / Horas totales: {times.plan.total}</Text>
        <Progress percent={(times.plan.user / times.plan.total) * 100} format={(v) => percentFormat.format(v / 100)} />
      </div>);
    }

    if (Object.keys(times?.skills ?? {}).length > 0) {

      let skills = Object.keys(times?.skills).map(k => times.skills[k]).sort((a, b) => b.skill_name.localeCompare(a.skill_name));
      elements.push(<div style={{ marginTop: 60 }}><Title level={3}>Competencias</Title></div>);
      elements.push(<Divider style={{ margin: 0 }} />);

      for (let skill of skills) {
        elements.push(<div style={{ marginTop: 20, marginRight: 20 }}>
          <Title level={4}>{skill.skill_name}</Title>
          <Text>Horas usuario: {skill.user} / Horas totales: {skill.total}</Text>
          <Progress percent={(skill.user / skill.total) * 100} format={(v) => percentFormat.format(v / 100)} />
        </div>);
      }
    }

    return elements;
  };


  renderAuditInfo = () => {
    const { audit_info } = this.state;
    var device_info = {};
    var osIcon = null;

    if (audit_info) {
      if (audit_info.device_info) {
        device_info = audit_info.device_info;

        osIcon =
          audit_info.device_info.os.toLowerCase() === 'ios'
            ? 'apple'
            : audit_info.device_info.os.toLowerCase() === 'android'
              ? 'android'
              : 'question';
      }
    }

    return [
      <Row key={1} gutter={16} style={{ marginTop: '20px' }}>
        <Col span={12}>
          {this.props.fetching_audit_info ? (
            <Skeleton active />
          ) : (
            <Statistic
              title='Fecha de última actividad'
              value={audit_info.last_activity}
              formatter={(val) =>
                val ? (
                  <Date format='dd/MM/yyyy hh:mm aa'>{val}</Date>
                ) : (
                  <span>--</span>
                )
              }
            />
          )}
        </Col>
        <Col span={12}>
          {this.props.fetching_audit_info ? (
            <Skeleton active />
          ) : (
            <Statistic
              title='Dispositivo'
              prefix={osIcon ? <Icon type={osIcon} /> : undefined}
              value={device_info.model || '--'}
            />
          )}
        </Col>
      </Row>,
      <Row key={2} gutter={16} style={{ marginTop: '20px' }}>
        <Col span={12}>
          {this.props.fetching_audit_info ? (
            <Skeleton active />
          ) : (
            <Statistic
              title='Versión SO'
              value={
                device_info.version
                  ? `${device_info.os} ${device_info.version}`
                  : '--'
              }
            />
          )}
        </Col>
        <Col span={12}>
          {this.props.fetching_audit_info ? (
            <Skeleton active />
          ) : (
            <Statistic
              title='Versión Intrena'
              value={device_info.app_version || '--'}
            />
          )}
        </Col>
      </Row>,
    ];
  };

  renderBadges = () => {
    let allBadges = (this.props.badges || {}).all || [];
    let badges = (this.props.badges || {}).badges || {};

    const courseBadges = Object.keys(badges)
      .map((k) => badges[k])
      .filter((b) => b.course && !b.lost);

    return (
      <Row gutter={16} style={{ marginLeft: '16px', marginRight: '16px' }}>
        {allBadges.concat(courseBadges).map((badge) => {
          let userBadge = badge.course ? badge : badges[badge.id];

          return (
            <Col
              span={6}
              key={badge.name}
              style={{ marginTop: '32px', textAlign: 'center' }}
            >
              <Tooltip title={badge.description_not_won}>
                <Badge
                  style={{ backgroundColor: 'black' }}
                  count={userBadge ? userBadge.count : 0}
                  className={`userBadge userBadge-${userBadge ? 'on' : 'off'}`}
                >
                  <img src={getImageUrl(badge, userBadge)} alt='' />
                  <div className='ub-title'>{badge.name}</div>
                </Badge>
              </Tooltip>
            </Col>
          );
        })}
      </Row>
    );
  };
}

function mapStateToProps(state) {
  return { ...state.users, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    get: (userId) => dispatch(actions.get(userId)),
    getusercourses: (userId) => dispatch(actions.getusercourses(userId)),
    getwhatsappusercourses: (userId) => dispatch(actions.getwhatsappusercourses(userId)),
    getauditinfo: (userId) => dispatch(actions.getauditinfo(userId)),
    getbadges: (userId) => dispatch(actions.getbadges(userId)),
    getcertifications: (userId) => dispatch(actions.getcertifications(userId)),
    assigncertification: (userId, certId, validThrough, dateCompleted) =>
      dispatch(
        actions.assigncertification(userId, certId, validThrough, dateCompleted)
      ),
    editcertificationassignment: (userId, id, certId, validThrough, dateCompleted) =>
      dispatch(
        actions.editcertificationassignment(userId, id, certId, validThrough, dateCompleted)
      ),
    unassigncertification: (userId, certId) =>
      dispatch(actions.unassigncertification(userId, certId)),
    addattachments: (userId, certId, files) =>
      dispatch(actions.addattachments(userId, certId, files)),
    revalidate: (userId, courseId, percentage, date, comments) =>
      dispatch(
        actions.revalidate(userId, courseId, percentage, date, comments)
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailPage);
