import React, { Component } from 'react';
import commonStyles from './common.module.css';

class UnknownTemplate extends Component {

  render() {
    const { index, type } = this.props;
    return <div className={commonStyles.template}>
      <div>{index+1}</div>
      <div>{`No template for ${type}.`}</div>
    </div>
  }

  static renderMiniature ({ element, index, parentIndex, ...props}) {
    return <div className={commonStyles.miniature}>
      <div className={commonStyles.miniatureIndex}>{props.type} {index+1}</div>
      <div className={commonStyles.miniatureTitle}>{element.name}</div>
    </div>
  }

  static getProps = ({ element }) => {
    return [
    ];
  }
}

export default UnknownTemplate;
