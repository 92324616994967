import { Button, Divider, Empty, Icon, message, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import FormationPathsService from "../../core/services/formation-paths";
import { AddCertificationModal } from "./add-certification.modal";

export const CertificationsList = ({ pathId, canEdit }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [certifications, setCertifications] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);

    const queryCertifications = useCallback(async () => {
        try {
            setFetching(true);
            const data = await FormationPathsService.getCertifications(pathId);
            setCertifications(data.map(d => ({ ...d, id: d.certification_id })).sort((a, b) => a.order - b.order));
        }
        catch (err) {
            console.log(err);
            message.error('Ocurrió un error al consultar las certificaciones del plan de formación');
        }
        finally {
            setFetching(false);
        }
    }, [pathId]);

    const removeCertification = async (course) => {
        try {
            await FormationPathsService.removeCertification(pathId, course.certification_id);
            await queryCertifications();
        }
        catch (err) {
            message.error('Ocurrió un error al eliminar la certificación del plan de formación');
        }
    }

    const addCertifications = async (newCertifications) => {
        try {
            setSaving(true);
            const maxOrder = Math.max(...certifications.map(c => c.order), 0);
            await FormationPathsService.addCertifications(pathId, newCertifications.map((c, idx) => ({ id: c, order: maxOrder + idx + 1 })));
            setModalVisible(false);
            await queryCertifications();
        }
        catch (err) {
            message.error('Ocurrió un error al agregar las certificaciones al plan de formación');
        }
        finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        queryCertifications();
    }, [pathId, queryCertifications]);

    const renderItem = ({ item }) => {
        return <div key={item.id} className="drag-item ext-drag-item">
            {item.name}
            {canEdit && <Icon type="close" className="del-icon" onClick={() => removeCertification(item)} />}
        </div>
    };

    return <div className="path-section">
        <div className="heading-row">
            <h2>Certificaciones</h2>
            {canEdit && <div className="top-buttons">
                <Button className="" type="ghost" onClick={() => setModalVisible(true)}><Icon type="plus" />Agregar certificación</Button>
            </div>}
        </div>
        <Spin spinning={fetching}>
            {certifications.length === 0 && <Empty description="Aún no hay certificaciones asignadas" />}
            {certifications.map(c => renderItem({ item: c }))}
        </Spin>
        <Divider />
        <AddCertificationModal visible={modalVisible} onCancel={() => setModalVisible(false)} confirmLoading={saving} onOk={addCertifications} initialSelection={certifications.map(c => c.id)} />
    </div>;

}