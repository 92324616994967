import React, { Component } from 'react';
import { Card } from 'antd';
import styles from './miniature.module.css';
import Templates from './templates';

const TPL_PREFIX = 'co.labhouse.didacta.ui.courses.course_playback.content_templates.';

class Miniature extends Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  scrollToSelf = (options) => {
    this.ref.current.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { type, element, index, onSelect, parentIndex, section, sectionId, subSectionId, selected } = this.props;
    var realType = type === 'content' ? element.content_template.replace(TPL_PREFIX, '') : type;

    const Tpl = Templates.hasOwnProperty(realType) ? Templates[realType] : Templates.Unknown;

    return  (
      <div className={`${styles.miniature} ${selected?styles.selected:''}`} ref={this.ref}>
        <Card hoverable={true} onClick={() => onSelect(type, element, index, parentIndex, section, sectionId, subSectionId)}>
          {Tpl.renderMiniature({ element, index, parentIndex})}
        </Card>
      </div>
    );
  }
}

export default Miniature;
