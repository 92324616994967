import BaseService from './baseService';

export default class PositionsService extends BaseService
{
  static async list(query, limit, skip) {
    return await super.request(`/positions?limit=${limit}&skip=${skip}&q=${query ? encodeURIComponent(query) : ''}`);
  }

  static async paths() {
    return await super.request(`/paths?limit=1000&skip=0`);
  }

  static async create(code, name, formation_path_id) {
    return await super.request(`/positions`, 'POST', { code, name, formation_path_id });
  }

  static async update(id, code, name, formation_path_id) {
    return await super.request(`/positions/${id}`, 'PUT', { code, name, formation_path_id });
  }

  static async delete(ids) {
    return await super.request(`/positions/${JSON.stringify(ids)}`, 'DELETE');
  }

  static async listUsers(positionId, limit, skip) {
    return await super.request(`/positions/${positionId}/users?limit=${limit}&skip=${skip}`);
  }

  static async import(data) {
    return await super.request(`/positions/import`, 'POST', { file: data });
  }

  static async updateCosts(id, data) {
    return await super.request(`/positions/${id}/costs`, 'PUT', data);
  }
}