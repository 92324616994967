import { createActions, handleActions } from 'redux-actions';

import client from '../core/services/formation-paths';

//INITIAL STATE
const initialState = { 
  formationPaths: [],
  fetching: false,
  error: null,
  metadata: null,
  saving: false,
  fetchingPath: false,
  error_path: null,
  path: {}
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

let actionCreators = createActions({
  PATHS: {
    FETCH: (page) => client.list(10, 10 * (page - 1)),
    CREATE: (name, courses) => client.create(name, courses),
    GET: (pathId) => client.get(pathId),
    UPSERT: (pathId, courses) => client.upsert(pathId, courses),
    REMOVECOURSE: (pathId, courseId) => client.removeCourse(pathId, courseId) 
  }
});

export const actions = actionCreators.paths;

//REDUCER
const reducer = handleActions({
  [`${actions.fetch}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, formationPaths: payload.results, metadata: payload.pagination, error: null, fetching: false };
  },
  [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, fetching: false };
  },
  [`${actions.create}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.create}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, saving: false };
  },
  [`${actions.create}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  },
  [`${actions.get}_${pending}`]: (state) => {
    return { ...state, fetching_path: true, error_path: null };
  },
  [`${actions.get}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, path: payload, error_path: null, fetching_path: false };
  },
  [`${actions.get}_${rejected}`]: (state, { payload }) => {
    return { ...state, error_path : payload, fetching_path: false };
  },
}, initialState);

export default reducer;
