import React, { Component } from 'react';
import { Button } from 'antd';
import commonStyles from './common.module.css';
import styles from './record.module.css';
import { stylizeText, colorIntToHex, getAssetUri } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class RecordTemplate extends Component {

  render() {
    const { element, section, theme, slug } = this.props;
    const { title, extended_props } = element;
    const { description } = extended_props||{};

    const textStyles = {
      color: theme.text_color || undefined
    };

    const bgImage = theme.background_image ? theme.background_image.startsWith('http') 
      ? theme.background_image : getAssetUri('images', slug, theme.background_image, false) : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined
    };
    
    const controlStyles = {
      backgroundColor: theme.controls_color || colorIntToHex(section.color) || DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    }

    return <div className={`${commonStyles.templateContainer}`} style={containerStyles}>
      <div className={commonStyles.templateTitle} style={textStyles}>{stylizeText(title, controlStyles.backgroundColor)}</div>
      { description && <div className={commonStyles.templateText} style={textStyles}>{stylizeText(description, controlStyles.backgroundColor)}</div> }
      <div className={commonStyles.templateBody}>
        <div className={styles.timer} style={textStyles}>00:00:00</div>
        <Button style={{ ...controlStyles, width: '8vw', height: '8vw', fontSize: '3vw', alignSelf: 'center', borderColor: controlStyles.backgroundColor  }} type="primary" shape="circle" color={controlStyles.color} icon="audio" size="large" onClick={this.toggleAudio} />
      </div>
    </div>;
  }

  static renderMiniature ({ element, index, parentIndex, ...props}) {
    return <div className={commonStyles.miniature} style={{backgroundColor: '#efefef'}}>
      <div className={commonStyles.minTitle}>{`${index+1}. Grabar audio`}</div>
      <div className={commonStyles.minTitle}>{stylizeText(element.title)}</div>
    </div>
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props||{};
    return [
      { name: 'title', display: 'Título', value: element.title, type: 'multiline' },
      { name: 'extended_props.description', display: 'Descripción', value: extProps.description, type: 'multiline' },
      { name: 'category', display: 'Categoría', value: element.category, type: 'select', values: [ { title: 'Conciencia', value: 'conciencia' }, { title: 'Concepto', value: 'concepto' }, { title: 'Contexto', value: 'contexto' } ] },
      { name: 'estimatedTime', display: 'Tiempo estimado (seg)', value: element.estimatedTime, type: 'number' }
    ];
  }
}

export default RecordTemplate;
