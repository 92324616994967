import React, { PureComponent } from 'react';
import { Modal, Form, Input, DatePicker, InputNumber } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';

import './revalidation-modal.module.css';

const FormItem = Form.Item;

class RevalidationModal extends PureComponent {

  state = {
  }

  render() {
    const { visible, onCancel, onSave, form, confirmLoading, course } = this.props;
      const { getFieldDecorator } = form;

    return <Modal title="Convalidar Curso"
      visible={visible}
      onOk={onSave}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText="Guardar"
      cancelText="Cancelar">
      <Form>
        <FormItem style={{ margin: 0 }}>
          <FormItem  style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            label="Porcentaje de avance"
            >
            {getFieldDecorator('percentage', {
              initialValue: (course||{}).percentage_completed || 100,
              rules: [{
                required: true, message: 'Debes especificar el porcentaje de avance',
              }]
            })(
              <InputNumber
                min={0}
                max={100}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
              />
            )}
          </FormItem>
          <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}></span>
          <FormItem style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            label="Fecha">
            {getFieldDecorator('date', {
              initialValue: (course||{}).date_completed ? moment(course.date_completed) : undefined,
              rules: [ { required: true, message: 'Debes especificar la fecha'} ]
            })(
              <DatePicker locale={locale} />
            )}
          </FormItem>
        </FormItem>
        <FormItem
          label="Comentarios">
          {getFieldDecorator('comments', {
            initialValue: (course||{}).revalidation_comments,
            rules: []
          })(
            <Input.TextArea rows={5} />
          )}
        </FormItem>
      </Form>
    </Modal>
  }
}

export default Form.create()(RevalidationModal);
