import React, { useEffect, useState } from 'react';
import { Icon, Spin } from 'antd';
import styles from './validation-page.module.css';
import logotype from '../../assets/logo_full_dark.svg';
import badge from '../../assets/cert-badge.svg';
import badgeWarn from '../../assets/cert-warn.svg';
import badgeErr from '../../assets/cert-error.svg';
import CoursesService from '../core/services/courses';

const ValidationPage = ({ match }) => {

  const [ loading, setLoading ] = useState(true);
  const [ info, setInfo ] = useState();

  let { hash } = match.params;

  useEffect(() => {
    const loadInfo = async () => {
      try {
        let data = await CoursesService.validateCourse(hash);
        setInfo(data);
      }
      catch(err) {
        console.error(err);
      }
      setLoading(false);
    }
    loadInfo();
  }, [hash]);
  
  const renderLoading = () => (
    <>
      <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
      <h2>Validando...</h2>
    </>
  );

  const renderContentError = () => (
    <>
      <img src={badgeErr} alt="" className={styles.badge}/>
      <p>El certificado consultado no es válido</p>
    </>
  );

  const renderContentWarn = () => (
    <>
      <img src={badgeWarn} alt="" className={styles.badge}/>
      <p>El certificado consultado NO se encuentra vigente, corresponde a:</p>
      <h1>{info?.full_name}</h1>
      <h2>C.C. {info?.identification_number}</h2>
      <p>para el curso</p>
      <h1>{info?.course_name}</h1>
      <h2>Vigente hasta: <span className={styles.red}>{info?.valid_through}</span></h2>
    </>
  );

  const renderContentValid = () => (
    <>
      <img src={badge} alt="" className={styles.badge}/>
      <p>El certificado consultado es válido y corresponde a:</p>
      <h1>{info?.full_name}</h1>
      <h2>C.C. {info?.identification_number}</h2>
      <p>para el curso</p>
      <h1>{info?.course_name}</h1>
      {info?.valid_through && <h2>Vigente hasta: {info?.valid_through}</h2>}
    </>
  );

  const renderContent = () => {
    if(info?.is_valid) return renderContentValid();
    else {
      if(info?.valid_through) return renderContentWarn();
      else return renderContentError();
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {loading ? renderLoading() : renderContent() }
        <p className={styles.muted}>Si necesitas mas información puedes solicitarla a <a href="mailto:info@intrena.co">info@intrena.co</a></p>
      </div>
      <img src={logotype} alt="intrena logo" />
    </div>
  );
};

export default ValidationPage;
