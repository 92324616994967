import React from 'react';
import { Layout } from 'antd';
import { Link } from "react-router-dom";

import Nav from '../../core/navigation/nav';
import logo from '../../../assets/logo.svg';
import logotype from '../../../assets/logo_type.svg';

import './sidebar.css';

const Sidebar = (props) => (
  <Layout.Sider collapsible
    breakpoint="xs"
    trigger={null}
    collapsed={props.collapsed}
    onCollapse={(collapsed, type) => { props.toggle(); }}>
    <Link to="/">
      <div className="logo">
        <img src={logo} alt="logo" className="imgLogo" />
        <img src={logotype} alt="logo" className="imgType" />
      </div>
    </Link>
    <Nav collapsed={props.collapsed}/>
  </Layout.Sider>
);

export default Sidebar;
