import BaseService from './baseService';

export default class FormationPathsService extends BaseService {
  static async list(limit, skip) {
    return await super.request(`/paths?limit=${limit}&skip=${skip}`);
  }

  static async get(pathId) {
    return await super.request(`/paths/${pathId}`);
  }

  static async getCourses(pathId) {
    return await super.request(`/paths/${pathId}/courses`);
  }

  static async create(name, courses) {
    return await super.request(`/paths`, 'POST', { name, courses });
  }

  static async addCourses(pathId, courses) {
    return await super.request(`/paths/${pathId}/courses`, 'PUT', { courses });
  }

  static async removeCourse(pathId, courseId) {
    return await super.request(`/paths/${pathId}/courses/${courseId}`, 'DELETE');
  }

  static async getExternalCourses(pathId) {
    return await super.request(`/paths/${pathId}/external_courses`);
  }

  static async addExternalCourses(pathId, courses) {
    return await super.request(`/paths/${pathId}/external_courses`, 'PUT', { courses });
  }

  static async removeExternalCourse(pathId, courseId) {
    return await super.request(`/paths/${pathId}/external_courses/${courseId}`, 'DELETE');
  }

  static async getCertifications(pathId) {
    return await super.request(`/paths/${pathId}/certifications`);
  }

  static async addCertifications(pathId, certifications) {
    return await super.request(`/paths/${pathId}/certifications`, 'PUT', { certifications });
  }

  static async removeCertification(pathId, certificationId) {
    return await super.request(`/paths/${pathId}/certifications/${certificationId}`, 'DELETE');
  }
}
