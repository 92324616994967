import React, { PureComponent } from 'react';
import { Button, DatePicker, Form, Select } from 'antd';
import service from '../../core/services/reports';
import styles from './filters-sidebar.module.css';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';

const FormItem = Form.Item;

class FiltersSidebarActivity extends PureComponent {
  state = {
    areas: [],
    courses: [],
    areasFetched: false
  };

  async componentDidMount() {
    const courses = this.props.selectCourse ? (await service.getCourses()) : [];
    const areas = (await service.getAreas());
    this.setState({ courses: courses.results, areas: areas.results, areasFetched: true });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onFilter(values);
      }
    });
  }

  render() {
    const { buttonText, form, fetching } = this.props;
    const { getFieldDecorator } = form;

    return <div className={`content-internal ${styles.filters}`}>
      <Form onSubmit={this.handleSubmit} className={styles.form} layout="horizontal">

        <FormItem help={false}>
          {getFieldDecorator('date', {
            initialValue: moment(),
            rules: [
            ]
          })(
            <DatePicker.MonthPicker locale={locale} />
          )}
        </FormItem>

        <FormItem help={false}>
          {getFieldDecorator('course', {
            rules: [
            ]
          })(
            <Select placeholder="Curso" notFoundContent={`${this.state.areasFetched ? 'No se encontraron cursos' : 'Cargando...'}`}>
              {(this.state.courses || []).map(c => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
            </Select>
          )}
        </FormItem>

        <FormItem help={false}>
          {getFieldDecorator('areas', {
            rules: [
              {
                required: false
              }
            ]
          })(
            <Select placeholder="Áreas" showArrow={true} mode="multiple" maxTagCount={3} notFoundContent={`${this.state.areasFetched ? 'No se encontraron areas' : 'Cargando...'}`}>
              {(this.state.areas || []).map(c => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
            </Select>
          )}
        </FormItem>
        <FormItem>
          <Button block size="large" type="primary" htmlType="submit" loading={fetching}>{buttonText}</Button>
        </FormItem>
      </Form>

    </div>
  }
}

export default Form.create()(FiltersSidebarActivity);
