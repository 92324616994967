import React, { PureComponent } from 'react';
import { Dropdown, Avatar, Menu, Icon } from 'antd';
import { connect } from 'react-redux';

import { logout } from '../../auth/auth.redux';
import './userMenu.css';

class UserMenu extends PureComponent {

  handleMenuSelect = ({ item, key, keyPath }) => {
    switch(key) {
      case '/logout' : 
        this.props.doLogout();
        break;
      default :
    }
  }

  render() {
    return  <div className="userMenu">
        <Dropdown overlay={
          <Menu onClick={this.handleMenuSelect}>
            <Menu.Item key="/logout">
              <Icon type="user" /><span>Cerrar sesión</span>
            </Menu.Item>
          </Menu>
        } trigger={['click']}>
          <span className="menuToggle">
            <Avatar className="avatar">{this.props.user.first_name.toUpperCase()[0]}</Avatar> 
            <span className="name">{this.props.user.first_name}</span>
          </span>
        </Dropdown>
      </div>
  }
}
  

function mapStateToProps(state) {
  return state.auth;
}

function mapDispatchToProps(dispatch) {
  return {
    doLogout: () => dispatch(logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
