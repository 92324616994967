import React from 'react';
import { format, formatDistance, subDays, isBefore, isPast } from 'date-fns';
import locale from 'date-fns/locale/es';

const RELATIVE_FROM_DAYS = 2;
const DISPLAY_FORMAT = 'dd/MM/yyyy';
const LONG_FORMAT = 'PPpp';

const DateDisplay = (props) => {
  const now = new Date();
  const date = new Date(props.children);
  const fmt = props.format || DISPLAY_FORMAT;
  const showRelative =
    props.relative && !isBefore(date, subDays(now, RELATIVE_FROM_DAYS));
  const display = showRelative
    ? formatDistance(date, now, { locale })
    : format(date, fmt, { locale });
  const title = format(date, LONG_FORMAT, { locale });

  return (
    <span
      title={title}
      style={{
        color: props.markDue && isPast(date) ? '#E23636' : undefined,
      }}
    >
      {display}
    </span>
  );
};

export default DateDisplay;
