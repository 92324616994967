import React, { PureComponent } from 'react';
import { Button, Row, Col, Icon, Spin, Table } from 'antd';
import { connect } from 'react-redux';

import { actions } from './reports.redux';
import FiltersSidebarActivity from './components/filters-sidebar-activity';
import DateDisplay from '../common/components/date';
import IndicatorCard from './components/indicator-card';
import moment from 'moment';
class ActivityReportPage extends PureComponent {
  state = {
    reportGenerated: false,
    sortInfo: null,
  };

  componentDidMount() {
    if (this.props.report_data && this.props.report_data.length > 0)
      this.setState({ reportGenerated: true });
    this.queryReport({ date: moment() })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.fetching && !nextProps.fetching) {
      if (!this.props.error) {
        this.setState({ reportGenerated: true });
      } else {
        //set error
      }
    }
  }

  renderTable = (report_data, custom_fields) => {
    var cols = [
      {
        title: 'Cédula',
        dataIndex: 'identification_number',
        sorter: (a, b) =>
          a.identification_number.localeCompare(b.identification_number),
      },
      {
        title: 'Nombre',
        dataIndex: 'full_name',
        sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      },
      {
        title: 'Area',
        dataIndex: 'area_name',
        sorter: (a, b) => a.area_name.localeCompare(b.area_name),
      },
      {
        title: 'Cargo',
        dataIndex: 'position_name',
        sorter: (a, b) => a.position_name.localeCompare(b.position_name),
      },
      {
        title: 'Ubicación',
        dataIndex: 'location',
        sorter: (a, b) => a.location.localeCompare(b.location),
      },
      ...(custom_fields || []).map((cf) => ({
        title: cf.field_display_name,
        dataIndex: cf.field_name,
        sorter: (a, b) =>
          a[cf.field_name].localeCompare(b[cf.field_name]),
      })),
      
      {
        title: 'Última actividad',
        dataIndex: 'last_activity',
        render: (val) => <DateDisplay>{val}</DateDisplay>,
        sorter: (a, b) => a.last_activity.localeCompare(b.last_activity),
      },
      {
        title: 'Actividad en cursos',
        dataIndex: 'courses',
        sorter: (a, b) => a.courses.localeCompare(b.courses),
      },
      {
        title: 'Porcentaje de avance',
        dataIndex: 'percentage_completed',
        sorter: (a, b) => a.percentage_completed.localeCompare(b.percentage_completed),
      },
    ];

    return (
      <div>
        <Button style={{ float: 'right' }} type='primary' icon='download' onClick={this.downloadReport}>
          Exportar
        </Button>
        <Row gutter={32} style={{ marginBottom: 32 }} type="flex">
          <Col span={8} style={{ display: 'flex' }}>
            <IndicatorCard title={report_data ? report_data.length : ''}
              subtitle="Usuarios activos"
              backgroundColor="#ddd"
              textColor="#555" />
          </Col>
        </Row>

        <Table
          rowKey={(u) => u.id}
          columns={cols}
          dataSource={report_data}
          size='middle'
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'row-normal' : 'row-alternate'
          }
          onChange={this.handleTableChange}
          scroll={{ x: true }}
        />
      </div>
    );
  };

  toCSVValue = (val, delimiter) => {
    var t = typeof val,
      output;
    if (t === 'undefined' || t === null || val === null) {
      output = '';
    } else if (t === 'string') {
      output = delimiter + val + delimiter;
    } else {
      output = String(val);
    }

    return output;
  };

  toCSV(objArray, excludedProps = [], delimiter = ',', stringDelimiter = '"') {
    var i,
      l,
      names = [],
      name,
      value,
      obj,
      row,
      output = '',
      n,
      nl;

    for (i = 0, l = objArray.length; i < l; i += 1) {
      // Get the names of the properties.
      obj = objArray[i];
      row = '';
      if (i === 0) {
        // Loop through the names
        for (name in obj) {
          if (obj.hasOwnProperty(name) && excludedProps.indexOf(name) < 0) {
            names.push(name);
            row += [stringDelimiter, name, stringDelimiter, delimiter].join('');
          }
        }
        row = row.substring(0, row.length - 1);
        output += row;
      }

      output += '\n';
      row = '';
      for (n = 0, nl = names.length; n < nl; n += 1) {
        name = names[n];
        value = obj[name];
        if (n > 0) {
          row += delimiter;
        }
        row += this.toCSVValue(value, stringDelimiter);
      }
      output += row;
    }

    return output;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      sortInfo: sorter,
    });
  };

  downloadReport = () => {
    const { report_data } = this.props;
    const { data: r_data } = report_data || {};

    let data = (r_data || []);

    this.downloadCSV(this.toCSV(data), 'reporte.csv');
  };

  downloadCSV = (data, fileName) => {
    var csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    //IE11 & Edge
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(csvData, fileName);
    } else {
      //In FF link must be added to DOM to be clicked
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  renderReport = () => {
    const { report_data } = this.props;
    if (!report_data) return <div />;
    return <div>{this.renderTable(report_data.data, report_data.custom_fields)}</div>;
  };

  queryReport = (values) => {
    this.setState({ filters: values });
    const queryFilters = {};
    if (values.date) queryFilters.date = values.date.toISOString();
    if (values.course) queryFilters.course = values.course;
    if (values.areas) queryFilters.area = values.areas;

    this.props.getreport(queryFilters);
  };

  renderEmpty = () => {
    return (
      <div style={{ textAlign: 'center', margin: 20 }}>
        <Icon
          type='reconciliation'
          theme='twoTone'
          style={{ fontSize: '240px', opacity: 0.3 }}
        />
        <p style={{ fontSize: '20px', color: '#ccc' }}>
          Para generar el reporte seleccione los filtros y presione el botón
        </p>
      </div>
    );
  };

  render() {
    return (
      <Row gutter={16}>
        <Col span={18}>
          <div className='content-internal'>
            <Spin spinning={this.props.fetching}>
              {this.state.reportGenerated && !this.props.fetching
                ? this.renderReport()
                : this.renderEmpty()}
            </Spin>
          </div>
        </Col>
        <Col span={6}>
          <FiltersSidebarActivity
            buttonText='Generar reporte'
            fetching={this.props.fetching}
            onFilter={this.queryReport}
            selectCourse={true}
          />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return state.reports;
}

function mapDispatchToProps(dispatch) {
  return {
    getreport: (filters) => dispatch(actions.getactivityreport(filters)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityReportPage);
