import React from 'react';
import { Layout, Icon } from 'antd';

import UserMenu from './userMenu';

import './header.css';

const Header = (props) => (
  <Layout.Header className="header">
    <Icon
      className="trigger"
      type={props.collapsed ? 'menu-unfold' : 'menu-fold'}
      onClick={props.toggle} />
    <div className="right">
      <UserMenu />
    </div>
    
  </Layout.Header> 
);

export default Header;