import React, { PureComponent } from 'react';
import { Modal, Table, Input } from 'antd';
import client from '../core/services/courses';

const columns = [
  {
    title: 'Nombres',
    dataIndex: 'full_name'
  },
  {
    title: 'Email',
    dataIndex: 'email'
  },
  {
    title: 'Identificación',
    dataIndex: 'identification_number'
  }
];

const PAGE_SIZE = 10;

class AddUserModal extends PureComponent {

  state = {
    users: [],
    error: null,
    fetching: false,
    selectedRowKeys: [],
    query: null,
    pagination: {},
  }

  async componentDidMount() {
    await this.queryUsers();
  }

  async componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
      this.setState({ selectedRowKeys: [], query: null, pagination: { ...this.state.pagination, current: 1 } }, () => this.queryUsers());

    }
  }

  queryUsers = async () => {
    try {
      this.setState({ fetching: true });
      const page = this.state.pagination?.current ?? 1;
      var resp = await client.availableUsers(this.props.courseId, this.state.query, PAGE_SIZE, PAGE_SIZE * (page - 1));
      this.setState({ users: resp.results, pagination: { ...this.state.pagination, total: resp.pagination.total, pageSize: resp.pagination.limit }, fetching: false });
    }
    catch (ex) {
      this.setState({ error: ex, fetching: false });
    }
  }

  onOk = () => {
    this.props.onOk(this.state.selectedRowKeys);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  getCheckboxProps = (record) => {
    return {
      disabled: false,
      name: record.first_name
    }
  }

  doSearch = (value) => {
    this.setState({ query: value, pagination: { ...this.state.pagination, current: 1 } },
      () => this.queryUsers());
  }

  handleTableChange = (pagination) => {
    this.setState({ pagination }, () => this.queryUsers());
  }

  render() {
    const { visible, onCancel, confirmLoading } = this.props;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps
    };

    return <Modal title="Asociar usuarios"
      visible={visible}
      onOk={this.onOk}
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onCancel={onCancel}
      width="60vw"
      okText="Guardar"
      cancelText="Cancelar">
      <Input.Search
        placeholder="Buscar..."
        onSearch={this.doSearch}
        style={{ width: 400, marginBottom: '5px' }}
      />
      <Table loading={this.state.fetching} onChange={this.handleTableChange}
        rowKey={u => u.id} columns={columns} rowSelection={rowSelection} pagination={this.state.pagination}
        dataSource={this.state.users} locale={{ emptyText: this.state.error ? 'Ocurrió un error al cargar los usuarios' : 'No hay usuarios para asignar' }} />

    </Modal>
  }
}

export default AddUserModal;
