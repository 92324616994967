import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Modal, Dropdown, Menu, Icon, Input } from 'antd';

import Date from '../common/components/date';
import { actions } from './organizational-units.redux';
import NewOrganizationalUnitModal from './new-organizational-unit.modal';
import EditOrganizationalUnitModal from './edit-organizational-unit.modal';
import './organizational-units.page.css';

const confirm = Modal.confirm;

class OrganizationalUnitsPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    modalTitle: 'Crear Unidad de negocio',
    modalVisible: false,
    editOU: null,
    editModalVisible: false,
    importModalVisible: false,
    query: null,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
      },
      {
        width: 150,
        title: 'Creado',
        dataIndex: 'created_at',
        render: (val, record) => {
          return <Date relative>{val}</Date>;
        },
      },
    ];

    if (this.canEdit()) {
      this.columns.push({
        title: '',
        width: 40,
        render: (val, record) => (
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key }) => this.handleTableAction(key, record)}
              >
                <Menu.Item key='edit'>Editar</Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button type='link' className='tbl-action'>
              <Icon type='ellipsis' />
            </Button>
          </Dropdown>
        ),
      });
    }
  }

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  componentDidMount() {
    this.props.fetch(null, 1);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metadata) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          total: nextProps.metadata.total,
          pageSize: nextProps.metadata.limit,
        },
      });
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.error) {
        alert(nextProps.error);
      } else {
        this.refreshTable();
      }
    }

    if (this.props.deleting && !nextProps.deleting) {
      if (nextProps.error) {
        alert(nextProps.error);
      } else {
        this.refreshTable();
      }
    }
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    const form2 = this.editFormRef.props.form;
    form2.resetFields();
    this.hideModal();
    this.hideEditModal();
    this.hideImportModal();

    this.setState({ selectedRowKeys: [] });
    this.props.fetch(this.state.query, this.state.pagination.current || 1);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    await this.setState({ pagination });
    this.props.fetch(this.state.query, pagination.current);
  };

  getCheckboxProps = (record) => {
    return {
      disabled: false, //record.disabled,
      name: record.name,
    };
  };

  doSearch = (value) => {
    this.setState(
      { query: value, pagination: { ...this.state.pagination, current: 1 } },
      () => this.props.fetch(this.state.query, 1)
    );
  };

  showDeleteConfirm = () => {
    var count =
      this.state.selectedRowKeys.length === 1
        ? 'la unidad de negocio seleccionada'
        : `las ${this.state.selectedRowKeys.length} unidades de negocio seleccionadas`;
    confirm({
      title: `¿Estás seguro que deseas eliminar ${count}?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.delete(this.state.selectedRowKeys);
      },
    });
  };

  handleAction = async (action) => {
    console.warn(`Action ${action}`);

    switch (action) {
      case 'import':
        this.openImport();
        break;
      default:
        console.warn('unknown action');
    }
  };

  handleTableAction = (action, area) => {
    switch (action) {
      case 'edit':
        this.openEdit(area);
        break;
      default:
        console.warn('unknown action');
    }
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps,
    };

    const hasSelected = this.state.selectedRowKeys.length > 0;
    return (
      <div className='content-internal'>
        <Input.Search
          placeholder='Buscar...'
          onSearch={this.doSearch}
          style={{ width: 200, float: 'left' }}
        />
        {this.canEdit() && (
          <div className='top-buttons'>
            <Button.Group>
              {hasSelected && (
                <Button icon='user-delete' onClick={this.showDeleteConfirm}>
                  Eliminar unidad de negocio
                </Button>
              )}
              <Button type='primary' icon='plus' onClick={this.openCreate}>
                Nueva unidad de negocio
              </Button>
            </Button.Group>
          </div>
        )}

        <Table
          {...this.state}
          loading={this.props.fetching}
          className='striped-rows'
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
          rowKey={(r) => r.id}
          rowSelection={this.canEdit() ? rowSelection : undefined}
          columns={this.columns}
          dataSource={this.props.organizational_units}
          size='small'
          tableLayout='fixed'
        />

        <NewOrganizationalUnitModal
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.modalVisible}
          confirmLoading={this.props.saving}
          onCancel={this.hideModal}
          onCreate={this.handleCreate}
        />

        <EditOrganizationalUnitModal
          wrappedComponentRef={this.saveFormRefEdit}
          organizationalUnit={this.state.editOU}
          visible={this.state.editModalVisible}
          confirmLoading={this.props.saving}
          onCancel={this.hideEditModal}
          onCreate={this.handleEdit}
        />
      </div>
    );
  }

  openImport = () => {
    this.setState({ importModalVisible: true });
  };

  handleImport = (data) => {
    this.props.import(data);
  };

  hideImportModal = () => {
    this.setState({ importModalVisible: false });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  saveFormRefEdit = (editFormRef) => {
    this.editFormRef = editFormRef;
  };

  openEdit = (ou) => {
    this.setState({ editOU: ou, editModalVisible: true });
  };

  openCreate = () => {
    this.setState({ modalVisible: true });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  hideEditModal = () => {
    this.setState({ editModalVisible: false });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.create(
        values.name,
        (values.courses || []).map((c) => c.key),
        (values.areas || []).map((a) => a.key)
      );
    });
  };

  handleEdit = () => {
    const form = this.editFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.update(
        this.state.editOU.id,
        values.name,
        (values.courses || []).map((c) => c.key),
        (values.areas || []).map((a) => a.key)
      );
    });
  };
}

function mapStateToProps(state) {
  return { ...state.organizationalUnits, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (query, page) => dispatch(actions.fetch(query, page)),
    create: (name, courses, areas) =>
      dispatch(actions.create(name, courses, areas)),
    update: (id, name, courses, areas) =>
      dispatch(actions.update(id, name, courses, areas)),
    delete: (ids) => dispatch(actions.delete(ids)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationalUnitsPage);
