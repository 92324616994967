import React, { PureComponent } from 'react';
import { Modal, Form, DatePicker, notification, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';

import CertificationsService from '../core/services/certifications';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class AssignCertificationsModal extends PureComponent {
  state = {
    error: null,
    fetching: false,
    certifications: [],
  };

  async loadSelects() {
    await this.loadCertifications();
  }

  loadCertifications = async () => {
    try {
      this.setState({ fetching: true });
      let certifications = (await CertificationsService.list('', 1000, 0))
        .results;
      this.setState({ certifications });
    } catch (ex) {
      notification.error({
        message: 'Ocurrió un error al consultar las certificaciones',
        description: ex.message,
      });
    }
    this.setState({ fetching: false });
  };

  async componentDidMount() {
    await this.loadSelects();
  }

  async componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
      await this.loadSelects();
    }
  }

  render() {
    const { visible, onCancel, onSave, form, confirmLoading, certificationAssignment } = this.props;
    const { getFieldDecorator } = form;

    const isEdition = !!certificationAssignment;
    return (
      <Modal
        title={isEdition ? 'Editar asignación' : 'Asignar certificación'}
        visible={visible}
        onOk={onSave}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Form>
          <FormItem {...formItemLayout} label='Certificación'>
            {getFieldDecorator('certification', {
              initialValue: certificationAssignment?.certification_id,
              rules: [
                {
                  required: true,
                  message: 'Debes seleccionar la certificación',
                },
              ],
            })(
              <Select
                placeholder='Seleccione'
                disabled={this.state.fetching}
                loading={this.state.fetching}
              >
                {this.state.certifications.map((c) => (
                  <Select.Option key={c.id} value={c.id} title={c.name}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Fecha certificación'>
            {getFieldDecorator('date_completed', {
              initialValue: certificationAssignment?.date_completed ? moment(certificationAssignment.date_completed) : undefined,
              rules: [
                {
                  required: true,
                  message: 'Debes especificar la fecha de la certificación',
                },
              ],
            })(<DatePicker locale={locale} format="DD/MM/yyyy" />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Válido hasta'>
            {getFieldDecorator('valid_through', {
              initialValue: certificationAssignment?.valid_through ? moment(certificationAssignment.valid_through) : undefined,
              rules: [],
            })(<DatePicker locale={locale} format="DD/MM/yyyy" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(AssignCertificationsModal);
