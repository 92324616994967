import React, { Component } from 'react';
import commonStyles from './common.module.css';
import styles from './whatsapp.module.css';
import { stylizeText, colorIntToHex } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';
import { Icon } from 'antd';

const isImage = (file) => {
  return /\.(jpg|png|gif|svg|jpeg|webp)(\?.*)?/i.test(file);
};

const isVideo = (file) => {
  return /\.(mpg|mp4|avi|mov|mkv|webm)(\?.*)?/i.test(file);
};

const getFileName = (file) => {
  try {
    let path = new URL(file).pathname;
    return path.substring(path.lastIndexOf('/') + 1);
  } catch (err) {
    return '';
  }
};

class WhatsAppTemplate extends Component {
  render() {
    const { element, theme, section, courseName } = this.props;
    const { extended_props } = element;
    const { text, file } = extended_props || {};

    let fileDiv = null;

    if (file) {
      if (isImage(file)) {
        fileDiv = (
          <div className={styles.image}>
            <img src={file} alt='imagen para whatsapp' />
          </div>
        );
      } else if (isVideo(file)) {
        fileDiv = (
          <div className={styles.image}>
            <video src={file} controls alt='video de whatsapp' />
          </div>
        );
      } else {
        fileDiv = (
          <div className={styles.file}>
            <Icon type='file' /> {getFileName(file)}
          </div>
        );
      }
    }
    return (
      <div className={`${commonStyles.templateContainer} ${styles.container}`}>
        <div className={styles.message}>
          Hola %nombre%, tienes un nuevo contenido de "{courseName}"
        </div>
        {(!!file || !!text) && (
          <div className={styles.message}>
            {fileDiv}
            {stylizeText(
              text,
              theme.controls_color ||
                colorIntToHex(section.color) ||
                DEFAULTS.controls_color
            )}
          </div>
        )}
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ color: 'white', backgroundColor: '#25D366' }}
      >
        <div className={commonStyles.minTitle}>{`${
          index + 1
        }. Píldora WhatsApp`}</div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'extended_props.text',
        display: 'Texto',
        value: extProps.text,
        type: 'multiline',
      },
      {
        name: 'extended_props.file',
        display: 'Archivo',
        value: extProps.file,
        type: 'file',
        note: 'Imágenes PNG, JPG. Audio MP3, AAC, 3GP. Video MPG, MP4. Archivos PDF, DOCX, PPTX, XLSX ó DOC.',
        accept:
          'image/png, image/jpeg, audio/mp3, audio/aac, audio/3gp, audio/mpeg, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/vcard',
      },
      {
        name: 'extended_props.sendAfterMinutes',
        display: 'Tiempo para enviar el mensaje después del anterior contenido',
        value: extProps.sendAfterMinutes,
        type: 'time',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
    ];
  };
}

export default WhatsAppTemplate;
