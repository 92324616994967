import { createActions, handleActions } from 'redux-actions';
import CoursesService from '../core/services/courses';

//INITIAL STATE
const initialState = {
  courses: [],
  course: null,
  fetching: false,
  error: null,
  metadata: null,
  saving: false,
  saving_error: null
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

let actionCreators = createActions({
  COURSES: {
    FETCH: (page) => CoursesService.list(100, 100 * (page - 1)),
    GET: (courseId) => CoursesService.getDetails(courseId),
    ASSIGNUSERS: (courseId, userIds) => CoursesService.assignUsers(courseId, userIds),
    DISABLEUSERS: (courseId, userIds) => CoursesService.disableUsers(courseId, userIds),
    IMPORT: (courseId, data) => CoursesService.import(courseId, data)
  }
});

export const actions = actionCreators.courses;

//REDUCER
const reducer = handleActions({
  [`${actions.fetch}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, courses: payload.results, metadata: payload.pagination, error: null, fetching: false };
  },
  [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
    return { ...state, error: payload, fetching: false };
  },
  [`${actions.get}_${pending}`]: (state) => {
    return { ...state, course: null, fetching: true, error: null };
  },
  [`${actions.get}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, course: payload, error: null, fetching: false };
  },
  [`${actions.get}_${rejected}`]: (state, { payload }) => {
    return { ...state, error: payload, fetching: false };
  },
  [`${actions.assignusers}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.assignusers}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving_error: null, saving: false };
  },
  [`${actions.assignusers}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error: payload, saving: false };
  },
  [`${actions.disableusers}_${pending}`]: (state) => {
    return { ...state, saving: true, saving_error: null };
  },
  [`${actions.disableusers}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving_error: null, saving: false };
  },
  [`${actions.disableusers}_${rejected}`]: (state, { payload }) => {
    return { ...state, saving_error: payload, saving: false };
  },
  [`${actions.import}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.import}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, error: null };
  },
  [`${actions.import}_${rejected}`]: (state, { payload }) => {
    return { ...state, error: payload, saving: false };
  }
}, initialState);

export default reducer;
