import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Modal, Dropdown, Menu, Icon, Input } from 'antd';

import Date from '../common/components/date';
import { actions } from './positions.redux';
import NewPositionModal from './new-position.modal';
import EditPositionModal from './edit-position.modal';
import ImportPositionsModal from './import-positions.modal';
import PositionCostsModal from './position-costs.modal';
import './positions.page.css';

const confirm = Modal.confirm;

class PositionsPage extends PureComponent {

  state = {
    selectedRowKeys: [],
    pagination: {},
    modalTitle: 'Crear Cargo',
    modalVisible: false,
    editPosition: null,
    editModalVisible: false,
    importModalVisible: false,
    query: null
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Código',
        dataIndex: 'code'
      },
      {
        title: 'Nombre',
        dataIndex: 'name'
      }, 
      {
        title: 'Plan de formación',
        dataIndex: 'formation_path'
      }, 
      {
        title: 'Creado',
        dataIndex: 'created_at',
        render: (val, record) => {
          return <Date relative>{val}</Date>
        }
      }
      ];

      if(this.canEdit()) {
        this.columns.push({
          title: '',
          width: 40,
          render: (val, record) => <Dropdown overlay={(
            <Menu onClick={({item, key}) => this.handleTableAction(key, record)}>
              <Menu.Item key="edit">Editar</Menu.Item>
              <Menu.Item key="costs">Costos promedio</Menu.Item>
            </Menu>
          )} trigger={['click']}>
          <Button type="link" className="tbl-action">
            <Icon type="ellipsis" />
          </Button>
        </Dropdown>
        });
      }
  }

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  componentDidMount() {
    this.props.fetch(null, 1);
    this.props.getpaths(1);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.metadata) {
      this.setState({ pagination: { ...this.state.pagination, total: nextProps.metadata.total, pageSize: nextProps.metadata.limit } })
    }

    if(this.props.saving && !nextProps.saving) {
      if(nextProps.error) {
        alert(nextProps.error);
      }
      else {
        this.refreshTable();
      }
    }

    if(this.props.deleting && !nextProps.deleting) {
      if(nextProps.error) {
        alert(nextProps.error);
      }
      else {
        this.refreshTable();
      }
    }
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    const form2 = this.editFormRef.props.form;
    form2.resetFields();

    this.hideModal();
    this.hideEditModal();
    this.hideImportModal();
    this.hideCostsModal();

    this.setState({ selectedRowKeys: [] });
    this.props.fetch(this.state.query, this.state.pagination.current||1);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    await this.setState({pagination});
    this.props.fetch(this.state.query, pagination.current);
  }

  getCheckboxProps = (record) => {
    return {
      disabled: false,//record.disabled,
      name: record.name
    }
  }

  doSearch = (value) => {
    this.setState({ query: value, pagination: { ...this.state.pagination, current: 1 } }, 
      () => this.props.fetch(this.state.query, 1));
  }

  showDeleteConfirm = () => {
    var count = this.state.selectedRowKeys.length === 1 ? 'el cargo seleccionado' : `los ${this.state.selectedRowKeys.length} cargos seleccionados`
    confirm({
      title: `¿Estás seguro que deseas eliminar ${count}?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.delete(this.state.selectedRowKeys)
      }
    });
  }

  handleAction = async(action) => {
    console.warn(`Action ${action}`);

    switch(action) {
      case 'import':
        this.openImport();
        break;
      default:
        console.warn('unknown action');
    }
  }

  handleTableAction = (action, position) => {

    switch(action) {
      case 'edit':
        this.openEdit(position);
        break;
      case 'costs':
        this.openCosts(position);
        break;
      default: 
        console.warn('unknown action');
    }
  }

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps
    };

    const hasSelected = this.state.selectedRowKeys.length > 0;
    return <div className="content-internal">
      <Input.Search
        placeholder="Buscar..."
        onSearch={this.doSearch}
        style={{ width: 200, float: 'left' }}
      />
      { this.canEdit() && <div className="top-buttons">
        <Button.Group>
          { hasSelected && 
            <Button icon="user-delete" onClick={this.showDeleteConfirm}>Eliminar cargos</Button>
          }
          <Button type="primary" icon="plus" onClick={this.openCreate}>Nuevo cargo</Button>
          <Dropdown overlay={(
            <Menu onClick={({item, key}) => this.handleAction(key)}>
              <Menu.Item key="import"><Icon type="upload" /> Importar cargos</Menu.Item>
            </Menu>
          )}>
          <Button type="primary" icon="down"></Button>
        </Dropdown>
        </Button.Group>
      </div> }
      
      <Table {...this.state} loading={this.props.fetching} size="small" className="striped-rows"
        onChange={this.handleTableChange} pagination={this.state.pagination} 
        rowKey={r => r.id} rowSelection={this.canEdit() ? rowSelection : undefined} columns={this.columns} 
        dataSource={this.props.positions} />

      <NewPositionModal wrappedComponentRef={this.saveFormRef}
        visible={this.state.modalVisible}
        confirmLoading={this.props.saving}
        paths={this.props.paths}
        paths_fetching={this.props.fetching_paths}
        paths_error={this.props.error_paths}
        onCancel={this.hideModal}
        onCreate={this.handleCreate} />
      
      <EditPositionModal wrappedComponentRef={this.saveFormRefEdit}
        position={this.state.editPosition}
        visible={this.state.editModalVisible}
        paths={this.props.paths}
        paths_fetching={this.props.fetching_paths}
        paths_error={this.props.error_paths}
        confirmLoading={this.props.saving}
        onCancel={this.hideEditModal}
        onCreate={this.handleEdit} />

      <ImportPositionsModal
        visible={this.state.importModalVisible}
        confirmLoading={this.props.saving}
        onCancel={this.hideImportModal}
        onOk={this.handleImport} />

      <PositionCostsModal
        position={this.state.editPosition}
        visible={this.state.costsModalVisible}
        confirmLoading={this.props.saving}
        onCancel={this.hideCostsModal}
        onCreate={this.handleCosts} />
    </div>
  }

  openImport = () => {
    this.setState({ importModalVisible: true });
  }

  handleImport = (data) => {
    this.props.import(data);
  }

  hideImportModal = () => {
    this.setState({ importModalVisible: false });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  saveFormRefEdit = (editFormRef) => {
    this.editFormRef = editFormRef;
  }

  openEdit = (position) => {
    this.setState({ editPosition: position, editModalVisible: true });
  }

  openCosts = (position) => {
    this.setState({ editPosition: position, costsModalVisible: true });
  }

  openCreate = () => {
    this.setState({ modalVisible: true });
  }

  hideModal = () => {
    this.setState({ modalVisible: false });
  }

  hideEditModal = () => {
    this.setState({ editModalVisible: false });
  }


  hideCostsModal = () => {
    this.setState({ costsModalVisible: false });
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.create(values.code, values.name, values.formation_path_id);
    });
  }

  handleEdit = () => {
    const form = this.editFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.update(this.state.editPosition.id, values.code, values.name, values.formation_path_id);
    });
  }

  handleCosts = (value) => {
    this.props.updatecosts(this.state.editPosition.id, value);
  }
}

function mapStateToProps(state) {
  return { ...state.positions, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (query, page) => dispatch(actions.fetch(query, page)),
    create: (code, name, formationPathId) => dispatch(actions.create(code, name, formationPathId)),
    update: (id, code, name, formationPathId) => dispatch(actions.update(id, code, name, formationPathId)),
    delete: (ids) => dispatch(actions.delete(ids)),
    getpaths: () => dispatch(actions.paths()),
    import: (data) => dispatch(actions.import(data)),
    updatecosts: (id, costs) => dispatch(actions.updatecosts(id, costs)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionsPage);
