import React, { PureComponent } from 'react';
import { Modal, Table, Input, Select, notification } from 'antd';
import client from '../core/services/externalCourses';
import FiltersService from '../core/services/filters';

const columns = [
  {
    title: 'Nombres',
    dataIndex: 'full_name'
  }, 
  {
    title: 'Email',
    dataIndex: 'email'
  }, 
  {
    title: 'Identificación',
    dataIndex: 'identification_number'
  }
];

class AddUserModal extends PureComponent {

  state = {
    users: [],
    error: null,
    fetching: false,
    selectedRowKeys: [],
    costCenters: [],
    filter: null,
    query: null,
    pagination: { defaultPageSize: 5 }
  }

  async componentDidMount() {
    await this.queryUsers();
    await this.loadCostCenters();
  }

  async componentWillReceiveProps(nextProps) {
    if(!this.props.visible && nextProps.visible) 
    {
      this.setState({ selectedRowKeys: [], query: null, pagination: { ...this.state.pagination, current: 1 } });
      await this.queryUsers();
      await this.loadCostCenters();
    }
  }

  loadCostCenters = async () => {
    try {
      this.setState({ loadingCostCenters: true });
      let costCenters = (await FiltersService.listValues(1, 200, 0)).results;
      this.setState({ costCenters });
    }
    catch (ex) {
      notification.error({ message: 'Ocurrió un error', description: ex.message });
    }
    this.setState({ loadingCostCenters: false });
  }

  queryUsers = async(query, filter) => {
    try {
      this.setState({ fetching: true });
      var resp = await client.availableUsers(this.props.courseId, query, filter); //TODO: Paginación
      this.setState({ users: resp, fetching: false });
    }
    catch(ex) {
      this.setState({ error: ex, fetching: false });
    }
  }

  onOk = () => {
    this.props.onOk(this.state.selectedRowKeys);
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  getCheckboxProps = (record) => {
    return {
      disabled: false,
      name: record.first_name
    }
  }

  doSearch = (value) => {
    this.setState({ query: value, pagination: { ...this.state.pagination, current: 1 } }, 
      () => this.queryUsers(this.state.query, this.state.filter));
  }

  handleFilter = (value) => {
    this.setState({ filter: value, pagination: { ...this.state.pagination, current: 1 } }, 
      () => this.queryUsers(this.state.query, this.state.filter));
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({pagination});
    
  }

  render() {
    const { visible, onCancel, confirmLoading } = this.props;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps
    };

    return <Modal title="Asociar usuarios"
      visible={visible}
      onOk={this.onOk}
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onCancel={onCancel}
      width="60vw"
      okText="Guardar"
      cancelText="Cancelar">
      <div style={{marginBottom: '5px', gap: '10px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
      <Input.Search
        placeholder="Buscar..."
        onSearch={this.doSearch}
        style={{ flex: 1 }}
      />
      <Select style={{ flex: 1 }} placeholder="Centro de costos" loading={this.state.loadingCostCenters} onChange={this.handleFilter}>
        {this.state.costCenters.map(c => <Select.Option key={c.code} value={c.code}>{c.value}</Select.Option>)}
      </Select>
      </div>
      
      <Table loading={this.state.fetching} onChange={this.handleTableChange}
        rowKey={u => u.id} columns={columns} rowSelection={rowSelection} pagination={this.state.pagination}
        dataSource={this.state.users} locale={{ emptyText: this.state.error ? 'Ocurrió un error al cargar los usuarios' : 'No hay usuarios para asignar'}}/>

    </Modal>
  }
}

export default AddUserModal;
