import React, { Component } from 'react';
import commonStyles from './common.module.css';
import styles from './concept.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class ConceptTemplate extends Component {
  state = {
    imageFailed: false,
  };

  onImageError = (e) => {
    if (this.state.imageFailed) return;
    const { element } = this.props;
    this.setState({ imageFailed: true });
    e.target.src = getAssetUri(
      'images',
      'general',
      element.extended_props.image
    );
  };

  render() {
    const { element, slug, theme, section } = this.props;
    const { title, extended_props } = element;
    const {
      text,
      image,
      text2,
      imageWidthDP,
      imageHeightDP,
      imagePadding,
      link,
    } = extended_props || {};

    const imgStyles = {};
    if (imageWidthDP) imgStyles.width = imageWidthDP / 10 + 'vw';
    if (imageHeightDP) imgStyles.height = imageHeightDP / 10 + 'vw';
    if (imagePadding) imgStyles.padding = imagePadding / 10 + 'vw';

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const linkStyles = {
      color: theme.link_color || theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http') ||
        theme.background_image.startsWith('data:')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer}`}
        style={containerStyles}
      >
        <div className={commonStyles.templateTitle} style={textStyles}>
          {stylizeText(title, controlStyles.backgroundColor)}
        </div>
        <div className={commonStyles.templateBody}>
          {!!image && (
            <img
              className={commonStyles.templateImage}
              style={imgStyles}
              alt={image}
              src={getAssetUri('images', slug, image)}
              onError={this.onImageError}
            />
          )}
          <div className={commonStyles.templateText} style={textStyles}>
            {stylizeText(text, controlStyles.backgroundColor)}
          </div>
          {text2 && (
            <div className={commonStyles.templateSubText} style={textStyles}>
              {stylizeText(text2, controlStyles.backgroundColor)}
            </div>
          )}
          {link && (
            <a
              href={link.url}
              className={`${commonStyles.templateText} ${styles.link}`}
              target='_blank'
              rel='noopener noreferrer'
              title={link.title}
              style={{ ...textStyles, ...linkStyles }}
            >
              {link.title}
            </a>
          )}
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${index + 1}. Concepto`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.image',
        display: 'Imagen',
        value: extProps.image,
        type: 'file',
        subtype: 'images',
        note: 'Solo imágenes PNG o SVG',
        accept: 'image/png, image/svg+xml',
      },
      {
        name: 'extended_props.text',
        display: 'Texto',
        value: extProps.text,
        type: 'multiline',
      },
      {
        name: 'extended_props.text2',
        display: 'Sub texto',
        value: extProps.text2,
        type: 'text',
      },
      {
        name: 'extended_props.textAlignment',
        display: 'Alineación Texto',
        value: extProps.textAlignment,
        type: 'select',
        values: [
          { title: 'Centrado', value: 'center' },
          { title: 'Izquierda', value: 'left' },
          { title: 'Derecha', value: 'right' },
        ],
      },
      {
        name: 'extended_props.imageWidthDP',
        display: 'Ancho imagen',
        value: extProps.imageWidthDP,
        type: 'number',
      },
      {
        name: 'extended_props.imageHeightDP',
        display: 'Alto imagen',
        value: extProps.imageHeightDP,
        type: 'number',
      },
      {
        name: 'extended_props.imagePadding',
        display: 'Padding',
        value: extProps.imagePadding,
        type: 'number',
      },
      {
        name: 'extended_props.link',
        display: 'Vínculo',
        value: extProps.link,
        type: 'url',
      },
      {
        name: 'extended_props.continueOnSoundEnd',
        display: 'Continuar después de sonido',
        value: extProps.continueOnSoundEnd,
        type: 'boolean',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
    ];
  };
}

export default ConceptTemplate;
