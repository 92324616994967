import React, { Component } from 'react';
import { Tag } from 'antd';
import commonStyles from './common.module.css';
import styles from './complete-phrase.module.css';
import { stylizeText, colorIntToHex, getAssetUri } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class CompletePhraseTemplate extends Component {
  render() {
    const { element, section, theme, slug } = this.props;
    const { title, extended_props, options } = element;
    const { description, help_text, phrase } = extended_props || {};

    const opts = Object.keys(options || {})
      .map((k) => options[k])
      .sort((a, b) => a.order - b.order);

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer}`}
        style={containerStyles}
      >
        <div className={commonStyles.templateTitle} style={textStyles}>
          {stylizeText(title, controlStyles.backgroundColor)}
        </div>
        {description && (
          <div
            className={commonStyles.templateText}
            style={{ ...textStyles, marginTop: '4vw' }}
          >
            {stylizeText(
              description,
              controlStyles.backgroundColor.backgroundColor
            )}
          </div>
        )}
        <div className={commonStyles.templateBody}>
          <div className={styles.phraseContainer}>
            {(phrase || '')
              .replace(/\n/g, ' <br/> ')
              .split(' ')
              .map((item, index) => {
                if (/^(_)+$/.test(item)) {
                  return (
                    <span
                      key={'word_' + index}
                      className={styles.phraseWord}
                      style={textStyles}
                    >
                      {item}
                    </span>
                  );
                } else if (item === '<br/>') {
                  return (
                    <span
                      key={'word_' + index}
                      className={styles.phraseBreakLine}
                    ></span>
                  );
                } else {
                  return (
                    <span
                      key={'word_' + index}
                      className={styles.phraseWord}
                      style={textStyles}
                    >
                      {item}
                    </span>
                  );
                }
              })}
          </div>
          <div className={styles.helpText} style={textStyles}>
            {stylizeText(help_text, controlStyles.backgroundColor)}
          </div>
          <div className={styles.optionsContainer}>
            {opts.map((o) => (
              <Tag
                className={styles.option}
                style={controlStyles}
                color={controlStyles.backgroundColor}
              >
                {o.text}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${
          index + 1
        }. Completar frase`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.description',
        display: 'Texto',
        value: extProps.description,
        type: 'multiline',
      },
      {
        name: 'extended_props.phrase',
        display: 'Frase',
        value: extProps.phrase,
        type: 'multiline',
      },
      {
        name: 'extended_props.help_text',
        display: 'Texto de ayuda',
        value: extProps.help_text,
        type: 'text',
      },
      {
        name: 'extended_props.hide_result',
        display: 'Ocultar resultado',
        value: extProps.hide_result,
        type: 'boolean',
      },
      {
        name: 'extended_props.only_feedback',
        display: 'Sólo feedback',
        value: extProps.only_feedback,
        type: 'boolean',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
      {
        name: 'options',
        display: 'Opciones',
        value: element.options,
        type: 'options',
      },
    ];
  };
}

export default CompletePhraseTemplate;
