import React from 'react';
import { Input } from 'antd';


const Time = ({ courseId, name, value, onChange, onBlur }) => {
 
  const timeValue = parseInt(value, 10) || 0;
  let hoursValue = Math.floor(timeValue/60);
  let minutesValue = timeValue%60;

  const onChanged=  (hours, minutes) => {
    onChange(hours * 60 + minutes);
  }

  const onHoursChanged = (e) => {
    const hrs = parseInt(e.target.value, 10);
    onChanged(hrs, minutesValue);
  };

  const onMinutesChanged = (e) => {
    let mins = Math.min(59, parseInt(e.target.value, 10));
    onChanged(hoursValue, mins);
  };

  return <Input.Group compact>
    <Input type="number" size="small" style={{ width: '50%' }} suffix="h" value={hoursValue} onChange={onHoursChanged} />
    <Input type="number" max={59} size="small" style={{ width: '50%' }} suffix="m" value={minutesValue} onChange={onMinutesChanged} />
  </Input.Group>;
};

export default Time;