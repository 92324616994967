import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd-country-phone-input';
import es from 'world_countries_lists/data/countries/es/world.json';

import Router from '../navigation/router';
import configureStore, { history } from '../redux';
import 'moment/locale/es';

const { store } = configureStore();

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <ConfigProvider locale={es}>
          <Router history={history} />
        </ConfigProvider>
      </Provider>
    );
  }
}

export default App;
