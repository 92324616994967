import BaseService from "./baseService";

export default class DashboardService extends BaseService
{
  static async hours(filters) {
    const filtersQuery = Object.keys(filters).filter(k => filters[k] !== undefined).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/external-courses/indicators/hours?${filtersQuery}`);
  }

  static async attendance(filters) {
    const filtersQuery = Object.keys(filters).filter(k => filters[k] !== undefined).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/external-courses/indicators/attendance?${filtersQuery}`);
  }

  static async approval(filters) {
    const filtersQuery = Object.keys(filters).filter(k => filters[k] !== undefined).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/external-courses/indicators/approval?${filtersQuery}`);
  }

  static async cost(filters) {
    const filtersQuery = Object.keys(filters).filter(k => filters[k] !== undefined).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/external-courses/indicators/cost?${filtersQuery}`);
  }

  static async historic(filters, type, groupBy) {
    const filtersQuery = Object.keys(filters).filter(k => filters[k] !== undefined).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/external-courses/historic/${type}/${groupBy||'month'}?${filtersQuery}`);
  }

  static async hoursByType(filters) {
    const filtersQuery = Object.keys(filters).filter(k => filters[k] !== undefined).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/external-courses/indicators/hours-by-type?${filtersQuery}`);
  }
}