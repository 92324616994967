import React, { PureComponent } from 'react';
import { Modal, InputNumber, Table } from 'antd';

const INITIAL_YEAR = 2018;

class PositionCostsModal extends PureComponent {

  state = {
    filters: 0,
    values: {}
  };

  componentDidMount() {
    const { position } = this.props;
    this.setState({ values: position && position.costs ? position.costs : {} });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.position !== this.props.position) {
      const { position } = this.props;
      this.setState({ values: position && position.costs ? position.costs : {} });
  
    }
  }

  onValueChanged = (year, newValue) => {
    const newValues = { ...this.state.values };
    newValues[year] = newValue;
    this.setState({ values: newValues });
  }

  onSave = () => {
    const { onCreate } = this.props;
    if(onCreate) onCreate(this.state.values);
  }

  render() {
    const { visible, onCancel, position, confirmLoading } = this.props;
    const { values } = this.state;

    if(!position) return <div/>;
    const currYear = new Date().getFullYear();
    const tableData = [...Array(currYear - INITIAL_YEAR + 1).keys()].map(a=> ({ year: currYear - a, value: values[currYear - a]||0 }));
    
    return <Modal title="Editar costos promedio"
      visible={visible}
      onOk={this.onSave}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText="Guardar"
      cancelText="Cancelar">
      <Table dataSource={tableData} bordered={false} pagination={false} size="small" columns={[
        {
          title: 'Año',
          dataIndex: 'year',
          key: 'year'
        },
        {
          title: 'Costo promedio',
          dataIndex: 'value',
          key: 'value',
          render: (v, r) => <InputNumber
            value={v}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(nv) => this.onValueChanged(r.year, nv)}
          />
        }
      ]} />
    </Modal>
  } 
}

export default PositionCostsModal;
