import React, { Component } from 'react';
import { Button } from 'antd';
import commonStyles from './common.module.css';
import styles from './sound.module.css';
import { stylizeText, getAssetUri, colorIntToHex } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class SoundTemplate extends Component {
  state = {
    playing: false,
    loaded: false,
  };

  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
  }

  onAudioLoaded = () => {
    this.setState({ loaded: true });
  };

  onAudioEnded = () => {
    this.setState({ playing: false });
  };

  onAudioError = () => {
    const sound = (this.props.element.extended_props || {}).sound;
    const audio = this.audioRef.current;

    if (audio) {
      audio.src = getAssetUri('audio', 'general', sound);
      audio.removeEventListener('error', this.onAudioError);
    }
  };

  toggleAudio = () => {
    const audio = this.audioRef.current;
    if (this.state.loaded) {
      if (this.state.playing) {
        audio.pause();
      } else {
        audio.play();
      }
      this.setState({ playing: !this.state.playing });
    }
  };

  componentDidMount() {
    const audio = this.audioRef.current;
    audio.addEventListener('canplay', this.onAudioLoaded.bind(this));
    audio.addEventListener('error', this.onAudioError.bind(this));
    audio.addEventListener('ended', this.onAudioEnded.bind(this));
  }

  componentWillUnmount() {
    const audio = this.audioRef.current;
    audio.removeEventListener('canplay', this.onAudioLoaded);
    audio.removeEventListener('error', this.onAudioError);
    audio.removeEventListener('ended', this.onAudioEnded);
  }

  render() {
    const { element, section, slug, theme } = this.props;
    const { title, extended_props } = element;
    const { sound, description } = extended_props || {};
    var icon = this.state.loaded
      ? this.state.playing
        ? 'pause'
        : 'caret-right'
      : 'loading';

    const textStyles = {
      color: theme.text_color || undefined,
    };

    const bgImage = theme.background_image
      ? theme.background_image.startsWith('http')
        ? theme.background_image
        : getAssetUri('images', slug, theme.background_image, false)
      : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined,
    };

    const controlStyles = {
      backgroundColor:
        theme.controls_color ||
        colorIntToHex(section.color) ||
        DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    };

    return (
      <div
        className={`${commonStyles.templateContainer}`}
        style={containerStyles}
      >
        <div className={commonStyles.templateTitle} style={textStyles}>
          {stylizeText(title, controlStyles.backgroundColor)}
        </div>
        {description && (
          <div className={commonStyles.templateText} style={textStyles}>
            {stylizeText(description, controlStyles.backgroundColor)}
          </div>
        )}
        <div className={commonStyles.templateBody}>
          <Button
            className={styles.button}
            style={{
              ...controlStyles,
              borderColor: controlStyles.backgroundColor,
            }}
            type='primary'
            shape='circle'
            color={controlStyles.backgroundColor}
            icon={icon}
            size='large'
            onClick={this.toggleAudio}
          />
        </div>
        <audio ref={this.audioRef} src={getAssetUri('audio', slug, sound)}>
          No se puede previsualizar el sonido. El navegador no soporta el
          elemento <code>audio</code>.
        </audio>
      </div>
    );
  }

  static renderMiniature({ element, index, parentIndex, ...props }) {
    return (
      <div
        className={commonStyles.miniature}
        style={{ backgroundColor: '#efefef' }}
      >
        <div className={commonStyles.minTitle}>{`${index + 1}. Audio`}</div>
        <div className={commonStyles.minTitle}>
          {stylizeText(element.title)}
        </div>
      </div>
    );
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props || {};
    return [
      {
        name: 'title',
        display: 'Título',
        value: element.title,
        type: 'multiline',
      },
      {
        name: 'extended_props.description',
        display: 'Descripción',
        value: extProps.description,
        type: 'multiline',
      },
      {
        name: 'extended_props.sound',
        display: 'Audio',
        value: extProps.sound,
        type: 'file',
        subtype: 'audio',
        note: 'Solo archivos m4a',
        accept: 'audio/mp4',
      },
      {
        name: 'extended_props.local',
        display: 'Local? (de grabación)',
        value: extProps.local,
        type: 'boolean',
      },
      {
        name: 'extended_props.max_plays',
        display: 'Reproducciones máximas',
        value: extProps.max_plays,
        type: 'number',
      },
      {
        name: 'extended_props.hide_result',
        display: 'Ocultar resultado',
        value: extProps.hide_result,
        type: 'boolean',
      },
      {
        name: 'extended_props.only_feedback',
        display: 'Sólo feedback',
        value: extProps.only_feedback,
        type: 'boolean',
      },
      {
        name: 'category',
        display: 'Categoría',
        value: element.category,
        type: 'select',
        values: [
          { title: 'Conciencia', value: 'conciencia' },
          { title: 'Concepto', value: 'concepto' },
          { title: 'Contexto', value: 'contexto' },
        ],
      },
      {
        name: 'estimatedTime',
        display: 'Tiempo estimado (seg)',
        value: element.estimatedTime,
        type: 'number',
      },
    ];
  };
}

export default SoundTemplate;
