import React, { PureComponent } from 'react';
import { Modal, Form, Input, Switch, DatePicker } from 'antd';
import * as moment from 'moment';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class EditUserModal extends PureComponent {

  state = {
    courses: []
  }

  handleSearch = async(value) => {
    // try {
    //   var response = await usersService.search(value);
    //   this.setState({ users: (response||{}).results||[] });
    // }
    // catch(ex) {
    //   console.error(ex);
    // }
  }

  render() {
    const { visible, onCancel, onOk, form, confirmLoading, user } = this.props;
    const { getFieldDecorator } = form;
      if(!user) return <></>;
    return <Modal title="Crear nuevo curso externo"
      visible={visible}
      onOk={onOk}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText="Guardar"
      cancelText="Cancelar">
      <Form>
        <FormItem
          {...formItemLayout}
          label="Desde">
          {getFieldDecorator('start_time', {
            initialValue: user.start_time ? moment(user.start_time) : undefined,
            rules: []
          })(
            <DatePicker />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Hasta">
          {getFieldDecorator('end_time', {
            initialValue: user.end_time ? moment(user.end_time) : undefined,
            rules: []
          })(
            <DatePicker />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Asistió">
          {getFieldDecorator('assisted', {
            initialValue: user.assisted,
            valuePropName: 'checked' 
          })(
            <Switch />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Aprobó"
        >
          {getFieldDecorator('approved', { 
            initialValue: user.approved,
            valuePropName: 'checked' 
          })(
            <Switch />
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Resultado">
          {getFieldDecorator('quantitative_result', {
            initialValue: user.quantitative_result,
            rules: []
          })(
            <Input type="number" />
          )}
        </FormItem>
      </Form>
    </Modal>
  }
}

export default Form.create()(EditUserModal);
