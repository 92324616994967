import React, { PureComponent } from 'react';
import {
  Form,
  Modal,
  Input,
  DatePicker,
  Upload,
  Switch,
  Icon,
  Button,
  Tag,
  message,
} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const getFileName = (url) => {
  let display = url;
  if (
    display.startsWith('https://firebasestorage') ||
    display.startsWith('https://storage.googleapis.com')
  ) {
    display = decodeURIComponent(new URL(display).pathname.split('/').pop());
    if (display.lastIndexOf('/') >= 0) {
      display = display.substring(display.lastIndexOf('/') + 1);
    }
  }
  return display;
};

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment();
}

const urlToObject = async (image) => {
  const response = await fetch(image);
  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], getFileName(image), { type: blob.type });
  return file;
};

class EditMessageModal extends PureComponent {
  state = {
    error: null,
    fetching: false,
    costCenters: [],
    loadingAreas: false,
    areas: [],
    loadingPositions: false,
    positions: [],
    loadingLocations: false,
    locations: [],
  };

  onOk = () => {
    const { onCreate, form } = this.props;
    const { file } = this.state;
    const { getFieldValue } = form;
    const fv = getFieldValue('body');
    if (!fv && !file) {
      message.warn('Debes diligenciar el mensaje y/o adjuntar un archivo.');
    } else {
      onCreate(file);
    }
  };

  beforeUpload = (file) => {
    this.setState({
      file,
    });
    return false;
  };

  removeFile = () => {
    this.setState({
      file: null,
    });
  };

  async componentDidUpdate(prevProps) {
    if (
      this.props.message?.file_url !== prevProps.message?.file_url &&
      this.props.message?.file_url
    ) {
      this.setState({ file: await urlToObject(this.props.message.file_url) });
    }
  }

  render() {
    const { visible, onCancel, confirmLoading, form, message } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    if (!message) return <div />;
    return (
      <Modal
        title='Editar mensaje'
        visible={visible}
        onOk={this.onOk}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        onCancel={onCancel}
        width='60vw'
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Form>
          <FormItem {...formItemLayout} label='Mensaje'>
            {getFieldDecorator('body', {
              initialValue: message.body,
              rules: [],
            })(<Input.TextArea />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Programar?'>
            {getFieldDecorator('programmed', {
              initialValue: !!message.programmed_date,
              valuePropName: 'checked',
              rules: [],
            })(<Switch />)}
          </FormItem>
          {getFieldValue('programmed') && (
            <FormItem {...formItemLayout} label='Fecha envío'>
              {getFieldDecorator('programmed_date', {
                initialValue: moment(message.programmed_date),
                rules: [
                  {
                    required: true,
                    message: 'Debes especificar la fecha de programación',
                  },
                ],
              })(
                <DatePicker
                  placeholder='Seleccione la fecha'
                  disabledDate={disabledDate}
                  showTime={{
                    defaultValue: moment('12:00:00', 'HH:mm:ss'),
                    use12Hours: true,
                    minuteStep: 5,
                    format: 'hh:mm',
                  }}
                  locale={locale}
                  format='DD/MM/YYYY hh:mm a'
                />
              )}
            </FormItem>
          )}
          <FormItem
            {...formItemLayout}
            label='Archivo adjunto'
            extra='Imágenes PNG, JPG. Audio MP3, AAC, 3GP. Video MPG, MP4.
                  Archivos PDF, DOCX, PPTX, XLSX ó DOC.'
          >
            {getFieldDecorator('attachment', {
              hidden: true,
              valuePropName: 'fileList',
            })(
              !this.state.file ? (
                <Upload
                  name='attachment'
                  maxCount={1}
                  showUploadList={false}
                  accept='image/png, image/jpeg, audio/mp3, audio/aac, audio/3gp, audio/mpeg, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/vcard'
                  beforeUpload={this.beforeUpload}
                >
                  <Button>
                    <Icon type='upload' /> Cargar archivo...
                  </Button>
                </Upload>
              ) : (
                <>
                  <Tag>{this.state.file.name}</Tag>
                  <Button type='link' icon='delete' onClick={this.removeFile} />
                </>
              )
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(EditMessageModal);
