import React, { PureComponent } from 'react';
import { Modal, List, Card, Typography, Input, Spin } from 'antd';
import { ReactComponent as ConceptIcon } from '../../../assets/concept.svg';
import { ReactComponent as ChallengeIcon } from '../../../assets/challenge.svg';
import { ReactComponent as ChartIcon } from '../../../assets/chart.svg';
import { ReactComponent as CompletePhraseIcon } from '../../../assets/complete-phrase.svg';
import { ReactComponent as DragAndDropIcon } from '../../../assets/drag-and-drop.svg';
import { ReactComponent as VideoIcon } from '../../../assets/video.svg';
import { ReactComponent as ImageChoiceIcon } from '../../../assets/image-choice.svg';
import { ReactComponent as ImageSliderIcon } from '../../../assets/image-slider.svg';
import { ReactComponent as MultipleChoiceIcon } from '../../../assets/multiple-choice.svg';
import { ReactComponent as SingleChoiceIcon } from '../../../assets/single-choice.svg';
import { ReactComponent as OrderPhraseIcon } from '../../../assets/order-phrase.svg';
import { ReactComponent as RecordIcon } from '../../../assets/record.svg';
import { ReactComponent as SortIcon } from '../../../assets/sort.svg';
import { ReactComponent as SoundIcon } from '../../../assets/sound.svg';
import { ReactComponent as TipIcon } from '../../../assets/tip.svg';
import { ReactComponent as TypeIcon } from '../../../assets/type.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/youtube.svg';
import { ReactComponent as WhatsAppIcon } from '../../../assets/whatsapp.svg';
import { ReactComponent as WhatsAppListIcon } from '../../../assets/whatsapp-list.svg';

import styles from './new-content-modal.module.css';

const data = [
  {
    id: 'Concept',
    title: 'Concepto',
    description: 'Pantalla para mostrar un concepto en texto y opcionalmente una imagen.',
    icon: <ConceptIcon />,
    order: 1
  },
  {
    id: 'Challenge',
    title: 'Reto',
    description: 'Pantalla para describirle al usuario un reto para realizar por fuera de la aplicación.',
    icon: <ChallengeIcon />,
    order: 14
  },
  {
    id: 'Chart',
    title: 'Gráfica (Barras)',
    description: 'Pantalla para mostrar una gráfica de barras donde se resumen las elecciones que realizó en las pasadas pantallas de selección única.',
    icon: <ChartIcon />,
    order: 18
  },
  {
    id: 'CompletePhrase',
    title: 'Completar frase',
    description: 'Pantalla que le permite al usuario completar una frase con unas opciones establecidas.',
    icon: <CompletePhraseIcon />,
    order: 4
  },
  {
    id: 'DragAndDrop',
    title: 'Arrastrar',
    description: 'Pantalla para arrastrar las opciones válidas a una imágen.',
    icon: <DragAndDropIcon />,
    order: 5
  },
  {
    id: 'FirebaseVideo',
    title: 'Video (desde Firebase)',
    description: 'Pantalla para visualizar un video.',
    icon: <VideoIcon />,
    order: 17
  },
  {
    id: 'ImageChoice',
    title: 'Selección de imagen',
    description: 'Pantalla donde se muestran máximo tres imágenes para que el usuario seleccione una.',
    icon: <ImageChoiceIcon />,
    order: 16
  },
  {
    id: 'ImagesSlider',
    title: 'Armar imagen (Slider)',
    description: 'Pantalla donde el usuario puede armar una imagen dividida en tres seleccionando las partes correctas.',
    icon: <ImageSliderIcon />,
    order: 19
  },
  {
    id: 'MultipleChoice',
    title: 'Selección multiple',
    description: 'Pantalla donde el usuario puede seleccionar multiples de las posibles.',
    icon: <MultipleChoiceIcon />,
    order: 3
  },
  {
    id: 'SingleChoice',
    title: 'Selección única',
    description: 'Pantalla donde el usuario puede escoger una única opción válida de las posibles.',
    icon: <SingleChoiceIcon />,
    order: 2
  },
  {
    id: 'OrderPhrase',
    title: 'Ordenar frase',
    description: 'Pantalla que le permite al usuario ordenar una frase que se encuentra en desorden.',
    icon: <OrderPhraseIcon />,
    order: 13
  },
  {
    id: 'Record',
    title: 'Grabar audio',
    description: 'Pantalla que le permite al usuario grabar un audio.',
    icon: <RecordIcon />,
    order: 12
  },
  {
    id: 'Sort',
    title: 'Podio (Ordenar 3)',
    description: 'Pantalla donde el usuario debe ordenar tres elementos según la importancia o prioridad.',
    icon: <SortIcon />,
    order: 11
  },
  {
    id: 'Sound',
    title: 'Audio',
    description: 'Pantalla donde el usuario puede escuchar un audio.',
    icon: <SoundIcon />,
    order: 6
  },
  {
    id: 'Tip',
    title: 'Tip',
    description: 'Pantalla para mostrarle un concepto corto al usuario en forma de tip.',
    icon: <TipIcon />,
    order: 15
  },
  {
    id: 'Type',
    title: 'Escribir (Recopilación)',
    description: 'Pantalla donde el usuario puede escribir.',
    icon: <TypeIcon />,
    order: 10
  },
  {
    id: 'Video',
    title: 'Video YouTube',
    description: 'Pantalla para visualizar un video de YouTube.',
    icon: <YoutubeIcon />,
    order: 7
  },
  {
    id: 'WhatsAppList',
    title: 'Selección única de WhatsApp',
    description: 'Plantilla de lista de WhatsApp para enviar una pregunta de selección única',
    icon: <WhatsAppListIcon />,
    order: 19
  },
  {
    id: 'WhatsApp',
    title: 'Píldora de WhatsApp',
    description: 'Pantalla que te permite programar un contenido para enviarse por WhatsApp.',
    icon: <WhatsAppIcon />,
    order: 18
  },
  {
    id: 'Infographic',
    title: 'Infografía',
    description: 'Pantalla para visualizar una infografía en formato SVG',
    icon: <ConceptIcon />,
    order: 19
  }
];

const accentFold = (str) => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

class NewContentModal extends PureComponent {

  state = {
    query: '',
    orders: []
  }

  doSearch = (query) => {
    this.setState({ query });
  }

  render() {
    const { visible, confirmLoading, onCreate, onCancel } = this.props;
    const { query } = this.state;

    let elements = data.sort((a,b) => a.order - b.order);
    if(query) elements = elements.filter(e => accentFold(e.title).indexOf(accentFold(query)) >= 0);

    return <Modal title="Nuevo contenido"
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      width="80vw"
      footer={null}>
      <Spin spinning={confirmLoading}>
        <Input.Search
          placeholder="Buscar plantilla (Ej. 'Concepto')"
          onSearch={this.doSearch}
          onChange={(e) => this.doSearch(e.target.value)}
          size="large"
          className={styles.searchBox}
        />
        <List
          grid={{ gutter: 32, column: 4 }}
          dataSource={elements}
          itemLayout="horizontal"
          renderItem={item => (
            <List.Item>
              <Card className={styles.card} onClick={() => onCreate(item.id)} hoverable cover={<div className="cover">{item.icon}</div> }>
                  <Card.Meta title={item.title} description={<Typography.Paragraph ellipsis={{ rows: 2 }}>
                    {item.description}
                  </Typography.Paragraph>} />
              </Card>
            </List.Item>
          )}
        />
      </Spin>
    </Modal>;
  } 


}

export default NewContentModal;
