import React, { PureComponent } from 'react';
import { Row, Col, Icon, Spin, Table } from 'antd';
import { G2, Chart, Geom, Axis, Tooltip } from 'bizcharts';
import { connect } from 'react-redux';

import { actions } from './admin.redux';
import FiltersSidebar from './components/filters-sidebar';
import IndicatorCard from './components/indicator-card';

G2.track(false);
class ReportPage extends PureComponent {
  state = {
    reportGenerated: false,
    sortInfo: {
      topTable: null,
      areasTable: null,
      areasTopUsersTable: null
    }
  };
  

  componentDidMount() {
    if(this.props.report_data && this.props.report_data.length > 0) this.setState({ reportGenerated: true });
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.fetching && !nextProps.fetching) {
      if(!this.props.error) {
        this.setState({ reportGenerated: true });
      }
      else {
        //set error
      }
    }
  }

  renderGeneralIndicators = (report_data) => {
    const participation = Math.floor(report_data.counts.course.active*100/report_data.counts.course.total);
    const finalization = Math.floor(report_data.counts.course.finished*100/report_data.counts.course.active);

    const avgMinutes = Math.floor(report_data.transactions.average_completion_time/60);
    const totalHours = Math.floor(report_data.transactions.total_seconds/60/60);
    
    return <div>
      <h2>Indicadores generales</h2>
      <Row gutter={32} style={{marginBottom:32}} type="flex">
        <Col span={8} style={{display:'flex'}}>
          <IndicatorCard title={report_data.counts.course.total}
            subtitle="Usuarios" 
            backgroundColor="#ddd"
            textColor="#555" />
        </Col>
        <Col span={8} style={{display:'flex'}}>
          <IndicatorCard title={`${Number.isNaN(participation) ? '-' : participation}%`}
            subtitle="Participación" 
            backgroundColor="#ED5244" 
            textColor="white" />
        </Col>
        <Col span={8} style={{display:'flex'}}>
          <IndicatorCard title={`${Number.isNaN(finalization) ? '-' : finalization}%`}
            subtitle="Finalización" 
            backgroundColor="#34495E" 
            textColor="white" />
        </Col>
      </Row>

      <Row gutter={32} type="flex">
        <Col span={8} style={{display:'flex'}}>
          <IndicatorCard title={report_data.counts.course.challenges.finished}
            subtitle="Retos realizados" 
            backgroundColor="#00BCD4" 
            textColor="white" />
        </Col>
        <Col span={8} style={{display:'flex'}}>
          <IndicatorCard title={`${avgMinutes} min.`}
            subtitle="Tiempo promedio finalizar curso" 
            backgroundColor="#ED5244" 
            textColor="white" />
        </Col>
        <Col span={8} style={{display:'flex'}}>
          <IndicatorCard title={`${totalHours}`}
            subtitle="Total horas de formación" 
            backgroundColor="#34495E" 
            textColor="white" />
        </Col>
      </Row>
    </div>;
  }

  handleTopTableChange = (pagination, filters, sorter) => {
    this.setState({
      sortInfo: {
        ...this.state.sortInfo,
        topTable: sorter
      } 
    });
  }

  renderTopTable = (report_data) => {
    const count = (this.state.filters||{}).users_per_table||10;
    const showZeroes = (this.state.filters||{}).include_zeroes;
    const topUsers = report_data.users.filter(u => showZeroes || u.points > 0).sort((a,b) => b.points - a.points)
    .slice(0, count);

    return <div style={{marginTop:48}}>
        <h2 style={{textAlign:'right'}}>Posiciones: Top puntajes individuales</h2>
        <Table  rowKey={u => u.id} columns={[
          {
            title: 'Nombre',
            dataIndex: 'full_name',
            sorter: (a,b) => a.full_name.localeCompare(b.full_name)
          },
          {
            title: 'Correo',
            dataIndex: 'email',
            sorter: (a,b) => a.email.localeCompare(b.email)
          },
          {
            title: 'Area',
            dataIndex: 'area',
            sorter: (a,b) => a.area.localeCompare(b.area)
          },
          {
            title: 'Cargo',
            dataIndex: 'position',
            sorter: (a,b) => a.position.localeCompare(b.position)
          },
          {
            title: 'Ubicación',
            dataIndex: 'location',
            sorter: (a,b) => a.location.localeCompare(b.location)
          },
          {
            title: 'Puntos',
            dataIndex: 'points',
            sorter: (a,b) => a.points - b.points,
            defaultSortOrder: 'descend',
            align: 'right'
          }
        ]} dataSource={topUsers} size="middle" pagination={false} 
          rowClassName={(record, index) => index%2===0 ? 'row-normal' : 'row-alternate'} 
          onChange={this.handleTopTableChange}/>
      </div>
  }

  handleAreasTableChange = (pagination, filters, sorter) => {
    this.setState({
      sortInfo: {
        ...this.state.sortInfo,
        areasTable: sorter
      } 
    });
  }

  renderAreasTable = (report_data) => {
    const count = (this.state.filters||{}).areas_per_table||10;
    const showZeroes = (this.state.filters||{}).include_zeroes;
    const data_areas = {};

    for(var usr of report_data.users) {
      if(!usr.area || (!showZeroes && usr.points === 0)) continue;
      var normalizedArea = usr.area.trim().toLowerCase();
      var current = data_areas[normalizedArea]||{ points: 0, users: 0 };
      current.points += usr.points;
      current.users++;
      current.name = usr.area.trim();
      data_areas[normalizedArea] = current;
    }

    var report_areas = Object.keys(data_areas).map(k => ({
      ...data_areas[k],
      average: data_areas[k].users > 0 ? Math.round(data_areas[k].points / data_areas[k].users) : 0
    })).filter(u => showZeroes || u.average > 0).sort((a,b) => b.average - a.average).slice(0, count);


    return <div style={{marginTop:48}}>
        <h2 style={{textAlign:'right'}}>Posiciones: Top puntajes promedio por área</h2>
        <Table rowKey={u => u.name} columns={[
          {
            title: 'Area',
            dataIndex: 'name',
            sorter: (a,b) => a.name.localeCompare(b.name)
          },
          {
            title: 'Puntaje promedio',
            dataIndex: 'average',
            align: 'right',
            sorter: (a,b) => a.average - b.average,
            defaultSortOrder: 'descend'
          }
        ]} dataSource={report_areas} size="middle" pagination={false} 
          rowClassName={(record, index) => index%2===0 ? 'row-normal' : 'row-alternate'}
          onChange={this.handleAreasTableChange} />
      </div>
  }

  handleAreasTopUsersTableChange = (pagination, filters, sorter) => {
    this.setState({
      sortInfo: {
        ...this.state.sortInfo,
        areasTopUsersTable: sorter
      } 
    });
  }

  renderAreasTopUsersTable = (report_data) => {
    const showZeroes = (this.state.filters||{}).include_zeroes;
    const data_areas = {};

    for(var usr of report_data.users) {
      if(!usr.area) continue;
      var normalizedArea = usr.area.trim().toLowerCase();
      var current = data_areas[normalizedArea];
      if(!current || usr.points > current.points) {
        current = usr;
      }
      data_areas[normalizedArea] = current;
    }

    var report_areas = Object.keys(data_areas).map(k => data_areas[k])
      .filter(u => showZeroes || u.points > 0)
      .sort((a,b) => a.area.localeCompare(b.area, 'es', {sensitivity: 'base'}));


    return <div style={{marginTop:48}}>
        <h2 style={{textAlign:'right'}}>Posiciones: Top puntajes individuales por área</h2>
        <Table rowKey={u => u.id} columns={[
          {
            title: 'Nombre',
            dataIndex: 'full_name',
            sorter: (a,b) => a.full_name.localeCompare(b.full_name)
          },
          {
            title: 'Correo',
            dataIndex: 'email',
            sorter: (a,b) => a.email.localeCompare(b.email)
          },
          {
            title: 'Area',
            dataIndex: 'area',
            sorter: (a,b) => a.area.localeCompare(b.area),
            defaultSortOrder: 'ascend'
          },
          {
            title: 'Cargo',
            dataIndex: 'position',
            sorter: (a,b) => a.position.localeCompare(b.position)
          },
          {
            title: 'Ubicación',
            dataIndex: 'location',
            sorter: (a,b) => a.location.localeCompare(b.location)
          },
          {
            title: 'Puntos',
            dataIndex: 'points',
            align: 'right',
            sorter: (a,b) => a.points - b.points
          }
        ]} dataSource={report_areas} size="middle" pagination={false} 
          rowClassName={(record, index) => index%2===0 ? 'row-normal' : 'row-alternate'}
          onChange={this.handleAreasTopUsersTableChange} />
      </div>
  }

  renderDailyUsers = (report_data) => {
    
    const data = Object.keys(report_data.dates).map(k => ({ ...report_data.dates[k], date: k}));
    const cols = {
      date: { alias: 'Fecha' },
      activeUsers: { alias: 'Usuarios activos', min: 0 }
    };

    return <div style={{marginTop:48}}>
        <h2 style={{textAlign:'right'}}>Comportamiento diario del curso (por usuarios activos)</h2>
        <Chart forceFit height={500} data={data} scale={cols}>
          <Axis name="date" />
          <Axis name="activeUsers" />
          <Tooltip />
          <Geom type="area" shape="smooth" position="date*activeUsers" color="#00BCD4" />
        </Chart>
      </div>
  }

  renderDailyPoints = (report_data) => {
    
    const data = Object.keys(report_data.dates).map(k => ({ ...report_data.dates[k], date: k}));
    const cols = {
      date: { alias: 'Fecha' },
      points: { alias: 'Puntos', min: 0 }
    };

    return <div style={{marginTop:48}}>
        <h2 style={{textAlign:'right'}}>Comportamiento diario del curso (por puntos)</h2>
        <Chart forceFit height={500} data={data} scale={cols}>
          <Axis name="date" />
          <Axis name="points"  />
          <Tooltip />
          <Geom type="area" shape="smooth" position="date*points" color="#00BCD4" />
        </Chart>
      </div>
  }

  renderReport = () => {
    const { report_data } = this.props;
    if(!report_data) return <div/>;
    return <div>
      {this.renderGeneralIndicators(report_data)}
      {this.renderTopTable(report_data)}
      {this.renderAreasTable(report_data)}
      {this.renderAreasTopUsersTable(report_data)}
      {this.renderDailyUsers(report_data)}
      {this.renderDailyPoints(report_data)}
    </div>;
  }

  queryReport = (values) => {
    const { course, company } = values;
    this.setState({ filters: values });

    const queryFilters = {};
    if(values.areas) queryFilters.area = values.areas;
    if(values.location) queryFilters.location = values.location;
    if(values.dates && values.dates.length > 0) {
      queryFilters.from = values.dates[0].valueOf();
      queryFilters.to = values.dates[1].valueOf();
    }

    this.props.getreport(company, course, queryFilters);
  }

  renderEmpty = () => {
    return <div style={{textAlign:'center', margin:20}}>
        <Icon type="reconciliation" theme="twoTone" style={{ fontSize: '240px', opacity: 0.3 }} />
        <p style={{ fontSize: '20px', color:'#ccc' }}>Para generar el reporte seleccione los filtros y presione el botón</p>
      </div>;
  }

  render() {
    return <Row gutter={16}>
      <Col span={18}>
        <div className="content-internal">
          <Spin spinning={this.props.fetching}>
              {this.state.reportGenerated && !this.props.fetching ? this.renderReport() : this.renderEmpty()}
          </Spin>
        </div>
      </Col>
      <Col span={6}>
        <FiltersSidebar buttonText="Generar reporte" fetching={this.props.fetching} onFilter={this.queryReport}/>
      </Col>
    </Row>;
  }
}

function mapStateToProps(state) {
  return state.admin;
}

function mapDispatchToProps(dispatch) {
  return {
    getreport: (tenantId, courseId, filters) => dispatch(actions.getreport(tenantId, courseId, filters))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
