import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, Card, Icon, Dropdown, Menu, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import NewCourseModal from '../admin/course-selection/new-course.modal';
import CoursesService from '../core/services/courses';
import { actions } from './courses.redux';
import './courses.page.css';
import AddSkillModal from './add-skill.modal';

const { Meta } = Card;

const trimDescription = (description) => {
  const maxLength = 60;
  return description && description.length > maxLength ? description.substring(0, maxLength - 3) + '...' : description;
}

class CoursesPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    loading: false,
    modalVisible: false,
    skillsModalVisible: false,
    assigning: false,
    selectedCourse: null
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  componentDidMount() {
    this.loadCourses(1);
  }

  loadCourses = (page) => {
    this.setState({ loading: true });
    this.props.fetch(page);
  }

  refreshTable = () => {
    const form = this.formRef.props.form;
    form.resetFields();
    this.hideModal();
    this.props.fetch(this.state.pagination.current ?? 1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetching && !this.props.fetching) {
      this.setState({ loading: false });
    }
    if (this.props.metadata !== prevProps.metadata) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          total: this.props.metadata.total,
          pageSize: this.props.metadata.limit
        }
      });
    }
  }

  handleCourseAction = (key, record) => {
    if (key === 'edit') {
      this.props.history.push(`/courses/${record.course_id}/design`);
    }
    else if (key === 'skills') {
      this.openSkillsModal(record.id);
    }
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  openCreate = () => {
    this.setState({ modalVisible: true });
  }

  hideModal = () => {
    this.setState({ modalVisible: false });
  }

  openSkillsModal = (courseId) => {
    this.setState({ selectedCourse: courseId, skillsModalVisible: true });
  }

  hideSkillsModal = () => {
    this.setState({ skillsModalVisible: false, selectedCourse: null });
  }

  handleAssignSkills = async (skills) => {
    await CoursesService.assignSkills(this.state.selectedCourse, skills);
    this.hideSkillsModal();
  }

  handleCreate = (files) => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }

      try {
        this.setState({ saving: true });
        delete values.gallery_image;
        delete values.detail_image;
        await CoursesService.createCourse(values, files);
        this.hideModal();
        this.refreshTable();

      }
      catch (ex) {
        console.error(ex);
        message.error('Ocurrió un error al guardar el curso');
      }
      this.setState({ saving: false });
    });
  }

  render() {
    const { loading, pagination } = this.state;

    return <div className="content-internal">
      <div className="top-buttons">
        {this.canEdit() && <Button type="primary" icon="plus" onClick={this.openCreate}>Nuevo curso</Button>}

      </div>
      <List
        grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 4 }}
        pagination={{ ...pagination, onChange: (page) => this.loadCourses(page) }}
        loading={loading}
        dataSource={this.props.courses}
        renderItem={item => (
          <List.Item>
            <Link to={`/courses/${item.id}`}>
              <Card
                hoverable
                style={{ height: 350 }}
                cover={item.image ? <img alt={item.name} className="course-image" src={item.image} /> : <div className="course-placeholder"><Icon type="picture" /></div>}
              >
                {this.canEdit() && <div className="card-actions" onClick={(e) => e.stopPropagation()}>
                  <Dropdown overlay={(
                    <Menu onClick={({ it, key }) => this.handleCourseAction(key, item)}>
                      <Menu.Item key="edit"><Icon type="edit" /> Editar curso</Menu.Item>
                      <Menu.Item key="skills"><Icon type="safety" /> Asignar competencias</Menu.Item>
                    </Menu>
                  )} trigger={['click']}>
                    <Button type="link" className="tbl-action" onClick={(e) => e.stopPropagation()}>
                      <Icon type="ellipsis" />
                    </Button>
                  </Dropdown>
                </div>}
                <Meta
                  title={item.name}
                  description={trimDescription(item.description)}
                />
              </Card>
            </Link>
          </List.Item>

        )}
      />
      <AddSkillModal visible={this.state.skillsModalVisible}
        onCancel={this.hideSkillsModal}
        onOk={this.handleAssignSkills}
        courseId={this.state.selectedCourse}
        confirmLoading={this.state.assigning}
      />

      <NewCourseModal wrappedComponentRef={this.saveFormRef}
        visible={this.state.modalVisible}
        confirmLoading={this.state.saving}
        isSA={false}
        onCancel={this.hideModal}
        onCreate={this.handleCreate} />
    </div>
  }
}

function mapStateToProps(state) {
  return { ...state.courses, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (page) => dispatch(actions.fetch(page))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesPage);
