import decode from 'jwt-decode';
import BaseService from './baseService';

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    } else return false;
  } catch (err) {
    return true;
  }
};

export default class AuthService extends BaseService {
  static async authenticate(username, password) {
    const result = await super.request(`/auth/login`, 'POST', {
      username,
      password,
    });
    localStorage.setItem(
      'user',
      JSON.stringify({ ...result.user, token: result.token })
    );
    return result;
  }

  static checkToken() {
    var { token, ...user } = JSON.parse(localStorage.getItem('user')) || {};
    return { isLoggedIn: !!token && !isTokenExpired(token), user };
  }

  static logout() {
    localStorage.removeItem('user');
  }
}
