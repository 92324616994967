import React, { PureComponent } from 'react';

class MainPage extends PureComponent {

  render() {
    return (
      <div>404 Not found</div>
    );
  }
}

export default MainPage;
