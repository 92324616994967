import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Icon,
  Menu,
  Dropdown,
  Modal,
  Badge,
  Input,
  Avatar,
} from 'antd';
import { Link } from 'react-router-dom';

import { actions } from './users.redux';
import NewUserModal from './new-user.modal';
import EditUserModal from './edit-user.modal';
import ChangePasswordModal from './change-password.modal';
import ImportUsersModal from './import-users.modal';
import BulkEnableDisableModal from './bulk-enable-disable.modal';
import usersService from '../core/services/users';
import profile_pic from '../../assets/profile_pic.png';

import './users.page.css';

const confirm = Modal.confirm;

class UsersPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    pagination: {},
    modalTitle: 'Crear usuario',
    modalVisible: false,
    editModalVisible: false,
    changePassModalVisible: false,
    importModalVisible: false,
    bulkDisableModalVisible: false,
    editUser: null,
    query: null,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Nombres',
        width: 300,
        dataIndex: 'full_name',
        render: (val, record) => {
          return (
            <Link to={'/users/' + record.id}>
              <div className='nameContainer'>
                <Badge
                  dot
                  offset={[-4, 4]}
                  title={record.disabled ? 'Deshabilitado' : 'Habilitado'}
                  status={record.disabled ? 'default' : 'success'}
                >
                  <Avatar
                    style={{ border: '1px solid #ddd' }}
                    src={record.avatar ? record.avatar : profile_pic}
                  />
                </Badge>
                <div className='nameAndEmail'>
                  <div title={val}>{val}</div>
                  <div title={record.email}>{record.email}</div>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        title: 'Identificación',
        dataIndex: 'identification_number',
        ellipsis: true,
        width: 100,
      },
      {
        title: 'Area',
        dataIndex: 'area_name',
        ellipsis: true,
      },
      {
        title: 'Cargo',
        dataIndex: 'position_name',
        ellipsis: true,
      },

      {
        title: 'Ubicación',
        dataIndex: 'location',
        ellipsis: true,
      },
    ];

    if (this.canEdit()) {
      this.columns.push({
        title: '',
        width: 40,
        render: (val, record) => (
          <Dropdown
            overlay={
              <Menu
                onClick={({ item, key }) => this.handleTableAction(key, record)}
              >
                <Menu.Item key='edit'>Editar</Menu.Item>
                <Menu.Item key={val.disabled ? 'enable' : 'disable'}>
                  {val.disabled ? 'Activar usuario' : 'Inactivar usuario'}
                </Menu.Item>
                <Menu.Item key='change_password'>
                  Restablecer contraseña
                </Menu.Item>
                {/* <Menu.Divider />
            <Menu.Item key="3">3rd menu item</Menu.Item> */}
              </Menu>
            }
            trigger={['click']}
          >
            <Button type='link' className='tbl-action'>
              <Icon type='ellipsis' />
            </Button>
          </Dropdown>
        ),
      });
    }
  }

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  componentDidMount() {
    this.props.fetch(null, 1);
    this.props.getAreas();
    this.props.getPositions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.metadata && nextProps.metadata !== this.props.metadata) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          total: nextProps.metadata.total,
          pageSize: nextProps.metadata.limit,
        },
      });
      this.props.layout.changeTitle(`Usuarios (${nextProps.metadata.total})`);
    }

    if (this.props.saving && !nextProps.saving) {
      if (nextProps.error) {
        alert(nextProps.error);
      } else {
        const form = this.formRef.props.form;
        form.resetFields();
        this.hideModal();

        const form2 = this.formRefEdit.props.form;
        form2.resetFields();
        this.hideEditModal();

        const form3 = this.formRefPass.props.form;
        form3.resetFields();
        this.hideChangePassModal();

        this.hideImportModal();
        this.hideBulkDisableModal();
        this.props.fetch(this.state.query, this.state.pagination.current || 1);
      }
    }
  }

  handleTableAction = (action, user) => {
    switch (action) {
      case 'enable':
      case 'disable':
        confirm({
          title: `¿Estás seguro que quieres ${
            action === 'enable' ? 'activar' : 'inactivar'
          } el usuario ${user.email}?`,
          content:
            action === 'disable'
              ? 'Si deshabilitas el usuario no podrá ingresar más a Intrena'
              : '',
          okText: 'Aceptar',
          cancelText: 'Cancelar',
          onOk: () => {
            this.props.enabledisable([user.id], action);
          },
        });
        break;
      case 'change_password':
        this.openChangePass(user);
        break;
      case 'edit':
        this.openEdit(user);
        break;
      case 'details':
        this.openDetails(user);
        break;
      default:
        console.warn('unknown action');
    }
  };

  downloadCSV = (data, fileName) => {
    var csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    //IE11 & Edge
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(csvData, fileName);
    } else {
      //In FF link must be added to DOM to be clicked
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  handleAction = async (action) => {
    const { selectedRowKeys } = this.state;

    switch (action) {
      case 'export':
        var data = await usersService.export(selectedRowKeys);
        this.downloadCSV(data, 'usuarios.csv');
        break;
      case 'import':
        this.openImport();
        break;
      case 'bulk_disable':
        this.openBulkDisable();
        break;
      default:
        console.warn('unknown action');
    }
  };

  handleMultiEnableDisable = (action) => {
    const { selectedRowKeys } = this.state;

    confirm({
      title: `¿Estás seguro que quieres ${
        action === 'enable' ? 'activar' : 'inactivar'
      } los ${selectedRowKeys.length} usuarios seleccionados?`,
      content:
        action === 'disable'
          ? 'Si deshabilitas los usuarios no podrán ingresar más a Intrena'
          : '',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      onOk: () => {
        this.props.enabledisable(selectedRowKeys, action);
      },
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  handleTableChange = async (pagination, filters, sorter) => {
    await this.setState({ pagination });
    this.props.fetch(this.state.query, pagination.current);
  };

  getCheckboxProps = (record) => {
    return {
      disabled: false,
      name: record.first_name,
    };
  };

  doSearch = (value) => {
    this.setState(
      { query: value, pagination: { ...this.state.pagination, current: 1 } },
      () => this.props.fetch(this.state.query, 1)
    );
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps,
      columnWidth: 32,
    };

    const hasSelected = this.state.selectedRowKeys.length > 0;
    var isDisabled = false;
    if (hasSelected)
      isDisabled = (
        this.props.users.find((u) => u.id === this.state.selectedRowKeys[0]) ||
        {}
      ).disabled;

    return (
      <div className='content-internal'>
        <div className='first-row'>
          <Input.Search
            placeholder='Buscar por nombres, identificación o correo (Ej. Juan Pérez) ...'
            onSearch={this.doSearch}
          />
          <div style={{ flexShrink: 0 }}>
            <Button.Group>
              {hasSelected && (
                <Button
                  icon='user-delete'
                  onClick={() =>
                    this.handleMultiEnableDisable(
                      isDisabled ? 'enable' : 'disable'
                    )
                  }
                >
                  {isDisabled ? 'Activar' : 'Inactivar'} Seleccionados
                </Button>
              )}
              {this.canEdit() && (
                <Button type='primary' icon='plus' onClick={this.openCreate}>
                  Nuevo usuario
                </Button>
              )}
              <Dropdown
                overlay={
                  <Menu onClick={({ item, key }) => this.handleAction(key)}>
                    <Menu.Item key='export'>
                      <Icon type='download' />
                      {hasSelected
                        ? 'Exportar usuarios seleccionados'
                        : 'Exportar todos los usuarios'}
                    </Menu.Item>
                    {this.canEdit() && (
                      <Menu.Item key='import'>
                        <Icon type='upload' /> Importar usuarios
                      </Menu.Item>
                    )}
                    {/* <Menu.Item key="bulk_disable"><Icon type="stop" /> Deshabilitar usuarios masivamente</Menu.Item> */}
                  </Menu>
                }
              >
                <Button type='primary' icon='down'></Button>
              </Dropdown>
            </Button.Group>
          </div>
        </div>

        <Table
          loading={this.props.fetching}
          size='medium'
          className='striped-rows'
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
          rowKey={(u) => u.id}
          rowSelection={this.canEdit() ? rowSelection : undefined}
          columns={this.columns}
          dataSource={this.props.users}
          locale={{ emptyText: 'No hay usuarios en el momento' }}
        />

        <NewUserModal
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.modalVisible}
          areas={this.props.areas}
          areas_fetching={this.props.fetching_areas}
          areas_error={this.props.error_areas}
          positions={this.props.positions}
          positions_fetching={this.props.fetching_positions}
          positions_error={this.props.error_positions}
          confirmLoading={this.props.saving}
          onCancel={this.hideModal}
          customFields={this.props.custom_fields}
          onCreate={this.handleCreate}
        />

        <EditUserModal
          wrappedComponentRef={this.saveFormRefEdit}
          user={this.state.editUser}
          visible={this.state.editModalVisible}
          areas={this.props.areas}
          areas_fetching={this.props.fetching_areas}
          areas_error={this.props.error_areas}
          positions={this.props.positions}
          positions_fetching={this.props.fetching_positions}
          positions_error={this.props.error_positions}
          confirmLoading={this.props.saving}
          customFields={this.props.custom_fields}
          onCancel={this.hideEditModal}
          onCreate={this.handleEdit}
        />

        <ChangePasswordModal
          wrappedComponentRef={this.saveFormRefPass}
          user={this.state.editUser}
          visible={this.state.changePassModalVisible}
          confirmLoading={this.props.saving}
          onCancel={this.hideChangePassModal}
          onCreate={this.handleChangePass}
        />

        <ImportUsersModal
          visible={this.state.importModalVisible}
          confirmLoading={this.props.saving}
          onCancel={this.hideImportModal}
          onOk={this.handleImport}
        />

        <BulkEnableDisableModal
          visible={this.state.bulkDisableModalVisible}
          disable={true}
          confirmLoading={this.props.saving}
          onCancel={this.hideBulkDisableModal}
          onOk={this.handleBulkDisable}
        />
      </div>
    );
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  saveFormRefEdit = (formRefEdit) => {
    this.formRefEdit = formRefEdit;
  };

  saveFormRefPass = (formRefPass) => {
    this.formRefPass = formRefPass;
  };

  openCreate = () => {
    this.setState({ modalVisible: true });
  };

  openEdit = (user) => {
    this.setState({ editUser: user, editModalVisible: true });
  };

  openDetails = (user) => {
    this.props.history.push(`/users/${user.id}`);
  };

  openChangePass = (user) => {
    this.setState({ editUser: user, changePassModalVisible: true });
  };

  openImport = () => {
    this.setState({ importModalVisible: true });
  };

  handleImport = (data) => {
    this.props.import(data);
  };

  openBulkDisable = () => {
    this.setState({ bulkDisableModalVisible: true });
  };

  handleBulkDisable = (data) => {
    confirm({
      title: `¿Estás seguro que deseas deshabilitar ${
        data.match(/\r?\n/g).length
      } usuarios?`,
      content:
        'Los usuarios no podrán ingresar a la aplicación hasta que los vuelvas a habilitar',
      onOk: () => {
        this.props.bulkdisableenable(data, 'disable');
      },
      onCancel: () => {},
    });
  };

  handleEdit = () => {
    const form = this.formRefEdit.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      let mobile =
        values.mobile && values.mobile.phone
          ? `+${values.mobile.code} ${values.mobile.phone
              .trim()
              .replace(/\s/g, '')}`
          : null;

      this.props.edit(
        this.state.editUser.id,
        values.email,
        values.first_name,
        values.last_name,
        mobile,
        null,
        values.area_id,
        values.position_id,
        values.role,
        values.identification_number,
        values.location,
        values.manager ? parseInt(values.manager.key, 10) : null,
        values.extended_field1,
        values.extended_field2,
        values.extended_field3,
        values.extended_field4,
        values.extended_field5,
        values.extended_field6,
        values.extended_field7,
        (values.organizational_units || []).map((u) => u.key)
      );
    });
  };

  handleChangePass = () => {
    const form = this.formRefPass.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.changepassword(
        this.state.editUser.user_id,
        values.set_pass ? values.password : null
      );
    });
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      let mobile =
        values.mobile && values.mobile.phone
          ? `+${values.mobile.code} ${values.mobile.phone
              .trim()
              .replace(/\s/g, '')}`
          : null;

      this.props.create(
        values.email,
        values.email,
        values.first_name,
        values.last_name,
        mobile,
        null,
        values.area_id,
        values.position_id,
        values.role,
        values.set_pass ? values.password : null,
        values.identification_number,
        values.location,
        values.manager,
        values.extended_field1,
        values.extended_field2,
        values.extended_field3,
        values.extended_field4,
        values.extended_field5,
        values.extended_field6,
        values.extended_field7,
        (values.organizational_units || []).map((u) => u.key)
      );
    });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  hideEditModal = () => {
    this.setState({ editModalVisible: false });
  };

  hideChangePassModal = () => {
    this.setState({ changePassModalVisible: false });
  };

  hideImportModal = () => {
    this.setState({ importModalVisible: false });
  };

  hideBulkDisableModal = () => {
    this.setState({ bulkDisableModalVisible: false });
  };
}

function mapStateToProps(state) {
  return { ...state.users, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (query, page) => dispatch(actions.fetch(query, page)),
    create: (
      username,
      email,
      firstName,
      lastName,
      mobile,
      avatar,
      area,
      position,
      role,
      pass,
      identification_number,
      location,
      manager,
      extended_field1,
      extended_field2,
      extended_field3,
      extended_field4,
      extended_field5,
      extended_field6,
      extended_field7,
      organizational_units
    ) =>
      dispatch(
        actions.create(
          username,
          email,
          firstName,
          lastName,
          mobile,
          avatar,
          area,
          position,
          role,
          pass,
          identification_number,
          location,
          manager,
          extended_field1,
          extended_field2,
          extended_field3,
          extended_field4,
          extended_field5,
          extended_field6,
          extended_field7,
          organizational_units
        )
      ),
    edit: (
      id,
      email,
      firstName,
      lastName,
      mobile,
      avatar,
      area,
      position,
      role,
      identification_number,
      location,
      manager,
      extended_field1,
      extended_field2,
      extended_field3,
      extended_field4,
      extended_field5,
      extended_field6,
      extended_field7,
      organizational_units
    ) =>
      dispatch(
        actions.edit(
          id,
          email,
          firstName,
          lastName,
          mobile,
          avatar,
          area,
          position,
          role,
          identification_number,
          location,
          manager,
          extended_field1,
          extended_field2,
          extended_field3,
          extended_field4,
          extended_field5,
          extended_field6,
          extended_field7,
          organizational_units
        )
      ),
    changepassword: (id, password) =>
      dispatch(actions.changepassword(id, password)),
    enabledisable: (id, action) => dispatch(actions.enabledisable(id, action)),
    import: (data) => dispatch(actions.import(data)),
    bulkdisableenable: (data, action) =>
      dispatch(actions.bulkdisableenable(data, action)),
    getAreas: () => dispatch(actions.getareas()),
    getPositions: () => dispatch(actions.getpositions()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
