import BaseService from './baseService';

export default class CommunicationsService extends BaseService {
  static async list(limit, skip) {
    return await super.request(`/communications?limit=${limit}&skip=${skip}`);
  }

  static async getCommunication(commId) {
    return await super.request(`/communications/${commId}`);
  }

  static async addCommunication(
    name,
    areas,
    positions,
    locations,
    extended_field1_values
  ) {
    return await super.request(`/communications`, 'POST', {
      name,
      areas,
      positions,
      locations,
      extended_field1_values,
    });
  }

  static async updateCommunication(
    id,
    name,
    areas,
    positions,
    locations,
    extended_field1_values
  ) {
    return await super.request(`/communications/${id}`, 'PUT', {
      name,
      areas,
      positions,
      locations,
      extended_field1_values,
    });
  }

  static async removeCommunication(id) {
    return await super.request(`/communications/${id}`, 'DELETE');
  }

  static async listMessages(commId, limit, skip) {
    return await super.request(
      `/communications/${commId}/messages?limit=${limit}&skip=${skip}`
    );
  }

  static async listDeliveries(commId, messageId) {
    return await super.request(
      `/communications/${commId}/messages/${messageId}/deliveries`
    );
  }

  static async removeMessage(id) {
    return await super.request(`/communications/messages/${id}`, 'DELETE');
  }

  static async addMessage(commId, body, programmed_date, attachment) {
    const formData = new FormData();
    formData.append('attachment', attachment);

    formData.append('body', body);
    if (programmed_date) formData.append('programmed_date', programmed_date);

    return await super.request(
      `/communications/${commId}/messages`,
      'POST',
      formData,
      { 'Content-Type': 'multipart/form-data' },
      false
    );
  }

  static async updateMessage(id, body, programmed_date, attachment) {
    const formData = new FormData();
    formData.append('attachment', attachment);

    formData.append('body', body);
    if (programmed_date) formData.append('programmed_date', programmed_date);

    return await super.request(
      `/communications/messages/${id}`,
      'PUT',
      formData,
      { 'Content-Type': 'multipart/form-data' },
      false
    );
  }
}
