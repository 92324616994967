import React, { Component } from 'react';
import { Tag } from 'antd';
import commonStyles from './common.module.css';
import styles from './order-phrase.module.css';
import { stylizeText, colorIntToHex, getAssetUri } from '../../utils';
import { DEFAULTS } from '../../theme-editor.modal';

class OrderPhraseTemplate extends Component {

  render() {
    const { element, section, theme, slug } = this.props;
    const { title, options } = element;

    const opts = Object.keys(options||{}).map(k => options[k]).sort((a,b) => a.order - b.order);

    const textStyles = {
      color: theme.text_color || undefined
    };

    const bgImage = theme.background_image ? theme.background_image.startsWith('http') 
      ? theme.background_image : getAssetUri('images', slug, theme.background_image, false) : undefined;
    const containerStyles = {
      backgroundImage: bgImage ? `url("${bgImage}")` : undefined,
      backgroundColor: theme.background_color || undefined
    };

    const controlStyles = {
      backgroundColor: theme.controls_color || colorIntToHex(section.color) || DEFAULTS.controls_color,
      color: theme.controls_text_color || undefined,
    }

    return <div className={`${commonStyles.templateContainer}`} style={containerStyles}>
      <div className={commonStyles.templateTitle} style={textStyles}>{stylizeText(title, controlStyles.backgroundColor)}</div>
      <div className={commonStyles.templateBody} style={{ justifyContent: 'start', alignSelf: 'stretch', padding: '2vw 0.5vw 0.5vw 0.5vw' }}>
        { opts.map(o => <Tag className={styles.option} style={controlStyles} color={controlStyles.backgroundColor}>{o.text}</Tag>)}
      </div>      
    </div>;
  }
 
  static renderMiniature ({ element, index, parentIndex, ...props}) {
    return <div className={commonStyles.miniature} style={{backgroundColor: '#efefef'}}>
      <div className={commonStyles.minTitle}>{`${index+1}. Ordenar frase`}</div>
      <div className={commonStyles.minTitle}>{stylizeText(element.title)}</div>
    </div>
  }

  static getProps = ({ element }) => {
    const extProps = element.extended_props||{};
    return [
      { name: 'title', display: 'Título', value: element.title, type: 'multiline' },
      { name: 'extended_props.description', display: 'Descripción', value: extProps.description, type: 'multiline' },
      { name: 'extended_props.hide_result', display: 'Ocultar resultado', value: extProps.hide_result, type: 'boolean' },
      { name: 'extended_props.only_feedback', display: 'Sólo feedback', value: extProps.only_feedback, type: 'boolean' },
      { name: 'category', display: 'Categoría', value: element.category, type: 'select', values: [ { title: 'Conciencia', value: 'conciencia' }, { title: 'Concepto', value: 'concepto' }, { title: 'Contexto', value: 'contexto' } ] },
      { name: 'estimatedTime', display: 'Tiempo estimado (seg)', value: element.estimatedTime, type: 'number' },
      { name: 'options', display: 'Opciones', value: element.options, type: 'options' }
    ];
  }
}

export default OrderPhraseTemplate;
