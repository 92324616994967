import React, { PureComponent } from 'react';
import { Modal, Form, Input, Select, Tooltip, Icon } from 'antd';
import usersService from '../core/services/users';
import CountryPhoneInput from 'antd-country-phone-input';
import OrganizationalUnitsService from '../core/services/organizationalUnits';
import DebounceSelect from '../main/components/debounceSelect';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

async function fetchOrganizationalUnits(query) {
  const result = await OrganizationalUnitsService.list(query, 20, 0);
  return result.results.map((r) => ({
    label: r.name,
    value: r.id,
    key: r.id,
  }));
}

async function fetchForUser(userId) {
  return await OrganizationalUnitsService.listForUser(userId);
}

const getCountryPhoneValueFromString = (val) => {
  if (val) {
    const parts = val.split(' ');
    return {
      code: parts.length >= 2 ? Number(parts[0]) : undefined,
      phone: parts.slice(1).join(''),
    };
  }

  return { short: 'CO' };
};
class EditUserModal extends PureComponent {
  state = {
    users: [],
    ous: [],
  };

  validateUnique = async (rule, value, callback) => {
    if (!value) callback();
    const { user } = this.props;
    if (user.username.toLowerCase() === value.toLowerCase()) callback();
    if (
      !value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      callback(); //Si no es un email válido no verificar si existe
    } else {
      var result = await usersService.emailExists(value);
      if (!result) callback();
      else callback('El correo electrónico ya existe');
    }
  };

  validateMobile = async (rule, value, callback) => {
    if (!value || !value.phone) callback();
    if (!value.phone.match(/^[\s0-9]+$/)) {
      callback('Ingresa un número de celular válido (solo números)');
    } else {
      const mobileValue = `+${value.code} ${(value.phone || '')
        .trim()
        .replace(/\s/g, '')}`;
      const { user } = this.props;
      if (user.mobile === mobileValue) callback();
      var result = await usersService.mobileExists(
        encodeURIComponent(mobileValue)
      );
      if (!result) callback();
      else callback('Este número de celular ya está asociado a otro usuario');
    }
  };

  setInitialOUs = async (userId) => {
    if (userId) {
      const ous = await fetchForUser(userId);
      this.setState({ ous });
    }
    else {
      this.setState({ ous: [] });
    }
  }

  async componentDidMount() {
    await this.setInitialOUs(this.props.user?.id);
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      await this.setInitialOUs(this.props.user?.id);
    }
  }

  handleSearch = async (value) => {
    try {
      var response = await usersService.search(value);
      this.setState({ users: (response || {}).results || [] });
    } catch (ex) {
      console.error(ex);
    }
  };

  render() {
    const {
      visible,
      onCancel,
      onCreate,
      form,
      confirmLoading,
      user,
      customFields,
      areas,
      areas_fetching,
      areas_error,
      positions,
      positions_fetching,
      positions_error,
    } = this.props;
    const { getFieldDecorator } = form;

    if (!user) return <div />;
    return (
      <Modal
        title='Editar usuario'
        visible={visible}
        onOk={onCreate}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Form>
          <FormItem {...formItemLayout} label='Correo electrónico'>
            {getFieldDecorator('email', {
              initialValue: user.username,
              rules: [
                {
                  type: 'email',
                  message: 'Debes especificar un correo válido',
                },
                {
                  required: true,
                  message: 'Debes especificar el correo electrónico',
                },
                {
                  validator: this.validateUnique,
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Nombres'>
            {getFieldDecorator('first_name', {
              initialValue: user.first_name,
              rules: [
                {
                  required: true,
                  message: 'Debes especificar el nombre',
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Apellidos'>
            {getFieldDecorator('last_name', {
              initialValue: user.last_name,
              rules: [],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Celular'>
            {getFieldDecorator('mobile', {
              initialValue: getCountryPhoneValueFromString(user.mobile),
              rules: [
                {
                  validator: this.validateMobile,
                },
              ],
            })(<CountryPhoneInput inline type='tel' />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Área'>
            {getFieldDecorator('area_id', {
              initialValue: user.area_id,
              rules: [],
            })(
              <Select
                showSearch
                placeholder='Seleccione el área'
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                optionFilterProp='title'
                notFoundContent='No hay resultados'
                onSearch={this.handleSearch}
              >
                {areas_error ? (
                  <Select.Option value=''>
                    Error al cargar las areas
                  </Select.Option>
                ) : areas_fetching ? (
                  <Select.Option value=''>Cargando ...</Select.Option>
                ) : (
                  (areas || []).map((a) => (
                    <Select.Option key={a.id} value={a.id} title={a.name}>
                      {a.name}
                    </Select.Option>
                  ))
                )}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Cargo'>
            {getFieldDecorator('position_id', {
              initialValue: user.position_id,
              rules: [],
            })(
              <Select
                notFoundContent='No hay resultados'
                disabled={positions_error || positions_fetching}
              >
                {positions_error ? (
                  <Select.Option value=''>
                    Error al cargar los cargos
                  </Select.Option>
                ) : positions_fetching ? (
                  <Select.Option value=''>Cargando ...</Select.Option>
                ) : (
                  (positions || []).map((a) => (
                    <Select.Option key={a.id} value={a.id}>
                      {a.name}
                    </Select.Option>
                  ))
                )}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Rol'>
            {getFieldDecorator('role', {
              initialValue: user.role,
              rules: [],
            })(
              <Select>
                <Select.Option value='user'>Usuario</Select.Option>
                <Select.Option value='reader'>Solo lectura</Select.Option>
                <Select.Option value='admin'>Administrador</Select.Option>
              </Select>
            )}
          </FormItem>
          {['reader', 'admin'].indexOf(form.getFieldValue('role')) >= 0 && (
            <FormItem
              {...formItemLayout}
              label={
                <span>
                  Unidades de negocio&nbsp;
                  <Tooltip title='Unidades de negocio a las que tendrá acceso el usuario administrador (solo podrá ver y editar usuarios y cursos de estas unidades de negocio)'>
                    <Icon type='question-circle-o' />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('organizational_units', {
                initialValue: (this.state.ous ?? []).map((a) => ({
                  ...a,
                  key: a.value,
                })),
                rules: [],
              })(
                <DebounceSelect
                  mode='multiple'
                  placeholder='Todas'
                  style={{ width: '100%' }}
                  fetchOptions={fetchOrganizationalUnits}
                />
              )}
            </FormItem>
          )}
          <FormItem {...formItemLayout} label='Número de Identificación'>
            {getFieldDecorator('identification_number', {
              initialValue: user.identification_number,
              rules: [],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Ubicación'>
            {getFieldDecorator('location', {
              initialValue: user.location,
              rules: [],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Jefe'>
            {getFieldDecorator('manager', {
              initialValue: user.manager_id
                ? {
                  key: user.manager_id,
                  label: `${user.manager_first_name || ''} ${user.manager_last_name || ''
                    }`,
                }
                : undefined,
              rules: [],
            })(
              <Select
                showSearch
                placeholder='Seleccione el jefe'
                defaultActiveFirstOption={false}
                labelInValue={true}
                showArrow={false}
                filterOption={false}
                notFoundContent='No hay resultados'
                onSearch={this.handleSearch}
              >
                {this.state.users.map((d) => (
                  <Select.Option key={d.id}>{`${d.first_name || ''} ${d.last_name || ''
                    }`}</Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
          {(customFields || []).map((cf) => (
            <FormItem
              key={cf.field_name}
              {...formItemLayout}
              label={cf.field_display_name}
            >
              {getFieldDecorator(cf.field_name, {
                initialValue: user[cf.field_name],
                rules: [],
              })(<Input />)}
            </FormItem>
          ))}
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(EditUserModal);
