import React, { PureComponent } from 'react';
import { Form, Icon, Input, Button, Checkbox, Alert } from 'antd';
import { connect } from 'react-redux';

import { login } from './auth.redux';
import styles from './login-page.module.css';

class LoginPage extends PureComponent {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.doLogin(values.email.trim(), values.password);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loggedIn) {
      this.props.history.replace('/');
    }
  }

  render() {
    const { error, form } = this.props;
    const { getFieldDecorator } = form;

    return (<>
      <div className={styles.container}>
        <h2>Ingresa a tu cuenta</h2>

        {error && <Alert className={styles.alert} message={error.message} type="error" showIcon />}
        {error?.data?.code !== '1002' && <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Ingresa tu correo electrónico' }],
            })(
              <Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Correo electrónico" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Ingresa tu contraseña' }],
            })(
              <Input size="large" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Contraseña" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(
              <Checkbox>Recordarme</Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block loading={this.props.loggingIn}>
              Ingresar
            </Button>
          </Form.Item>
        </Form>}
        <div className={styles.adminOnly}>
          <p>Esta opción es únicamente para los usuarios administrativos. Para realizar los cursos descarga la app:</p>
          <p>Descargala aquí</p>
          <div>
            <a href='https://play.google.com/store/apps/details?id=co.labhouse.intrena'><img alt='Disponible en Google Play' src='https://firebasestorage.googleapis.com/v0/b/project-7764566410387601080.appspot.com/o/images%2Femails%2Fwelcome%2Fbtn-googleplay.png?alt=media&token=a5a37a52-0017-4a37-a966-e09eadfc62b7' /></a>
            <a href='https://itunes.apple.com/app/id1245858529'><img alt='Disponible en App Store' src="https://firebasestorage.googleapis.com/v0/b/project-7764566410387601080.appspot.com/o/images%2Femails%2Fwelcome%2Fbtn-appstore.png?alt=media&token=45aba7c9-dec7-41e5-a106-73713d7fd15b" /></a>
          </div>
        </div>
      </div>

    </>);
  }
}

function mapStateToProps(state) {
  return state.auth;
}

function mapDispatchToProps(dispatch) {
  return {
    doLogin: (email, password) => dispatch(login(email, password))
  }
}

export default Form.create()(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
