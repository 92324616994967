import { Button, Divider, Empty, Icon, message, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Nestable from 'react-nestable';
import FormationPathsService from "../../core/services/formation-paths";
import { AddCourseModal } from "./add-course.modal";

export const CoursesList = ({ pathId, canEdit }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [courses, setCourses] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);

    const setIndexes = (items, index) => {
        if (!items) return items;
        let prefix = index > 0 ? index + '.' : '';

        return items.map((item, idx) => {
            item.index = prefix + (idx + 1);
            item.children = setIndexes(item.children, item.index);
            return item;
        });
    }

    const flattenCourses = (courses, parent) => {
        var result = [];
        var order = 1;
        for (var course of courses) {
            result.push({ id: course.tenant_course_id, order, parent_course_id: parent });
            if (course.children) result = result.concat(flattenCourses(course.children, course.tenant_course_id));
            order++;
        }

        return result;
    }



    const queryCourses = useCallback(async () => {

        const unflattenCourses = (courses, parentId = null) => {
            const parentOrder = parentId ? courses.findIndex(c => c.tenant_course_id === parentId) + 1 : null;
            var levelCourses = courses.filter(c => c.parent_course_id === parentId).sort((a, b) => a.order - b.order).map((c, idx) => ({ ...c, index: parentOrder ? parentOrder + '.' + (idx + 1) : idx + 1 }));

            for (var course of levelCourses) {
                course.children = unflattenCourses(courses, course.tenant_course_id);
            }

            return levelCourses;
        }

        try {
            setFetching(true);
            const data = await FormationPathsService.getCourses(pathId);
            setCourses(unflattenCourses(data.map(d => ({ ...d, id: d.tenant_course_id }))));
        }
        catch (err) {
            console.log(err);
            message.error('Ocurrió un error al consultar los cursos del plan de formación');
        }
        finally {
            setFetching(false);
        }
    }, [pathId]);

    const removeCourse = async (course) => {
        try {
            await FormationPathsService.removeCourse(pathId, course.tenant_course_id);
            await queryCourses();
        }
        catch (err) {
            message.error('Ocurrió un error al eliminar el curso del plan de formación');
        }
    }

    const addCourses = async (newCourses) => {
        try {
            setSaving(true);
            const maxOrder = Math.max(...courses.map(c => c.order), 0);
            await FormationPathsService.addCourses(pathId, newCourses.map((c, idx) => ({ id: c, order: maxOrder + idx + 1 })));
            setModalVisible(false);
            await queryCourses();
        }
        catch (err) {
            message.error('Ocurrió un error al agregar los cursos del plan de formación');
        }
        finally {
            setSaving(false);
        }
    }

    const onOrderChange = async (items) => {
        var newItems = setIndexes(items, 0);
        setCourses(newItems);
        await FormationPathsService.addCourses(pathId, flattenCourses(newItems));
        await queryCourses();
    }

    useEffect(() => {
        queryCourses();
    }, [pathId, queryCourses]);

    const renderItem = ({ item }) => {
        return <div className="drag-item">
            <span className="badge">{item.index}</span>
            {item.name}
            {canEdit && <Icon type="close" className="del-icon" onClick={() => removeCourse(item)} />}
        </div>
    };

    return <div className="path-section">
        <div className="heading-row">
            <h2>Cursos</h2>
            {canEdit && <div className="top-buttons">
                <Button className="" type="ghost" onClick={() => setModalVisible(true)}><Icon type="plus" />Agregar curso</Button>
            </div>}
        </div>
        <Spin spinning={fetching}>
            {courses.length === 0 && <Empty description="Aún no hay cursos asignados" />}
            <Nestable
                items={courses}
                renderItem={renderItem}
                onChange={onOrderChange}
                confirmChange={() => canEdit}
            />
        </Spin>
        <Divider />
        <AddCourseModal visible={modalVisible} onCancel={() => setModalVisible(false)} confirmLoading={saving} onOk={addCourses} initialSelection={flattenCourses(courses).map(c => c.id)} />
    </div>;

}