import BaseService from "./baseService";

export default class FiltersService extends BaseService
{
  static async list(limit, skip) {
    return await super.request(`/filters?limit=${limit}&skip=${skip}`);
  }

  static async get(id) {
    return await super.request(`/filters/${id}`);
  }

  static async listValues(id, limit, skip) {
    return await super.request(`/filters/${id}/values?limit=${limit}&skip=${skip}`);
  }

}