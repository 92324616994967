import React, { PureComponent } from 'react';
import { Modal, Form, Input } from 'antd';
import './new-group.modal.css';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class NewGroupModal extends PureComponent {

  state = {
    filters: 0
  };

  render() {
    const { visible, onCancel, onCreate, form, confirmLoading } = this.props;
      const { getFieldDecorator } = form;

    return <Modal title="Crear nuevo grupo"
      visible={visible}
      onOk={onCreate}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText="Crear"
      cancelText="Cancelar">
      <Form>
        <FormItem
          {...formItemLayout}
          label="Nombre">
          {getFieldDecorator('name', {
            rules: [{
              required: true, message: 'Debes especificar el nombre del grupo',
            }],
          })(
            <Input />
          )}
        </FormItem>
      </Form>
    </Modal>
  } 
}

export default Form.create()(NewGroupModal);
