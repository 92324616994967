import React, { PureComponent } from 'react';
import { Modal, Form, Input } from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class EditCreateCertificationModal extends PureComponent {
  state = {
    filters: 0,
  };

  render() {
    const { visible, onCancel, onSave, form, certification, confirmLoading } =
      this.props;
    const { getFieldDecorator } = form;

    const isEdition = !!certification;
    return (
      <Modal
        title={isEdition ? 'Editar certificación' : 'Crear certificación'}
        visible={visible}
        onOk={onSave}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Form>
          <FormItem {...formItemLayout} label='Nombre'>
            {getFieldDecorator('name', {
              initialValue: certification?.name,
              rules: [
                {
                  required: true,
                  message: 'Debes especificar el nombre de la certificación',
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Duración (en horas)">
            {getFieldDecorator('duration_hours', {
              rules: []
            })(
              <Input type="number" />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(EditCreateCertificationModal);
