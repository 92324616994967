import React, { useEffect, useState } from 'react';
import { Avatar, Icon, message, Select, Spin, Typography, Upload } from 'antd';

const exts = {
  'image/png' : 'png',
  'image/jpeg' : 'jpg',
  'image/svg+xml' : 'svg',
  'audio/mp3' : 'mp3',
  'audio/mp4': 'm4a',
  'audio/aac' : 'aac',
  'audio/3gp' : '3gp',
  'audio/mpeg' : 'mpg',
  'video/mp4' : 'mp4',
  'application/msword' : 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' : 'pptx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'xlsx',
  'text/vcard': 'vcf'
};

const fileMeetsContentTypes = (acceptsString, filePath) => {
  if(!acceptsString) return true;
  const cts = acceptsString.toLowerCase().split(', ');
  const possibleExtensions = [];
  for(let ct of cts) {
    if(ct.endsWith('*')) {
      const prefix = ct.replace('*', '');
      for(let key in exts) {
        if(key.startsWith(prefix)) possibleExtensions.push(exts[key]);
      }
    }
    else {
      possibleExtensions.push(exts[ct]);
    }
  }
  const fileName = getFileDisplayName(filePath);
  const ext = fileName.toLowerCase().split('.').pop();
  return possibleExtensions.indexOf(ext) >= 0;
};

const getFileDisplayName = (url) => {
  let display = url;
  if(display.startsWith('https://firebasestorage') || display.startsWith('https://storage.googleapis.com')) {
    display = decodeURIComponent(new URL(display).pathname.split('/').pop());
    if(display.lastIndexOf('/') >= 0) {
      display = display.substring(display.lastIndexOf('/') + 1);
    }
  }
  return display;
}

const File = ({ courseId, elementId, name, value, accept, subtype, note, service, onChange, onBlur }) => {
  
  const [ loading, setLoading ] = useState(false);
  const [ resources, setResources ] = useState([]);

  useEffect(() => {
    let func = async () => {
      try {
        setLoading(true);
        let { resources } = await service.getCourseResources(courseId, subtype);
        setResources(resources.filter(f => fileMeetsContentTypes(accept, f)));
      }
      catch(err) {
        setResources([]);
        console.log(err);
        message.error('Ocurrió un error al consultar los archivos existentes');
      }
      setLoading(false);
    };
    func();
  }, [ courseId, name, accept, subtype, elementId, service ]);

  const optionChanged = (file) => {
    if(file === '__upload') return;
    else if(file === '') onChange(undefined);
    else onChange(file);
  }

  const uploadFile = async (file) => {
    try {
      setLoading(true);
      let { url } = await service.addContentResource(courseId, file);
      setResources([...resources, url ]);
      onChange(url);
    }
    catch(err) {
      console.log(err);
      message.error('Ocurrió un error al guardar el archivo');
    }
    setLoading(false);
    return false;
  };

  return <Spin spinning={loading} indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
    <Select size="small" optionLabelProp="label" value={value||''} loading={loading} onChange={(val) => optionChanged(val)}>
      <Select.Option value="" label="Ninguno">
        { subtype === 'images' && <Avatar shape="square" icon="close" />} Ninguno
      </Select.Option>
      { resources.map(r => <Select.Option value={r} label={getFileDisplayName(r)}>
        { subtype === 'images' && <Avatar shape="square" src={r} />} {getFileDisplayName(r)} 
      </Select.Option>)}
      <Select.Option value="__upload" label="Cargar archivo...">
        <Upload accept={accept} beforeUpload={uploadFile} fileList={false}>
        { subtype === 'images' ? <Avatar shape="square" icon="upload" /> : <Icon type="upload" />} Cargar archivo...
        </Upload>
      </Select.Option>
    </Select>
    { !!note && <Typography.Text type="secondary">{note}</Typography.Text>}
  </Spin>;
};

export default File;