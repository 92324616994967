import React, { PureComponent } from 'react';
import { Modal, Table, Tabs, notification } from 'antd';
import CommunicationsService from '../core/services/communications';
import moment from 'moment';

const columns1 = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    render: (_, obj) => `${obj.first_name || ''} ${obj.last_name || ''}`.trim(),
  },
  {
    title: 'Celular',
    dataIndex: 'to',
    key: 'to',
    render: (v) => (v ?? '-sin celular-').replace('whatsapp:', ''),
  },
  {
    title: 'Fecha envío',
    dataIndex: 'sent_date',
    key: 'sent_date',
    render: (v) => moment(v).format('DD/MM/YYYY hh:mm a'),
  },
];

const columns2 = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    render: (_, obj) => `${obj.first_name || ''} ${obj.last_name || ''}`.trim(),
  },
  {
    title: 'Celular',
    dataIndex: 'to',
    key: 'to',
    render: (v) => (v ?? '-sin celular-').replace('whatsapp:', ''),
  },
  {
    title: 'Error',
    dataIndex: 'error',
    key: 'error',
  },
];

class StatisticsModal extends PureComponent {
  state = {
    deliveries: [],
    loadingDeliveries: false,
  };

  onOk = () => {
    this.props.onOk();
  };

  loadDeliveries = async (communicationId, messageId) => {
    try {
      if (!messageId) return;
      this.setState({ loadingDeliveries: true });
      let deliveries = await CommunicationsService.listDeliveries(
        communicationId,
        messageId
      );

      this.setState({ deliveries });
    } catch (ex) {
      notification.error({
        message: 'Ocurrió un error al consultar los envíos',
        description: ex.message,
      });
    }
    this.setState({ loadingDeliveries: false });
  };

  async componentWillReceiveProps(nextProps) {
    const { communicationId, messageId } = nextProps;
    if (communicationId && messageId) {
      await this.loadDeliveries(communicationId, messageId);
    }
  }

  render() {
    const { visible, onCancel, confirmLoading } = this.props;
    const { loadingDeliveries, deliveries } = this.state;

    const sent = deliveries.filter((d) => d.status === 'delivered');
    const err = deliveries.filter((d) => d.status === 'error');
    // const others = deliveries.filter(
    //   (d) => d.status !== 'error' && d.status !== 'delivered'
    // );

    return (
      <Modal
        title='Estado de mensajes'
        visible={visible}
        onOk={this.onOk}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
        onCancel={onCancel}
        width='60vw'
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Tabs defaultActiveKey='1'>
          <Tabs.TabPane tab={`Enviados (${sent.length})`} key='1'>
            <Table
              dataSource={sent}
              columns={columns1}
              loading={loadingDeliveries}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={`Problemas (${err.length})`} key='2'>
            <Table
              dataSource={err}
              columns={columns2}
              loading={loadingDeliveries}
            />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab={`Pendientes (${others.length})`} key='3'>
            <Table
              dataSource={others}
              columns={columns1}
              loading={loadingDeliveries}
            />
          </Tabs.TabPane> */}
        </Tabs>
      </Modal>
    );
  }
}

export default StatisticsModal;
