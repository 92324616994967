import BaseService from './baseService';

export default class CertificationsService extends BaseService {
  static async list(query, limit, skip) {
    return await super.request(
      `/certifications?limit=${limit}&skip=${skip}&q=${query ? encodeURIComponent(query) : ''
      }`,
    );
  }

  static async create(name, duration_hours) {
    return await super.request('/certifications', 'POST', { name, duration_hours });
  }

  static async update(id, name, duration_hours) {
    return await super.request(`/certifications/${id}`, 'PUT', { name, duration_hours });
  }

  static async getUsers(id) {
    return await super.request(`/certifications/${id}/users`);
  }

  static async delete(ids) {
    return await super.request(
      `/certifications/${JSON.stringify(ids)}`,
      'DELETE',
    );
  }

  static async listForUser(userId) {
    return await super.request(`/certifications/user/${userId}`);
  }

  static async assign(userId, certId, valid_through, date_completed) {
    return await super.request(`/certifications/user/${userId}`, 'POST', {
      certification_id: certId,
      valid_through,
      date_completed,
    });
  }

  static async updateAssignment(userId, id, certId, valid_through, date_completed) {
    return await super.request(`/certifications/user/${userId}/${id}`, 'PUT', {
      certification_id: certId,
      valid_through,
      date_completed,
    });
  }

  static async unassign(userId, certId) {
    return await super.request(
      `/certifications/user/${userId}/${certId}`,
      'DELETE',
    );
  }

  static async addAttachments(userId, certId, files) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('attachments', file.originFileObj);
    }

    return await super.request(
      `/certifications/user/${userId}/${certId}/attachments`,
      'POST',
      formData,
      { 'Content-Type': 'multipart/form-data' },
      false,
    );
  }

  static async getAssignedSkills(certId) {
    return await super.request(
      `/certifications/${certId}/skills`
    );
  }

  static async assignSkills(certId, skills) {
    return await super.request(
      `/certifications/${certId}/skills/${JSON.stringify(skills)}`,
      'POST'
    );
  }
}
