import React, { PureComponent } from 'react';
import { Modal, List, Checkbox } from 'antd';
import client from '../core/services/groups';

class AddUserModal extends PureComponent {

  state = {
    users: [],
    selectedItems: [],
    error: null,
    fetching: false
  }

  async componentDidMount() {
    await this.queryUsers();
  }

  async componentWillReceiveProps(nextProps) {
    if(!this.props.visible && nextProps.visible) await this.queryUsers();
  }

  queryUsers = async() => {
    try {
      this.setState({ selectedItems: [], fetching: true });
      var resp = await client.listUsersAvailable(this.props.groupId); //TODO: Paginación
      this.setState({ users: resp, fetching: false });
    }
    catch(ex) {
      this.setState({ error: ex, fetching: false });
    }
  }

  toggleItem  = (item) => {
    var idx = this.state.selectedItems.indexOf(item.id);
    var newArr = [...this.state.selectedItems];
    if(idx >= 0) {
      newArr.splice(idx, 1);
    }
    else {
      newArr.push(item.id);
    }

    this.setState({ selectedItems: newArr });
  }

  onOk = () => {
    this.props.onOk(this.state.selectedItems);
  }

  render() {
    const { visible, onCancel, confirmLoading } = this.props;

    return <Modal title="Asociar usuarios"
      visible={visible}
      onOk={this.onOk}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText="Guardar"
      cancelText="Cancelar">
      <List
        bordered
        loading={this.state.fetching}
        locale={{ emptyText: this.state.error ? 'Ocurrió un error al cargar los usuarios' : 'No hay usuarios para asignar'}}
        dataSource={this.state.users}
        renderItem={item => (<List.Item><Checkbox checked={(this.state.selectedItems||[]).indexOf(item.id) >= 0} onChange={() => this.toggleItem(item)}>{item.full_name}</Checkbox></List.Item>)}
      />
    </Modal>
  }
}

export default AddUserModal;
