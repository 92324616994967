import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Icon, Button, Modal } from 'antd';

import { actions } from './groups.redux';
import './group-detail.page.css';
import AddCourseModal from './add-course.modal';
import AddPlanModal from './add-plan.modal';
import AddUserModal from './add-user.modal';

const confirm = Modal.confirm;

class GroupDetailPage extends PureComponent {
  state = {
    group: null,
    pagination: {},
    pagination_courses: {},
    pagination_plans: {},
    courses_columns: {},
    planModalVisible: false,
    courseModalVisible: false,
    userModalVisible: false
  };

  constructor(props) {
    super(props);

    this.users_columns = [
      {
        title: 'Nombres',
        dataIndex: 'full_name'
      }, 
      {
        title: 'Email',
        dataIndex: 'email'
      }, 
      {
        title: 'Area',
        dataIndex: 'area_name'
      }, 
      {
        title: 'Cargo',
        dataIndex: 'position_name'
      }
    ];

    this.courses_columns = [
      {
        title: 'Nombre',
        dataIndex: 'name'
      }
    ];

    this.plans_columns = [
      {
        title: 'Nombre',
        dataIndex: 'name'
      }
    ];

    if(this.canEdit()) {
      this.users_columns.push({
        title: '',
        width: 40,
        align: 'center',
        render: (val, record) => <Button shape="circle" title="Eliminar" icon="delete" onClick={() => this.openUserDelete(record)} />
      });

      this.courses_columns.push({
        title: '',
        width: 40,
        align: 'center',
        render: (val, record) => <Button shape="circle" title="Eliminar" icon="delete" onClick={() => this.openCourseDelete(record)} />
      });

      this.plans_columns.push({
        title: '',
        width: 40,
        align: 'center',
        render: (val, record) => <Button shape="circle" title="Eliminar" icon="delete" onClick={() => this.openPlanDelete(record)} />
      });
    }
  }

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  componentDidMount() {
    const groupId = this.props.match.params.id;
    const group = this.props.groups.find(g => g.id === parseInt(groupId, 10));
    this.setState({ group });
    this.props.layout.changeTitle(group.name);
    this.props.fetchUsers(groupId, 1);
    this.props.fetchCourses(groupId, 1);
    this.props.fetchPlans(groupId, 1);
  }

  openUserDelete = (user) => {
    confirm({
      title: '¿Está seguro que desea eliminar el usuario del grupo?',
      content: '',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      onOk: () => {
        return this.props.deleteUser(this.state.group.id, user.id);
      },
      onCancel: () => {
      },
    });
  }

  openCourseDelete = (course) => {
    confirm({
      title: '¿Está seguro que desea desasociar el curso del grupo?',
      content: '',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      onOk: () => {
        return this.props.deleteCourse(this.state.group.id, course.id);
      },
      onCancel: () => {
      },
    });
  }

  openPlanDelete = (plan) => {
    confirm({
      title: '¿Está seguro que desea desasociar el plan de formación del grupo?',
      content: '',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      onOk: () => {
        return this.props.deletePlan(this.state.group.id, plan.id);
      },
      onCancel: () => {
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.metadata_users) {
      this.setState({ pagination: { ...this.state.pagination, total: nextProps.metadata_users.total, pageSize: nextProps.metadata_users.limit } })
    }

    if(nextProps.metadata_courses) {
      this.setState({ pagination_courses: { ...this.state.pagination_courses, total: nextProps.metadata_courses.total, pageSize: nextProps.metadata_courses.limit } })
    }

    if(nextProps.metadata_plans) {
      this.setState({ pagination_plans: { ...this.state.pagination_plans, total: nextProps.metadata_plans.total, pageSize: nextProps.metadata_plans.limit } })
    }

    if(this.props.deleting_user && !nextProps.deleting_user) {
      this.props.fetchUsers(this.state.group.id, this.state.pagination.current||1);
    }

    if(this.props.deleting_plan && !nextProps.deleting_plan) {
      this.props.fetchPlans(this.state.group.id, this.state.pagination_plans.current||1);
    }

    if(this.props.deleting_course && !nextProps.deleting_course) {
      this.props.fetchCourses(this.state.group.id, this.state.pagination_courses.current||1);
    }

    if(this.props.saving && !nextProps.saving) {
      if(nextProps.error) {
        alert(nextProps.error);
      }
      else {
        if(this.state.planModalVisible) {
          this.hideAddPlan();
          this.props.fetchPlans(this.state.group.id, this.state.pagination_plans.current||1);
        }
        else if(this.state.courseModalVisible) {
          this.hideAddCourse();
          this.props.fetchCourses(this.state.group.id, this.state.pagination_courses.current||1);
        }
        else if(this.state.userModalVisible) {
          this.hideAddUser();
          this.props.fetchUsers(this.state.group.id, this.state.pagination.current||1);
        }
      }
    }
  }

  handleTableChange = async (pagination, filters, sorter) => {
    await this.setState({pagination});
    this.props.fetchUsers(this.state.group.id, pagination.current);
  }

  handleCoursesTableChange = async (pagination, filters, sorter) => {
    await this.setState({pagination_courses: pagination});
    this.props.fetchCourses(this.state.group.id, pagination.current);
  }

  handlePlansTableChange = async (pagination, filters, sorter) => {
    await this.setState({pagination_plans: pagination});
    this.props.fetchPlans(this.state.group.id, pagination.current);
  }

  openAddUser = () => {
    this.setState({ userModalVisible: true });
  }

  hideAddUser = () => {
    this.setState({ userModalVisible: false });
  }

  openAddPlan = () => {
    this.setState({ planModalVisible: true });
  }

  hideAddPlan = () => {
    this.setState({ planModalVisible: false });
  }

  openAddCourse = () => {
    this.setState({ courseModalVisible: true });
  }

  hideAddCourse = () => {
    this.setState({ courseModalVisible: false });
  }

  handleAddUsers = (users) => {
    this.props.addUsers(this.state.group.id, users);
  }

  handleAddCourses = (courses) => {
    this.props.addCourses(this.state.group.id, courses);
  }

  handleAddPlans = (plans) => {
    this.props.addPlans(this.state.group.id, plans);
  }

  render() {
    if(!this.state.group) return <div/>;
    return  (
    <div>
      <h2><Icon type="user"/> Usuarios</h2>
      <div className="content-internal">
        { this.canEdit() && <div className="top-buttons">
          <Button type="primary" icon="plus" onClick={this.openAddUser}>Asociar usuarios</Button>
        </div> }
        <Table {...this.state} loading={this.props.fetching_users} 
          onChange={this.handleTableChange} pagination={this.state.pagination} 
          rowKey={u => u.id} columns={this.users_columns} 
          dataSource={this.props.users} locale={{emptyText: 'El grupo no tiene usuarios en el momento'}}/>
      </div>
      <h2><Icon type="profile"/> Cursos</h2>
      <div className="content-internal">
        { this.canEdit() && <div className="top-buttons">
          <Button type="primary" icon="plus" onClick={this.openAddCourse}>Asociar cursos</Button>
        </div> }
        <Table {...this.state} loading={this.props.fetching_courses} 
          onChange={this.handleCoursesTableChange} pagination={this.state.pagination_courses} 
          rowKey={u => u.id} columns={this.courses_columns} 
          dataSource={this.props.courses} locale={{emptyText: 'El grupo no tiene cursos asignados en el momento'}}/>
      </div>
      <h2><Icon type="fork"/> Planes de formación</h2>
      <div className="content-internal">
        { this.canEdit() && <div className="top-buttons">
          <Button type="primary" icon="plus" onClick={this.openAddPlan}>Asociar planes</Button>
        </div> }
        <Table {...this.state} loading={this.props.fetching_plans} 
          onChange={this.handlePlansTableChange} pagination={this.state.pagination_plans} 
          rowKey={u => u.id} columns={this.plans_columns} 
          dataSource={this.props.plans} locale={{emptyText: 'El grupo no tiene planes de formación asignados en el momento'}}/>
      </div>
      <AddCourseModal visible={this.state.courseModalVisible} onCancel={this.hideAddCourse}
        confirmLoading={this.state.saving} onOk={this.handleAddCourses} groupId={this.state.group.id}/>
      <AddPlanModal visible={this.state.planModalVisible} onCancel={this.hideAddPlan}
        confirmLoading={this.state.saving} onOk={this.handleAddPlans} groupId={this.state.group.id}/>
      <AddUserModal visible={this.state.userModalVisible} onCancel={this.hideAddUser}
        confirmLoading={this.state.saving} onOk={this.handleAddUsers} groupId={this.state.group.id}/>
    </div>
    );
  }
}

function mapStateToProps(state) {
  return  { ...state.groups, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: (groupId, page) => dispatch(actions.fetchUsers(groupId, page)),
    fetchCourses: (groupId, page) => dispatch(actions.fetchCourses(groupId, page)),
    deleteUser: (groupId, userId) => dispatch(actions.deleteUser(groupId, userId)),
    deleteCourse: (groupId, courseId) => dispatch(actions.deleteCourse(groupId, courseId)),
    fetchPlans: (groupId, page) => dispatch(actions.fetchPlans(groupId, page)),
    deletePlan: (groupId, planId) => dispatch(actions.deletePlan(groupId, planId)),
    addPlans: (groupId, planIds) => dispatch(actions.addPlans(groupId, planIds)),
    addCourses: (groupId, courseIds) => dispatch(actions.addCourses(groupId, courseIds)),
    addUsers: (groupId, userIds) => dispatch(actions.addUsers(groupId, userIds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetailPage);