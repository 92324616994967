import { createActions, handleActions } from 'redux-actions';
import CommunicationsService from '../core/services/communications';

//INITIAL STATE
const initialState = {
  communications: [],
  fetching: false,
  error: null,
  metadata: null,
  saving: false,
  saving_error: null,
  messages: [],
  error_messages: false,
  fetching_messages: false,
  metadata_messages: null,
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

let actionCreators = createActions({
  COMMUNICATIONS: {
    FETCH: (page) => CommunicationsService.list(100, 100 * (page - 1)),
    CREATE: (name, areas, positions, locations, extended_field1_values) =>
      CommunicationsService.addCommunication(
        name,
        areas,
        positions,
        locations,
        extended_field1_values
      ),
    EDIT: (id, name, areas, positions, locations, extended_field1_values) =>
      CommunicationsService.updateCommunication(
        id,
        name,
        areas,
        positions,
        locations,
        extended_field1_values
      ),
    DELETE: (id) => CommunicationsService.removeCommunication(id),
    FETCHMESSAGES: (commId, page) =>
      CommunicationsService.listMessages(commId, 100, 100 * (page - 1)),
    CREATEMESSAGE: (commId, body, programmed_date, attachment) =>
      CommunicationsService.addMessage(
        commId,
        body,
        programmed_date,
        attachment
      ),
    UPDATEMESSAGE: (id, body, programmed_date, attachment) =>
      CommunicationsService.updateMessage(
        id,
        body,
        programmed_date,
        attachment
      ),
    DELETEMESSAGE: (id) => CommunicationsService.removeMessage(id),
  },
});

export const actions = actionCreators.communications;

//REDUCER
const reducer = handleActions(
  {
    [`${actions.fetch}_${pending}`]: (state) => {
      return { ...state, fetching: true, error: null };
    },
    [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
      return {
        ...state,
        communications: payload.results,
        metadata: payload.pagination,
        error: null,
        fetching: false,
      };
    },
    [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
      return { ...state, error: payload, fetching: false };
    },
    [`${actions.create}_${pending}`]: (state) => {
      return { ...state, saving: true, saving_error: null };
    },
    [`${actions.create}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, saving: false, saving_error: null };
    },
    [`${actions.create}_${rejected}`]: (state, { payload }) => {
      return { ...state, saving_error: payload, saving: false };
    },
    [`${actions.edit}_${pending}`]: (state) => {
      return { ...state, saving: true, saving_error: null };
    },
    [`${actions.edit}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, saving: false, saving_error: null };
    },
    [`${actions.edit}_${rejected}`]: (state, { payload }) => {
      return { ...state, saving_error: payload, saving: false };
    },
    [`${actions.delete}_${pending}`]: (state) => {
      return { ...state, saving: true, saving_error: null };
    },
    [`${actions.delete}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, saving: false, saving_error: null };
    },
    [`${actions.delete}_${rejected}`]: (state, { payload }) => {
      return { ...state, saving_error: payload, saving: false };
    },
    [`${actions.fetchmessages}_${pending}`]: (state) => {
      return { ...state, fetching_messages: true, error_messages: null };
    },
    [`${actions.fetchmessages}_${fulfilled}`]: (state, { payload }) => {
      return {
        ...state,
        messages: payload.results,
        metadata_messages: payload.pagination,
        error_messages: null,
        fetching_messages: false,
      };
    },
    [`${actions.fetchmessages}_${rejected}`]: (state, { payload }) => {
      return { ...state, error_messages: payload, fetching_messages: false };
    },
    [`${actions.createmessage}_${pending}`]: (state) => {
      return { ...state, saving: true, saving_error: null };
    },
    [`${actions.createmessage}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, saving: false, saving_error: null };
    },
    [`${actions.createmessage}_${rejected}`]: (state, { payload }) => {
      return { ...state, saving_error: payload, saving: false };
    },
    [`${actions.updatemessage}_${pending}`]: (state) => {
      return { ...state, saving: true, saving_error: null };
    },
    [`${actions.updatemessage}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, saving: false, saving_error: null };
    },
    [`${actions.updatemessage}_${rejected}`]: (state, { payload }) => {
      return { ...state, saving_error: payload, saving: false };
    },
    [`${actions.deletemessage}_${pending}`]: (state) => {
      return { ...state, saving: true, saving_error: null };
    },
    [`${actions.deletemessage}_${fulfilled}`]: (state, { payload }) => {
      return { ...state, saving: false, saving_error: null };
    },
    [`${actions.deletemessage}_${rejected}`]: (state, { payload }) => {
      return { ...state, saving_error: payload, saving: false };
    },
  },
  initialState
);

export default reducer;
