import { createActions, handleActions } from 'redux-actions';

import client from '../core/services/positions';

//INITIAL STATE
const initialState = { 
  positions: [],
  fetching: false,
  error: null,
  metadata: null,
  deleting: false,
  saving: false,
  saving_error: null,
  paths: [],
  fetching_paths: false,
  error_paths: null
};

//ACTIONS
const pending = 'PENDING';
const fulfilled = 'FULFILLED';
const rejected = 'REJECTED';

export const actions = createActions({
  fetch: (query, page) => client.list(query, 10, 10 * (page - 1)),
  create: (code, name, formationPathId) => client.create(code, name, formationPathId),
  update: (id, code, name, formationPathId) => client.update(id, code, name, formationPathId),
  delete: (ids) => client.delete(ids),
  paths: () => client.paths(),
  fetchUsers: (positionId, page) => client.listUsers(positionId, 10, 10 * (page - 1)),
  import: (data) => client.import(data),
  updatecosts: (id, costs) => client.updateCosts(id, costs),
}, { prefix: 'positions' });

//REDUCER
const reducer = handleActions({
  [`${actions.fetch}_${pending}`]: (state) => {
    return { ...state, fetching: true, error: null };
  },
  [`${actions.fetch}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, positions: payload.results, metadata: payload.pagination, error: null, fetching: false };
  },
  [`${actions.fetch}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, fetching: false };
  },
  [`${actions.create}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.create}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, saving: false };
  },
  [`${actions.create}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  },
  [`${actions.update}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.update}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, saving: false };
  },
  [`${actions.update}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  },
  [`${actions.delete}_${pending}`]: (state) => {
    return { ...state, deleting: true, error: null };
  },
  [`${actions.delete}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, deleting: false };
  },
  [`${actions.delete}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, deleting: false };
  },
  [`${actions.paths}_${pending}`]: (state) => {
    return { ...state, fetching_paths: true, error_paths: null };
  },
  [`${actions.paths}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, paths: payload.results, error_paths: null, fetching_paths: false };
  },
  [`${actions.paths}_${rejected}`]: (state, { payload }) => {
    return { ...state, error_paths : payload, fetching_paths: false };
  },
  [`${actions.import}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.import}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, saving: false, error: null };
  },
  [`${actions.import}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  },
  [`${actions.updatecosts}_${pending}`]: (state) => {
    return { ...state, saving: true, error: null };
  },
  [`${actions.updatecosts}_${fulfilled}`]: (state, { payload }) => {
    return { ...state, error: null, saving: false };
  },
  [`${actions.updatecosts}_${rejected}`]: (state, { payload }) => {
    return { ...state, error : payload, saving: false };
  }
}, initialState);

export default reducer;
