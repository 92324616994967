import BaseService from './baseService';

export default class ReportsService extends BaseService {
  static async getCustomFields() {
    return await super.request(`/reports/values/custom-fields`);
  }

  static async getCourses() {
    return await super.request(`/courses?limit=1000&skip=0`);
  }

  static async getAreas() {
    return await super.request(`/areas?limit=1000&skip=0`);
  }

  static async getLocations() {
    return await super.request(`/users/locations`);
  }

  static async getExtendedFieldValues(idx) {
    return await super.request(`/users/extended_field_values?index=${idx}`);
  }

  static async getModules(courseId) {
    return await super.request(`/reports/values/modules?course=${courseId}`);
  }

  static async getRoutesReport(filters) {
    const filtersQuery = Object.keys(filters).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/reports/routes?${filtersQuery}`);
  }

  static async getCoursesReport(filters) {
    const filtersQuery = Object.keys(filters).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/reports/courses?${filtersQuery}`);
  }

  static async getResponsesReport(filters) {
    const filtersQuery = Object.keys(filters).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/reports/responses?${filtersQuery}`);
  }

  static async getActivityReport(filters) {
    const filtersQuery = Object.keys(filters).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/reports/activity?${filtersQuery}`);
  }

  static async getModulesReport(filters) {
    const filtersQuery = Object.keys(filters).reduce((prev, curr) => `${prev}&${curr}=${filters[curr]}`, '');
    return await super.request(`/reports/modules?${filtersQuery}`);
  }
}