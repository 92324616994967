import React, { PureComponent } from 'react';
import { Modal, Form, Input } from 'antd';
import CoursesService from '../core/services/courses';
import AreasService from '../core/services/areas';
import DebounceSelect from '../main/components/debounceSelect';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

async function fetchCourses(query: string) {
  const result = await CoursesService.list(20, 0, query);
  return result.results.map((r) => ({
    label: r.name,
    value: r.id,
    key: r.id,
  }));
}

async function fetchAreas(query: string) {
  const result = await AreasService.list(query, 20, 0);
  return result.results.map((r) => ({
    label: r.name,
    value: r.id,
    key: r.id,
  }));
}

class EditOrganizationalUnitModal extends PureComponent {
  state = {
    filters: 0,
  };

  render() {
    const {
      visible,
      onCancel,
      onCreate,
      form,
      organizationalUnit,
      confirmLoading,
    } = this.props;
    const { getFieldDecorator } = form;
    if (!organizationalUnit) return <div />;

    return (
      <Modal
        title='Editar Unidad de negocio'
        visible={visible}
        onOk={onCreate}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        okText='Guardar'
        cancelText='Cancelar'
      >
        <Form>
          <FormItem {...formItemLayout} label='Nombre'>
            {getFieldDecorator('name', {
              initialValue: organizationalUnit.name,
              rules: [
                {
                  required: true,
                  message:
                    'Debes especificar el nombre de la unidad de negocio',
                },
              ],
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label='Cursos'>
            {getFieldDecorator('courses', {
              initialValue: organizationalUnit.courses.map((c) => ({
                ...c,
                key: c.value,
              })),
              rules: [],
            })(
              <DebounceSelect
                mode='multiple'
                placeholder='Todos los cursos'
                style={{ width: '100%' }}
                fetchOptions={fetchCourses}
              />
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Areas'>
            {getFieldDecorator('areas', {
              initialValue: organizationalUnit.areas.map((a) => ({
                ...a,
                key: a.value,
              })),
              rules: [],
            })(
              <DebounceSelect
                mode='multiple'
                placeholder='Todas las areas'
                style={{ width: '100%' }}
                fetchOptions={fetchAreas}
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(EditOrganizationalUnitModal);
