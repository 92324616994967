import React, { useState, useEffect } from 'react';
import DashboardService from '../core/services/dashboard';

import 'react-circular-progressbar/dist/styles.css';
import * as styles from './indicator.module.css';

const Cost = ({filters}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await DashboardService.cost(filters);
      setData({ value: res.total });
    }
    
    fetchData();
  }, [filters]);
  
  return <div className={styles.number}>
    {data ? `$ ${((data.value||0)/1000000).toLocaleString()}` : 'Cargando...'}
    </div>
};

export default Cost;