import React, { PureComponent } from 'react';
import { Modal, Form, Input } from 'antd';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class NewFormationPathModal extends PureComponent {

  onOk = () =>{
    const form = this.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      
      this.props.onOk(values.name);
      form.resetFields();
    });
  }

  render() {
    const { visible, onCancel, confirmLoading, form } = this.props;
    const { getFieldDecorator } = form;

    return <Modal title="Nuevo plan de formación"
      visible={visible}
      onOk={this.onOk}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      okText="Guardar"
      cancelText="Cancelar">
      <Form>
        <FormItem
          {...formItemLayout}
          label="Nombre">
          {getFieldDecorator('name', {
            rules: [{
              required: true, message: 'Debes especificar el nombre',
            }]
          })(
            <Input />
          )}
        </FormItem>
      </Form>
    </Modal>
  }
}

export default Form.create()(NewFormationPathModal);
