import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Dropdown, Menu, Icon, Modal } from 'antd';

import { actions } from './external-courses.redux';
import './external-course-detail.page.css';
import AddUserModal from './add-user.modal';
import EditUserModal from './edit-user.modal';
import DateDisplay from '../common/components/date';
import ImportUsersModal from './import-users.modal';
import service from '../core/services/externalCourses';

const confirm = Modal.confirm;

class ExternalCourseDetailPage extends PureComponent {
  state = {
    course: null,
    userModalVisible: false,
    editModalVisible: false,
    disableModalVisible: false,
    importModalVisible: false,
    selectedUser: null
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  }

  async componentDidMount() {
    this.usersTableCols = [
      {
        title: 'Nombres',
        dataIndex: 'full_name'
      }, 
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Desde',
        dataIndex: 'start_time',
        render: (val) => val ? <DateDisplay>{val}</DateDisplay> : ''
      },
      {
        title: 'Hasta',
        dataIndex: 'end_time',
        render: (val) => val ? <DateDisplay>{val}</DateDisplay> : ''
      },
      {
        title: 'Asistió',
        dataIndex: 'assisted',
        render: (val) => val ? 'Sí' : 'No'
      },
      {
        title: 'Aprobó',
        dataIndex: 'approved',
        render: (val) => val ? 'Sí' : 'No'
      },
      {
        title: 'Resultado',
        dataIndex: 'quantitative_result'
      }
    ];

    if(this.canEdit()) {
      this.usersTableCols.push({
        title: '',
        width: 40,
        render: (val, record) => <Dropdown overlay={(
          <Menu onClick={({item, key}) => this.handleTableAction(key, record)}>
            <Menu.Item key="edit">Editar</Menu.Item>
            <Menu.Item key="delete">Eliminar</Menu.Item>
          </Menu>
        )} trigger={['click']}>
        <Button type="link" className="tbl-action">
          <Icon type="ellipsis" />
        </Button>
      </Dropdown>
      });
    }

    const courseId = this.props.match.params.id;
    let course = this.props.courses.find(c => c.id === parseInt(courseId, 10));
    if(!course) {
      course = await service.getCourse(courseId);
    }
    this.props.getusers(courseId);
    this.setState({ course });
    this.props.layout.changeTitle(course.name);
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.saving && !nextProps.saving) {
      if(nextProps.error) {
        alert(nextProps.error);
      }
      else {
        if(this.state.userModalVisible) {
          this.hideAddUser();
        }
        else if(this.state.importModalVisible) {
          this.hideEditModal();
        }
        else if(this.state.disableModalVisible) {
          this.hideDisableUser();
        }
        else if(this.state.editModalVisible) {
          this.hideEditModal();
        }
        this.hideImportModal();
        this.props.getusers(this.state.course.id);
      }
    }
  }

  openAddUser = () => {
    this.setState({ userModalVisible: true });
  }

  hideAddUser = () => {
    this.setState({ userModalVisible: false });
  }

  handleAddUsers = (users) => {
    this.props.addusers(this.state.course.id, users);
  }

  openDisableUser = () => {
    this.setState({ disableModalVisible: true });
  }

  hideDisableUser = () => {
    this.setState({ disableModalVisible: false });
  }

  handleDisableUsers = (users) => {
    this.props.disableusers(this.state.course.id, users);
  }

  handleTableAction = async (action, record) => {
    console.warn(`Action ${action}`);

    switch(action) {
      case 'edit':
        this.openEdit(record);
        break;
      case 'delete':
        this.showDeleteConfirm(record);
        break;
      default:
        console.warn('unknown action');
    }
  }

  getCheckboxProps = (record) => {
    return {
      disabled: !record.enabled,
    }
  }

  downloadCSV = (data, fileName) => {
    var csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
    //IE11 & Edge
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvData, fileName);
    } else {
        //In FF link must be added to DOM to be clicked
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvData);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);    
        link.click();
        document.body.removeChild(link);    
    }
  }

  handleAction = async (action, record) => {
    switch(action) {
      case 'export':
        var data = await service.export(this.state.course.id);
        this.downloadCSV(data, `Usuarios Curso - ${this.state.course.name}.csv`);
        break;
      case 'import':
        this.openImport();
        break;
      default:
        console.warn('unknown action');
    }
  }

  render() {
    if(!this.state.course) return <div/>;

    return <div className="content-internal">
      <div style={{ textAlign: 'right'}}>
        <Button.Group>
          { this.canEdit() && <Button type="primary" onClick={this.openAddUser}>Asignar Usuarios</Button>}
          <Dropdown overlay={(
            <Menu onClick={({item, key}) => this.handleAction(key)}>
              { this.canEdit() &&<Menu.Item key="import"><Icon type="import" /> Importar usuarios</Menu.Item> }
              <Menu.Item key="export"><Icon type="export" /> Exportar</Menu.Item>
            </Menu>
          )}>
          <Button type="primary" icon="down"></Button>
        </Dropdown>
        </Button.Group>
      </div>
      <div>
        <h2>Usuarios asignados</h2>
        <Table {...this.state} loading={this.props.fetching} size="small" className="striped-rows"
        pagination={false} rowKey={r => r.id} columns={this.usersTableCols} 
        dataSource={this.props.assigned_users} locale={{ emptyText: 'No hay usuarios asignados en el momento' }} />
      </div>
      <AddUserModal visible={this.state.userModalVisible} onCancel={this.hideAddUser}
        confirmLoading={this.props.saving} onOk={this.handleAddUsers} courseId={this.state.course.id}/>
      <EditUserModal wrappedComponentRef={this.saveEditFormRef} visible={this.state.editModalVisible} onCancel={this.hideEditModal} user={this.state.selectedUser}
        confirmLoading={this.props.saving} onOk={this.handleEdit} courseId={this.state.course.id}/>
      <ImportUsersModal visible={this.state.importModalVisible} onCancel={this.hideImportModal} 
        confirmLoading={this.props.saving} onOk={this.handleImport} courseId={this.state.course.id}/>
    </div>
  }

  saveEditFormRef = (formRef) => {
    this.editFormRef = formRef;
  }

  openEdit = (user) => {
    this.setState({ editModalVisible: true, selectedUser: user });
  }

  handleEdit = (data) => {
    const form = this.editFormRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      
      this.props.edituser(this.state.selectedUser.id, this.state.course.id, values);
    });
  }

  hideEditModal = () => {
    this.setState({ editModalVisible: false, selectedUser: null  });
  }

  openImport = () => {
    this.setState({ importModalVisible: true });
  }

  handleImport = (data) => {
    this.hideImportModal();
    this.props.getusers(this.state.course.id);
  }

  hideImportModal = () => {
    this.setState({ importModalVisible: false });
  }

  showDeleteConfirm = (record) => {
    
    confirm({
      title: `¿Estás seguro que deseas eliminar el usuario seleccionado?`,
      content: '',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.deleteuser(this.state.course.id, record.id)
      }
    });
  }
}

function mapStateToProps(state) {
  return   { ...state.externalCourses, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {
    getusers: (courseId) => dispatch(actions.getusers(courseId)),
    addusers: (courseId, userIds) => dispatch(actions.assignusers(courseId, userIds)),
    disableusers: (courseId, userIds) => dispatch(actions.disableusers(courseId, userIds)),
    edituser: (userId, courseId, data) => dispatch(actions.edituser(userId, courseId, data)),
    import: (courseId, data) => dispatch(actions.import(courseId, data)),
    deleteuser: (courseId, userId) => dispatch(actions.deleteuser(courseId, userId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalCourseDetailPage);
